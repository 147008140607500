import React, { useEffect, useState } from 'react';
import { LoadingIndicatorContainer, Table, TableHead } from './styles';
import { HEADER_DATA } from './constants';
import { Props } from './types';
import StatusTrackerItem from './StatusTrackerItem';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, LoadingIndicator, theme } from '@policygenius/mortar';
import { DETAIL_ROUTE, REPORTS_ROUTE } from 'routes/constants';
import { Events, track } from 'utils/analytics';
import { useNavContext } from 'context/NavContext';
import StatusTrackerEmpty from './StatusTrackerEmpty';
import { LARGE_DESKTOP_WIDTH } from 'utils/helpers';
import StatusTrackerTimeout from './StatusTrackerTimeout';
import { StatusTrackerHeaderV2 } from './StatusTrackerHeader';

const StatusTrackerTable: React.FC<Props> = ({
  data,
  loading,
  indexOffset,
  hasProducerRole,
  isMultiTierAdmin,
  isReportsError,
  flipSort,
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const { setIsOpen } = useNavContext();

  const handleSelectedRow = (idx: number) => {
    const report = data![idx - indexOffset];

    track(Events.SELECTED_CASE, {
      referenceId: report.referenceId,
    });

    if (window.innerWidth < theme.breakpoints.large) {
      navigate(`${DETAIL_ROUTE}/${report.referenceId}`);

      return;
    }
    navigate(`${REPORTS_ROUTE}/${report.referenceId}`);

    setSelectedIndex(idx - indexOffset);

    if (window.innerWidth < LARGE_DESKTOP_WIDTH) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    setSelectedIndex(undefined);
    const selectedIdx = data?.findIndex((report) => report.referenceId === id);

    setSelectedIndex(selectedIdx);
  }, [data]);

  return (
    <>
      <Table>
        <TableHead>
          <StatusTrackerHeaderV2 flipSort={flipSort} isMultiTierAdmin={isMultiTierAdmin} />
        </TableHead>
        {loading && (
          <tbody data-testId="loader">
            <tr>
              <LoadingIndicatorContainer colSpan={Object.keys(HEADER_DATA).length}>
                <LoadingIndicator color="gray500" />
              </LoadingIndicatorContainer>
            </tr>
          </tbody>
        )}
        {!loading && (
          <tbody>
            {data?.map((report, index) => (
              <StatusTrackerItem
                report={report}
                listNumber={index + indexOffset}
                loading={loading}
                key={report.referenceId}
                selected={index === selectedIndex && !!id}
                handleDetail={handleSelectedRow}
                anySelected={!!id}
                isMultiTierAdmin={isMultiTierAdmin}
              />
            ))}
          </tbody>
        )}
      </Table>
      {!loading && !data?.length && !isReportsError && (
        <Col>
          <StatusTrackerEmpty hasProducerRole={hasProducerRole} />
        </Col>
      )}
      {isReportsError && (
        <Col>
          <StatusTrackerTimeout />
        </Col>
      )}
    </>
  );
};

export default StatusTrackerTable;
