import { UtilityText, theme, TitleText } from '@policygenius/mortar';
import styled from 'styled-components';
import { Gradient } from 'components/BackgroundGradient/styles';

export const Container = styled.div`
  display: flex;
  min-height: 100vh;
  justify-content: center;
  @media (min-width: ${theme.breakpoints.medium}px) {
    ${Gradient};
  }
`;

export const Background = styled.div`
  background-color: ${theme.colors.white};
  padding: 24px;

  @media (min-width: ${theme.breakpoints.medium}px) {
    padding: 0px;
    height: 412px;
    width: 552px;
    border-radius: 4px;
    margin-top: 94px;
  }
`;

export const Row = styled.div`
  button {
    width: 100%;
    margin-bottom: 32px;
  }

  @media (min-width: ${theme.breakpoints.medium}px) {
    margin: 40px;

    button {
      width: initial;
    }
  }
`;

export const Title = styled(TitleText)`
  margin-bottom: 24px;
`;

export const Terms = styled(UtilityText)`
  margin-bottom: 32px;
`;

export const UtilityLink = styled(UtilityText)`
  text-decoration: underline;
`;

export const LabelContainer = styled.div`
  margin-bottom: 28px;
`;
