import { Rules } from '@policygenius/dif-ts-types';
import { differenceInYears } from 'date-fns';
import { Beneficiary, ExistingPolicy } from './types';

const HAS_BRAIN_CONDITION_VALUE = 'question_3da55b24_65e0_4f5d_8aec_463f92be2f37.value';
const HAS_CARDIOVASCULAR_CONDITION_VALUE = 'question_4da55b24_65e0_4f5d_8aec_463f92be2f37.value';
const HAS_RESPIRATORY_CONDITION_VALUE = 'question_3da55b24_75e0_4f5d_8aec_463f92be2f37.value';
const HAS_DIGESTIVE_CONDITION_VALUE = 'question_4da55b24_65e0_4f5d_8aec_463f92be2g37.value';
const HAS_URINARY_REPRODUCTIVE_CONDITION_VALUE =
  'question_4da55b24_68e0_4f5d_8aec_463f92be2f37.value';
const HAS_ENDOCRINE_CONDITION_VALUE = 'question_4da55b24_68e0_4f5d_8aec_468f92be2f37.value';
const HAS_MUSCULOSKELETAL_CONDITION_VALUE = 'question_4dv55b24_68e0_4f5d_8aec_463f92be2f37.value';
const HAS_SENSORY_CONDITION_VALUE = 'question_4da55b24_68e0_4f5d_8aec_463f02be2f37.value';
const HAS_CANCER_ABNORMAL_GROWTH_CONDITION_VALUE =
  'question_4da55b24_68a0_4f5d_8aec_463f92be2f37.value';
const HAS_DEFORMITY_AMPUTATION_CONDITION_VALUE =
  'question_1da55b24_68a0_4f5d_8aec_463f92be2f37.value';
const HAS_MENTAL_HEALTH_CONDITION_VALUE = 'question_2da55b24_68e0_4f5d_8aec_463f92be2f37.value';
const HAS_IMMUNE_DEFICIENCY_CONDITION_VALUE = 'question_3da55b24_68e0_4f5d_8aec_463f92be2f37.value';
const HAS_DRUG_USE_CONDITION_VALUE = 'question_3da55b24_68e0_4f5d_8aee_463f92be2f37.value';
const HAS_DRUG_TREATMENT_CONDITION_VALUE = 'question_3da57b24_68e0_4f5d_8aee_463f92be2f37.value';
const HAS_DIZZINESS_SHORTNESS_OF_BREATH_CHEST_PAIN_FEVER_CONDITION_VALUE =
  'question_3da57b24_68e0_4f5d_8aee_462f92be2f37.value';

export const PRESENTATION_RULES_MAPPINGS: {
  [key: string]: { [key: string]: Rules };
} = {
  /* eslint-disable camelcase */
  // Insured Personal Details
  'urn:pg:interview:section:00ae5334-d2b1-4421-a707-ebcf22bd437b': {
    // State of birth
    question_4630b8ac_2958_4b19_9d42_3226700c98a8: {
      operation: '=',
      field: 'question_9bb0dc9c_d7a4_406b_9ad7_7dcfcf26a628.value',
      value: 'United States of America',
    },
    // Country of citizenship
    question_95930847_31ca_407e_94f6_073c341734a0: {
      operation: '!=',
      field: 'question_b21b7bc0_1db7_45a2_ada7_3d9730441ff9.value',
      value: 'Citizen',
    },
    // Date of entry to US
    question_ec83c898_9375_404c_9faf_d35e957581be: {
      operation: '!=',
      field: 'question_b21b7bc0_1db7_45a2_ada7_3d9730441ff9.value',
      value: 'Citizen',
    },
    // Expiration date
    question_5c703ffc_5590_44da_b7a0_cd5d4acf9329: {
      operation: '!=',
      field: 'question_b21b7bc0_1db7_45a2_ada7_3d9730441ff9.value',
      value: 'Citizen',
    },
    // Visa type
    question_33d10fdc_c49d_4f41_b76f_10fb296d56b3: {
      operation: '!=',
      field: 'question_b21b7bc0_1db7_45a2_ada7_3d9730441ff9.value',
      value: 'Citizen',
    },
    // Driver license state
    question_428d2542_3feb_4a7c_99b9_45585def2da7: {
      operation: '=',
      field: 'question_has_driver_license.value',
      value: true,
    },
    // Driver license number
    question_a06f6c7b_5dbb_4cf0_9828_ec8353c5a3e0: {
      operation: '=',
      field: 'question_has_driver_license.value',
      value: true,
    },
  },
  // Insured Employment Information
  'urn:pg:interview:section:ecd528c2-b719-44a8-a05e-b6f27e084d92': {
    question_1cf1d239_a21f_4cb8_977a_20348ced51b6: {
      operation: '=',
      field: 'question_insured_employment_type.value',
      value: 'employed',
    },
    question_97b4bbc8_f604_46af_9d8c_10e58310d929: {
      operation: '=',
      field: 'question_insured_employment_type.value',
      value: 'employed',
    },
  },
  // Insured Financial Information
  'urn:pg:interview:section:0b6ad521-daf9-4230-9738-1fe55800c834': {
    // Spouse income known
    question_spouse_income_known: {
      operation: '=',
      field: 'question_spouse_income_used.value',
      value: true,
    },
    // Spouse income
    question_spouse_income: {
      operation: '=',
      field: 'question_spouse_income_known.value',
      value: true,
    },
    // Spouse coverage amount known
    question_spouse_coverage_amount_known: {
      operation: '=',
      field: 'question_spouse_income_used.value',
      value: true,
    },
    // Spouse coverage amount
    question_spouse_coverage_amount: {
      operation: '=',
      field: 'question_spouse_coverage_amount_known.value',
      value: true,
    },
  },
  // Insured Application Details
  'urn:pg:interview:section:insured-application-details': {
    question_life_insured_owner_trust: {
      operation: '=',
      field: 'question_life_insured_policy_owner.value',
      value: false,
    },
    question_life_insured_business_name: {
      operation: 'OR',
      value: [
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'buy_sell',
        },
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'key_person',
        },
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'other_business',
        },
      ],
    },
    question_life_insured_business_street_1: {
      operation: 'OR',
      value: [
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'buy_sell',
        },
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'key_person',
        },
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'other_business',
        },
      ],
    },
    question_life_insured_business_street_2: {
      operation: 'OR',
      value: [
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'buy_sell',
        },
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'key_person',
        },
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'other_business',
        },
      ],
    },
    question_life_insured_business_city: {
      operation: 'OR',
      value: [
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'buy_sell',
        },
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'key_person',
        },
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'other_business',
        },
      ],
    },
    question_life_insured_business_state: {
      operation: 'OR',
      value: [
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'buy_sell',
        },
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'key_person',
        },
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'other_business',
        },
      ],
    },
    question_life_insured_business_zip: {
      operation: 'OR',
      value: [
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'buy_sell',
        },
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'key_person',
        },
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'other_business',
        },
      ],
    },
    question_life_insured_business_taxpayer_id: {
      operation: 'OR',
      value: [
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'buy_sell',
        },
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'key_person',
        },
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'other_business',
        },
      ],
    },
    question_life_insured_business_nature: {
      operation: 'OR',
      value: [
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'buy_sell',
        },
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'key_person',
        },
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'other_business',
        },
      ],
    },
    question_life_insured_business_entity_type: {
      operation: 'OR',
      value: [
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'buy_sell',
        },
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'key_person',
        },
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'other_business',
        },
      ],
    },
    question_life_insured_business_percentage_owned: {
      operation: 'OR',
      value: [
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'buy_sell',
        },
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'key_person',
        },
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'other_business',
        },
      ],
    },
    question_life_insured_business_net_annual_income: {
      operation: 'OR',
      value: [
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'buy_sell',
        },
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'key_person',
        },
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'other_business',
        },
      ],
    },
    question_life_insured_business_assets: {
      operation: 'OR',
      value: [
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'buy_sell',
        },
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'key_person',
        },
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'other_business',
        },
      ],
    },
    question_life_insured_business_liabilities: {
      operation: 'OR',
      value: [
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'buy_sell',
        },
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'key_person',
        },
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'other_business',
        },
      ],
    },
    question_life_insured_business_approximate_market_value: {
      operation: 'OR',
      value: [
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'buy_sell',
        },
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'key_person',
        },
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'other_business',
        },
      ],
    },
    question_life_insured_business_founded_at: {
      operation: 'OR',
      value: [
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'buy_sell',
        },
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'key_person',
        },
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'other_business',
        },
      ],
    },
    question_life_insured_business_key_person_coverage: {
      operation: 'OR',
      value: [
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'buy_sell',
        },
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'key_person',
        },
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'other_business',
        },
      ],
    },
    question_life_insured_business_bankruptcy_or_lien_in_last_5_years: {
      operation: 'OR',
      value: [
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'buy_sell',
        },
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'key_person',
        },
        {
          operation: '=',
          field: 'question_life_insured_coverage_reason.value',
          value: 'other_business',
        },
      ],
    },
    // Policy owner street 1
    question_dcd48c47_f3b5_4f53_804b_7a4bc718d599: {
      operation: 'AND',
      value: [
        {
          operation: '=',
          field: 'question_life_insured_policy_owner.value',
          value: false,
        },
        {
          operation: '=',
          field: 'question_life_insured_owner_trust.value',
          value: false,
        },
      ],
    },
    // Policy owner street 2
    question_7fb56a9f_10f4_451d_a527_065bcd085d50: {
      operation: 'AND',
      value: [
        {
          operation: '=',
          field: 'question_life_insured_policy_owner.value',
          value: false,
        },
        {
          operation: '=',
          field: 'question_life_insured_owner_trust.value',
          value: false,
        },
      ],
    },
    // Policy owner city
    question_23c31454_8aa0_415d_82a2_0b7a88f0c126: {
      operation: 'AND',
      value: [
        {
          operation: '=',
          field: 'question_life_insured_policy_owner.value',
          value: false,
        },
        {
          operation: '=',
          field: 'question_life_insured_owner_trust.value',
          value: false,
        },
      ],
    },
    // Policy owner state
    question_39459d28_cbad_429e_a7d4_2c1fac857f19: {
      operation: 'AND',
      value: [
        {
          operation: '=',
          field: 'question_life_insured_policy_owner.value',
          value: false,
        },
        {
          operation: '=',
          field: 'question_life_insured_owner_trust.value',
          value: false,
        },
      ],
    },
    // Policy owner zip code
    question_a039ae15_e89d_4399_9d46_5bec4f0b02e3: {
      operation: 'AND',
      value: [
        {
          operation: '=',
          field: 'question_life_insured_policy_owner.value',
          value: false,
        },
        {
          operation: '=',
          field: 'question_life_insured_owner_trust.value',
          value: false,
        },
      ],
    },
    // Policy owner date of birth
    question_6f8d2221_9909_4304_9771_3b2dd1d111df: {
      operation: 'AND',
      value: [
        {
          operation: '=',
          field: 'question_life_insured_policy_owner.value',
          value: false,
        },
        {
          operation: '=',
          field: 'question_life_insured_owner_trust.value',
          value: false,
        },
      ],
    },
    // Policy owner first name
    question_0b479249_524a_4500_9eca_726f25d12ee8: {
      operation: 'AND',
      value: [
        {
          operation: '=',
          field: 'question_life_insured_policy_owner.value',
          value: false,
        },
        {
          operation: '=',
          field: 'question_life_insured_owner_trust.value',
          value: false,
        },
      ],
    },
    // Policy owner last name
    question_6f34d998_5456_454b_907d_c66d57592de9: {
      operation: 'AND',
      value: [
        {
          operation: '=',
          field: 'question_life_insured_policy_owner.value',
          value: false,
        },
        {
          operation: '=',
          field: 'question_life_insured_owner_trust.value',
          value: false,
        },
      ],
    },
    // Policy owner taxpayer ID or SSN
    question_aac17642_7738_48c4_b7e5_ffcf3abc50e6: {
      operation: 'AND',
      value: [
        {
          operation: '=',
          field: 'question_life_insured_policy_owner.value',
          value: false,
        },
        {
          operation: '=',
          field: 'question_life_insured_owner_trust.value',
          value: false,
        },
      ],
    },
    // Relationship to policy owner
    question_cf3b3cd7_75de_49b4_9212_ca8f8d21289d: {
      operation: 'AND',
      value: [
        {
          operation: '=',
          field: 'question_life_insured_policy_owner.value',
          value: false,
        },
        {
          operation: '=',
          field: 'question_life_insured_owner_trust.value',
          value: false,
        },
      ],
    },
    // Policy owner email address
    question_446dea91_c8d7_4a29_8737_ea3a458719f8: {
      operation: 'AND',
      value: [
        {
          operation: '=',
          field: 'question_life_insured_policy_owner.value',
          value: false,
        },
        {
          operation: '=',
          field: 'question_life_insured_owner_trust.value',
          value: false,
        },
      ],
    },
    // Policy owner phone number
    question_b45b94e5_2929_46dd_8f52_b8bf2d08167b: {
      operation: 'AND',
      value: [
        {
          operation: '=',
          field: 'question_life_insured_policy_owner.value',
          value: false,
        },
        {
          operation: '=',
          field: 'question_life_insured_owner_trust.value',
          value: false,
        },
      ],
    },
    // Trust street 1
    question_3d1a54bc_1175_4cfc_802d_dfa376a15efd: {
      operation: 'AND',
      value: [
        {
          operation: '=',
          field: 'question_life_insured_policy_owner.value',
          value: false,
        },
        {
          operation: '=',
          field: 'question_life_insured_owner_trust.value',
          value: true,
        },
      ],
    },
    // Trust street 2
    question_ee2c489c_5cab_421f_b3be_ea15955ff17f: {
      operation: 'AND',
      value: [
        {
          operation: '=',
          field: 'question_life_insured_policy_owner.value',
          value: false,
        },
        {
          operation: '=',
          field: 'question_life_insured_owner_trust.value',
          value: true,
        },
      ],
    },
    // Trust city
    question_b35d12f3_5066_4157_87ce_7c972f9d6491: {
      operation: 'AND',
      value: [
        {
          operation: '=',
          field: 'question_life_insured_policy_owner.value',
          value: false,
        },
        {
          operation: '=',
          field: 'question_life_insured_owner_trust.value',
          value: true,
        },
      ],
    },
    // Trust state
    question_614eab7b_69f4_4b16_83c1_2b563410cd0f: {
      operation: 'AND',
      value: [
        {
          operation: '=',
          field: 'question_life_insured_policy_owner.value',
          value: false,
        },
        {
          operation: '=',
          field: 'question_life_insured_owner_trust.value',
          value: true,
        },
      ],
    },
    // Trust zip code
    question_1d30289f_dd43_4482_b094_73bd4b4fee34: {
      operation: 'AND',
      value: [
        {
          operation: '=',
          field: 'question_life_insured_policy_owner.value',
          value: false,
        },
        {
          operation: '=',
          field: 'question_life_insured_owner_trust.value',
          value: true,
        },
      ],
    },
    // Trust date of creation
    question_549a0e99_5c6a_4405_ab72_c29223389e42: {
      operation: 'AND',
      value: [
        {
          operation: '=',
          field: 'question_life_insured_policy_owner.value',
          value: false,
        },
        {
          operation: '=',
          field: 'question_life_insured_owner_trust.value',
          value: true,
        },
      ],
    },
    // Trust name
    question_9422e54a_8469_48a2_bacb_c865a223651d: {
      operation: 'AND',
      value: [
        {
          operation: '=',
          field: 'question_life_insured_policy_owner.value',
          value: false,
        },
        {
          operation: '=',
          field: 'question_life_insured_owner_trust.value',
          value: true,
        },
      ],
    },
    // Trust taxpayer ID number
    question_e11ab731_674a_40a5_8518_472db8fab089: {
      operation: 'AND',
      value: [
        {
          operation: '=',
          field: 'question_life_insured_policy_owner.value',
          value: false,
        },
        {
          operation: '=',
          field: 'question_life_insured_owner_trust.value',
          value: true,
        },
      ],
    },
    // Relationship of signer to insured
    question_c47b5a33_65fb_479b_864c_96052b68e587: {
      operation: 'AND',
      value: [
        {
          operation: '=',
          field: 'question_life_insured_policy_owner.value',
          value: false,
        },
        {
          operation: '=',
          field: 'question_life_insured_owner_trust.value',
          value: true,
        },
      ],
    },
    // Email address of trustee
    question_a9c53b18_54be_4e56_a30f_762ec3aec785: {
      operation: 'AND',
      value: [
        {
          operation: '=',
          field: 'question_life_insured_policy_owner.value',
          value: false,
        },
        {
          operation: '=',
          field: 'question_life_insured_owner_trust.value',
          value: true,
        },
      ],
    },
    // Policy premium payer name
    question_policy_premium_payer_name: {
      operation: '=',
      field: 'question_policy_insured_premium_payer.value',
      value: false,
    },
    // Policy premium payer address street 1
    question_policy_premium_payer_address_street_1: {
      operation: '=',
      field: 'question_policy_insured_premium_payer.value',
      value: false,
    },
    // Policy premium payer address street 2
    question_policy_premium_payer_address_street_2: {
      operation: '=',
      field: 'question_policy_insured_premium_payer.value',
      value: false,
    },
    // Policy premium payer address city
    question_policy_premium_payer_address_city: {
      operation: '=',
      field: 'question_policy_insured_premium_payer.value',
      value: false,
    },
    // Policy premium payer address state
    question_policy_premium_payer_address_state: {
      operation: '=',
      field: 'question_policy_insured_premium_payer.value',
      value: false,
    },
    // Policy premium payer address zip
    question_policy_premium_payer_address_zip: {
      operation: '=',
      field: 'question_policy_insured_premium_payer.value',
      value: false,
    },
    // Policy premium payer email
    question_policy_premium_payer_email: {
      operation: '=',
      field: 'question_policy_insured_premium_payer.value',
      value: false,
    },
    // Policy premium payer phone number
    question_policy_premium_payer_phone_number: {
      operation: '=',
      field: 'question_policy_insured_premium_payer.value',
      value: false,
    },
    // Policy premium payer insured relationship
    question_policy_premium_payer_insured_relationship: {
      operation: '=',
      field: 'question_policy_insured_premium_payer.value',
      value: false,
    },
    // Policy premium payer date of birth
    question_policy_premium_payer_date_of_birth: {
      operation: '=',
      field: 'question_policy_insured_premium_payer.value',
      value: false,
    },
    // Policy premium payer gender
    question_policy_premium_payer_gender: {
      operation: '=',
      field: 'question_policy_insured_premium_payer.value',
      value: false,
    },
    // Policy premium payer citizenship status
    question_policy_premium_payer_citizenship_status: {
      operation: '=',
      field: 'question_policy_insured_premium_payer.value',
      value: false,
    },
    // Policy premium payer social security number
    question_policy_premium_payer_social_security_number: {
      operation: '=',
      field: 'question_policy_insured_premium_payer.value',
      value: false,
    },
    // Policy premium payer driver license issue state
    question_policy_premium_payer_driver_license_issue_state: {
      operation: '=',
      field: 'question_policy_insured_premium_payer.value',
      value: false,
    },
    // Policy premium payer driver-license-number
    question_policy_premium_payer_driver_license_number: {
      operation: '=',
      field: 'question_policy_insured_premium_payer.value',
      value: false,
    },
    // Existing policies
    section_existing_policies: {
      operation: '=',
      field: 'question_existing_insurance.value',
      value: true,
    },
    // Bank account holder name
    question_insured_bank_account_holder_name: {
      operation: '=',
      field: 'question_policy_applied_temporary_coverage.value',
      value: true,
    },
    // Bank name
    question_insured_bank_name: {
      operation: '=',
      field: 'question_policy_applied_temporary_coverage.value',
      value: true,
    },
    // Bank routing number
    question_insured_bank_routing_number: {
      operation: '=',
      field: 'question_policy_applied_temporary_coverage.value',
      value: true,
    },
    // Bank account number
    question_insured_bank_account_number: {
      operation: '=',
      field: 'question_policy_applied_temporary_coverage.value',
      value: true,
    },
    // Payment account type
    question_insured_payment_account_type: {
      operation: '=',
      field: 'question_policy_applied_temporary_coverage.value',
      value: true,
    },
  },
  // Other Conditions
  'urn:pg:interview:section:7946ae41-1e16-43a0-bf15-969cb6b7e374': {
    // Other medication information
    question_ee23fc96_f7bc_4f50_ada1_b6d96c4760ce: {
      operation: '=',
      field: 'question_a1abbfc6_d33f_4085_8ce7_3d4f307e727a.value',
      value: true,
    },
    // Active work history details
    question_active_work_history_details: {
      operation: '=',
      field: 'question_39489375_b1dd_491c_aa4e_080a017e5ab4.value',
      value: false,
    },
  },
  // Client Source
  'urn:pg:interview:section:client-source': {
    // Source category description
    question_exit_survey_source_category_description: {
      operation: '=',
      field: 'question_exit_survey_source_category.value',
      value: 'other',
    },
    // Referring advisor email
    question_pg_pro_referring_agent_email: {
      operation: '=',
      field: 'question_pg_pro_referral.value',
      value: true,
    },
  },
  // Riders
  'urn:pg:interview:section:riders': {
    // Rider details
    question_rider_details: {
      operation: '=',
      field: 'question_riders.value',
      value: true,
    },
  },
  // Section - Health Conditions
  'urn:pg:interview:section:62c4ba88-aa14-4cfa-8c91-b2d36e7f24ce': {
    // Seizures
    question_86c8500b_89a6_4d0d_b9a0_de89fcd35cc6: {
      operation: '=',
      field: HAS_BRAIN_CONDITION_VALUE,
      value: true,
    },
    // Fainting
    question_a52cb8ac_e528_4024_8498_473c5e3240bf: {
      operation: '=',
      field: HAS_BRAIN_CONDITION_VALUE,
      value: true,
    },
    // Stroke
    question_a963bd86_ced5_4f5e_9b92_9c9c18d69070: {
      operation: '=',
      field: HAS_BRAIN_CONDITION_VALUE,
      value: true,
    },
    // Loss of consciousness
    question_58d55125_3998_4039_ab43_f21624931672: {
      operation: '=',
      field: HAS_BRAIN_CONDITION_VALUE,
      value: true,
    },
    // Tremor
    question_d354b517_5f31_4ce5_b469_13db2883ed1f: {
      operation: '=',
      field: HAS_BRAIN_CONDITION_VALUE,
      value: true,
    },
    // Paralysis
    question_6a5dac8e_635f_41eb_986e_ceb2cb01c888: {
      operation: '=',
      field: HAS_BRAIN_CONDITION_VALUE,
      value: true,
    },
    // Multiple sclerosis
    question_b541b19f_5b3e_4230_bda4_67de60107516: {
      operation: '=',
      field: HAS_BRAIN_CONDITION_VALUE,
      value: true,
    },
    // Epilepsy
    question_d3c5119d_2aae_4171_9240_1dca30cad0f6: {
      operation: '=',
      field: HAS_BRAIN_CONDITION_VALUE,
      value: true,
    },
    // Any disease or abnormality of the brain
    question_091cf594_e648_46dc_a9a3_1f15fc8e1ce1: {
      operation: '=',
      field: HAS_BRAIN_CONDITION_VALUE,
      value: true,
    },
    // High blood pressure
    question_137ec849_c219_45e7_888c_455c288ba4be: {
      operation: '=',
      field: HAS_CARDIOVASCULAR_CONDITION_VALUE,
      value: true,
    },
    // Heart attack
    question_e477b117_8515_46de_8fcc_86b3e47d08b9: {
      operation: '=',
      field: HAS_CARDIOVASCULAR_CONDITION_VALUE,
      value: true,
    },
    // Murmur
    question_3695bfea_9d81_44d2_b7b3_814f24623e5f: {
      operation: '=',
      field: HAS_CARDIOVASCULAR_CONDITION_VALUE,
      value: true,
    },
    // Palpitation
    question_c681b4a8_38d4_4859_ae76_cd86bdf7077b: {
      operation: '=',
      field: HAS_CARDIOVASCULAR_CONDITION_VALUE,
      value: true,
    },
    // Anemia
    question_06110b04_d985_4956_b1f1_09eb0d0c6952: {
      operation: '=',
      field: HAS_CARDIOVASCULAR_CONDITION_VALUE,
      value: true,
    },
    // Any disease or abnormality of the heart
    question_b00d293d_7ba2_4725_a542_c6547b7ec294: {
      operation: '=',
      field: HAS_CARDIOVASCULAR_CONDITION_VALUE,
      value: true,
    },
    // Any disease of the blood vessels or blood
    question_b1a88d35_7085_4a59_a47f_414201b0d31b: {
      operation: '=',
      field: HAS_CARDIOVASCULAR_CONDITION_VALUE,
      value: true,
    },
    // Asthma
    question_b5eb6b5d_fd78_45a6_adcb_79dda056fd1a: {
      operation: '=',
      field: HAS_RESPIRATORY_CONDITION_VALUE,
      value: true,
    },
    // Chronic bronchitis
    question_48035a9c_20a2_4319_b213_d27641278cf7: {
      operation: '=',
      field: HAS_RESPIRATORY_CONDITION_VALUE,
      value: true,
    },
    // Pneumonia
    question_826676e4_f6f5_4973_99a3_70671fa80d9d: {
      operation: '=',
      field: HAS_RESPIRATORY_CONDITION_VALUE,
      value: true,
    },
    // Emphysema
    question_0ab46220_fd17_4a0d_9f5b_5a2173cabfa6: {
      operation: '=',
      field: HAS_RESPIRATORY_CONDITION_VALUE,
      value: true,
    },
    // Tuberculosis
    question_6debc267_019c_49cf_8b5e_4e8e066798fe: {
      operation: '=',
      field: HAS_RESPIRATORY_CONDITION_VALUE,
      value: true,
    },
    // Any disease or abnormality of the lungs
    question_6f2099e9_0cac_4e9f_881c_f3fc5520ecc6: {
      operation: '=',
      field: HAS_RESPIRATORY_CONDITION_VALUE,
      value: true,
    },
    // Any disease or abnormality of the bronchial tubes
    question_62113675_8f11_495e_b098_7f4b1f2ff1da: {
      operation: '=',
      field: HAS_RESPIRATORY_CONDITION_VALUE,
      value: true,
    },
    // Any disease or abnormality of the respiratory system
    question_48a055d6_f243_425f_945b_dc9b078ca24b: {
      operation: '=',
      field: HAS_RESPIRATORY_CONDITION_VALUE,
      value: true,
    },
    // Ulcer
    question_8e2e2076_9bb2_4c47_ae9b_131b2d3ffa65: {
      operation: '=',
      field: HAS_DIGESTIVE_CONDITION_VALUE,
      value: true,
    },
    // Colitis
    question_1c3d0dee_924e_491d_a52d_339e7fc79645: {
      operation: '=',
      field: HAS_DIGESTIVE_CONDITION_VALUE,
      value: true,
    },
    // Hepatitis
    question_efab8f93_be1d_496b_8f0d_a9c8b6c4f7b7: {
      operation: '=',
      field: HAS_DIGESTIVE_CONDITION_VALUE,
      value: true,
    },
    // Cirrhosis
    question_19d0d95b_8b45_4c95_8abd_789e503e87c6: {
      operation: '=',
      field: HAS_DIGESTIVE_CONDITION_VALUE,
      value: true,
    },
    // Any disease or abnormality of the esophagus
    question_7463b3f7_5d53_437f_bbc1_b4021057fe1a: {
      operation: '=',
      field: HAS_DIGESTIVE_CONDITION_VALUE,
      value: true,
    },
    // Any disease or abnormality of the stomach
    question_44d7c88a_6bc4_4cf4_a204_ca0c581dac8d: {
      operation: '=',
      field: HAS_DIGESTIVE_CONDITION_VALUE,
      value: true,
    },
    // Any disease or abnormality of the intestines
    question_b47f70ca_4b36_4024_ac79_fdcc410f2a78: {
      operation: '=',
      field: HAS_DIGESTIVE_CONDITION_VALUE,
      value: true,
    },
    // Any disease or abnormality of the rectum
    question_c1c8333e_d1fb_4b25_a43f_61d03c66c6c5: {
      operation: '=',
      field: HAS_DIGESTIVE_CONDITION_VALUE,
      value: true,
    },
    // Any disease or abnormality of the gallbladder
    question_d3297f38_a7b1_4d10_a5cf_7c71009ce347: {
      operation: '=',
      field: HAS_DIGESTIVE_CONDITION_VALUE,
      value: true,
    },
    // Any disease or abnormality of the liver
    question_e0f2d696_1ab8_4e2b_a214_832a248a4e82: {
      operation: '=',
      field: HAS_DIGESTIVE_CONDITION_VALUE,
      value: true,
    },
    // Sugar protein or blood in urine
    question_a85e3a46_b4a6_4e3e_a54d_48af761e20e1: {
      operation: '=',
      field: HAS_URINARY_REPRODUCTIVE_CONDITION_VALUE,
      value: true,
    },
    // Sexually transmitted disease
    question_7cadeb14_cdcc_44da_9615_7905d8e8e092: {
      operation: '=',
      field: HAS_URINARY_REPRODUCTIVE_CONDITION_VALUE,
      value: true,
    },
    // Stone or any disease or abnormality of the kidney
    question_1f610016_10d1_462e_8d7e_c67d14b98e44: {
      operation: '=',
      field: HAS_URINARY_REPRODUCTIVE_CONDITION_VALUE,
      value: true,
    },
    // Bladder
    question_c0cd6dcb_2fae_46d7_a50f_3ddbb0f79f38: {
      operation: '=',
      field: HAS_URINARY_REPRODUCTIVE_CONDITION_VALUE,
      value: true,
    },
    // Prostate
    question_3f79c233_ce47_4123_950c_6dd05b680cf3: {
      operation: '=',
      field: HAS_URINARY_REPRODUCTIVE_CONDITION_VALUE,
      value: true,
    },
    // Breast
    question_7dc84bf9_0685_4823_8d4b_43bfdbd4e003: {
      operation: '=',
      field: HAS_URINARY_REPRODUCTIVE_CONDITION_VALUE,
      value: true,
    },
    // Ovaries
    question_7dc626d3_d40a_4761_8003_cc24b0923468: {
      operation: '=',
      field: HAS_URINARY_REPRODUCTIVE_CONDITION_VALUE,
      value: true,
    },
    // Other reproductive system
    question_2fda6959_7e83_4a40_8271_8d4174fa51cc: {
      operation: '=',
      field: HAS_URINARY_REPRODUCTIVE_CONDITION_VALUE,
      value: true,
    },
    // Diabetes
    question_faf2c914_508e_425b_bf3f_fe84fc65d4bb: {
      operation: '=',
      field: HAS_ENDOCRINE_CONDITION_VALUE,
      value: true,
    },
    // Any disease or abnormality of the thyroid
    question_068936fe_53b5_48b2_b732_cef9cc7d27b4: {
      operation: '=',
      field: HAS_ENDOCRINE_CONDITION_VALUE,
      value: true,
    },
    // Adrenal
    question_845b8109_85f4_41f3_9966_1fa36ba10f3a: {
      operation: '=',
      field: HAS_ENDOCRINE_CONDITION_VALUE,
      value: true,
    },
    // Pituitary
    question_7743cd88_89cd_461e_9fd1_2812e6cbb337: {
      operation: '=',
      field: HAS_ENDOCRINE_CONDITION_VALUE,
      value: true,
    },
    // Other glands
    question_6187cac1_e375_478f_b447_9b6d0a55901c: {
      operation: '=',
      field: HAS_ENDOCRINE_CONDITION_VALUE,
      value: true,
    },
    // Arthritis
    question_219b18cb_6456_4a5b_b5ff_71bf04549ea8: {
      operation: '=',
      field: HAS_MUSCULOSKELETAL_CONDITION_VALUE,
      value: true,
    },
    // Gout
    question_2e61ac84_9169_4d0b_9494_b141f25b331f: {
      operation: '=',
      field: HAS_MUSCULOSKELETAL_CONDITION_VALUE,
      value: true,
    },
    // Connective tissue disease
    question_56d73b0e_ba93_444b_a442_27623297d717: {
      operation: '=',
      field: HAS_MUSCULOSKELETAL_CONDITION_VALUE,
      value: true,
    },
    // Back trouble
    question_68ab663a_a894_4a41_b135_e21a728ce6c4: {
      operation: '=',
      field: HAS_MUSCULOSKELETAL_CONDITION_VALUE,
      value: true,
    },
    // Any disease or abnormality of the joints
    question_eec04c5a_b383_4ecd_b267_37655d6b89df: {
      operation: '=',
      field: HAS_MUSCULOSKELETAL_CONDITION_VALUE,
      value: true,
    },
    // Muscles
    question_322ee701_2aaf_4021_bd8d_97bffe85643e: {
      operation: '=',
      field: HAS_MUSCULOSKELETAL_CONDITION_VALUE,
      value: true,
    },
    // Bones
    question_b1a2786d_002d_4e71_88c8_e15a9c8ca2ca: {
      operation: '=',
      field: HAS_MUSCULOSKELETAL_CONDITION_VALUE,
      value: true,
    },
    // Eyes
    question_d53cd8af_976a_4a43_a3c7_18f109aa1db0: {
      operation: '=',
      field: HAS_SENSORY_CONDITION_VALUE,
      value: true,
    },
    // Ears
    question_0075e235_bfdd_4f41_aa72_b14e5a88dac5: {
      operation: '=',
      field: HAS_SENSORY_CONDITION_VALUE,
      value: true,
    },
    // Nose
    question_c4b171a4_8b6d_4a00_b45a_fa8ef7d3e69c: {
      operation: '=',
      field: HAS_SENSORY_CONDITION_VALUE,
      value: true,
    },
    // Throat
    question_3dfbc543_9c99_450f_bcab_db30f704d681: {
      operation: '=',
      field: HAS_SENSORY_CONDITION_VALUE,
      value: true,
    },
    // Skin
    question_4c697a0a_3d8b_48b7_8ff6_c46cd3ed68e5: {
      operation: '=',
      field: HAS_SENSORY_CONDITION_VALUE,
      value: true,
    },
    // Cancer
    question_b7e450cb_99dd_48fb_b71e_776b1f822ac8: {
      operation: '=',
      field: HAS_CANCER_ABNORMAL_GROWTH_CONDITION_VALUE,
      value: true,
    },
    // Tumor
    question_61a4e781_4aca_4963_ab5f_aa0fb91756ac: {
      operation: '=',
      field: HAS_CANCER_ABNORMAL_GROWTH_CONDITION_VALUE,
      value: true,
    },
    // Polyp
    question_886db577_a5db_4735_abea_336d630e571e: {
      operation: '=',
      field: HAS_CANCER_ABNORMAL_GROWTH_CONDITION_VALUE,
      value: true,
    },
    // Cyst
    question_4f5d9f43_4132_484d_accb_ec3cb50a2973: {
      operation: '=',
      field: HAS_CANCER_ABNORMAL_GROWTH_CONDITION_VALUE,
      value: true,
    },
    // Physical deformity
    question_8fe71800_9afb_49ed_9469_6a29864ac3e6: {
      operation: '=',
      field: HAS_DEFORMITY_AMPUTATION_CONDITION_VALUE,
      value: true,
    },
    // Amputation
    question_92469d05_985b_4fea_9fe5_47c12b31a5ca: {
      operation: '=',
      field: HAS_DEFORMITY_AMPUTATION_CONDITION_VALUE,
      value: true,
    },
    // Anxiety
    question_9e5b70c3_a2bf_4b2a_a4df_543989d03691: {
      operation: '=',
      field: HAS_MENTAL_HEALTH_CONDITION_VALUE,
      value: true,
    },
    // Depression
    question_d443430f_17b6_4b25_b929_84602c3ba7e5: {
      operation: '=',
      field: HAS_MENTAL_HEALTH_CONDITION_VALUE,
      value: true,
    },
    // Suicide attempt
    question_96f4830c_8226_4303_9a02_818fee547159: {
      operation: '=',
      field: HAS_MENTAL_HEALTH_CONDITION_VALUE,
      value: true,
    },
    // Psychiatric condition
    question_67170f05_3685_4349_be28_2722450ab636: {
      operation: '=',
      field: HAS_MENTAL_HEALTH_CONDITION_VALUE,
      value: true,
    },
    // Mental condition or disorder
    question_54facf61_43e7_4c8e_bda4_fda90e8c1aa6: {
      operation: '=',
      field: HAS_MENTAL_HEALTH_CONDITION_VALUE,
      value: true,
    },
    // Emotional condition or disorder
    question_ad175f7d_3632_47f0_9c60_80eb2d5c0aee: {
      operation: '=',
      field: HAS_MENTAL_HEALTH_CONDITION_VALUE,
      value: true,
    },
    // AIDS
    question_b877f0e0_0af9_4971_b0cb_7957c1f78a62: {
      operation: '=',
      field: HAS_IMMUNE_DEFICIENCY_CONDITION_VALUE,
      value: true,
    },
    // ARC
    question_a91855b7_4da6_46ce_92b1_76995e7278c3: {
      operation: '=',
      field: HAS_IMMUNE_DEFICIENCY_CONDITION_VALUE,
      value: true,
    },
    // HIV
    question_df3ce06a_138f_49ba_ad05_0dddc7c10b94: {
      operation: '=',
      field: HAS_IMMUNE_DEFICIENCY_CONDITION_VALUE,
      value: true,
    },
    // Any immune deficiency disorder
    question_ab132d9a_785d_4987_ab55_5d8bbc33482e: {
      operation: '=',
      field: HAS_IMMUNE_DEFICIENCY_CONDITION_VALUE,
      value: true,
    },
    // Tested positive on an AIDS/HIV-related test
    question_c0160fe8_6255_4f79_a07e_d621bcbdedf1: {
      operation: '=',
      field: HAS_IMMUNE_DEFICIENCY_CONDITION_VALUE,
      value: true,
    },
  },
  // Section - Risk Conditions
  'urn:pg:interview:section:02a3f0ea-7fdf-41b5-b7df-032ad54f1277': {
    // Sedatives
    question_d52d9197_a492_46a8_bb0e_f332923b1587: {
      operation: '=',
      field: HAS_DRUG_USE_CONDITION_VALUE,
      value: true,
    },
    // Amphetamines
    question_725e197a_42e7_43b8_aee3_3d43df34a7b3: {
      operation: '=',
      field: HAS_DRUG_USE_CONDITION_VALUE,
      value: true,
    },
    // Barbiturates
    question_33a933a6_6034_47a6_a72d_885b66cad8f3: {
      operation: '=',
      field: HAS_DRUG_USE_CONDITION_VALUE,
      value: true,
    },
    // Morphine
    question_56d82ea1_34b5_4781_aaae_40f5a867eda4: {
      operation: '=',
      field: HAS_DRUG_USE_CONDITION_VALUE,
      value: true,
    },
    // Cocaine
    question_431a4f8e_a23d_4496_b924_c19b1535d3b4: {
      operation: '=',
      field: HAS_DRUG_USE_CONDITION_VALUE,
      value: true,
    },
    // Crack
    question_3d5e5228_3cc1_4454_a4c6_bf0109001e5d: {
      operation: '=',
      field: HAS_DRUG_USE_CONDITION_VALUE,
      value: true,
    },
    // Methamphetamine
    question_9dfe6946_acff_472d_8fb2_89a0b9838477: {
      operation: '=',
      field: HAS_DRUG_USE_CONDITION_VALUE,
      value: true,
    },
    // Ecstacy (MDMA)
    question_a02f0cfb_d15c_4891_a529_8366a5fcc948: {
      operation: '=',
      field: HAS_DRUG_USE_CONDITION_VALUE,
      value: true,
    },
    // Heroin
    question_0d0ada4a_d037_41aa_a361_2761a509ab66: {
      operation: '=',
      field: HAS_DRUG_USE_CONDITION_VALUE,
      value: true,
    },
    // Marijuana
    question_b2cd588a_2e77_4b27_b076_642319f82adf: {
      operation: '=',
      field: HAS_DRUG_USE_CONDITION_VALUE,
      value: true,
    },
    // LSD
    question_2af75277_03cd_4d50_aef9_54678e80dcbb: {
      operation: '=',
      field: HAS_DRUG_USE_CONDITION_VALUE,
      value: true,
    },
    // PCP
    question_e58c7cbd_9028_44e7_b6bc_ac744c30bff0: {
      operation: '=',
      field: HAS_DRUG_USE_CONDITION_VALUE,
      value: true,
    },
    // Any hallucinogenic drug
    question_368850fd_629e_436c_bdd7_726dc2a299a4: {
      operation: '=',
      field: HAS_DRUG_USE_CONDITION_VALUE,
      value: true,
    },
    // Any narcotic drug
    question_8f21d576_2d49_4d8b_b237_fd98f8737f0c: {
      operation: '=',
      field: HAS_DRUG_USE_CONDITION_VALUE,
      value: true,
    },
    // Alcohol
    question_880ebd70_bc1f_4f72_96d1_e943263b8931: {
      operation: '=',
      field: HAS_DRUG_TREATMENT_CONDITION_VALUE,
      value: true,
    },
    // Drugs
    question_49e0b3a5_391a_4d00_a8bd_d5b826205725: {
      operation: '=',
      field: HAS_DRUG_TREATMENT_CONDITION_VALUE,
      value: true,
    },
    // Other substance
    question_e7dea963_c082_45f7_81a0_9f0dae22050e: {
      operation: '=',
      field: HAS_DRUG_TREATMENT_CONDITION_VALUE,
      value: true,
    },
    // Joined an organization for alcohol or drug dependence or abuse
    question_pi_joined_alcohol_or_drug_org: {
      operation: '=',
      field: HAS_DRUG_TREATMENT_CONDITION_VALUE,
      value: true,
    },
  },
  // Section - Recent Conditions
  'urn:pg:interview:section:ada39a54-de00-45ed-9220-773b47e278aa': {
    // Dizziness
    question_2601be2c_2daa_4a73_8dad_ab1cd8bb737b: {
      operation: '=',
      field: HAS_DIZZINESS_SHORTNESS_OF_BREATH_CHEST_PAIN_FEVER_CONDITION_VALUE,
      value: true,
    },
    // Shortness of breath
    question_b4e52aa1_2c2d_4b87_866b_a1abfe1fc363: {
      operation: '=',
      field: HAS_DIZZINESS_SHORTNESS_OF_BREATH_CHEST_PAIN_FEVER_CONDITION_VALUE,
      value: true,
    },
    // Pain or pressure in the chest
    question_54e7b94c_91e5_4de8_bc50_aebccde4238a: {
      operation: '=',
      field: HAS_DIZZINESS_SHORTNESS_OF_BREATH_CHEST_PAIN_FEVER_CONDITION_VALUE,
      value: true,
    },
    // Persistent fever
    question_df315a79_4689_46c4_9b6f_c84edfd66a6a: {
      operation: '=',
      field: HAS_DIZZINESS_SHORTNESS_OF_BREATH_CHEST_PAIN_FEVER_CONDITION_VALUE,
      value: true,
    },
  },
};
/* eslint-enable camelcase */

export const SECTION_RULES_MAPPINGS: {
  [key: string]: Rules;
} = {
  // INSURED PERSONAL DETAILS
  'urn:pg:interview:section:00ae5334-d2b1-4421-a707-ebcf22bd437b': {
    field: 'children.all.valid',
    operation: '=',
    value: true,
  },
  // INSURED RESIDENCE
  'urn:pg:interview:section:aea16971-de07-423d-b002-43b8f24aed3b': {
    field: 'children.all.valid',
    operation: '=',
    value: true,
  },
  // INSURED HOUSEHOLD INFORMATION
  'urn:pg:interview:section:insured-household-information': {
    field: 'children.all.valid',
    operation: '=',
    value: true,
  },
  // INSURED EMPLOYMENT INFORMATION
  'urn:pg:interview:section:ecd528c2-b719-44a8-a05e-b6f27e084d92': {
    field: 'children.all.valid',
    operation: '=',
    value: true,
  },
  // INSURED FINANCIAL INFORMATION
  'urn:pg:interview:section:0b6ad521-daf9-4230-9738-1fe55800c834': {
    field: 'children.all.valid',
    operation: '=',
    value: true,
  },
  // INSURED APPLICATION DETAILS
  'urn:pg:interview:section:insured-application-details': {
    field: 'children.all.valid',
    operation: '=',
    value: true,
  },
  // EXISTING POLICIES
  'urn:pg:interview:section:existing-policies': {
    field: 'children.all.valid',
    operation: '=',
    value: true,
  },
  // COMPLIANCE
  'urn:pg:interview:section:compliance': {
    field: 'children.all.valid',
    operation: '=',
    value: true,
  },
  // PRIMARY BENEFICIARY INFORMATION
  'urn:pg:interview:section:primary-beneficiaries': {
    field: 'children.all.valid',
    operation: '=',
    value: true,
  },
  // CONTINGENT BENEFICIARY INFORMATION
  'urn:pg:interview:section:contingent-beneficiaries': {
    field: 'children.all.valid',
    operation: '=',
    value: true,
  },
  // NON-MEDICAL HEALTH HISTORY
  'urn:pg:interview:section:non-medical-health-history': {
    field: 'children.all.valid',
    operation: '=',
    value: true,
  },
  // HEALTH CONDITIONS
  'urn:pg:interview:section:62c4ba88-aa14-4cfa-8c91-b2d36e7f24ce': {
    field: 'children.all.valid',
    operation: '=',
    value: true,
  },
  // PRIMARY CARE PHYSICIAN DETAILS
  'urn:pg:interview:section:primary-care-physician-details': {
    field: 'children.all.valid',
    operation: '=',
    value: true,
  },
  // RISK CONDITIONS
  'urn:pg:interview:section:02a3f0ea-7fdf-41b5-b7df-032ad54f1277': {
    field: 'children.all.valid',
    operation: '=',
    value: true,
  },
  // TOP LEVEL FAMILY HISTORY
  'urn:pg:interview:section:top-level-family-history': {
    field: 'children.all.valid',
    operation: '=',
    value: true,
  },
  // FAMILY HEALTH HISTORY
  'urn:pg:interview:section:family-member-health-histories': {
    field: 'children.all.valid',
    operation: '=',
    value: true,
  },
  // RECENT CONDITIONS
  'urn:pg:interview:section:ada39a54-de00-45ed-9220-773b47e278aa': {
    field: 'children.all.valid',
    operation: '=',
    value: true,
  },
  // CONDITION DETAILS
  'urn:pg:interview:section:condition-detailss': {
    field: 'children.all.valid',
    operation: '=',
    value: true,
  },
  // OTHER CONDITIONS
  'urn:pg:interview:section:7946ae41-1e16-43a0-bf15-969cb6b7e374': {
    field: 'children.all.valid',
    operation: '=',
    value: true,
  },
  // CLIENT SOURCE
  'urn:pg:interview:section:client-source': {
    field: 'children.all.valid',
    operation: '=',
    value: true,
  },
};

export const QUESTION_RULES_MAPPINGS: {
  [key: string]: Rules;
} = {
  // INSURED PERSONAL DETAILS
  // First name
  'urn:pg:interview:question:2b00ab35-cb05-44f3-8049-830231765cf0': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Last name
  'urn:pg:interview:question:9addd909-a81c-45f6-9bfb-5cafcf903e14': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Gender
  'urn:pg:interview:question:e2dede48-43b5-4103-8ad7-1d55a5657f12': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Date of birth
  'urn:pg:interview:question:e891bbc1-a584-497f-8257-266d15b017d1': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Country of birth
  'urn:pg:interview:question:9bb0dc9c-d7a4-406b-9ad7-7dcfcf26a628': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Residency status
  'urn:pg:interview:question:b21b7bc0-1db7-45a2-ada7-3d9730441ff9': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Social security number
  'urn:pg:interview:question:c23936e0-c10b-4163-9b1a-c12d8acf9c17': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // INSURED RESIDENCE
  // Street (line 1)
  'urn:pg:interview:question:da6baf4a-059d-44ad-8dea-e01357bb6cb4': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // City
  'urn:pg:interview:question:c7442f61-edbc-4036-93bb-a13418261dca': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // State
  'urn:pg:interview:question:4232ed9f-1896-47a1-892d-21b34c8e3469': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Zip code
  'urn:pg:interview:question:eb6736ea-72cb-47bd-8c9f-5870a723103c': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // INSURED HOUSEHOLD INFORMATION
  // Marital status
  'urn:pg:interview:question:insured-marital-status': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // INSURED EMPLOYMENT INFORMATION
  // Employment status
  'urn:pg:interview:question:insured-employment-type': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Employer name
  'urn:pg:interview:question:1cf1d239-a21f-4cb8-977a-20348ced51b6': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Occupation
  'urn:pg:interview:question:97b4bbc8-f604-46af-9d8c-10e58310d929': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // INSURED FINANCIAL INFORMATION
  // Assets
  'urn:pg:interview:question:9858f9dd-98cf-454b-9122-5f50e997ccd4': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Liabilities
  'urn:pg:interview:question:e1175a32-ae74-4ac4-89aa-a916adc3ce7a': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Earned income
  'urn:pg:interview:question:earned-income': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Unearned income
  'urn:pg:interview:question:unearned-income': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Spouse income used
  'urn:pg:interview:question:spouse-income-used': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  'urn:pg:interview:question:spouse-income-known': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  'urn:pg:interview:question:spouse-income': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  'urn:pg:interview:question:spouse-coverage-amount-known': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  'urn:pg:interview:question:spouse-coverage-amount': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // APPLICATION DETAILS
  // Policy owner
  'urn:pg:interview:question:life-insured-policy-owner': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Policy owner trust
  'urn:pg:interview:question:life-insured-owner-trust': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Policy owner street 1
  'urn:pg:interview:question:dcd48c47-f3b5-4f53-804b-7a4bc718d599': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Policy owner city
  'urn:pg:interview:question:23c31454-8aa0-415d-82a2-0b7a88f0c126': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Policy owner state
  'urn:pg:interview:question:39459d28-cbad-429e-a7d4-2c1fac857f19': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Policy owner zip code
  'urn:pg:interview:question:a039ae15-e89d-4399-9d46-5bec4f0b02e3': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Policy owner date of birth
  'urn:pg:interview:question:6f8d2221-9909-4304-9771-3b2dd1d111df': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Policy owner first name
  'urn:pg:interview:question:0b479249-524a-4500-9eca-726f25d12ee8': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Policy owner last name
  'urn:pg:interview:question:6f34d998-5456-454b-907d-c66d57592de9': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Policy owner ssn or tax id
  'urn:pg:interview:question:aac17642-7738-48c4-b7e5-ffcf3abc50e6': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Policy owner relationship
  'urn:pg:interview:question:cf3b3cd7-75de-49b4-9212-ca8f8d21289d': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Policy owner email
  'urn:pg:interview:question:446dea91-c8d7-4a29-8737-ea3a458719f8': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Policy owner phone number
  'urn:pg:interview:question:b45b94e5-2929-46dd-8f52-b8bf2d08167b': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Trust street 1
  'urn:pg:interview:question:3d1a54bc-1175-4cfc-802d-dfa376a15efd': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Trust city
  'urn:pg:interview:question:b35d12f3-5066-4157-87ce-7c972f9d6491': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Trust state
  'urn:pg:interview:question:614eab7b-69f4-4b16-83c1-2b563410cd0f': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Trust zip code
  'urn:pg:interview:question:1d30289f-dd43-4482-b094-73bd4b4fee34': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Trust date of creation
  'urn:pg:interview:question:549a0e99-5c6a-4405-ab72-c29223389e42': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Trust name
  'urn:pg:interview:question:9422e54a-8469-48a2-bacb-c865a223651d': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Trust tax id
  'urn:pg:interview:question:e11ab731-674a-40a5-8518-472db8fab089': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Signer relationship
  'urn:pg:interview:question:c47b5a33-65fb-479b-864c-96052b68e587': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Signer email
  'urn:pg:interview:question:a9c53b18-54be-4e56-a30f-762ec3aec785': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Policy insured premium payer
  'urn:pg:interview:question:policy-insured-premium-payer': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Policy premium payer name
  'urn:pg:interview:question:policy-premium-payer-name': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Existing insurance
  'urn:pg:interview:question:existing-insurance': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Compliance
  // Transfer policy
  'urn:pg:interview:question:compliance-ever-paid-as-incentive-for-policy': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Transfer policy within next two years
  'urn:pg:interview:question:compliance-third-party-will-gain-interest': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Existing Policies
  // Carrier name
  'urn:pg:interview:question:existing-policies:carrier-name': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Face amount
  'urn:pg:interview:question:existing-policies:face-amount': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Product type
  'urn:pg:interview:question:existing-policies:product-type': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Policy type
  'urn:pg:interview:question:existing-policies:policy-type': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Coverage reason
  'urn:pg:interview:question:existing-policies:coverage-reason': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Current status
  'urn:pg:interview:question:existing-policies:current-status': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Replacing
  'urn:pg:interview:question:existing-policies:replacing': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Bank account
  'urn:pg:interview:question:insured-bank-account-holder-name': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Bank name
  'urn:pg:interview:question:insured-bank-name': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Bank payment type
  'urn:pg:interview:question:insured-payment-account-type': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Bank routing number
  'urn:pg:interview:question:insured-bank-routing-number': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Bank account number
  'urn:pg:interview:question:insured-bank-account-number': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // PRIMARY BENEFICIARY INFORMATION
  // Full name
  'urn:pg:interview:question:primary-beneficiaries:full-name': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Date of birth
  'urn:pg:interview:question:primary-beneficiaries:date-of-birth': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Relationship
  'urn:pg:interview:question:primary-beneficiaries:relationship': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Percentage
  'urn:pg:interview:question:primary-beneficiaries:percentage': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // CONTINGENT BENEFICIARY INFORMATION
  // Full name
  'urn:pg:interview:question:contingent-beneficiaries:full-name': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Date of birth
  'urn:pg:interview:question:contingent-beneficiaries:date-of-birth': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Relationship
  'urn:pg:interview:question:contingent-beneficiaries:relationship': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Percentage
  'urn:pg:interview:question:contingent-beneficiaries:percentage': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // FAMILY HEALTH HISTORY
  // Relationship
  'urn:pg:interview:question:family-member-health-histories:relationship': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Recent conditions
  // Consulted by doc
  'urn:pg:interview:question:21b82370-9bb4-4133-b510-a83fcec93a14': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // X-ray
  'urn:pg:interview:question:f9abe9a3-7b09-4583-a492-1f27f226edc9': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // clinic treatment
  'urn:pg:interview:question:7ebe50ca-8ebf-4499-9124-3d1a2e34c468': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // surgical procedure
  'urn:pg:interview:question:2bc79e0d-a8be-4ec3-ba2b-5563385aa326': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // fever or shortness of breath
  'urn:pg:interview:question:3da57b24-68e0-4f5d-8aee-462f92be2f37': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // injury
  'urn:pg:interview:question:fff1c749-d4d3-4ecc-883e-5b3bb364744d': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // 15 lbs
  'urn:pg:interview:question:2081d697-9016-46d4-b860-2c3a5cc3a518': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // postponed life insurance
  'urn:pg:interview:question:9c': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // pregnant
  'urn:pg:interview:question:4d4b8c7c-0b67-4508-adbf-ac7b8cef9cb1': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Health Conditions
  // Gating Question: Brain Condition
  'urn:pg:interview:question:3da55b24-65e0-4f5d-8aec-463f92be2f37': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // seizure
  'urn:pg:interview:question:86c8500b-89a6-4d0d-b9a0-de89fcd35cc6': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Fainting
  'urn:pg:interview:question:a52cb8ac-e528-4024-8498-473c5e3240bf': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Stroke
  'urn:pg:interview:question:a963bd86-ced5-4f5e-9b92-9c9c18d69070': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Loss of consciousness
  'urn:pg:interview:question:58d55125-3998-4039-ab43-f21624931672': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Tremor
  'urn:pg:interview:question:d354b517_5f31_4ce5_b469_13db2883ed1f': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Paralysis
  'urn:pg:interview:question:6a5dac8e-635f-41eb-986e-ceb2cb01c888': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Multiple sclerosis
  'urn:pg:interview:question:b541b19f-5b3e-4230-bda4-67de60107516': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Epilepsy
  'urn:pg:interview:question:d3c5119d-2aae-4171-9240-1dca30cad0f6': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Any disease or abnormality of the brain
  'urn:pg:interview:question:091cf594-e648-46dc-a9a3-1f15fc8e1ce1': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Gating Question: Brain Condition
  'urn:pg:interview:question:4da55b24-65e0-4f5d-8aec-463f92be2f37': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // High blood pressure
  'urn:pg:interview:question:137ec849-c219-45e7-888c-455c288ba4be': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Heart attack
  'urn:pg:interview:question:e477b117-8515-46de-8fcc-86b3e47d08b9': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Murmur
  'urn:pg:interview:question:3695bfea-9d81-44d2-b7b3-814f24623e5f': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Palpitation
  'urn:pg:interview:question:c681b4a8-38d4-4859-ae76-cd86bdf7077b': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Anemia
  'urn:pg:interview:question:06110b04-d985-4956-b1f1-09eb0d0c6952': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Gating Question: Respiratory Condition
  'urn:pg:interview:question:3da55b24-75e0-4f5d-8aec-463f92be2f37': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Asthma
  'urn:pg:interview:question:b5eb6b5d-fd78-45a6-adcb-79dda056fd1a': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Chronic bronchitis
  'urn:pg:interview:question:48035a9c-20a2-4319-b213-d27641278cf7': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Pneumonia
  'urn:pg:interview:question:826676e4-f6f5-4973-99a3-70671fa80d9d': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Emphysema
  'urn:pg:interview:question:0ab46220-fd17-4a0d-9f5b-5a2173cabfa6': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Tuberculosis
  'urn:pg:interview:question:6debc267-019c-49cf-8b5e-4e8e066798fe': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Any disease or abnormality of the lungs
  'urn:pg:interview:question:6f2099e9-0cac-4e9f-881c-f3fc5520ecc6': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Any disease or abnormality of the bronchial tubes
  'urn:pg:interview:question:62113675-8f11-495e-b098-7f4b1f2ff1da': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Any disease or abnormality of the respiratory system
  'urn:pg:interview:question:48a055d6-f243-425f-945b-dc9b078ca24b': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Gating Question: Digestive Condition
  'urn:pg:interview:question:4da55b24-65e0-4f5d-8aec-463f92be2g37': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Ulcer
  'urn:pg:interview:question:8e2e2076-9bb2-4c47-ae9b-131b2d3ffa65': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Colitis
  'urn:pg:interview:question:1c3d0dee-924e-491d-a52d-339e7fc79645': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Hepatitis
  'urn:pg:interview:question:efab8f93-be1d-496b-8f0d-a9c8b6c4f7b7': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Cirrhosis
  'urn:pg:interview:question:19d0d95b-8b45-4c95-8abd-789e503e87c6': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Any disease or abnormality of the esophagus
  'urn:pg:interview:question:7463b3f7-5d53-437f-bbc1-b4021057fe1a': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Any disease or abnormality of the stomach
  'urn:pg:interview:question:44d7c88a-6bc4-4cf4-a204-ca0c581dac8d': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Any disease or abnormality of the intestines
  'urn:pg:interview:question:b47f70ca-4b36-4024-ac79-fdcc410f2a78': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Any disease or abnormality of the rectum
  'urn:pg:interview:question:c1c8333e-d1fb-4b25-a43f-61d03c66c6c5': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Any disease or abnormality of the gallbladder
  'urn:pg:interview:question:d3297f38-a7b1-4d10-a5cf-7c71009ce347': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Any disease or abnormality of the liver
  'urn:pg:interview:question:e0f2d696-1ab8-4e2b-a214-832a248a4e82': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Gating Question: Urinary Reproductive Condition
  'urn:pg:interview:question:4da55b24-68e0-4f5d-8aec-463f92be2f37': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Sugar protein or blood in urine
  'urn:pg:interview:question:a85e3a46-b4a6-4e3e-a54d-48af761e20e1': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Sexually transmitted disease
  'urn:pg:interview:question:7cadeb14-cdcc-44da-9615-7905d8e8e092': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Stone or any disease or abnormality of the kidney
  'urn:pg:interview:question:1f610016-10d1-462e-8d7e-c67d14b98e44': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Bladder
  'urn:pg:interview:question:c0cd6dcb-2fae-46d7-a50f-3ddbb0f79f38': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Prostate
  'urn:pg:interview:question:3f79c233-ce47-4123-950c-6dd05b680cf3': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Breast
  'urn:pg:interview:question:7dc84bf9-0685-4823-8d4b-43bfdbd4e003': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Ovaries
  'urn:pg:interview:question:7dc626d3-d40a-4761-8003-cc24b0923468': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Other reproductive system
  'urn:pg:interview:question:2fda6959-7e83-4a40-8271-8d4174fa51cc': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Gating Question: Endocrine Condition
  'urn:pg:interview:question:4da55b24-68e0-4f5d-8aec-468f92be2f37': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Diabetes
  'urn:pg:interview:question:faf2c914-508e-425b-bf3f-fe84fc65d4bb': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Any disease or abnormality of the thyroid
  'urn:pg:interview:question:068936fe-53b5-48b2-b732-cef9cc7d27b4': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Adrenal
  'urn:pg:interview:question:845b8109-85f4-41f3-9966-1fa36ba10f3a': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Pituitary
  'urn:pg:interview:question:7743cd88-89cd-461e-9fd1-2812e6cbb337': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Other glands
  'urn:pg:interview:question:6187cac1-e375-478f-b447-9b6d0a55901c': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Gating Question: Musculoskeletal Condition
  'urn:pg:interview:question:4dv55b24-68e0-4f5d-8aec-463f92be2f377': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Arthritis
  'urn:pg:interview:question:219b18cb-6456-4a5b-b5ff-71bf04549ea8': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Gout
  'urn:pg:interview:question:2e61ac84-9169-4d0b-9494-b141f25b331f': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Connective tissue disease
  'urn:pg:interview:question:56d73b0e-ba93-444b-a442-27623297d717': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Back trouble
  'urn:pg:interview:question:68ab663a-a894-4a41-b135-e21a728ce6c4': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Any disease or abnormality of the joints
  'urn:pg:interview:question:eec04c5a-b383-4ecd-b267-37655d6b89df': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Muscles
  'urn:pg:interview:question:322ee701-2aaf-4021-bd8d-97bffe85643e': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Bones
  'urn:pg:interview:question:b1a2786d-002d-4e71-88c8-e15a9c8ca2ca': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Gating Question: Sensory Condition
  'urn:pg:interview:question:4da55b24-68e0-4f5d-8aec-463f02be2f37': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Eyes
  'urn:pg:interview:question:d53cd8af-976a-4a43-a3c7-18f109aa1db0': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Ears
  'urn:pg:interview:question:0075e235-bfdd-4f41-aa72-b14e5a88dac5': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Nose
  'urn:pg:interview:question:c4b171a4-8b6d-4a00-b45a-fa8ef7d3e69c': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Throat
  'urn:pg:interview:question:3dfbc543-9c99-450f-bcab-db30f704d681': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Skin
  'urn:pg:interview:question:4c697a0a-3d8b-48b7-8ff6-c46cd3ed68e5': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Gating Question: Cancer Abnormal Growth Condition
  'urn:pg:interview:question:4da55b24-68a0-4f5d-8aec-463f92be2f37': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Cancer
  'urn:pg:interview:question:b7e450cb-99dd-48fb-b71e-776b1f822ac8': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Tumor
  'urn:pg:interview:question:61a4e781-4aca-4963-ab5f-aa0fb91756ac': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Polyp
  'urn:pg:interview:question:886db577-a5db-4735-abea-336d630e571e': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Cyst
  'urn:pg:interview:question:4f5d9f43-4132-484d-accb-ec3cb50a2973': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Gating Question: Physical Deformity Condition
  'urn:pg:interview:question:1da55b24-68a0-4f5d-8aec-463f92be2f37': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Physical deformity
  'urn:pg:interview:question:8fe71800-9afb-49ed-9469-6a29864ac3e6': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Amputation
  'urn:pg:interview:question:92469d05-985b-4fea-9fe5-47c12b31a5ca': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Gating Question: Mental Health Condition
  'urn:pg:interview:question:2da55b24-68e0-4f5d-8aec-463f92be2f37': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Anxiety
  'urn:pg:interview:question:9e5b70c3-a2bf-4b2a-a4df-543989d03691': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Depression
  'urn:pg:interview:question:d443430f-17b6-4b25-b929-84602c3ba7e5': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Suicide attempt
  'urn:pg:interview:question:96f4830c-8226-4303-9a02-818fee547159': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Psychiatric condition
  'urn:pg:interview:question:67170f05-3685-4349-be28-2722450ab636': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Mental condition or disorder
  'urn:pg:interview:question:54facf61-43e7-4c8e-bda4-fda90e8c1aa6': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Emotional condition or disorder
  'urn:pg:interview:question:ad175f7d-3632-47f0-9c60-80eb2d5c0aee': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Gating Question: Immune Condition
  'urn:pg:interview:question:3da55b24-68e0-4f5d-8aec-463f92be2f37': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // AIDS
  'urn:pg:interview:question:b877f0e0-0af9-4971-b0cb-7957c1f78a62': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // ARC
  'urn:pg:interview:question:a91855b7-4da6-46ce-92b1-76995e7278c3': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // HIV
  'urn:pg:interview:question:df3ce06a-138f-49ba-ad05-0dddc7c10b94': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Any immune deficiency disorder
  'urn:pg:interview:question:ab132d9a-785d-4987-ab55-5d8bbc33482e': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Tested positive on an AIDS/HIV-related test
  'urn:pg:interview:question:c0160fe8-6255-4f79-a07e-d621bcbdedf1': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // RISK CONDITIONS
  // Gating Question: Drug Use Condition
  'urn:pg:interview:question:3da55b24-68e0-4f5d-8aee-463f92be2f37': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Sedatives
  'urn:pg:interview:question:d52d9197-a492-46a8-bb0e-f332923b1587': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Amphetamines
  'urn:pg:interview:question:725e197a-42e7-43b8-aee3-3d43df34a7b3': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Barbiturates
  'urn:pg:interview:question:33a933a6-6034-47a6-a72d-885b66cad8f3': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Morphine
  'urn:pg:interview:question:56d82ea1-34b5-4781-aaae-40f5a867eda4': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Cocaine
  'urn:pg:interview:question:431a4f8e-a23d-4496-b924-c19b1535d3b4': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Crack
  'urn:pg:interview:question:3d5e5228-3cc1-4454-a4c6-bf0109001e5d': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Methamphetamine
  'urn:pg:interview:question:9dfe6946-acff-472d-8fb2-89a0b9838477': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Ecstacy (MDMA)
  'urn:pg:interview:question:a02f0cfb-d15c-4891-a529-8366a5fcc948': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Heroin
  'urn:pg:interview:question:0d0ada4a-d037-41aa-a361-2761a509ab66': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Marijuana
  'urn:pg:interview:question:b2cd588a-2e77-4b27-b076-642319f82adf': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // LSD
  'urn:pg:interview:question:2af75277-03cd-4d50-aef9-54678e80dcbb': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // PCP
  'urn:pg:interview:question:e58c7cbd-9028-44e7-b6bc-ac744c30bff0': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Any hallucinogenic drug
  'urn:pg:interview:question:368850fd-629e-436c-bdd7-726dc2a299a4': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Any narcotic drug
  'urn:pg:interview:question:8f21d576-2d49-4d8b-b237-fd98f8737f0c': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Gating Question: Drug Treatment Condition
  'urn:pg:interview:question:3da57b24-68e0-4f5d-8aee-463f92be2f37': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Alcohol
  'urn:pg:interview:question:880ebd70-bc1f-4f72-96d1-e943263b8931': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Drugs
  'urn:pg:interview:question:49e0b3a5-391a-4d00-a8bd-d5b826205725': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Other substance
  'urn:pg:interview:question:e7dea963-c082-45f7-81a0-9f0dae22050e': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Joined an organization for alcohol or drug dependence or abuse
  'urn:pg:interview:question:pi-joined-alcohol-or-drug-org': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Relative health conditions
  'urn:pg:interview:question:insured-risk-relevant-direct-relation-health-conditions': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // OTHER CONDITIONS
  // Gating Question: Vitamin, Supplement, Medication Condition
  'urn:pg:interview:question:a1abbfc6-d33f-4085-8ce7-3d4f307e727a': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Indicate vitamin, supplement, medication question
  'urn:pg:interview:question:ee23fc96-f7bc-4f50-ada1-b6d96c4760ce': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Nicotine
  'urn:pg:interview:question:03c118af-7b47-44ad-b5a9-c47780d97a7e': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Gating Question: Work Frequency Condition
  'urn:pg:interview:question:39489375-b1dd-491c-aa4e-080a017e5ab4': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Work history details
  'urn:pg:interview:question:active-work-history-details': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Regular weekly exercise
  'urn:pg:interview:question:0fa0a27d-4ccb-4562-9624-bd52bf88f624': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Team athletics
  'urn:pg:interview:question:211edfd2-4d31-41cd-adf6-3beb93e51ee3': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Tobacco use
  'urn:pg:interview:question:0fb040c8-38aa-49ef-9455-f8ed9066a04d': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Regular physicals
  'urn:pg:interview:question:35b78bc0-57be-48b1-942e-b1563eeeb105': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Annual dental
  'urn:pg:interview:question:3cfec9e5-8521-45da-962f-c81bf65ead80': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Clean house and yard
  'urn:pg:interview:question:7741c180-c397-49e6-8c23-d34003208e6f': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Pet
  'urn:pg:interview:question:b4bf16e8-9722-4c28-abe3-b9d9b6d7b236': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
  // Charity
  'urn:pg:interview:question:c6b70215-2e31-443a-ba15-3be7773b9965': {
    field: 'value.present',
    operation: '=',
    value: true,
  },

  // Gating Question: Underwriting Coverage
  'urn:pg:interview:question:policy-applied-temporary-coverage': {
    field: 'value.present',
    operation: '=',
    value: true,
  },
};

export const NON_MED_URN = 'urn:pg:data-point:insured-non-med-proceed';
export const PRIMARY_BENEFICIARIES_SECTION_URN = 'urn:pg:interview:section:primary-beneficiaries';
export const CONTINGENT_BENEFICIARIES_SECTION_URN =
  'urn:pg:interview:section:contingent-beneficiaries';
export const APP_DETAILS = 'urn:pg:interview:section:insured-application-details';
export const EXISTING_POLICIES = 'urn:pg:data-point:existing-policies';

const eligibilityRules: { ageRange: [number, number]; maxCoverage: number }[] = [
  { ageRange: [18, 45], maxCoverage: 2000000 },
  { ageRange: [46, 55], maxCoverage: 1000000 },
  { ageRange: [56, 60], maxCoverage: 99999 },
  { ageRange: [61, 70], maxCoverage: 50000 },
];

export const nonMedEligible = (coverageAmount?: number, dob?: string | null): boolean => {
  if (!dob || !coverageAmount) {
    return false;
  }

  const age = differenceInYears(Date.now(), new Date(dob));

  return eligibilityRules.some(
    (rule) =>
      age >= rule.ageRange[0] && age <= rule.ageRange[1] && coverageAmount <= rule.maxCoverage
  );
};

export const benefitPercentageEligible = (beneficiaries: Beneficiary[]): boolean => {
  // if ALL beneficiaries are being deleted, we don't care about the percentages
  const validBenes = beneficiaries.filter((benefit) => benefit.operation !== 'DELETE');

  if (validBenes.length === 0) {
    return true;
  }

  if (beneficiaries.length === 0) {
    return true;
  }

  const total = beneficiaries.reduce((acc, value) => acc + value.values.percentage, 0);

  return total === 100;
};

export const validateExistingPolicyYearOfIssue = (data: ExistingPolicy[]): boolean => {
  if (!data || data.length === 0) {
    return true;
  }
  // if all policies are being deleted, we don't care about year validations
  const validPolicies = data.filter((policy: any) => policy.operation !== 'DELETE');

  if (validPolicies.length === 0) {
    return true;
  }

  let allYearsValid = true;

  validPolicies.forEach((policy) => {
    const inForceYear = policy.values['year-of-issue'];

    if (inForceYear) {
      const asInt = parseInt(inForceYear, 10);

      if (asInt < 1900) {
        allYearsValid = false;
      }
    }
  });

  return allYearsValid;
};
