import React from 'react';
import { UtilityText } from '@policygenius/mortar';
import { EmailLink } from './styles';

export const DropTicketErrorMessage: React.FC = () => {
  return (
    <>
      <UtilityText size="medium">
        Thank you for using Policygenius Pro! Our system is having some trouble processing this
        referral. While we investigate, if you could please send over your client&#39;s name, phone
        number, email, and any recommendations you have to&nbsp;
        <EmailLink href="mailto:pgproaccounts@policygenius.com">
          pgproaccounts@policygenius.com
        </EmailLink>
        , someone from our team will reach out to your client as soon as possible.
      </UtilityText>
    </>
  );
};

export const DropTicketErrorMessageNY: React.FC = () => (
  <UtilityText size="medium">
    The advisor is not appointed with any New York carriers and is therefore unable to refer
    business on behalf of New York state residents. For help, reach out through chat or email{' '}
    <EmailLink href="mailto:pgproaccounts@policygenius.com">
      pgproaccounts@policygenius.com.
    </EmailLink>
  </UtilityText>
);

export const EAppSubmissionErrorMessage: React.FC = () => (
  <UtilityText size="medium">
    Feel free to try again or reach out to our team through the portal chat for assistance.
  </UtilityText>
);
