import React from 'react';
import { Col, Row, Selectbox, Textbox, ToggleGroup, Textarea } from '@policygenius/mortar';
import {
  SubmitOrContinueButton,
  Disclaimer,
  Email,
  Recommendation,
  RecSubtext,
  SharedBottomMargin,
  Title,
} from './styles';
import { useField } from 'react-final-form';
import DateField from 'components/DateField';
import { STATES, TERM_LENGTHS } from 'pages/ClientInfo/form/constants';
import { useFormContext } from 'context/FormContext';
import PolicyPlayback from 'components/PolicyPlayback';
import { Gender, AgencyType, Role } from 'graph/types';
import { useAuth0 } from '@auth0/auth0-react';
import { Props } from './types';
import { toString, userHasSelectedPolicy } from 'utils/helpers';
import MaskedTextField from 'components/MaskedTextField';
import { numberMask, phoneMask } from 'components/MaskedTextField/constants';
import { useCurrentUserQuery } from 'query/currentUser/query';
import AdvisorSelect from 'components/AdvisorSelect';
import { useAvailableProducersQuery } from 'query/producers/query';

/* eslint-disable complexity */
const DropTicketForm: React.FC<Props> = ({ handleSubmit, invalid, submitting }) => {
  const firstName = useField('firstName');
  const lastName = useField('lastName');
  const gender = useField('gender');
  const dateOfBirth = useField('dateOfBirth');
  const stateUS = useField('state');
  const phone = useField('phone');
  const email = useField('email');
  const coverageAmount = useField('coverageAmount');
  const term = useField('term');
  const notes = useField('notes');
  const referringAgent = useField('referringAgent');
  const { isAuthenticated } = useAuth0();

  const { state } = useFormContext();

  const submitOrContinue = isAuthenticated ? 'Submit' : 'Continue';

  const recommendationContent =
    'Please note that as our agents conduct a detailed health history, coverage and term are subject to change.';

  const legalCopy = `By clicking ${submitOrContinue}, you confirm that you've received written consent from the above-referenced client 1) to share such client's contact information (email and phone number, as entered above) with Policygenius, and 2) for Policygenius to contact such client directly via text message and live, automated and pre-recorded telephone calls using the information provided.`;

  const { data: userData, loading, error } = useCurrentUserQuery();

  if (error) {
    console.error(error);
  }

  const {
    data: advisorData,
    loading: loadingAdvisor,
    error: errorAdvisor,
  } = useAvailableProducersQuery();

  if (errorAdvisor) {
    console.error(errorAdvisor);
  }

  const assistant = userData?.currentUser?.roles.includes(Role.Assistant);

  const notesHelperText =
    userData?.currentUser.agencyType === AgencyType.FeeOnly
      ? 'Please do not include client health details'
      : undefined;

  return (
    <form onSubmit={handleSubmit} noValidate>
      {!loading && assistant && !loadingAdvisor && (
        <AdvisorSelect
          referringAgent={referringAgent}
          listOfAgents={advisorData?.availableProducers || []}
        />
      )}
      <Title
        size={{
          small: 'medium',
        }}
      >
        Client information
      </Title>
      <Row nested>
        <Col
          span={{
            medium: 6,
            small: 4,
          }}
        >
          <SharedBottomMargin>
            <Textbox
              {...firstName.input}
              id="first-name"
              name="first-name"
              label="First name"
              placeholder="Enter"
              value={firstName.input.value}
              defaultValue=""
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                firstName.input.onChange(e.target.value);
              }}
              type="text"
              errorMessage={
                firstName.meta.touched && !firstName.meta.active ? firstName.meta.error : undefined
              }
            />
          </SharedBottomMargin>
        </Col>
        <Col
          span={{
            medium: 6,
            small: 4,
          }}
        >
          <SharedBottomMargin>
            <Textbox
              {...lastName.input}
              id="last-name"
              name="last-name"
              label="Last name"
              placeholder="Enter"
              value={lastName.input.value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                lastName.input.onChange(e.target.value)
              }
              type="text"
              errorMessage={
                lastName.meta.touched && !lastName.meta.active ? lastName.meta.error : undefined
              }
            />
          </SharedBottomMargin>
        </Col>
      </Row>
      <SharedBottomMargin>
        <ToggleGroup
          {...gender.input}
          groupLabel="Gender"
          defaultValue={toString(gender.meta.initial)}
          errorMessage={gender.meta.touched && !gender.meta.active ? gender.meta.error : undefined}
          name="gender_selection"
          options={[
            { label: 'Male', value: Gender.Male },
            { label: 'Female', value: Gender.Female },
          ]}
          showGroupLabel
        />
      </SharedBottomMargin>
      <Row nested>
        <Col
          span={{
            medium: 8,
            small: 4,
          }}
        >
          <SharedBottomMargin>
            <DateField label="Date of birth" {...dateOfBirth} initialDate={state.dateOfBirth} />
          </SharedBottomMargin>
        </Col>
        <Col
          span={{
            medium: 4,
            small: 4,
          }}
        >
          <SharedBottomMargin>
            <Selectbox
              id="state"
              name="select-box"
              label="State"
              options={STATES}
              defaultValue={stateUS.input.value}
              errorMessage={
                stateUS.meta.touched && !stateUS.meta.active ? stateUS.meta.error : undefined
              }
              onChange={stateUS.input.onChange} // eslint-disable-line react/jsx-handler-names
              onBlur={stateUS.input.onBlur} // eslint-disable-line react/jsx-handler-names
            />
          </SharedBottomMargin>
        </Col>
      </Row>

      <SharedBottomMargin>
        <MaskedTextField
          input={phone.input}
          meta={phone.meta}
          id="phone"
          label="Phone number"
          showLabel
          placeholder="Enter"
          type="tel"
          defaultValue={phone.input.value}
          maskOptions={phoneMask}
        />
      </SharedBottomMargin>
      <Email>
        <Textbox
          {...email.input}
          id="email"
          name="email"
          label="Email address"
          placeholder="Enter"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            email.input.onChange(e.target.value)
          }
          type="text"
          errorMessage={email.meta.touched && !email.meta.active ? email.meta.error : undefined}
        />
      </Email>
      <Recommendation size="medium">Recommendation (optional)</Recommendation>
      <RecSubtext
        size={{
          medium: 'large',
          small: 'medium',
        }}
      >
        {recommendationContent}
      </RecSubtext>
      {userHasSelectedPolicy(state) ? (
        <PolicyPlayback />
      ) : (
        <Row nested>
          <Col
            span={{
              medium: 6,
              small: 4,
            }}
          >
            <SharedBottomMargin data-testid="coverageAmount">
              <MaskedTextField
                input={coverageAmount.input}
                meta={coverageAmount.meta}
                id="coverage-amount"
                label="Coverage amount"
                showLabel
                placeholder="0"
                type="text"
                defaultValue={coverageAmount.input.value}
                maskOptions={numberMask}
                prefix="$"
              />
            </SharedBottomMargin>
          </Col>
          <Col
            span={{
              medium: 6,
              small: 4,
            }}
          >
            <SharedBottomMargin data-testid="termLength">
              <Selectbox
                id="term"
                name="select-box"
                label="Term length"
                options={TERM_LENGTHS}
                defaultValue={term.input.value}
                errorMessage={term.meta.touched && !term.meta.active ? term.meta.error : undefined}
                onChange={term.input.onChange} // eslint-disable-line react/jsx-handler-names
                onBlur={term.input.onBlur} // eslint-disable-line react/jsx-handler-names
              />
            </SharedBottomMargin>
          </Col>
        </Row>
      )}
      <SharedBottomMargin>
        <Textarea
          id="notes"
          label="Notes for Policygenius"
          rows={6}
          {...notes.input}
          errorMessage={notes.meta.touched && !notes.meta.active ? notes.meta.error : undefined}
          helperText={notesHelperText}
        />
      </SharedBottomMargin>
      {!userData?.currentUser.tcpaRequired && <Disclaimer size="small">{legalCopy}</Disclaimer>}
      <SubmitOrContinueButton
        analyticsText="Continue"
        size="large"
        onClick={() => null}
        disabled={invalid || submitting}
        variant="black"
      >
        {submitOrContinue}
      </SubmitOrContinueButton>
    </form>
  );
};

export default DropTicketForm;
