import React, { useState, useEffect } from 'react';
import {
  ButtonContainer,
  Container,
  Divider,
  HeaderContainer,
  OptionsContainer,
  ResetButton,
  SortRadioGroup,
  StatusCheckbox,
  StatusContainer,
  StatusLabel,
} from './styles';
import { ActionClose, Button, UtilityText } from '@policygenius/mortar';
import { REPORTS_ROUTE } from 'routes/constants';
import { useNavigate, useLocation } from 'react-router-dom';
import { DEFAULT_PAGE_NUMBER, DEFAULT_SORTING, useReportContext } from 'context/ReportContext';
import { Events, track } from 'utils/analytics';
import { OrderBy, TierType } from 'graph/types';
import {
  BASE_SORT_OPTIONS,
  NEW_ALL_SORT_OPTIONS,
  STATUS_FILTER_OPTIONS,
} from 'pages/Reports/constants';
import { useUserContext } from 'context/UserContext';
import { useCurrentUserQuery } from 'query/currentUser/query';

export const STATUS_CONTAINER_ID = 'status-filter-section';
const FilterPage: React.FC = () => {
  window.HubSpotConversations?.widget.remove();

  const navigate = useNavigate();

  const { sortOrder, setSortOrder, filter, setFilter, setPageNumber, setPageTokens } =
    useReportContext();
  const [disableReset, setDisableReset] = useState(
    sortOrder === DEFAULT_SORTING && filter.length === 0
  );
  const { userIsAdmin } = useUserContext();
  const { data, error } = useCurrentUserQuery();

  if (error) {
    console.error(error);
  }

  const sortOptions =
    userIsAdmin && data?.currentUser.tierType === TierType.MultiTier
      ? NEW_ALL_SORT_OPTIONS
      : BASE_SORT_OPTIONS;

  useEffect(() => {
    if (disableReset === true) {
      const defaultRadio = document.querySelector(
        'input[value="REFERRAL_DATE_DESC"]'
      ) as HTMLInputElement;

      defaultRadio!.checked = true;
    }
  }, [disableReset]);

  const getSelectedRadioValue = () => {
    const selectedRadio = document.querySelector(
      'input[name="sort_selection"]:checked'
    ) as HTMLInputElement;

    return selectedRadio.value;
  };

  const setSort = () => {
    const selectedRadioValue = getSelectedRadioValue();

    setSortOrder(selectedRadioValue as OrderBy);
  };

  const applyOnClick = () => {
    setSort();
    track(Events.SELECTED_OPTION, {
      sort: getSelectedRadioValue(),
      filter,
    });
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageTokens(['']);
    navigate(REPORTS_ROUTE);
  };

  const checkDisableReset = () => {
    const defaultRadio = document.querySelector(
      'input[value="REFERRAL_DATE_DESC"]'
    ) as HTMLInputElement;

    const checkedBoxes = document.querySelectorAll('input[type="checkbox"]:checked');

    if (defaultRadio!.checked === true && checkedBoxes.length === 0) {
      setDisableReset(true);
    } else {
      setDisableReset(false);
    }
  };

  const updateStatusSelections = (option: string, checked: boolean) => {
    // if the option is already checked when the user clicks the checkbox,
    // that means we should remove it from filter state so that it becomes unchecked
    if (checked) {
      const newFilter = filter.filter((object) => object.applicationStatus !== option);

      setFilter(newFilter);
    } else {
      setFilter([...filter, { applicationStatus: option }]);
    }
  };

  const location = useLocation();
  const scrollToStatus = location.hash === `#${STATUS_CONTAINER_ID}`;

  // StatusContainer margin needs to be large enough so that the container sits just below the Filter header
  // 602px = Filter header + container top margin + Status checkboxes height + buttons
  const statusBottomMargin = scrollToStatus ? window.innerHeight - 602 : 0;

  return (
    <Container>
      <HeaderContainer>
        <UtilityText size="xlarge" weight="bold">
          Filter {filter.length > 0 && <span>({filter.length})</span>}
        </UtilityText>
        <span data-testid="close-icon" onClick={() => navigate(REPORTS_ROUTE)}>
          <ActionClose size="large" color="gray400" />
        </span>
      </HeaderContainer>
      <OptionsContainer>
        <SortRadioGroup
          id="radioSort"
          groupLabel="Sort"
          showGroupLabel
          name="sort_selection"
          options={sortOptions}
          defaultValue={sortOrder}
          onChange={checkDisableReset}
        />
        <Divider />
        <StatusContainer id={STATUS_CONTAINER_ID} bottomMargin={statusBottomMargin}>
          <StatusLabel size="large">Status</StatusLabel>
          {STATUS_FILTER_OPTIONS.map((option) => {
            return (
              <StatusCheckbox
                label={option.label}
                value={option.value}
                helpText={option.helpText}
                checked={!!filter.find((object) => object.applicationStatus === option.value)}
                key={option.value}
                onChange={() => {
                  checkDisableReset();
                  updateStatusSelections(
                    option.value,
                    !!filter.find((object) => object.applicationStatus === option.value)
                  );
                }}
              />
            );
          })}
        </StatusContainer>
      </OptionsContainer>
      <ButtonContainer>
        <ResetButton
          fullWidth
          onClick={() => {
            setDisableReset(true);
            setFilter([]);
          }}
          variant="filled"
          disabled={disableReset}
        >
          Reset
        </ResetButton>
        <Button fullWidth onClick={applyOnClick} variant="black">
          Apply
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default FilterPage;
