import styled from 'styled-components';
import { Col, Link, Row, theme, TitleText, UtilityText } from '@policygenius/mortar';
import { Mail } from 'components/NavigationCard/mail';
import { Send } from 'components/NavigationCard/send';
import { Card } from 'components/NavigationCard/styles';
import { Share } from 'components/NavigationCard/share';
import { NavigationCard as NaviCard } from 'components/NavigationCard';
import Badge from 'components/Badge';
import Button from 'components/Button';

export const Background = styled.div`
  background-color: ${theme.colors.white};
  min-height: 80vh;
  padding-bottom: 80px;
  @media (min-width: ${theme.breakpoints.medium}px) {
    padding-bottom: 120px;
  }
`;

export const ButtonContainer = styled(Row)`
  margin-top: 24px;
`;

export const MobileButtonContainer = styled(Row)`
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 24px;
`;

export const MobileButton = styled(Button)`
  width: 100%;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 240px;
`;

export const CardMail = styled(Mail)`
  ${Card}:hover & > path.envelope {
    fill: ${theme.colors.orange500};
  }

  ${Card}:hover & > path.paper {
    fill: ${theme.colors.orange200};
  }
`;

export const CardSend = styled(Send)`
  ${Card}:hover & > path.lines {
    fill: ${theme.colors.orange200};
  }

  ${Card}:hover & > path.arrow {
    fill: ${theme.colors.orange500};
  }
`;

export const CardShare = styled(Share)`
  ${Card}:hover & > path.box {
    fill: ${theme.colors.orange200};
  }

  ${Card}:hover & > path.arrow {
    fill: ${theme.colors.orange500};
  }
`;

export const NavigationCard = styled(NaviCard)`
  padding: 24px 0;
  margin: 0;
`;

export const ForkContainer = styled(Row)`
  margin: 0 auto;
  padding: 32px 16px 30px 16px;
  @media (min-width: ${theme.breakpoints.medium}px) {
    padding: 64px 24px 0px 24px;
  }
`;

export const Line = styled.div`
  height: 100%;
  width: 1px;
  background: ${theme.colors.gray300};
  margin: auto;
`;

export const ReferralURLContainer = styled.div`
  margin: 24px auto 40px auto;
`;

export const ReferralURL = styled(Link)`
  font-size: 16px;
  overflow-wrap: break-word;

  &:hover {
    color: ${theme.colors.gray500};
  }
`;

export const SectionTitle = styled(TitleText)`
  padding: 32px 0 24px 0;
  @media (min-width: ${theme.breakpoints.medium}px) {
    padding-top: 32px;
  }
  @media (min-width: ${theme.breakpoints.large}px) {
    padding-top: 0px;
  }
`;

export const ReferralLinkTitle = styled(SectionTitle)`
  padding-top: 40px;
  display: inline-flex;
  align-items: center;

  @media (min-width: ${theme.breakpoints.large}px) {
    padding-top: 0px;
  }
`;

export const NewBadge = styled(Badge)`
  margin-left: 8px;
  height: 20px;
`;

export const Spacer = styled.div`
  height: 16px;
  @media (min-width: ${theme.breakpoints.medium}px) {
    height: 84px;
  }
  @media (min-width: ${theme.breakpoints.large}px) {
    height: 52px;
  }
`;

export const Subtext = styled(UtilityText)`
  text-align: center;
  width: 240px;
`;

export const Subtitle = styled(UtilityText)`
  margin-bottom: 0;
  @media (min-width: ${theme.breakpoints.large}px) {
    margin-bottom: 64px;
  }
`;

export const Title = styled(TitleText)`
  margin-bottom: 8px;
`;

export const SubmitReferral = styled(Col)`
  padding-top: 64px;
`;
