import { theme } from '@policygenius/mortar';
import React from 'react';

interface Props {
  className?: string;
}

export const Mail: React.FunctionComponent<Props> = ({ className }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="paper"
      d="M12.9434 4H36.9434C38.0434 4 38.9434 4.9 38.9434 6V24C38.9434 25.1 38.0434 26 36.9434 26H12.9434C11.8434 26 10.9434 25.1 10.9434 24V6C10.9434 4.9 11.8434 4 12.9434 4ZM15.9434 10C15.3434 10 14.9434 10.4 14.9434 11C14.9434 11.6 15.3434 12 15.9434 12H27.9434C28.5434 12 28.9434 11.6 28.9434 11C28.9434 10.4 28.5434 10 27.9434 10H15.9434ZM15.9434 14C15.3434 14 14.9434 14.4 14.9434 15C14.9434 15.6 15.3434 16 15.9434 16H21.9434C22.5434 16 22.9434 15.6 22.9434 15C22.9434 14.4 22.5434 14 21.9434 14H15.9434Z"
      fill={`${theme.colors.gray300}`}
    />
    <path
      className="envelope"
      d="M8.6 12.1744L25 24.0744L41.4 12.1744C41.8 11.8744 42.5 11.9744 42.8 12.3744C42.9 12.5744 43 12.7744 43 12.9744V32.9744C43 35.1744 41.2 36.9744 39 36.9744H11C8.8 36.9744 7 35.1744 7 32.9744V13.0744C7 12.4744 7.4 12.0744 8 12.0744C8.2 12.0744 8.4 12.0744 8.6 12.1744Z"
      fill={`${theme.colors.gray500}`}
    />
  </svg>
);
