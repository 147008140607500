import React, { createContext, useState, useContext } from 'react';
import { ContextProps, SectionSavedStatuses } from './types';
import localStore from 'store2';

export const storeSectionKey = 'savedSections';

export const EappContext = createContext<ContextProps>({
  savedSections: {},
  setSavedSections: () => undefined,
});

EappContext.displayName = 'EappContext';

export const EappProvider: React.FunctionComponent = ({ children }) => {
  const [savedSections, setSavedSections] = useState<SectionSavedStatuses>(
    localStore.get(storeSectionKey) || {}
  );

  const setSavedSectionsWithLocalStore = (value: SectionSavedStatuses) => {
    localStore.set(storeSectionKey, value);
    setSavedSections(value);
  };

  return (
    <EappContext.Provider
      value={{
        savedSections,
        setSavedSections: setSavedSectionsWithLocalStore,
      }}
    >
      {children}
    </EappContext.Provider>
  );
};

export const useEappContext = (): ContextProps => useContext(EappContext);

export default EappProvider;
