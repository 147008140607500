import styled from 'styled-components';
import { Button, theme } from '@policygenius/mortar';

export const EmailCSV = styled(Button)`
  margin-right: 16px;
  display: none;

  @media (min-width: ${theme.breakpoints.medium}px) {
    display: block;
  }
`;

export const Buttons = styled.div`
  display: flex;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
