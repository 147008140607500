import React from 'react';
import { BadgeProps, textColorMap } from './types';
import { LabelText } from '@policygenius/mortar';
import { BadgeWrapper } from './styles';

const Badge: React.FC<BadgeProps> = ({ icon, label, variant = 'INFO', className }) => {
  return (
    <BadgeWrapper variant={variant} className={className} id={label}>
      {icon}
      <LabelText size="small" color={textColorMap[variant]}>
        {label}
      </LabelText>
    </BadgeWrapper>
  );
};

export default Badge;
