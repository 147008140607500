/* THIS FILE WAS AUTO-GENERATED. DO NOT MODIFY */
/* eslint-disable */
import * as Types from '../../graph/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PoliciesQueryVariables = Types.Exact<{
  dateOfBirth: Types.Scalars['String']['input'];
  tobaccoUse: Types.Scalars['Boolean']['input'];
  healthClass: Types.Scalars['String']['input'];
  coverageAmount: Types.Scalars['Int']['input'];
  termLength: Types.Scalars['Int']['input'];
  gender: Types.Gender;
  state: Types.Scalars['String']['input'];
  referringAgent?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type PoliciesQuery = { __typename?: 'Query', policies: Array<{ __typename?: 'Policy', carrier: string, carrierName: string, monthlyPremium: number, annualPremium: number, name: string, returnOfPremium: boolean, underwritingClass: string, isTobacco: boolean, healthCategory: string, coverageAmount: number, termInYears: number, tableRating: number, issueType: string, issueAge: number, planIdentifierName: string, productType: string, useNearestAge: boolean, attributes: Array<string> }> };


export const PoliciesDocument = gql`
    query Policies($dateOfBirth: String!, $tobaccoUse: Boolean!, $healthClass: String!, $coverageAmount: Int!, $termLength: Int!, $gender: Gender!, $state: String!, $referringAgent: String) {
  policies(
    input: {dateOfBirth: $dateOfBirth, tobaccoUse: $tobaccoUse, healthClass: $healthClass, coverageAmount: $coverageAmount, termLength: $termLength, gender: $gender, state: $state, referringAgent: $referringAgent}
  ) {
    carrier
    carrierName
    monthlyPremium
    annualPremium
    name
    returnOfPremium
    underwritingClass
    isTobacco
    healthCategory
    coverageAmount
    termInYears
    tableRating
    issueType
    issueAge
    planIdentifierName
    productType
    useNearestAge
    attributes
  }
}
    `;

/**
 * __usePoliciesQuery__
 *
 * To run a query within a React component, call `usePoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePoliciesQuery({
 *   variables: {
 *      dateOfBirth: // value for 'dateOfBirth'
 *      tobaccoUse: // value for 'tobaccoUse'
 *      healthClass: // value for 'healthClass'
 *      coverageAmount: // value for 'coverageAmount'
 *      termLength: // value for 'termLength'
 *      gender: // value for 'gender'
 *      state: // value for 'state'
 *      referringAgent: // value for 'referringAgent'
 *   },
 * });
 */
export function usePoliciesQuery(baseOptions: Apollo.QueryHookOptions<PoliciesQuery, PoliciesQueryVariables> & ({ variables: PoliciesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PoliciesQuery, PoliciesQueryVariables>(PoliciesDocument, options);
      }
export function usePoliciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PoliciesQuery, PoliciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PoliciesQuery, PoliciesQueryVariables>(PoliciesDocument, options);
        }
export function usePoliciesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PoliciesQuery, PoliciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PoliciesQuery, PoliciesQueryVariables>(PoliciesDocument, options);
        }
export type PoliciesQueryHookResult = ReturnType<typeof usePoliciesQuery>;
export type PoliciesLazyQueryHookResult = ReturnType<typeof usePoliciesLazyQuery>;
export type PoliciesSuspenseQueryHookResult = ReturnType<typeof usePoliciesSuspenseQuery>;
export type PoliciesQueryResult = Apollo.QueryResult<PoliciesQuery, PoliciesQueryVariables>;