import React from 'react';
import { getYear } from 'date-fns';
import { Col } from '@policygenius/mortar';
import { Body, Container, Grid, TabletPlusLink, OptOut } from './styles';

const currentYear = getYear(new Date());

const Footer: React.FunctionComponent = () => {
  return (
    <Container>
      <Grid>
        <Col
          span={{
            large: 3,
            medium: 4,
            small: 4,
          }}
        >
          <TabletPlusLink
            weight="bold"
            size="xsmall"
            onClick={() => {
              window.open('mailto:pgproaccounts@policygenius.com', '_self');
            }}
          >
            pgproaccounts@policygenius.com
          </TabletPlusLink>
          <OptOut
            weight="bold"
            size="xsmall"
            forwardedAs="a"
            href="https://www.policygenius.com/about/privacy/#online-tracking-opt-out-guide"
          >
            Online Tracking Opt-Out Guide
          </OptOut>
        </Col>
        <Col
          span={{
            large: 9,
            medium: 8,
            small: 4,
          }}
        >
          <Body size="xsmall">
            Policygenius Inc. (DBA Policygenius Insurance Services in CA), a DE corporation with its
            principal place of business in NY, NY, is a licensed independent insurance broker.
            Estimates, quotes, and ranges are non-binding, informational/educational, and subject to
            the{' '}
            <a href="https://www.policygenius.com/about/policygenius-pro-terms/">
              Terms and Conditions{' '}
            </a>
            and <a href="https://www.policygenius.com/about/privacy/">Privacy Policy</a>. Product
            availability and eligibility may vary. Copyright Policygenius © 2014-{currentYear}
          </Body>
        </Col>
      </Grid>
    </Container>
  );
};

export default Footer;
