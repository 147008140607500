import React from 'react';
import { Link, UtilityText } from '@policygenius/mortar';
import { useFormContext } from 'context/FormContext';
import {
  CarrierLink,
  CoverageSelections,
  Label,
  PlaybackContainer,
  Price,
  Selection,
} from './styles';
import Premium from '../Premium';
import { maskMoney } from 'forms/masks';

export const PolicyPlaybackV2: React.FC = () => {
  const { state } = useFormContext();

  return (
    <PlaybackContainer>
      <Link
        href={state.policySelection.carrierLink!}
        variant="secondary"
        target="_blank"
        rel="noreferrer"
        color="inherit"
      >
        <CarrierLink weight="bold" size="xlarge" text-decoration-line="underline">
          {state.policySelection.carrierName}
        </CarrierLink>
      </Link>
      <CoverageSelections>
        <UtilityText size="medium">{state.policySelection.planName}</UtilityText>
      </CoverageSelections>
      <CoverageSelections>
        <Selection>
          <Label>Coverage</Label>
          <UtilityText size="medium">${maskMoney(state.coverageAmount)}</UtilityText>
        </Selection>
        <Selection>
          <Label>Term</Label>
          <UtilityText size="medium">{state.term} years</UtilityText>
        </Selection>
      </CoverageSelections>
      <Price>
        <Premium
          premiumAmount={
            state.paymentMode === 'Monthly'
              ? state.policySelection.monthlyPremium!
              : state.policySelection.annualPremium!
          }
          frequency={state.paymentMode === 'Monthly' ? '/mo' : '/yr'}
        />
      </Price>
    </PlaybackContainer>
  );
};
