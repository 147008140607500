import { theme, UtilityText } from '@policygenius/mortar';
import styled from 'styled-components';

export const Container = styled.div`
  border: solid;
  border-width: 1px;
  border-color: ${theme.colors.gray200};
  border-radius: 8px;
  padding: 0px 16px;
  margin-top: 8px;
  & > :not(:last-child) {
    border-bottom: solid;
    border-bottom-width: 1px;
    border-bottom-color: ${theme.colors.gray200};
    border-bottom-radius: 8px;
    padding-bottom: 0px;
  }
`;

export const Content = styled.div<{ collapsedItems: boolean }>`
  opacity: ${({ collapsedItems }) => (collapsedItems ? '0' : '1')};
  transition: max-height 0.15s linear, opacity 0.25s linear, padding-bottom 0.15s linear;
  max-height: ${({ collapsedItems }) => (collapsedItems ? '0px' : '250px')};
  overflow: ${({ collapsedItems }) => (collapsedItems ? 'hidden' : 'auto')};
  padding-bottom: ${({ collapsedItems }) => (collapsedItems ? '0px' : '12px')};
`;

export const Header = styled.div`
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  svg {
    cursor: pointer;
  }
`;

export const UtilityLink = styled(UtilityText)`
  text-decoration: underline;
  cursor: pointer;
`;
