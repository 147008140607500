import React from 'react';
import {
  Content,
  ContentRightAlign,
  HeaderNoDesktop,
  HeaderNoTablet,
  HeaderRightAligned,
  LastUpdatedHeader,
  SortDescendArrow,
  TableHeader,
} from './styles';
import { theme } from '@policygenius/mortar';
import { PropsV2 } from './types';
import { useWindowSize } from 'hooks/useWindowSize';
import { useParams } from 'react-router-dom';
import { SORT_MAP, SortDirection, SortType } from 'pages/Reports/constants';
import { useReportContext } from 'context/ReportContext';

export const StatusTrackerHeaderV2: React.FunctionComponent<PropsV2> = ({
  flipSort,
  isMultiTierAdmin,
}) => {
  const windowSize = useWindowSize();
  const { id } = useParams();
  const { sortOrder } = useReportContext();

  const currentSortLabel = SORT_MAP.find((a) => {
    return sortOrder === a.id;
  });

  return (
    <tr>
      <TableHeader forwardedAs="th" size="small">
        #
      </TableHeader>
      <TableHeader
        activeLabel={currentSortLabel?.sortType === SortType.Client}
        isFilter
        onClick={() =>
          !!windowSize.width &&
          windowSize.width > theme.breakpoints.medium &&
          flipSort(SortType.Client)
        }
        forwardedAs="th"
        size="small"
        show
      >
        <Content>
          {!!windowSize.width && windowSize.width < theme.breakpoints.medium
            ? 'Client | Referred'
            : 'Client'}
          <SortDescendArrow
            desc={currentSortLabel?.orderDirection === SortDirection.Descending}
            selected={currentSortLabel?.sortType === SortType.Client}
          />
        </Content>
      </TableHeader>
      {isMultiTierAdmin && (
        <TableHeader
          activeLabel={currentSortLabel?.sortType === SortType.Organization}
          isFilter
          onClick={() => flipSort(SortType.Organization)}
          forwardedAs="th"
          size="small"
        >
          <Content>
            Organization
            <SortDescendArrow
              desc={currentSortLabel?.orderDirection === SortDirection.Descending}
              selected={currentSortLabel?.sortType === SortType.Organization}
            />
          </Content>
        </TableHeader>
      )}
      <TableHeader
        activeLabel={currentSortLabel?.sortType === SortType.Advisor}
        isFilter
        onClick={() => flipSort(SortType.Advisor)}
        forwardedAs="th"
        size="small"
      >
        <Content>
          Advisor
          <SortDescendArrow
            desc={currentSortLabel?.orderDirection === SortDirection.Descending}
            selected={currentSortLabel?.sortType === SortType.Advisor}
          />
        </Content>
      </TableHeader>
      <TableHeader
        activeLabel={currentSortLabel?.sortType === SortType.Stage}
        isFilter
        onClick={() => flipSort(SortType.Stage)}
        forwardedAs="th"
        size="small"
      >
        <Content>
          Stage
          <SortDescendArrow
            desc={currentSortLabel?.orderDirection === SortDirection.Descending}
            selected={currentSortLabel?.sortType === SortType.Stage}
          />
        </Content>
      </TableHeader>
      <TableHeader forwardedAs="th" size="small">
        Status
      </TableHeader>
      <HeaderRightAligned
        isFilter
        onClick={() => flipSort(SortType.Premium)}
        forwardedAs="th"
        size="small"
        selected={!!id}
        activeLabel={currentSortLabel?.sortType === SortType.Premium}
      >
        <ContentRightAlign>
          <SortDescendArrow
            desc={currentSortLabel?.orderDirection === SortDirection.Descending}
            selected={currentSortLabel?.sortType === SortType.Premium}
          />
          Annual Premium
        </ContentRightAlign>
      </HeaderRightAligned>
      {!isMultiTierAdmin && (
        <HeaderRightAligned forwardedAs="th" size="small" selected={!!id}>
          Created by
        </HeaderRightAligned>
      )}
      <HeaderRightAligned
        isFilter
        onClick={() => flipSort(SortType.CreatedDate)}
        forwardedAs="th"
        size="small"
        selected={!!id}
        activeLabel={currentSortLabel?.sortType === SortType.CreatedDate}
      >
        <ContentRightAlign>
          <SortDescendArrow
            desc={currentSortLabel?.orderDirection === SortDirection.Descending}
            selected={currentSortLabel?.sortType === SortType.CreatedDate}
          />
          Created
        </ContentRightAlign>
      </HeaderRightAligned>
      {isMultiTierAdmin ? (
        <HeaderNoTablet
          isFilter
          onClick={() => flipSort(SortType.LastUpdated)}
          alignRight
          forwardedAs="th"
          size="small"
          activeLabel={currentSortLabel?.sortType === SortType.LastUpdated}
        >
          <ContentRightAlign>
            <SortDescendArrow
              desc={currentSortLabel?.orderDirection === SortDirection.Descending}
              selected={currentSortLabel?.sortType === SortType.LastUpdated}
            />
            Last Updated
          </ContentRightAlign>
        </HeaderNoTablet>
      ) : (
        <LastUpdatedHeader
          activeLabel={currentSortLabel?.sortType === SortType.LastUpdated}
          isFilter
          onClick={() => flipSort(SortType.LastUpdated)}
          forwardedAs="th"
          size="small"
        >
          <ContentRightAlign>
            <SortDescendArrow
              desc={currentSortLabel?.orderDirection === SortDirection.Descending}
              selected={currentSortLabel?.sortType === SortType.LastUpdated}
            />
            Last updated
          </ContentRightAlign>
        </LastUpdatedHeader>
      )}
      <HeaderNoDesktop />
    </tr>
  );
};
