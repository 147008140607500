export const CLIENT_INFO_ROUTE = '/client-info';
export const DROP_TICKET_ROUTE = '/drop-ticket';
export const FILTER_ROUTE = '/filter';
export const FORK_ROUTE = '/new-case';
export const HELP_CENTER_ROUTE = '/help';
export const QUOTES_ROUTE = '/quotes';
export const SCHEDULE = '/schedule';
export const DETAIL_ROUTE = '/detail';
export const REPORTS_ROUTE = '/reports';
export const INDEX_ROUTE = '/';
export const REFERRAL_ROUTE = '/pro/referral/:slug';
export const SETTINGS_ROUTE = '/settings';
export const USER_NOT_FOUND_ROUTE = '/user-not-found';
export const SELF_SIGNUP_ROUTE = '/self-signup';
export const ACCEPT_TERMS = '/accept-terms-and-conditions';
export const EAPP_ROUTE = '/eapp';

export const DISABLE_HUB_SPOT_CHAT: Array<string> = [];
