import React from 'react';
import { UtilIconTaskDone, StatusOpen, UtilIconTaskUpcoming } from '@policygenius/mortar';

export const PACKET_STATUS_ICON = {
  SENT: {
    sendToClient: <UtilIconTaskDone color="blue400" />,
    reviewAndSign: <StatusOpen color="gray300" />,
    submitToCarrier: <UtilIconTaskUpcoming color="gray300" />,
  },
  SIGNED: {
    sendToClient: <UtilIconTaskDone color="blue400" />,
    reviewAndSign: <UtilIconTaskDone color="blue400" />,
    submitToCarrier: <StatusOpen color="gray300" />,
  },
  SUBMITTED: {
    sendToClient: <UtilIconTaskDone color="blue400" />,
    reviewAndSign: <UtilIconTaskDone color="blue400" />,
    submitToCarrier: <UtilIconTaskDone color="blue400" />,
  },
  INTERVIEW: {
    sendToClient: <UtilIconTaskDone color="blue400" />,
    reviewAndSign: <UtilIconTaskDone color="blue400" />,
    submitToCarrier: <UtilIconTaskDone color="blue400" />,
  },
  NOT_REQUIRED: {
    sendToClient: <React.Fragment />,
    reviewAndSign: <React.Fragment />,
    submitToCarrier: <React.Fragment />,
  },
  PAPERWORK_STATUS_UNSPECIFIED: {
    sendToClient: <React.Fragment />,
    reviewAndSign: <React.Fragment />,
    submitToCarrier: <React.Fragment />,
  },
};

export const MEDICAL_EXAM_STATUS_ICON = {
  UNSCHEDULED: {
    schedule: <StatusOpen color="gray300" />,
    complete: <UtilIconTaskUpcoming color="gray300" />,
  },
  SCHEDULED: {
    schedule: <UtilIconTaskDone color="blue400" />,
    complete: <StatusOpen color="gray300" />,
  },
  EXAM_DONE: {
    schedule: <UtilIconTaskDone color="blue400" />,
    complete: <UtilIconTaskDone color="blue400" />,
  },
  EXAM_SUBMITTED: {
    schedule: <UtilIconTaskDone color="blue400" />,
    complete: <UtilIconTaskDone color="blue400" />,
  },
  MED_EXAM_STATUS_UNSPECIFIED: {
    schedule: <React.Fragment />,
    complete: <React.Fragment />,
  },
  EXAM_NOT_REQUIRED: {
    schedule: <React.Fragment />,
    complete: <React.Fragment />,
  },
  OTHER: {
    schedule: <React.Fragment />,
    complete: <React.Fragment />,
  },
};

export const MEDICAL_RECORD_TOOLTIP =
  "Based on your client's medical history or coverage amount, the carrier is pursuing medical records from their personal physician in order to continue the underwriting process.";
