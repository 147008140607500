import styled from 'styled-components';
import { Row, TitleText, UtilityText, theme } from '@policygenius/mortar';
import { V2_CONTAINER_PADDING } from 'utils/helpers';

export const FormContainer = styled(Row)`
  padding: 32px 16px 80px 16px;
  @media (min-width: ${theme.breakpoints.medium}px) {
    padding: ${V2_CONTAINER_PADDING};
  }
`;

export const Title = styled(TitleText)`
  margin-bottom: 8px;
`;

export const Subtitle = styled(UtilityText)`
  margin-bottom: 40px;
`;
