/* THIS FILE WAS AUTO-GENERATED. DO NOT MODIFY */
/* eslint-disable */
import * as Types from '../../graph/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StatusReportQueryVariables = Types.Exact<{
  internalCaseRefID: Types.Scalars['String']['input'];
}>;


export type StatusReportQuery = { __typename?: 'Query', statusReport: { __typename?: 'StatusReport', applicationStatus: Types.ApplicationStatus, applicationStage: Types.ApplicationStage, apsMilestones?: Array<Types.AttendingPhysicianStatement | null> | null, createdDate: string, medicalExamStatus?: Types.MedicalExamStatus | null, packetStatus?: Types.PacketStatus | null, referenceId: string, updatedDate: string, creationMethod: Types.CreationMethod, client: { __typename?: 'ReportClient', firstName: string, lastName: string, dateOfBirth?: string | null, state?: string | null, email: string }, lifeAppliedPolicy?: { __typename?: 'LifeAppliedPolicy', carrier: string, product: Types.ProductType, term: number, coverageAmount: number, premium: number, healthClass: string, issueType: Types.IssueType, isAgeChange: boolean, isFinancialModification: boolean, policyNumber?: string | null, policyName: string, premiumMode: Types.PremiumMode, tableRating: number, underwritingClassDecision: Types.UnderwritingClassDecision } | null, referringAgent: { __typename?: 'ReferringAgent', agencyName: string, firstName: string, lastName: string, uuid: string } } };


export const StatusReportDocument = gql`
    query StatusReport($internalCaseRefID: String!) {
  statusReport(input: $internalCaseRefID) {
    applicationStatus
    applicationStage
    apsMilestones
    createdDate
    medicalExamStatus
    packetStatus
    referenceId
    updatedDate
    client {
      firstName
      lastName
      dateOfBirth
      state
      email
    }
    lifeAppliedPolicy {
      carrier
      product
      term
      coverageAmount
      premium
      healthClass
      issueType
      isAgeChange
      isFinancialModification
      policyNumber
      policyName
      premiumMode
      tableRating
      underwritingClassDecision
    }
    referringAgent {
      agencyName
      firstName
      lastName
      uuid
    }
    creationMethod
  }
}
    `;

/**
 * __useStatusReportQuery__
 *
 * To run a query within a React component, call `useStatusReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatusReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatusReportQuery({
 *   variables: {
 *      internalCaseRefID: // value for 'internalCaseRefID'
 *   },
 * });
 */
export function useStatusReportQuery(baseOptions: Apollo.QueryHookOptions<StatusReportQuery, StatusReportQueryVariables> & ({ variables: StatusReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StatusReportQuery, StatusReportQueryVariables>(StatusReportDocument, options);
      }
export function useStatusReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StatusReportQuery, StatusReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StatusReportQuery, StatusReportQueryVariables>(StatusReportDocument, options);
        }
export function useStatusReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<StatusReportQuery, StatusReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<StatusReportQuery, StatusReportQueryVariables>(StatusReportDocument, options);
        }
export type StatusReportQueryHookResult = ReturnType<typeof useStatusReportQuery>;
export type StatusReportLazyQueryHookResult = ReturnType<typeof useStatusReportLazyQuery>;
export type StatusReportSuspenseQueryHookResult = ReturnType<typeof useStatusReportSuspenseQuery>;
export type StatusReportQueryResult = Apollo.QueryResult<StatusReportQuery, StatusReportQueryVariables>;