import { LifeApplicationInfo } from '../context/FormContext/types';
import {
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInMonths,
} from 'date-fns';

export const LARGE_DESKTOP_WIDTH = 1441;

export const V2_CONTAINER_PADDING = '64px 24px 120px 24px';

export const userHasSelectedPolicy = (info: LifeApplicationInfo): boolean =>
  Object.values(info.policySelection).filter(Boolean).length > 0;

export const usdFormat = (num: number) => {
  return num?.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
};

export const moneyToString = (money: string | null) => {
  if (!money) return null;

  return money.replace(/,/g, '');
};

export const dateDifference = (date: string) => {
  const today = new Date();
  const updatedDate = new Date(date);

  const months = Math.abs(differenceInMonths(today, updatedDate));
  const days = Math.abs(differenceInDays(today, updatedDate));
  const hours = Math.abs(differenceInHours(today, updatedDate));
  const minutes = Math.abs(differenceInMinutes(today, updatedDate));

  if (months > 0) {
    if (months === 1) {
      return `1 month ago`;
    }

    return `${months} months ago`;
  } else if (days > 0) {
    if (days === 1) {
      return `a day ago`;
    }

    return `${days} days ago`;
  } else if (hours > 0) {
    if (hours === 1) {
      return `an hour ago`;
    }

    return `${hours} hours ago`;
  } else if (minutes > 0) {
    if (minutes === 1) {
      return `a minute ago`;
    }

    return `${minutes} minutes ago`;
  }

  return 'a few seconds ago';
};

export const toString = (value: unknown): string => {
  if (typeof value === 'string') {
    return value;
  }

  return JSON.stringify(value);
};

export const fromString = (value: string): unknown => {
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
};

export const nameCase = (name: string): string => {
  const splitName = name.split('').slice(1);
  const reformattedName = splitName.map((char: string) => char.toLowerCase());

  return `${name[0].toUpperCase()}${reformattedName.join('')}`;
};

export const encodeReferralUrl = ({
  first,
  last,
  slug,
  uuid,
}: {
  uuid?: string;
  slug?: string;
  last?: string;
  first?: string;
}): string => {
  const referralLink = `${process.env.REACT_APP_PG_BASE_DOMAIN}/pro/referral/${slug}?advisor=${first}-${last}&uuid=${uuid}`;

  return encodeURI(referralLink);
};

export const setLifeApplicationInfo = (lifeApplicationInfo: LifeApplicationInfo): void => {
  sessionStorage.setItem('lifeApplicationInfo', JSON.stringify(lifeApplicationInfo));
};

export const retrieveLifeApplicationInfo = (): LifeApplicationInfo | null => {
  const lifeApplicationInfo = sessionStorage.getItem('lifeApplicationInfo');

  if (!lifeApplicationInfo) {
    return null;
  }

  return JSON.parse(lifeApplicationInfo);
};

export const unsetLifeApplicationInfo = (): void => {
  sessionStorage.removeItem('lifeApplicationInfo');
};
