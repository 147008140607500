import { LabelText, UtilIconExpandMore, theme } from '@policygenius/mortar';
import styled from 'styled-components';
import { HeaderProps } from './types';

export const TableHeader = styled(LabelText)<{
  activeLabel?: boolean;
  isFilter?: boolean;
  show?: boolean;
}>`
  display: ${({ show }) => (show ? 'table-cell' : 'none')};
  text-align: left;
  padding: 12px 16px;
  @media (min-width: ${theme.breakpoints.medium}px) {
    display: table-cell;
    ${({ activeLabel }) =>
      activeLabel &&
      `
    background-color: ${theme.colors.gray100};
      span {
        color: ${theme.colors.gray500};
      }
    `}
    ${({ isFilter }) =>
      isFilter &&
      `&:hover {
      background-color: ${theme.colors.gray100};
      cursor: pointer;
      svg{visibility: initial; color: ${theme.colors.gray400}};
    }`}
  }
`;

export const HeaderNoTablet = styled(TableHeader)<HeaderProps>`
  ${({ alignRight }) =>
    alignRight &&
    `
    text-align: right;
  `}

  @media (min-width: ${theme.breakpoints.medium}px) {
    display: none;
  }

  @media (min-width: ${theme.breakpoints.large}px) {
    display: ${(props) => (props.selected ? 'none' : 'table-cell')};
  }
`;

export const HeaderRightAligned = styled(HeaderNoTablet)`
  text-align: right;
`;

export const LastUpdatedHeader = styled(TableHeader)`
  text-align: right;
`;

export const HeaderNoDesktop = styled.th`
  @media (min-width: ${theme.breakpoints.large}px) {
    display: none;
  }
`;

export const Content = styled.span`
  display: flex;
  align-items: center;
`;

export const SortDescendArrow = styled(UtilIconExpandMore)<{
  selected?: boolean;
  desc?: boolean;
}>`
  margin: 0px 10px 0px 10px;
  visibility: hidden;
  @media (min-width: ${theme.breakpoints.medium}px) {
    ${({ selected }) =>
      selected &&
      `
    visibility: initial;
  `}
  }

  ${({ desc }) =>
    !desc &&
    `
    rotate: 180deg;
  `}
`;

export const ContentRightAlign = styled.span`
  display: flex;
  align-items: center;
  justify-content: right;
`;
