/* THIS FILE WAS AUTO-GENERATED. DO NOT MODIFY */
/* eslint-disable */
import * as Types from '../../graph/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateNotificationPreferenceMutationVariables = Types.Exact<{
  subscriptionPreferences: Array<Types.SubscriptionPreference> | Types.SubscriptionPreference;
}>;


export type UpdateNotificationPreferenceMutation = { __typename?: 'Mutation', updateNotificationPreference: { __typename?: 'Preference', notificationPreferences: Array<{ __typename?: 'NotificationPreference', name: string, description?: string | null, notificationURN: string, subscribed: boolean }> } };


export const UpdateNotificationPreferenceDocument = gql`
    mutation UpdateNotificationPreference($subscriptionPreferences: [SubscriptionPreference!]!) {
  updateNotificationPreference(input: $subscriptionPreferences) {
    notificationPreferences {
      name
      description
      notificationURN
      subscribed
    }
  }
}
    `;
export type UpdateNotificationPreferenceMutationFn = Apollo.MutationFunction<UpdateNotificationPreferenceMutation, UpdateNotificationPreferenceMutationVariables>;

/**
 * __useUpdateNotificationPreferenceMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationPreferenceMutation, { data, loading, error }] = useUpdateNotificationPreferenceMutation({
 *   variables: {
 *      subscriptionPreferences: // value for 'subscriptionPreferences'
 *   },
 * });
 */
export function useUpdateNotificationPreferenceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNotificationPreferenceMutation, UpdateNotificationPreferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNotificationPreferenceMutation, UpdateNotificationPreferenceMutationVariables>(UpdateNotificationPreferenceDocument, options);
      }
export type UpdateNotificationPreferenceMutationHookResult = ReturnType<typeof useUpdateNotificationPreferenceMutation>;
export type UpdateNotificationPreferenceMutationResult = Apollo.MutationResult<UpdateNotificationPreferenceMutation>;
export type UpdateNotificationPreferenceMutationOptions = Apollo.BaseMutationOptions<UpdateNotificationPreferenceMutation, UpdateNotificationPreferenceMutationVariables>;