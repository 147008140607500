import React from 'react';
import ReferralLandingPage from 'pages/ReferralLandingPage';
import { REFERRAL_ROUTE } from './constants';
import { Route, Routes } from 'react-router-dom';

export const PublicRoutes = () => (
  <Routes>
    <Route path={REFERRAL_ROUTE} element={<ReferralLandingPage />} />
  </Routes>
);
