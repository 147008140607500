import { theme, TitleText } from '@policygenius/mortar';
import styled from 'styled-components';
import Button from 'components/Button';

export const Title = styled(TitleText)`
  margin-bottom: 24px;
`;

export const MarginBottomDiv = styled.div`
  margin-bottom: 32px;
`;

export const HealthClassContainer = styled.div`
  margin-bottom: 56px;
`;

export const StyledButton = styled(Button)`
  display: block;
  margin: 8px auto 0 auto;
  width: 100%;
  @media (min-width: ${theme.breakpoints.medium}px) {
    margin: 64px 0px 0px auto;
    width: initial;
  }
  @media (min-width: ${theme.breakpoints.large}px) {
    margin-top: 40px;
  }
`;
