import React from 'react';
import { BodyText, Col, LabelText } from '@policygenius/mortar';
import { Background, Grid, DisclosuresLink, Header, SectionOneText } from './styles';

const MarketingDisclosureContainer = () => (
  <Background>
    <Grid>
      <Col span={{ medium: 12 }}>
        <Header size="large">Marketing Disclosures</Header>
        <SectionOneText>
          <LabelText size="medium" as="span">
            No-exam/accelerated underwriting coverage:&nbsp;
          </LabelText>
          Issuance of a term life insurance policy without a medical exam is subject to product
          availability and your eligibility, and may depend upon your truthful answers to a health
          questionnaire.
        </SectionOneText>
        <BodyText>
          Curious about other prices or claims in our ads? Additional details are available on our{' '}
          <DisclosuresLink
            href={'https://www.policygenius.com/about/disclosures/'}
            forwardedAs="a"
            target="_blank"
            rel="no opener noreferrer"
          >
            Disclosures page
          </DisclosuresLink>
          .
        </BodyText>
      </Col>
    </Grid>
  </Background>
);

export default MarketingDisclosureContainer;
