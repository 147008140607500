import { theme, TitleText } from '@policygenius/mortar';
import styled from 'styled-components';

export const CalculatorContainer = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
`;

export const Background = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${theme.colors.gray100};
  padding: 60px 0;
  margin-bottom: 60px;
`;

export const Title = styled(TitleText)`
  padding: 0 16px;
`;
