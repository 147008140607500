import { Row, theme, UtilityText, TitleText } from '@policygenius/mortar';
import Button from 'components/Button';
import styled from 'styled-components';
import { V2_CONTAINER_PADDING } from 'utils/helpers';

export const FormContainer = styled(Row)`
  padding: 32px 16px;
  @media (min-width: ${theme.breakpoints.medium}px) {
    padding: ${V2_CONTAINER_PADDING};
  }
`;

export const Submitted = styled(TitleText)`
  margin-bottom: 8px;
  margin-left: 16px;

  @media (min-width: ${theme.breakpoints.medium}px) {
    margin-left: 0;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  margin-left: 16px;
  margin-bottom: 16px;
  @media (min-width: ${theme.breakpoints.medium}px) {
    margin: 0px auto 16px;
  }
`;

export const Subtitle = styled(UtilityText)`
  margin-left: 16px;
  margin-right: 16px;
  @media (min-width: ${theme.breakpoints.medium}px) {
    margin-left: 0px;
    margin-right: 0px;
  }
`;

export const TopSubtitle = styled(Subtitle)`
  margin-bottom: 32px;
`;

export const BottomSubtitle = styled(Subtitle)`
  margin-bottom: 40px;
`;

export const ButtonContainer = styled.div`
  margin-right: 16px;
  margin-left: 16px;
  @media (min-width: ${theme.breakpoints.medium}px) {
    display: flex;
    margin: auto 0px;
    gap: 24px;
    justify-content: space-between;
  }
`;

export const ScheduleButton = styled(Button)`
  margin-bottom: 16px;
  @media (min-width: ${theme.breakpoints.medium}px) {
    flex: 2;
  }
`;

export const HomeButton = styled(Button)`
  @media (min-width: ${theme.breakpoints.medium}px) {
    flex: 1;
  }
`;

export const UtilityLink = styled(UtilityText)`
  text-decoration: underline;
`;
