import styled from 'styled-components';
import { LabelText, theme, UtilityText } from '@policygenius/mortar';
import { Shield } from '../ProgressTab/shield';
import { Cake } from '../ProgressTab/cake';
import { Flag } from '../ProgressTab/flag';

export const ApprovalDetails = styled.div`
  padding-bottom: ${theme.spacing('large')};
`;

export const ApprovalDetailsName = styled.div`
  display: flex;
`;

export const CakeIcon = styled(Cake)`
  padding-right: ${theme.spacing('small')};
`;

export const Capitalize = styled(UtilityText)`
  text-transform: capitalize;
`;

export const Container = styled.div`
  padding: ${theme.spacing('medium')};
`;

export const Description = styled(UtilityText)`
  padding-bottom: ${theme.spacing('small')};
`;

export const Empty = styled.div`
  width: 20px;
  height: 1px;
  background: ${theme.colors.gray300};
  margin: ${theme.spacing('small')} 120px ${theme.spacing('small')} 0;
`;

export const FlagIcon = styled(Flag)`
  padding-right: ${theme.spacing('small')};
`;

export const Item = styled.div`
  padding: 12px 0;
`;

export const Label = styled(LabelText)`
  padding-bottom: 4px;
`;

export const ShieldIcon = styled(Shield)`
  padding-right: ${theme.spacing('small')};
`;

export const Status = styled.div`
  display: flex;
`;
