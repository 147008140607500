import React from 'react';
import { UtilityText } from '@policygenius/mortar';
import { Icon, Left, Row } from './styles';
import { ProgressStepProps } from './types';

export const ProgressStep: React.FunctionComponent<ProgressStepProps> = ({
  icon,
  text,
  responsible,
}) => {
  return (
    <Row>
      <Left>
        <Icon>{icon}</Icon>
        <UtilityText size="small">{text}</UtilityText>
      </Left>
      <UtilityText size="small">{responsible}</UtilityText>
    </Row>
  );
};
