import { TitleText, UtilityText } from '@policygenius/mortar';
import Button from 'components/Button';
import styled from 'styled-components';

export const Title = styled(TitleText)`
  margin-bottom: 24px;
`;

export const InputContainer = styled.div`
  margin-bottom: 32px;
`;

export const NotesContainer = styled.div`
  margin-bottom: 24px;
`;

export const Disclaimer = styled(UtilityText)`
  margin-bottom: 40px;
`;

export const ContinueButton = styled(Button)`
  width: 100%;
`;

export const FormTitle = styled(TitleText)`
  padding-bottom: 24px;
`;
