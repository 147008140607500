import { Row, theme, TitleText } from '@policygenius/mortar';
import styled from 'styled-components';

export const Container = styled(Row)`
  background-color: ${theme.colors.gray100};
  text-align: center;
  height: 200px;
  display: grid;
  align-items: center;
`;

export const FeedbackHeader = styled(TitleText)`
  margin-bottom: 8px;
`;
