/* THIS FILE WAS AUTO-GENERATED. DO NOT MODIFY */
/* eslint-disable */
import * as Types from '../../graph/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AvailableProducersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AvailableProducersQuery = { __typename?: 'Query', availableProducers: Array<{ __typename?: 'Producer', firstName: string, lastName: string, email: string, uuid: string } | null> };


export const AvailableProducersDocument = gql`
    query AvailableProducers {
  availableProducers {
    firstName
    lastName
    email
    uuid
  }
}
    `;

/**
 * __useAvailableProducersQuery__
 *
 * To run a query within a React component, call `useAvailableProducersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableProducersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableProducersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableProducersQuery(baseOptions?: Apollo.QueryHookOptions<AvailableProducersQuery, AvailableProducersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailableProducersQuery, AvailableProducersQueryVariables>(AvailableProducersDocument, options);
      }
export function useAvailableProducersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailableProducersQuery, AvailableProducersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailableProducersQuery, AvailableProducersQueryVariables>(AvailableProducersDocument, options);
        }
export function useAvailableProducersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AvailableProducersQuery, AvailableProducersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AvailableProducersQuery, AvailableProducersQueryVariables>(AvailableProducersDocument, options);
        }
export type AvailableProducersQueryHookResult = ReturnType<typeof useAvailableProducersQuery>;
export type AvailableProducersLazyQueryHookResult = ReturnType<typeof useAvailableProducersLazyQuery>;
export type AvailableProducersSuspenseQueryHookResult = ReturnType<typeof useAvailableProducersSuspenseQuery>;
export type AvailableProducersQueryResult = Apollo.QueryResult<AvailableProducersQuery, AvailableProducersQueryVariables>;