import React, { useState } from 'react';
import { getDate, getYear, format, parse } from 'date-fns';
import { UtilIconClose, UtilityText } from '@policygenius/mortar';
import {
  CloseIcon,
  Container,
  CreatedByContainer,
  CreatedByItem,
  CreatedByRow,
  Header,
  LastUpdatedSubheader,
  Tab,
  Tabs,
  TooltipIcon,
} from './styles';
import { dateDifference, nameCase } from 'utils/helpers';
import { Props } from './types';
import { ProgressTab } from './ProgressTab';
import { PolicyTab } from './PolicyTab';
import { ClientTab } from './ClientTab';
import { useMedicalInfoQuery } from 'query/medicalInfo/query';
import { Events, track } from 'utils/analytics';
import { CREATED_BY_TOOLTIP_DEFINITIONS, convertCreationMethodDisplay } from './constants';
import LoadingDetailPanel from './LoadingDetailPanel';
import { useStatusReportQuery } from 'query/statusReport/query';
import { useCurrentUserQuery } from 'query/currentUser/query';
import { Role, TierType, AgencyType } from 'graph/types';
import { useListFilesQuery } from 'query/files/query';
import { DocumentTab } from './DocumentTab';

/* eslint-disable complexity */
const CaseDetailPanel: React.FC<Props> = ({ closeHandler, id }) => {
  const { data, loading, error } = useStatusReportQuery({
    variables: {
      internalCaseRefID: id!,
    },
    fetchPolicy: 'no-cache',
  });

  if (error) {
    console.error(error);
  }

  const {
    data: files,
    loading: filesLoading,
    error: filesError,
  } = useListFilesQuery({
    variables: {
      filter: `reference_id=${id}`,
      orderBy: '',
      pageSize: 25,
      pageToken: '',
    },
  });

  if (error) {
    console.error(`Error fetching files: ${filesError}`);
  }

  const {
    data: medicalData,
    loading: medicalInfoLoading,
    error: medicalInfoError,
  } = useMedicalInfoQuery({
    variables: {
      refID: id!,
    },
  });

  if (medicalInfoError) {
    console.error(medicalInfoError);
  }

  const { data: userData, loading: userLoading, error: userError } = useCurrentUserQuery();

  if (userError) {
    console.error(userError);
  }

  const createdDate = parse(
    data?.statusReport.createdDate.slice(0, -4) || '',
    'yyyy-MM-dd H:mm:ss xx',
    new Date()
  );
  const [selectedTab, setSelectedTab] = useState('Progress');

  const APSstatuses = data?.statusReport.apsMilestones || [];

  const selectTab = (tab: string) => {
    track(Events.CLICKED_TAB, {
      tabName: tab,
    });
    setSelectedTab(tab);
  };

  const isAdmin = userData?.currentUser.roles.includes(Role.Admin);
  const isMultiTier = userData?.currentUser.tierType === TierType.MultiTier;
  const renderOrganization = isAdmin && isMultiTier;

  return (
    <React.Fragment>
      {loading || medicalInfoLoading || userLoading || filesLoading ? (
        <LoadingDetailPanel />
      ) : (
        <Container>
          <Header>
            <div>
              <UtilityText weight="bold" size="large">
                {data?.statusReport.client.firstName} {data?.statusReport.client.lastName}
              </UtilityText>
              <LastUpdatedSubheader size="small">
                Last updated {dateDifference(data?.statusReport.updatedDate || '')}
              </LastUpdatedSubheader>
            </div>
            <CloseIcon data-testid="closeIcon" onClick={closeHandler}>
              <UtilIconClose size="large" />
            </CloseIcon>
          </Header>
          <CreatedByContainer>
            {renderOrganization && (
              <CreatedByRow>
                <CreatedByItem size="small">Organization</CreatedByItem>
                <CreatedByItem size="small" weight="bold">
                  {data?.statusReport.referringAgent.agencyName}
                </CreatedByItem>
              </CreatedByRow>
            )}
            <CreatedByRow>
              <CreatedByItem size="small">Advisor</CreatedByItem>
              <CreatedByItem size="small" weight="bold">
                {`${nameCase(data!.statusReport.referringAgent?.firstName)} ${nameCase(
                  data!.statusReport.referringAgent?.lastName
                )}`}
              </CreatedByItem>
            </CreatedByRow>
            <CreatedByRow>
              <CreatedByItem size="small">Created via</CreatedByItem>
              <CreatedByItem size="small" weight="bold">
                {convertCreationMethodDisplay[data!.statusReport.creationMethod]}
                <TooltipIcon>
                  {CREATED_BY_TOOLTIP_DEFINITIONS[data!.statusReport.creationMethod]}
                </TooltipIcon>
              </CreatedByItem>
            </CreatedByRow>
            <CreatedByRow>
              <CreatedByItem size="small">Created</CreatedByItem>
              <CreatedByItem size="small" weight="bold">
                {format(createdDate, 'MMM')} {getDate(createdDate)}, {getYear(createdDate)}
              </CreatedByItem>
            </CreatedByRow>
          </CreatedByContainer>
          <React.Fragment>
            <Tabs>
              <Tab
                isSelected={selectedTab === 'Progress'}
                color={selectedTab === 'Progress' ? 'primary' : 'secondary'}
                onClick={() => {
                  selectTab('Progress');
                }}
              >
                Progress
              </Tab>
              <Tab
                isSelected={selectedTab === 'Policy'}
                color={selectedTab === 'Policy' ? 'primary' : 'secondary'}
                onClick={() => {
                  selectTab('Policy');
                }}
              >
                Policy
              </Tab>
              {userData?.currentUser.agencyType !== AgencyType.FeeOnly && (
                <Tab
                  isSelected={selectedTab === 'Client'}
                  color={selectedTab === 'Client' ? 'primary' : 'secondary'}
                  onClick={() => {
                    selectTab('Client');
                  }}
                >
                  Client
                </Tab>
              )}
              {!!files?.listFiles?.files?.length && (
                <Tab
                  isSelected={selectedTab === 'Files'}
                  color={selectedTab === 'Files' ? 'primary' : 'secondary'}
                  onClick={() => {
                    selectTab('Files');
                  }}
                >
                  Documents
                </Tab>
              )}
            </Tabs>
            {selectedTab === 'Progress' && (
              <ProgressTab
                applicationStage={data!.statusReport.applicationStage}
                applicationStatus={data!.statusReport.applicationStatus}
                examDate={
                  medicalData?.medicalInfo.examDate
                    ? new Date(medicalData.medicalInfo.examDate)
                    : undefined
                }
                medicalExamStatus={data!.statusReport.medicalExamStatus}
                packetStatus={data!.statusReport.packetStatus}
                APSstatuses={APSstatuses}
              />
            )}
            {selectedTab === 'Policy' && (
              <PolicyTab
                carrier={data!.statusReport.lifeAppliedPolicy?.carrier}
                isAgeChange={data!.statusReport.lifeAppliedPolicy?.isAgeChange}
                isFinancialModification={
                  data!.statusReport.lifeAppliedPolicy?.isFinancialModification
                }
                issueType={data!.statusReport.lifeAppliedPolicy?.issueType}
                policyNumber={data!.statusReport.lifeAppliedPolicy?.policyNumber}
                product={data!.statusReport.lifeAppliedPolicy?.product}
                policyName={data!.statusReport.lifeAppliedPolicy?.policyName}
                healthClass={data!.statusReport.lifeAppliedPolicy?.healthClass}
                coverageAmount={data!.statusReport.lifeAppliedPolicy?.coverageAmount}
                term={data!.statusReport.lifeAppliedPolicy?.term}
                premium={data!.statusReport.lifeAppliedPolicy?.premium}
                premiumMode={data!.statusReport.lifeAppliedPolicy?.premiumMode}
                tableRating={data!.statusReport.lifeAppliedPolicy?.tableRating}
                underwritingClassDecision={
                  data!.statusReport.lifeAppliedPolicy?.underwritingClassDecision
                }
              />
            )}
            {selectedTab === 'Client' && <ClientTab client={data?.statusReport.client} />}
            {selectedTab === 'Files' && <DocumentTab files={files?.listFiles} />}
          </React.Fragment>
        </Container>
      )}
    </React.Fragment>
  );
};

export default CaseDetailPanel;
