import React, { useEffect } from 'react';
import { Button, UtilityText } from '@policygenius/mortar';
import { Container, Background, UtilityLink, Row, LabelContainer, Title, Terms } from './styles';
import { PgProLogo } from 'components/PgIcons/PgProLogo';
import { CurrentUserDocument, useCurrentUserQuery } from 'query/currentUser/query';
import { useAcceptTermsAndConditionsMutation } from 'mutation/acceptTermsAndConditions/mutation';
import { useNavigate } from 'react-router-dom';
import { INDEX_ROUTE } from 'routes/constants';

const TermsAndConditions: React.FC = () => {
  const { data, loading } = useCurrentUserQuery();
  const navigate = useNavigate();

  const [acceptedTerms] = useAcceptTermsAndConditionsMutation({
    onCompleted: () => {
      navigate(INDEX_ROUTE);
    },
    onError: (e) => {
      console.log(e);
    },
    refetchQueries: [{ query: CurrentUserDocument }],
  });

  useEffect(() => {
    if (data?.currentUser.portalOptions.acceptedTermsAndConditions) {
      navigate(INDEX_ROUTE);
    }
  }, [loading, data]);

  return (
    <Container>
      <Background>
        <Row>
          <LabelContainer>
            <PgProLogo />
          </LabelContainer>
          <Title size="large">
            Join {data?.currentUser.highestTierParentName} on Policygenius Pro
          </Title>
          <Terms size="large">
            By clicking &quot;Continue&quot; you agree to the Policygenius Pro Portal{' '}
            <UtilityLink
              forwardedAs="a"
              href="https://www.policygenius.com/about/policygenius-pro-terms"
              target="_blank"
              rel="noopener noreferrer"
              size="large"
              color="orange"
            >
              Terms of Use.
            </UtilityLink>{' '}
          </Terms>
          <Button onClick={() => acceptedTerms()} variant="black">
            Continue
          </Button>
          <UtilityText size="small">
            Need help? Contact us at pgproaccounts@policygenius.com
          </UtilityText>
        </Row>
      </Background>
    </Container>
  );
};

export default TermsAndConditions;
