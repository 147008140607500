import styled from 'styled-components';
import { LabelText, theme } from '@policygenius/mortar';
import MaskedTextField from 'components/MaskedTextField';
import Button from 'components/Button';

export const Continue = styled(Button)`
  margin-bottom: 24px;
  width: fit-content;
  @media (min-width: ${theme.breakpoints.medium}px) {
    align-self: flex-end;
    margin: 0;
  }
  @media (min-width: ${theme.breakpoints.large}px) {
    padding-bottom: 10px;
  }
`;

export const Coverage = styled(MaskedTextField)`
  margin-bottom: 16px;
  & div: {
    padding: 1rem;
    height: 58px;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${theme.breakpoints.medium}px) {
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 24px;
    gap: 16px;
  }
`;

export const Label = styled(LabelText)`
  margin-bottom: 8px;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HealthClassContainer = styled(LabelContainer)`
  padding-bottom: 16px;
  @media (min-width: ${theme.breakpoints.medium}px) {
    padding-bottom: initial;
  }
`;

export const LabelContainerTermAndPayment = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  @media (min-width: ${theme.breakpoints.medium}px) {
    width: initial;
  }
`;

export const TermPayment = styled.div`
  justify-content: space-between;
  display: flex;
  margin-bottom: 24px;
  gap: 16px;
  @media (min-width: ${theme.breakpoints.medium}px) {
    & div: {
      width: auto;
    }
    margin-bottom: 0;
  }
`;
