// Useful constants
export const AGE_MIN = 18;
export const AGE_MAX = 85;

// Errors
export const EMAIL_ERROR = "That doesn't look like a valid email!";
export const DATE_IN_PAST_ERROR = 'Must be a date in the past';
export const DATE_BETWEEN_ERROR = "That doesn't look like a valid date";
export const DOB_RANGE_ERROR = `Whoops! You must be between ages ${AGE_MIN}-${AGE_MAX} to get quotes.`;
export const IS_PRESENT_ERROR = 'This field is required';
export const PASSWORD_ERROR = 'Must be at least 8 characters';
export const PHONE_ERROR = 'Must be a valid phone number';
export const ZIPCODE_ERROR = "That doesn't look like a valid ZIP code";
