import React from 'react';
import AppRouter from 'routes';
import { useNavigate } from 'react-router-dom';
import { MortarProvider } from '@policygenius/mortar';
import { Auth0Provider, AppState } from '@auth0/auth0-react';
import Authenticator from 'components/Authenticator';
import {
  OptimizelyProvider,
  createInstance,
  OptimizelyDecideOption,
  enums,
} from '@optimizely/react-sdk';
import ApolloWrapper from 'components/ApolloWrapper';

const optimizelyClient = createInstance({
  datafile: window.optimizelyDatafile,
  sdkKey: process.env.REACT_APP_OPTIMIZELY,
  logLevel: enums.LOG_LEVEL.WARNING,
});

const disableOptimizely = createInstance({
  datafile: window.optimizelyDatafile,
  defaultDecideOptions: [OptimizelyDecideOption.DISABLE_DECISION_EVENT],
});

const App: React.FC = () => {
  const params = new URLSearchParams(window.location.search);
  const organization = params.get('org');
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    if (appState?.returnTo && appState.returnTo !== '/logout') {
      navigate(appState.returnTo);
    }

    navigate(window.location.pathname);
  };

  return (
    <OptimizelyProvider
      optimizely={
        window.location.search.includes('optimizely_disable=true')
          ? disableOptimizely
          : optimizelyClient
      }
      user={{ id: 'agent-portal' }}
    >
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN!}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
        onRedirectCallback={onRedirectCallback}
        authorizationParams={{
          /* eslint-disable-next-line camelcase */
          redirect_uri: window.location.origin,
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          organization: organization || undefined,
        }}
      >
        <Authenticator>
          <ApolloWrapper>
            <MortarProvider>
              <AppRouter />
            </MortarProvider>
          </ApolloWrapper>
        </Authenticator>
      </Auth0Provider>
    </OptimizelyProvider>
  );
};

export default App;
