import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, LabelText, LoadingIndicator, Modal } from '@policygenius/mortar';
import {
  Background,
  ButtonContainer,
  CardMail,
  CardSend,
  CardShare,
  ForkContainer,
  Line,
  MobileButton,
  MobileButtonContainer,
  NavigationCard,
  NewBadge,
  ReferralLinkTitle,
  ReferralURL,
  ReferralURLContainer,
  SectionTitle,
  Spacer,
  Subtext,
  Subtitle,
  Title,
} from './styles';
import { CLIENT_INFO_ROUTE, DROP_TICKET_ROUTE } from 'routes/constants';
import { useCurrentUserQuery } from 'query/currentUser/query';
import Button from 'components/Button';
import { Duplicate } from './duplicate';
import { encodeReferralUrl } from 'utils/helpers';
import { Role } from 'graph/types';

const ForkPage: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [copyButtonText, setCopyButtonText] = useState('Copy link');
  const navigate = useNavigate();
  const { data, loading, error } = useCurrentUserQuery();

  if (error) {
    console.error(error);
  }

  if (loading) {
    return <LoadingIndicator color={'black'} />;
  }

  const referralLink = encodeReferralUrl({
    slug: data?.currentUser.agencySlug,
    first: data?.currentUser.firstName,
    last: data?.currentUser.lastName,
    uuid: data?.currentUser.uuid,
  });

  const isAssistant = data?.currentUser.roles.includes(Role.Assistant);

  return (
    <Background>
      <ForkContainer>
        <Col
          span={{
            medium: 11,
            small: 4,
          }}
          push={{
            medium: 1,
          }}
        >
          <Title size="xlarge">Start a new Life insurance case</Title>
          <Subtitle size="large">Choose a path below to get started.</Subtitle>
        </Col>
        <Col
          span={{
            large: 3,
            medium: 5,
            small: 4,
          }}
          push={{
            medium: 1,
          }}
        >
          <SectionTitle size="small">Submit a drop ticket</SectionTitle>
          <NavigationCard
            analyticsText="Referral only"
            onClick={() => {
              navigate(DROP_TICKET_ROUTE);
            }}
          >
            <CardSend />
            <LabelText>Referral only</LabelText>
            <Subtext size="small">
              Submit basic client info and Policygenius handles the rest
            </Subtext>
          </NavigationCard>
        </Col>
        <Col
          span={{
            large: 3,
            medium: 5,
            small: 4,
          }}
        >
          <Spacer />
          <NavigationCard
            analyticsText="Quotes and referral"
            onClick={() => {
              navigate(CLIENT_INFO_ROUTE);
            }}
          >
            <CardMail />
            <LabelText>Quotes and referral</LabelText>
            <Subtext size="small">Compare your client’s options before submission</Subtext>
          </NavigationCard>
        </Col>
        {!isAssistant && (
          <>
            <Col
              span={{
                large: 1,
                medium: 0,
                small: 0,
              }}
            >
              <Line />
            </Col>
            <Col
              span={{
                large: 3,
                medium: 5,
                small: 4,
              }}
              push={{
                large: 0,
                medium: 1,
              }}
            >
              <ReferralLinkTitle size="small">
                Share referral link <NewBadge label="New" variant="NEW" />
              </ReferralLinkTitle>
              <NavigationCard
                analyticsText="Share referral link"
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                <CardShare />
                <LabelText>Unique referral link</LabelText>
                <Subtext size="small">
                  Clients can apply directly from your co-branded landing page
                </Subtext>
              </NavigationCard>
            </Col>
          </>
        )}
      </ForkContainer>
      <Modal
        showModal={isOpen}
        onRequestClose={() => {
          setIsOpen(false);
          setCopyButtonText('Copy link');
        }}
        title="Share referral link"
        description="Your unique referral link leads to a co-branded, life insurance landing page. From here, your clients can apply on their own time while you track their progress in the portal."
      >
        <ReferralURLContainer>
          <ReferralURL
            id="referral-link"
            href={referralLink}
            target="_blank"
            rel="no opener noreferrer"
            variant="secondary"
          >
            {referralLink}
          </ReferralURL>
        </ReferralURLContainer>
        <ButtonContainer fullWidth>
          <Col
            span={{
              small: 0,
              medium: 4,
            }}
            push={{
              small: 0,
              medium: 10,
            }}
          >
            <Button
              variant="filled"
              onClick={() => {
                navigator.clipboard.writeText(referralLink);
                setCopyButtonText('Copied!');
                setTimeout(() => setCopyButtonText('Copy link'), 1000);
              }}
              analyticsText={'copied referral link'}
            >
              <span>{copyButtonText}</span>
              <Duplicate />
            </Button>
          </Col>
        </ButtonContainer>
        <MobileButtonContainer fullWidth>
          <Col
            span={{
              small: 4,
              medium: 0,
            }}
          >
            <MobileButton
              variant="filled"
              onClick={() => {
                navigator.clipboard.writeText(referralLink);
                setCopyButtonText('Copied!');
                setTimeout(() => setCopyButtonText('Copy link'), 1000);
              }}
              analyticsText={'copied referral link'}
            >
              <span>{copyButtonText}</span>
              <Duplicate />
            </MobileButton>
          </Col>
        </MobileButtonContainer>
      </Modal>
    </Background>
  );
};

export default ForkPage;
