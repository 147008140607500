import React from 'react';
import {
  Body,
  ContainerRow,
  PartnerLogo,
  GetQuotesButton,
  Title,
  RightCol,
  PoweredByText,
  PoweredByContainer,
  PoweredByBorder,
} from './styles';
import { Col, theme } from '@policygenius/mortar';
import { useWindowSize } from 'hooks/useWindowSize';
import { Props } from './types';
import PgLogo from 'components/PgIcons/PgLogo';

const DEFAULT_IMAGE_URL =
  'https://images.ctfassets.net/u8ph4bd6pxag/2sx4TklxVKv6tR49v3MdhK/f52b5be81c140385a21eabaab6bbd323/Life.png';

export const DEFAULT_VIDEO_URL =
  'https://images.ctfassets.net/3uw9cov4u60w/6aG5Qq8IjhQIYYGKAWcrRP/044d8c570eba1ef20379e6d70e638e64/Landing_Compare_cropped.gif';

const Hero: React.FunctionComponent<Props> = ({ agentUUID, imageURL, subtext, title }) => {
  const windowSize = useWindowSize();
  const buttonFullWidth = !!windowSize.width && windowSize.width < theme.breakpoints.medium;

  const getQuotesURL = `${process.env.REACT_APP_LIFE_WEB_FLOW_URL}?agent_uuid=${agentUUID}`;

  const openQuotesForm = () => {
    window.open(getQuotesURL, '_blank')?.focus();
  };

  let srcURL = imageURL;

  if (imageURL === '' || imageURL === DEFAULT_IMAGE_URL) {
    srcURL = DEFAULT_VIDEO_URL;
  }

  return (
    <ContainerRow>
      <Col span={{ small: 4, medium: 12 }}>
        <PoweredByContainer>
          <PoweredByText size="small" color="secondary">
            Powered by{' '}
          </PoweredByText>
          <PgLogo />
        </PoweredByContainer>
        <PoweredByBorder />
      </Col>
      <Col span={{ small: 4, medium: 6 }}>
        <Title size={{ small: 'xlarge', medium: 'xxlarge' }}>{title}</Title>
        <Body size={{ small: 'large', medium: 'xlarge' }}>{subtext}</Body>
        <GetQuotesButton
          analyticsText="Get quotes"
          fullWidth={buttonFullWidth}
          size="large"
          onClick={openQuotesForm}
        >
          Get quotes
        </GetQuotesButton>
      </Col>
      <RightCol span={{ small: 4, medium: 6 }}>
        <PartnerLogo src={srcURL} />
      </RightCol>
    </ContainerRow>
  );
};

export default Hero;
