import React, { useEffect, useState } from 'react';
import {
  Button as MortarButton,
  Col,
  LoadingIndicator,
  NavigatePrevious,
  UtilityText,
} from '@policygenius/mortar';
import { useFormContext } from 'context/FormContext';
import { retrieveLifeApplicationInfo, userHasSelectedPolicy } from 'utils/helpers';
import {
  Title,
  Container,
  Subheader,
  SelectedPolicyContainer,
  SelectedPolicyTitle,
  SelectedPolicyBody,
  Name,
  ButtonContainer,
  ButtonLoading,
} from './styles';
import { Background } from 'components/Background';
import PolicyPlayback from 'components/PolicyPlayback';
import { Actions } from 'context/FormContext/types';
import { useCurrentUserQuery } from 'query/currentUser/query';
import { Role } from 'graph/types';
import { useNavigate, useParams } from 'react-router-dom';
import { useCompleteEappMutation } from 'mutation/completeEapp/mutation';
import Button from 'components/Button';
import { EAPP_ROUTE } from 'routes/constants';
import { useToastContext } from 'context/ToastContext';
import { TOAST_TEXT } from 'pages/DropTicket/constants';

export const EAppReview = () => {
  const navigate = useNavigate();
  const { refId } = useParams();
  const { data: userData, error } = useCurrentUserQuery();
  const [submitting, setSubmitting] = useState(false);

  if (error) {
    console.error(error);
  }

  const { state, dispatch } = useFormContext();
  const { show } = useToastContext();

  useEffect(() => {
    const lifeApplicationInfo = retrieveLifeApplicationInfo();

    if (lifeApplicationInfo) {
      dispatch({
        type: Actions.UPDATE_CLIENT,
        payload: lifeApplicationInfo,
      });
      dispatch({
        type: Actions.SELECT_POLICY,
        payload: lifeApplicationInfo.policySelection,
      });
    }
  }, []);

  const [completeEapp] = useCompleteEappMutation({
    onCompleted: () => {
      navigate(`${EAPP_ROUTE}/confirmation/${refId}`);
      setSubmitting(false);
    },
    onError: (err) => {
      show({
        type: 'error',
        title: TOAST_TEXT.errorEAppSubmission.title,
        body: TOAST_TEXT.errorEAppSubmission.body,
      });

      console.error('error completing eapp', err);
      setSubmitting(false);
    },
  });

  const submit = async () => {
    setSubmitting(true);
    await completeEapp({
      variables: {
        internalCaseReferenceID: refId!,
      },
    });
  };
  const isAssistant = userData?.currentUser.roles.includes(Role.Assistant);

  const getReferringAgent = () => {
    if (isAssistant) {
      return `${state.referringAgent?.firstName} ${state.referringAgent?.lastName}`;
    }

    return `${userData?.currentUser.firstName} ${userData?.currentUser?.lastName}`;
  };

  return (
    <Background>
      <Container>
        <Col
          span={{
            small: 4,
            medium: 10,
            large: 7,
          }}
          push={{ medium: 1, large: 1 }}
        >
          <Title size="xlarge">Review and submit eApp</Title>
          <Subheader size="large">
            Ensure the information below is accurate before submitting
          </Subheader>
          <Name>
            <UtilityText size="large" weight="bold">
              Referring advisor:
            </UtilityText>
            <UtilityText size="large">{getReferringAgent()}</UtilityText>
          </Name>
          <SelectedPolicyContainer>
            <SelectedPolicyTitle size="medium">Selected policy</SelectedPolicyTitle>
            <SelectedPolicyBody>
              Please note that this is an estimate and the final price is subject to change.
            </SelectedPolicyBody>
            {userHasSelectedPolicy(state) && <PolicyPlayback />}
          </SelectedPolicyContainer>
          <ButtonContainer>
            <MortarButton variant="filled" size="large" onClick={() => navigate(-1)}>
              <NavigatePrevious size="large" color="black" />
              <span>Back</span>
            </MortarButton>
            <Button size="large" onClick={submit} analyticsText="Submit eApp" variant="black">
              <span style={{ visibility: submitting ? 'hidden' : 'visible' }}>Submit eApp</span>
              {submitting && (
                <ButtonLoading>
                  <LoadingIndicator color="white" />
                </ButtonLoading>
              )}
            </Button>
          </ButtonContainer>
        </Col>
      </Container>
    </Background>
  );
};
