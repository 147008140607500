import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
// eslint-disable-next-line import/no-unassigned-import
import '@policygenius/mortar/dist/theme/fonts.css';

ReactDOM.render(
  <React.StrictMode>
    <Router basename={process.env.REACT_APP_UAT_BASE && `/${process.env.REACT_APP_UAT_BASE}`}>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
