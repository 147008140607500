import React from 'react';

export const LifePlus: React.FunctionComponent = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_295_334)">
      <path
        d="M12.073 7.43536C13.6505 7.43536 14.9294 6.15649 14.9294 4.57893C14.9294 3.00137 13.6505 1.7225 12.073 1.7225C10.4954 1.7225 9.21655 3.00137 9.21655 4.57893C9.21655 6.15649 10.4954 7.43536 12.073 7.43536Z"
        fill="#FF6E00"
      />
      <path
        d="M20.0744 14.2925V6.29321C15.6559 6.29321 12.073 9.87607 12.073 14.2925H20.0744Z"
        fill="#007562"
      />
      <path
        d="M12.073 14.2947V22.2939C16.4916 22.2939 20.0744 18.7111 20.0744 14.2925L12.073 14.2947Z"
        fill="black"
      />
      <path
        d="M12.073 14.2925C12.073 9.87393 8.49011 6.29108 4.07153 6.29108V14.2904C4.07153 18.7111 7.65439 22.2939 12.073 22.2939V14.2925Z"
        fill="#D44513"
      />
      <g clipPath="url(#clip1_295_334)">
        <path
          d="M18.5 24C21.5376 24 24 21.5376 24 18.5C24 15.4624 21.5376 13 18.5 13C15.4624 13 13 15.4624 13 18.5C13 21.5376 15.4624 24 18.5 24Z"
          fill="white"
        />
        <path
          d="M17.9167 17.9167V15.5833C17.9167 15.2625 18.1792 15 18.5 15C18.8208 15 19.0833 15.2625 19.0833 15.5833V17.9167H21.4167C21.7375 17.9167 22 18.1792 22 18.5C22 18.8208 21.7375 19.0833 21.4167 19.0833H19.0833V21.4167C19.0833 21.7375 18.8208 22 18.5 22C18.1792 22 17.9167 21.7375 17.9167 21.4167V19.0833H15.5833C15.2625 19.0833 15 18.8208 15 18.5C15 18.1792 15.2625 17.9167 15.5833 17.9167H17.9167Z"
          fill="#383838"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_295_334">
        <rect width="24" height="24" fill="white" />
      </clipPath>
      <clipPath id="clip1_295_334">
        <rect width="11" height="11" fill="white" transform="translate(13 13)" />
      </clipPath>
    </defs>
  </svg>
);
