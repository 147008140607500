import { theme, UtilityText } from '@policygenius/mortar';
import styled from 'styled-components';

export const Empty = styled.div`
  margin: auth 130px auto 0;
  width: 20px;
  height: 1px;
  background: ${theme.colors.gray300};
`;

export const Wrapper = styled.div`
  display: flex;
`;

export const CircleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Title = styled(UtilityText)`
  text-transform: capitalize;
  display: initial;
  margin-left: 8px;
`;
