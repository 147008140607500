import { BadgeVariant, themeColorMap } from './types';
import styled from 'styled-components';

export const BadgeWrapper = styled.div<{ variant: BadgeVariant }>`
  background-color: ${({ variant }) => `${themeColorMap[variant]}`};
  margin-left: ${({ variant }) => (variant === 'BETA' ? '16px' : '0px')};
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
  padding: 0px 12px;
  width: fit-content;
  border-radius: 100px;
`;
