import React from 'react';
import { AttendingPhysicianStatement, MedicalExamStatus, PacketStatus } from 'graph/types';
import { Container, Left } from './styles';
import { StatusBar } from './StatusBar';
import { PaperworkStatus } from './PaperworkStatus';
import { MedicalExam } from './MedicalExam';
import { ProgressTabProps } from './types';
import CollapsibleDetailsContainer from 'components/CollapsibleDetailsContainer';
import { Item } from 'components/CollapsibleDetailsContainer/types';
import { BodyText, LabelText, Tooltip } from '@policygenius/mortar';
import { MEDICAL_RECORD_TOOLTIP } from './constants';
import { MedicalRecords } from './MedicalRecords/MedicalRecords';

export const ProgressTab: React.FunctionComponent<ProgressTabProps> = ({
  applicationStage,
  applicationStatus,
  examDate,
  medicalExamStatus,
  packetStatus,
  APSstatuses,
}) => {
  const renderPaperworkStatus =
    packetStatus &&
    packetStatus !== PacketStatus.PaperworkStatusUnspecified &&
    packetStatus !== PacketStatus.NotRequired;
  const renderMedExamStatus =
    medicalExamStatus &&
    medicalExamStatus !== MedicalExamStatus.MedExamStatusUnspecified &&
    medicalExamStatus !== MedicalExamStatus.Other;
  const renderAPSstatus = APSstatuses && APSstatuses.length;

  const tasks: Item[] = [];

  if (renderPaperworkStatus) {
    tasks.push({
      name: <LabelText size="medium">Application paperwork</LabelText>,
      body: <PaperworkStatus packetStatus={packetStatus} />,
    });
  }

  if (renderMedExamStatus) {
    tasks.push({
      name: <LabelText size="medium">Medical exam</LabelText>,
      body: <MedicalExam medicalExamStatus={medicalExamStatus} examDate={examDate} />,
    });
  }

  if (renderAPSstatus) {
    APSstatuses.map((status, index) => {
      return tasks.push({
        name: (
          <Left>
            <LabelText size="medium">
              Medical Records Request {APSstatuses?.length === 1 ? '' : `(${index + 1})`}
            </LabelText>
            <Tooltip headerText="Medical records request">
              <BodyText>{MEDICAL_RECORD_TOOLTIP}</BodyText>
            </Tooltip>
          </Left>
        ),
        body: <MedicalRecords status={status || AttendingPhysicianStatement.StatusUnknown} />,
      });
    });
  }

  return (
    <Container>
      <StatusBar applicationStage={applicationStage} applicationStatus={applicationStatus} />
      {tasks.length !== 0 && <CollapsibleDetailsContainer labelText="Tasks" items={tasks} />}
    </Container>
  );
};
