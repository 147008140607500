import React from 'react';

export const Check: React.FunctionComponent = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14px" height="14px">
    <g id="Form-Flow" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M14.7325585,3.55271368e-15 C14.4131587,3.55271368e-15 14.0778968,0.120713478 13.8521727,0.338535043 L5.85459799,8.05351284 L2.14379408,4.49683804 C1.87520048,4.23266612 1.61939246,4.1434256 1.26451216,4.13566702 C0.909631854,4.12790844 0.611832176,4.23649751 0.35441445,4.49683804 C0.0969967237,4.75717858 -0.00700821186,5.02494755 0.000364523118,5.37676574 C0.0077372581,5.72858394 0.120840809,5.98908173 0.35441445,6.22227572 L4.06999602,9.77212302 C4.59855104,10.3131649 4.93105692,10.6303034 5.06751367,10.7235385 C5.27219878,10.8633911 5.50926027,11 5.85459799,11 C6.1999357,11 6.52446857,10.8740357 6.75410585,10.6532207 C6.90719737,10.5060106 7.20247374,10.2123114 7.63993498,9.77212302 L15.6398089,2.06397272 C15.8649885,1.8456246 16.0011217,1.52633834 15.999993,1.21846274 C15.9988644,0.910587148 15.8665838,0.55534378 15.6398089,0.338535043 C15.4140848,0.120713478 15.0519584,3.55271368e-15 14.7325585,3.55271368e-15 Z"
        id="Shape"
        fill="#00CC00"
        fillRule="nonzero"
      />
    </g>
  </svg>
);
