import { IMask } from 'react-imask';

export const phoneMask: IMask.AnyMaskedOptions = {
  mask: '(000) 000-0000',
};

export const numberMask: IMask.AnyMaskedOptions = {
  mask: Number,
  thousandsSeparator: ',',
};
