import { BadgeVariant } from 'components/Badge/types';
import { ApplicationStatus } from 'graph/types';

export const HEADER_DATA = {
  number: '#',
  client: 'Client | Created',
  status: 'Status',
  stage: 'Stage',
  product: 'Carrier | Product',
  term: 'Term',
  coverage: 'Coverage',
  premium: 'Premium(est.)',
  lastUpdated: 'Last updated',
  createdBy: 'Created by',
};

export const STATUS_BADGE: StatusMapping = {
  IN_FORCE: { statusName: 'In force', variant: 'SUCCESS' },
  LAPSED: { statusName: 'Lapsed', variant: 'WARNING' },
  POSTPONED: { statusName: 'Postponed', variant: 'WARNING' },
  WITHDRAWN: { statusName: 'Withdrawn', variant: 'DANGER' },
  DECLINED: { statusName: 'Declined', variant: 'DANGER' },
  PHONE_DECLINE: { statusName: 'Phone decline', variant: 'DANGER' },
  APPROVED: { statusName: 'Approved', variant: 'SUCCESS' },
  PENDING: { statusName: 'Pending', variant: 'NEUTRAL' },
  NOT_TAKEN: { statusName: 'Not Taken', variant: 'DANGER' },
  SURRENDERED: { statusName: 'Surrendered', variant: 'DANGER' },
  DECEASED: { statusName: 'Deceased', variant: 'DANGER' },
  COLD: { statusName: 'Cold', variant: 'WARNING' },
};

export type StatusMapping = {
  [key in ApplicationStatus]: { statusName: string; variant: BadgeVariant };
};
