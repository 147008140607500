import styled, { keyframes } from 'styled-components';
import { Button, Checkbox, LabelText, RadioGroup, theme } from '@policygenius/mortar';

export const ButtonContainer = styled.div`
  display: flex;
  padding: 16px;
  justify-content: space-between;
  border-top: 1px solid ${theme.colors.gray100};
  box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.05);
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
`;

const slideRight = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  background-color: ${theme.colors.white};
  animation: 0.2s ease-in ${slideRight};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const Divider = styled.hr`
  margin-bottom: 24px;
  border-top: 1px solid ${theme.colors.gray200};
`;

export const HeaderContainer = styled.div`
  border-bottom: 1px solid ${theme.colors.gray100};
  padding: 16px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
`;

export const OptionsContainer = styled.div`
  margin-top: 16px;
  margin-left: 16px;
  position: absolute;
  top: 60px;
  bottom: 72px;
  left: 0px;
  overflow: auto;
`;

export const ResetButton = styled(Button)`
  margin-right: 16px;
`;

export const SortRadioGroup = styled(RadioGroup)`
  margin-bottom: 16px;
`;

export const StatusCheckbox = styled(Checkbox)`
  padding: 12px 0px 12px;
`;

export const StatusContainer = styled.div<{ bottomMargin: number }>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => props.bottomMargin}px;
`;

export const StatusLabel = styled(LabelText)`
  margin-bottom: 16px;
`;
