import React from 'react';
import { usePoliciesQuery } from 'query/policy/query';
import { useFormContext } from 'context/FormContext';
import { Col, LoadingIndicator, UtilityText } from '@policygenius/mortar';
import { differenceInYears } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { EditClientButton, FormContainer, Title, ContinueButton } from './styles';
import { Form } from 'react-final-form';
import { InitialValues } from 'forms/types';
import InputFields from './InputFields';
import { conditionPolicies, getValidator } from './utils';
import Card from 'components/Card';
import { LifeApplicationInfo } from 'context/FormContext/types';
import { track, Events } from 'utils/analytics';
import { CLIENT_INFO_ROUTE, DROP_TICKET_ROUTE } from 'routes/constants';
import { Background } from 'components/Background';
import { useCurrentUserQuery } from 'query/currentUser/query';
import { useDecision } from '@optimizely/react-sdk';
import { EAPP_INTERVIEW } from 'utils/optimizelyConstants';
import { AgencyType } from 'graph/types';

const Quotes: React.FC = () => {
  const navigate = useNavigate();

  const { state, dispatch } = useFormContext();

  const age = differenceInYears(Date.now(), new Date(state.dateOfBirth!));

  const smokerStatus = state.tobacco ? 'smoker' : 'non-smoker';
  const gender = state.gender?.toLowerCase();

  const onSubmit = () => {
    console.log('update state');
  };

  const { data, loading, error } = usePoliciesQuery({
    variables: {
      dateOfBirth: state.dateOfBirth!,
      tobaccoUse: state.tobacco!,
      healthClass: state.healthClass!,
      coverageAmount: Number(state.coverageAmount),
      termLength: Number(state.term),
      state: state.state!,
      gender: state.gender!,
      referringAgent: state.referringAgent?.uuid,
    },
    onCompleted: () => {
      track(Events.FETCHED_QUOTES, {
        policyLength: state.term,
        healthClass: state.healthClass,
        coverageAmount: state.coverageAmount,
        paymentMode: state.paymentMode,
      });
    },
  });

  const sortedPolicies = data?.policies ? conditionPolicies(data.policies, state.paymentMode!) : [];

  const hasPolicies = sortedPolicies.length > 0;

  const { data: userData, error: userError } = useCurrentUserQuery();

  if (userError) {
    console.log('error', userError);
  }

  const [eappFlag, optimizelyClientReady] = useDecision(
    EAPP_INTERVIEW,
    {},
    {
      overrideUserId: 'agent-portal',
      overrideAttributes: {
        organization: userData?.currentUser.agencySlug || '',
      },
    }
  );
  const eappEnabled = eappFlag.enabled && optimizelyClientReady;

  const isBOR = userData?.currentUser.agencyType === AgencyType.BrokerOfRecord;

  const mapPoliciesToCards = () => {
    return sortedPolicies.map((policy) => (
      <Card
        key={`${policy.carrierName}-${policy.planIdentifierName}`}
        {...{ eappEnabled, isBOR, policy: { ...policy } }}
      />
    ));
  };

  if (error) {
    console.log('error', error);
  }

  const clearPolicy = () => {
    dispatch({
      type: 'CLEAR_POLICY',
    });
    navigate(DROP_TICKET_ROUTE, { state: { keepClientState: true } });
  };

  return (
    <Background>
      <FormContainer>
        <Col push={{ large: 1 }} span={{ small: 4, medium: 12, large: 10 }}>
          <Title
            size={{
              medium: 'large',
              small: 'medium',
            }}
          >
            Quotes for a {age}-year-old {gender}, {smokerStatus} in {state.state}
          </Title>
          <EditClientButton
            analyticsText="Edit client info"
            onClick={() => {
              navigate(CLIENT_INFO_ROUTE, { state: { keepClientState: true } });
            }}
            variant="filled"
            size="small"
          >
            Edit client info
          </EditClientButton>
          <ContinueButton
            analyticsText="Continue without selection"
            onClick={clearPolicy}
            variant="filled"
            size="small"
          >
            Continue without selection
          </ContinueButton>
          <Form<LifeApplicationInfo, InitialValues<LifeApplicationInfo>>
            onSubmit={onSubmit}
            initialValues={state}
            validate={getValidator}
          >
            {(formProps) => <InputFields {...formProps} />}
          </Form>
          {loading && <LoadingIndicator color="gray500" />}
          {!loading && hasPolicies && mapPoliciesToCards()}
          {!loading && !hasPolicies && (
            <UtilityText size="xlarge">No policies available with current selections</UtilityText>
          )}
        </Col>
      </FormContainer>
    </Background>
  );
};

export default Quotes;
