import React from 'react';
import { Title, Container, Body } from './styles';
import { Background } from 'components/Background';
import { Button, Col, theme } from '@policygenius/mortar';
import { DETAIL_ROUTE, REPORTS_ROUTE } from 'routes/constants';
import { useNavigate, useParams } from 'react-router-dom';

export const EAppConfirmation = () => {
  const { refId } = useParams();
  const navigate = useNavigate();

  const navToCase = () => {
    if (window.innerWidth < theme.breakpoints.large) {
      navigate(`${DETAIL_ROUTE}/${refId}`);

      return;
    }
    navigate(`${REPORTS_ROUTE}/${refId}`);
  };

  return (
    <Background>
      <Container fullWidth>
        <Col
          span={{
            small: 4,
            medium: 10,
            large: 7,
          }}
          push={{ large: 1 }}
        >
          <Title size="xlarge">eApplication submitted!</Title>
          <Body size="large">
            Our team is reviewing your client’s information and will follow up with a final
            application for them to sign before we submit to the carrier for underwriting.
            <br />
            <br />
            As always, you can track your client’s progress in your case report.
          </Body>
          <Button onClick={() => navToCase()} variant="filled">
            Go to case report
          </Button>
        </Col>
      </Container>
    </Background>
  );
};
