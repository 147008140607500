import get from 'lodash/get';
import inRange from 'lodash/inRange';
import { LAST_DATE_MONTH } from './constants';
import { isValid } from 'date-fns';

export const isValidMonth = (month: string): boolean => {
  if (month === '00') {
    return false;
  }

  return !month || inRange(Number(month), 0, 13);
};

export const isValidDay = (day: string, month: string | undefined | null): boolean => {
  const maxDate = get(LAST_DATE_MONTH, `${Number(month)}`, 32);

  if (day === '00') {
    return false;
  }

  return !day || inRange(Number(day), 0, maxDate);
};

export const getInitialDateValues = (date: Date | null) => {
  if (date && isValid(date)) {
    return {
      initialMonthValue: (date.getUTCMonth() + 1).toString(),
      initialDayValue: date.getUTCDate().toString(),
      initialYearValue: date.getUTCFullYear().toString(),
    };
  }

  return {
    initialMonthValue: '',
    initialDayValue: '',
    initialYearValue: '',
  };
};
