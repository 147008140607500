import { Link, theme } from '@policygenius/mortar';
import styled from 'styled-components';

export const EmailLink = styled(Link)`
  font-size: 16px;
  color: ${theme.colors.orange400};
  text-decoration: underline;

  &:hover {
    color: ${theme.colors.black};
  }
`;
