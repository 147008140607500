/* THIS FILE WAS AUTO-GENERATED. DO NOT MODIFY */
/* eslint-disable */
import * as Types from '../../graph/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetFileDataQueryVariables = Types.Exact<{
  referenceId: Types.Scalars['String']['input'];
  attachmentId: Types.Scalars['Int']['input'];
}>;


export type GetFileDataQuery = { __typename?: 'Query', getFileData?: { __typename?: 'FileData', data?: string | null, fileName: string } | null };


export const GetFileDataDocument = gql`
    query getFileData($referenceId: String!, $attachmentId: Int!) {
  getFileData(input: {referenceId: $referenceId, attachmentId: $attachmentId}) {
    data
    fileName
  }
}
    `;

/**
 * __useGetFileDataQuery__
 *
 * To run a query within a React component, call `useGetFileDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFileDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFileDataQuery({
 *   variables: {
 *      referenceId: // value for 'referenceId'
 *      attachmentId: // value for 'attachmentId'
 *   },
 * });
 */
export function useGetFileDataQuery(baseOptions: Apollo.QueryHookOptions<GetFileDataQuery, GetFileDataQueryVariables> & ({ variables: GetFileDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFileDataQuery, GetFileDataQueryVariables>(GetFileDataDocument, options);
      }
export function useGetFileDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFileDataQuery, GetFileDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFileDataQuery, GetFileDataQueryVariables>(GetFileDataDocument, options);
        }
export function useGetFileDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetFileDataQuery, GetFileDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetFileDataQuery, GetFileDataQueryVariables>(GetFileDataDocument, options);
        }
export type GetFileDataQueryHookResult = ReturnType<typeof useGetFileDataQuery>;
export type GetFileDataLazyQueryHookResult = ReturnType<typeof useGetFileDataLazyQuery>;
export type GetFileDataSuspenseQueryHookResult = ReturnType<typeof useGetFileDataSuspenseQuery>;
export type GetFileDataQueryResult = Apollo.QueryResult<GetFileDataQuery, GetFileDataQueryVariables>;