import { theme } from '@policygenius/mortar';
import { Colors, StyleOps } from './types';
import styled from 'styled-components';

const CIRCLE_COLOR_MAP: {
  [K in Colors]: string;
} = {
  lightblue: theme.colors.blue200,
  blue: theme.colors.blue500,
  grey: theme.colors.gray100,
};

export const TrackerCircle = styled.div<StyleOps>`
  background-color: ${({ variant }) =>
    !!variant && !!CIRCLE_COLOR_MAP[variant] ? CIRCLE_COLOR_MAP[variant] : CIRCLE_COLOR_MAP.grey};
  border-radius: 50%;
  height: 12px;
  width: 12px;
  margin-right: 4px;
`;
