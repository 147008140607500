import { BodyText, Col, Link } from '@policygenius/mortar';
import React from 'react';
import { Container, FeedbackHeader } from './styles';

const FeedbackContainer = () => {
  return (
    <Container fullWidth>
      <Col>
        <FeedbackHeader>Have feedback or questions?</FeedbackHeader>
        <BodyText size="large">
          We&#39;d love to hear from you! Email us at&nbsp;
          <Link href="mailto:pgproaccounts@policygenius.com" size="large">
            partnerships@policygenius.com
          </Link>
        </BodyText>
      </Col>
    </Container>
  );
};

export default FeedbackContainer;
