import React from 'react';
import { useField } from 'react-final-form';
import { theme, Textbox, Textarea, Row, Col } from '@policygenius/mortar';
import { Props } from './types';
import { phoneMask } from 'components/MaskedTextField/constants';
import MaskedTextField from 'components/MaskedTextField';
import { ContinueButton, Disclaimer, FormTitle, InputContainer, NotesContainer } from './styles';

export const EAppInternalCaseForm: React.FC<Props> = ({ handleSubmit, invalid, submitting }) => {
  const firstName = useField('firstName');
  const lastName = useField('lastName');
  const phone = useField('phone');
  const email = useField('email');
  const notes = useField('note');

  return (
    <form onSubmit={handleSubmit} noValidate>
      <FormTitle size="medium">Client information</FormTitle>
      <Row nested>
        <Col
          span={{
            small: 4,
            medium: 6,
          }}
        >
          <InputContainer>
            <Textbox
              {...firstName.input}
              id="first-name"
              name="first-name"
              label="First name"
              placeholder="Enter"
              value={firstName.input.value}
              defaultValue=""
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                firstName.input.onChange(e.target.value);
              }}
              type="text"
              errorMessage={
                firstName.meta.touched && !firstName.meta.active ? firstName.meta.error : undefined
              }
            />
          </InputContainer>
        </Col>
        <Col
          span={{
            small: 4,
            medium: 6,
          }}
        >
          <InputContainer>
            <Textbox
              {...lastName.input}
              id="last-name"
              name="last-name"
              label="Last name"
              placeholder="Enter"
              value={lastName.input.value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                lastName.input.onChange(e.target.value)
              }
              type="text"
              errorMessage={
                lastName.meta.touched && !lastName.meta.active ? lastName.meta.error : undefined
              }
            />
          </InputContainer>
        </Col>
      </Row>
      <InputContainer>
        <MaskedTextField
          input={phone.input}
          meta={phone.meta}
          id="phone"
          label="Phone number"
          showLabel
          placeholder="Enter"
          type="tel"
          defaultValue={phone.input.value}
          maskOptions={phoneMask}
        />
      </InputContainer>
      <InputContainer>
        <Textbox
          {...email.input}
          id="email"
          name="email"
          label="Email address"
          placeholder="Enter"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            email.input.onChange(e.target.value)
          }
          type="text"
          errorMessage={email.meta.touched && !email.meta.active ? email.meta.error : undefined}
        />
      </InputContainer>
      <NotesContainer>
        <Textarea
          id="notes"
          label="Notes for Policygenius"
          rows={1}
          {...notes.input}
          errorMessage={notes.meta.touched && !notes.meta.active ? notes.meta.error : undefined}
        />
      </NotesContainer>
      <Disclaimer size="small" weight="medium">
        By clicking Continue, you confirm that you&apos;ve received written consent from the
        above-referenced client 1) to share such client&apos;s contact information (email and phone
        number, as entered above) with Policygenius, and 2) for Policygenius to contact such client
        directly via text message and live, automated and pre-recorded telephone calls using the
        information provided
      </Disclaimer>
      <Row nested>
        <Col
          span={{
            small: 4,
            medium: 4,
          }}
          push={{
            medium: 8,
          }}
        >
          <ContinueButton
            fullWidth={window.innerWidth < theme.breakpoints.medium}
            analyticsText="Continue to eApp"
            onClick={() => null}
            disabled={submitting || invalid}
            variant="black"
          >
            Continue to eApp
          </ContinueButton>
        </Col>
      </Row>
    </form>
  );
};
