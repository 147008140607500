import styled from 'styled-components';
import { UtilityText, theme, UtilIconClose, UtilIconHambuger } from '@policygenius/mortar';
import { BarProps, MenuProps } from './types';
import Link from 'components/Link';

export const Bar = styled.div<BarProps>`
  display: ${(props) => (props.open ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  height: 100%;
  width: 100%;
  background: radial-gradient(
        20.17% 53.85% at 38.61% 85.19%,
        rgba(189, 189, 189, 0.2) 17.19%,
        rgba(189, 189, 189, 0) 95.83%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    linear-gradient(295.18deg, rgba(0, 0, 0, 0.126) 16.72%, rgba(0, 0, 0, 0) 57.98%),
    linear-gradient(257.68deg, rgba(0, 84, 115, 0.28) 29.17%, rgba(56, 56, 56, 0.11) 46.69%),
    linear-gradient(125.41deg, rgba(112, 112, 112, 0.2) 19.21%, rgba(112, 112, 112, 0.066) 43.11%),
    radial-gradient(
        51.42% 99.35% at 81.11% 25.1%,
        rgba(0, 50, 69, 0.2) 2.95%,
        rgba(112, 112, 112, 0.126) 46.14%,
        rgba(56, 56, 56, 0.2) 85.67%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    linear-gradient(71.4deg, rgba(0, 0, 0, 0.2) 36.82%, rgba(112, 112, 112, 0.2) 64.43%),
    radial-gradient(26.08% 97.1% at 63.19% 61.44%, #013f3a 20.68%, #000000 94.06%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  z-index: 1;
  @media (min-width: ${theme.breakpoints.medium}px) {
    width: ${(props) => (props.open ? '236px' : '0px')};
    background: ${theme.colors.black};
  }
  @media (min-width: ${theme.breakpoints.large}px) {
    display: flex;
    width: ${(props) => (props.open ? '228px' : '72px')};
    position: fixed;
    background: none;
  }
`;

export const BottomMenu = styled.div`
  padding-bottom: 16px;
`;

export const OpenCloseIcon = styled.span`
  padding: 16px;
  cursor: pointer;

  @media (min-width: ${theme.breakpoints.medium}px) {
    padding: 16px 24px;
  }
`;

export const MenuItem = styled(Link)<MenuProps>`
  display: flex;
  padding: 16px 0 16px 16px;
  cursor: pointer;
  text-decoration: none;
  ${({ selected }) =>
    selected &&
    `
    border-right: 4px solid ${theme.colors.orange400};
    background-color: ${theme.colors.gray500};
  `}
  @media (min-width: ${theme.breakpoints.medium}px) {
    padding: 8px 0 8px 24px;
  }

  &:hover {
    background-color: ${theme.colors.gray500};
  }
`;

export const MenuText = styled(UtilityText)<BarProps>`
  padding-left: 16px;
  display: ${({ open }) => (open ? 'block' : 'none')};
`;

export const Close = styled(UtilIconClose)`
  display: block;
`;

export const HamburgerIcon = styled(UtilIconHambuger)`
  display: none;
  @media (min-width: ${theme.breakpoints.large}px) {
    display: block;
  }
`;

export const TopMenu = styled.div`
  display: flex;
  flex-direction: column;
`;
