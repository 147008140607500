import React, { useEffect } from 'react';
import { identify } from 'utils/analytics';
import {
  CLIENT_INFO_ROUTE,
  DROP_TICKET_ROUTE,
  FORK_ROUTE,
  QUOTES_ROUTE,
  SCHEDULE,
  INDEX_ROUTE,
  DETAIL_ROUTE,
  REPORTS_ROUTE,
  HELP_CENTER_ROUTE,
  SETTINGS_ROUTE,
  USER_NOT_FOUND_ROUTE,
  ACCEPT_TERMS,
  FILTER_ROUTE,
  EAPP_ROUTE,
  DISABLE_HUB_SPOT_CHAT,
  SELF_SIGNUP_ROUTE,
} from './constants';
import { useCurrentUserQuery } from 'query/currentUser/query';
import NavProvider from 'context/NavContext';
import FormProvider from 'context/FormContext';
import { PageContainer } from 'components/PageContainer';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import {
  ClientInfo,
  Dashboard,
  DetailPage,
  DropTicket,
  ForkPage,
  Quotes,
  Reports,
  Schedule,
  Settings,
  TermsAndConditions,
  UserNotFound,
  Filter,
  LplUserNotFound,
} from 'pages';
import HelpCenter from 'pages/HelpCenter';
import { ToastProvider } from 'context/ToastContext';
import { CurrentUser } from 'graph/types';
import { EApp } from 'pages/EApp';
import { useDecision } from '@optimizely/react-sdk';
import { EAPP_INTERVIEW } from 'utils/optimizelyConstants';
import { EAppInternalCase } from 'pages/EAppCase';
import { EAppReview } from 'pages/EAppReview';
import { EAppConfirmation } from 'pages/EAppConfirmation';
import { useAuth0 } from '@auth0/auth0-react';
import { SelfSignup } from 'pages/SelfSignup';

const loggedInUser = (user: CurrentUser) => {
  identify(user.uuid, {
    ...user,
    pgProPortalUser: true,
  });

  (window as any).pendo.initialize({
    visitor: {
      id: user.uuid,
      email: user.email,
      /* eslint-disable camelcase */
      full_name: `${user.firstName} ${user.lastName}`,
      /* eslint-enable camelcase */
      role: user.roles,
    },
    account: {
      id: user.agencySlug,
      agencyType: user.agencyType,
      agencySlug: user.agencySlug,
      agencyName: user.agencyName,
      ...(user.highestTierParent && {
        highestTierParent: user.highestTierParent,
      }),
    },
  });
};

export const PrivateRoutes = () => {
  const { data, loading, error } = useCurrentUserQuery();
  const { user } = useAuth0();

  const [eappInterview, optimizelyClientReady] = useDecision(
    EAPP_INTERVIEW,
    {},
    {
      overrideUserId: 'agent-portal',
      overrideAttributes: {
        organization: data?.currentUser.agencySlug || '',
      },
    }
  );

  const eappEnabled = eappInterview.enabled && optimizelyClientReady;

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!loading && data?.currentUser) {
      loggedInUser(data.currentUser);
    }
  }, [loading]);

  useEffect(() => {
    if (error?.message.includes('self-signup')) {
      navigate(SELF_SIGNUP_ROUTE, {
        state: { orgName: error?.message.split(':')[1] },
      });
    }

    if (error?.message === 'cannot find agent') {
      navigate(USER_NOT_FOUND_ROUTE);
    }
  }, [error?.message]);

  useEffect(() => {
    /* eslint-disable camelcase */
    if (DISABLE_HUB_SPOT_CHAT.includes(user?.org_id)) {
      /* eslint-enabled camelcase */
      window.HubSpotConversations?.widget.remove();
    }
  }, [window.HubSpotConversations, user]);

  if (
    data?.currentUser.portalOptions.acceptedTermsAndConditions === false &&
    location.pathname !== ACCEPT_TERMS
  ) {
    navigate(ACCEPT_TERMS);
  }

  if (error) {
    console.error(error);
  }

  return (
    <NavProvider>
      <FormProvider>
        <ToastProvider>
          <Routes>
            <Route
              path={USER_NOT_FOUND_ROUTE}
              element={
                user?.org_id === 'org_QGZN3osIANJizPmy' ? <LplUserNotFound /> : <UserNotFound />
              }
            />
            <Route
              path={SCHEDULE}
              element={
                <PageContainer>
                  <Schedule />
                </PageContainer>
              }
            />
            <Route
              path={DROP_TICKET_ROUTE}
              element={
                <PageContainer>
                  <DropTicket />
                </PageContainer>
              }
            />
            <Route
              path={CLIENT_INFO_ROUTE}
              element={
                <PageContainer>
                  <ClientInfo />
                </PageContainer>
              }
            />
            <Route
              path={HELP_CENTER_ROUTE}
              element={
                <PageContainer>
                  <HelpCenter />
                </PageContainer>
              }
            />
            <Route
              path={QUOTES_ROUTE}
              element={
                <PageContainer>
                  <Quotes />
                </PageContainer>
              }
            />
            <Route
              path={FORK_ROUTE}
              element={
                <PageContainer>
                  <ForkPage />
                </PageContainer>
              }
            />
            <Route
              path={`${DETAIL_ROUTE}/:id`}
              element={
                <PageContainer>
                  <DetailPage />
                </PageContainer>
              }
            />
            <Route
              path={`${REPORTS_ROUTE}/:id?`}
              element={
                <PageContainer>
                  <Reports />
                </PageContainer>
              }
            />
            <Route
              path={INDEX_ROUTE}
              element={
                <PageContainer>
                  <Dashboard />
                </PageContainer>
              }
            />
            <Route
              path={SETTINGS_ROUTE}
              element={
                <PageContainer>
                  <Settings />
                </PageContainer>
              }
            />
            {eappEnabled && (
              <Route
                path={EAPP_ROUTE}
                element={
                  <PageContainer>
                    <EAppInternalCase />
                  </PageContainer>
                }
              />
            )}
            {eappEnabled && (
              <Route
                path={`${EAPP_ROUTE}/:carrier/:page/:refId`}
                element={
                  <PageContainer>
                    <EApp />
                  </PageContainer>
                }
              />
            )}
            {eappEnabled && (
              <Route
                path={`${EAPP_ROUTE}/:carrier/review/:refId`}
                element={
                  <PageContainer>
                    <EAppReview />
                  </PageContainer>
                }
              />
            )}
            {eappEnabled && (
              <Route
                path={`${EAPP_ROUTE}/confirmation/:refId`}
                element={
                  <PageContainer>
                    <EAppConfirmation />
                  </PageContainer>
                }
              />
            )}
            <Route path={USER_NOT_FOUND_ROUTE} element={<UserNotFound />} />
            <Route path={SELF_SIGNUP_ROUTE} element={<SelfSignup />} />
            <Route path={ACCEPT_TERMS} element={<TermsAndConditions />} />
            <Route path={FILTER_ROUTE} element={<Filter />} />
          </Routes>
        </ToastProvider>
      </FormProvider>
    </NavProvider>
  );
};
