import React from 'react';

interface Props {
  className?: string;
}

export const Shield: React.FunctionComponent<Props> = ({ className }) => (
  <div className={className}>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.07764 3.33333L10.4526 2.125C10.661 2.08333 10.8693 2.08333 11.0776 2.125L17.411 3.33333V11.0833C17.411 13.5417 16.161 15.7917 14.0776 17.0833L11.2026 18.875C10.9526 19.0417 10.5776 19.0417 10.3276 18.875L7.41097 17.0833C5.32764 15.7917 4.07764 13.5 4.07764 11.0833V3.33333Z"
        fill="#A4350F"
      />
      <path
        d="M10.0784 12.2917C9.87004 12.2917 9.6617 12.2083 9.5367 12.0417L7.95337 10.4583C7.62004 10.125 7.62004 9.66667 7.95337 9.33333C8.2867 9 8.7867 9 9.07837 9.33333L10.12 10.375L12.745 7.75C13.0784 7.41667 13.5367 7.41667 13.87 7.75C14.2034 8.08333 14.2034 8.54167 13.87 8.875L10.6617 12.0833C10.4534 12.2083 10.245 12.2917 10.0784 12.2917Z"
        fill="#ECA389"
      />
    </svg>
  </div>
);
