import React, { MouseEvent } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { Link as MortarLink } from '@policygenius/mortar';
import { Props } from './types';
import { track, Events } from 'utils/analytics';

const Link: React.FC<Props> = ({
  analyticsProperties,
  children,
  className,
  href,
  onClick,
  target,
  rel,
  external,
  ...otherLinkProps
}) => {
  const handleClick: React.MouseEventHandler<HTMLAnchorElement> = (
    event: MouseEvent<HTMLAnchorElement>
  ) => {
    if (analyticsProperties) {
      track(Events.CLICKED_LINK, {
        href,
        ...analyticsProperties,
      });
    }

    if (onClick) {
      onClick(event);
    }
  };

  if (external) {
    return (
      <MortarLink href={href} className={className} target={target} onClick={handleClick} rel={rel}>
        {children}
      </MortarLink>
    );
  }

  return (
    <ReactLink
      to={{
        pathname: href,
      }}
      state={{
        ...otherLinkProps,
      }}
      className={className}
      target={target}
      onClick={handleClick}
      rel={rel}
    >
      {children}
    </ReactLink>
  );
};

export default Link;
