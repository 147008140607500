import React from 'react';
import logo from './PgProLogo.png';
import logoDark from './PgProLogoDark.png';
import styled from 'styled-components';

const Img = styled.img`
  width: 155px;
`;

export const PgProLogo: React.FC = () => {
  return <Img src={logo} />;
};

export const PgProLogoDark: React.FC = () => {
  return <Img src={logoDark} />;
};
