import React from 'react';
import { Props } from './types';
import { Heading, Empty, Container, Tab } from './styles';
import { LabelText } from '@policygenius/mortar';

export const ClientTab: React.FC<Props> = ({ client }) => (
  <Tab>
    <Container>
      <Heading size="small">Birthdate</Heading>
      {client?.dateOfBirth ? <LabelText size="medium">{client!.dateOfBirth}</LabelText> : <Empty />}
    </Container>
    <Container>
      <Heading size="small">State</Heading>
      {client?.state ? <LabelText size="medium">{client?.state}</LabelText> : <Empty />}
    </Container>
    <Container>
      <Heading size="small">Email</Heading>
      <LabelText size="medium">{client?.email}</LabelText>
    </Container>
  </Tab>
);
