import { Row, TitleText, UtilityText, theme } from '@policygenius/mortar';
import styled from 'styled-components';

export const Container = styled(Row)`
  padding: 0px 16px 80px 16px;

  @media (min-width: ${theme.breakpoints.medium}px) {
    padding: 0px 84px 120px 84px;
  }
`;

export const Title = styled(TitleText)`
  text-align: left;
  margin-top: 64px;
  margin-bottom: 16px;
`;

export const Body = styled(UtilityText)`
  margin-bottom: 40px;
`;
