import React, { useEffect, useState } from 'react';
import { Col, LoadingIndicator } from '@policygenius/mortar';
import ClientInfoForm from './form';
import { Form } from 'react-final-form';
import { getValidator } from './utils/validate';
import { InitialValues } from 'forms/types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormContext } from 'context/FormContext';
import { LifeApplicationInfo as Client } from 'context/FormContext/types';
import { QUOTES_ROUTE } from 'routes/constants';
import { FormContainer, Subtitle, Title } from './styles';
import { Background } from 'components/Background';

const ClientInfo: React.FC = () => {
  const navigate = useNavigate();
  const { dispatch, state } = useFormContext();
  const location = useLocation();
  const [stateUpdating, setStateUpdating] = useState(true);

  const onSubmit = (values: Client) => {
    dispatch({ type: 'UPDATE_CLIENT', payload: values });
    navigate(QUOTES_ROUTE);
  };

  useEffect(() => {
    if (!location.state?.keepClientState) {
      dispatch({
        type: 'RESET_STATE',
      });
    }
    setStateUpdating(false);
  }, []);

  return (
    <Background>
      <FormContainer>
        {stateUpdating ? (
          <Col>
            <LoadingIndicator color="gray500" />
          </Col>
        ) : (
          <Col push={{ medium: 1 }} span={{ small: 4, medium: 10, large: 7 }}>
            <Title
              size={{
                medium: 'xlarge',
                small: 'large',
              }}
            >
              Get quotes
            </Title>
            <Subtitle
              size={{
                medium: 'large',
                small: 'medium',
              }}
            >
              Enter criteria below to generate quotes.
            </Subtitle>
            <Form<Client, InitialValues<Client>>
              onSubmit={onSubmit}
              initialValues={state}
              validate={getValidator}
            >
              {(formProps) => <ClientInfoForm {...formProps} />}
            </Form>
          </Col>
        )}
      </FormContainer>
    </Background>
  );
};

export default ClientInfo;
