import React from 'react';
import { Events, track } from 'utils/analytics';
import { Card } from './styles';
import { Props } from './types';

export const NavigationCard: React.FunctionComponent<Props> = ({
  analyticsText,
  children,
  onClick,
  className,
}) => {
  const handleClick: React.MouseEventHandler = () => {
    track(Events.CLICKED_TILE, { analyticsText });

    onClick();
  };

  return (
    <Card onClick={handleClick} className={className}>
      {children}
    </Card>
  );
};
