import React from 'react';
import { useTrackPages } from 'utils/analytics';
import ReportProvider from 'context/ReportContext';
import UserProvider from 'context/UserContext';
import ScrollToTop from 'components/ScrollToTop';
import { PrivateRoutes } from './private';
import { PublicRoutes } from './public';
import { useAuth0 } from '@auth0/auth0-react';
import EappProvider from 'context/EappContext';

const AppRouter: React.FC = () => {
  useTrackPages();
  const { isAuthenticated } = useAuth0();

  return (
    <EappProvider>
      <ReportProvider>
        <UserProvider>
          <ScrollToTop>
            <PublicRoutes />
            {isAuthenticated && <PrivateRoutes />}
          </ScrollToTop>
        </UserProvider>
      </ReportProvider>
    </EappProvider>
  );
};

export default AppRouter;
