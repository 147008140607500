import styled from 'styled-components';
import { LabelText, theme, Tooltip, UtilityText } from '@policygenius/mortar';
import { SelectedTabProps } from './types';

export const CloseIcon = styled.span`
  cursor: pointer;
`;

export const Container = styled.div`
  background-color: ${theme.colors.white};
  height: 100%;
  min-height: 75vh;

  @media (min-width: ${theme.breakpoints.large}px) {
    animation: none;
    position: relative;
    top: initial;
    width: 100%;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px ${theme.spacing('large')};
  background-color: ${theme.colors.white};

  @media (min-width: ${theme.breakpoints.large}px) {
    border-radius: ${theme.spacing('small')} ${theme.spacing('small')} 0px 0px;
  }
`;

export const Tabs = styled.div`
  display: flex;
  padding: 0 14px;
  background: ${theme.colors.white};
  border-bottom: 2px solid ${theme.colors.gray100};
`;

export const Tab = styled(LabelText)<SelectedTabProps>`
  margin: 0 12px;
  cursor: pointer;
  border-bottom: ${({ isSelected }) => (isSelected ? `2px solid ${theme.colors.gray500}` : 'none')};
`;

export const LastUpdatedSubheader = styled(UtilityText)`
  color: ${theme.colors.gray400};
`;

export const CreatedByContainer = styled.div`
  padding: 0 ${theme.spacing('large')} ${theme.spacing('large')} ${theme.spacing('large')};
  background-color: ${theme.colors.white};

  & > div:not(:last-child) {
    padding-bottom: 4px;
  }
`;

export const CreatedByRow = styled.div`
  display: flex;
`;

export const CreatedByItem = styled(UtilityText)`
  width: 140px;
`;

export const TooltipIcon = styled(Tooltip)`
  display: inline-flex;
  vertical-align: text-top;
`;
