import React from 'react';
import { BarChart, Button } from '@policygenius/mortar';
import { AccessText, EmptyTableTitleText, PlaceholderBox } from './styles';
import { useNavigate } from 'react-router-dom';
import Life from '@policygenius/mortar/dist/components/Icons/Brand/components/Life';
import { FORK_ROUTE } from 'routes/constants';
import { Props } from './types';
import { useReportContext } from 'context/ReportContext';

const StatusTrackerEmpty: React.FC<Props> = ({ hasProducerRole }) => {
  const navigate = useNavigate();
  const { filter, setFilter } = useReportContext();
  const messageText = filter.length !== 0 ? 'No case results' : 'No cases yet';

  return (
    <PlaceholderBox>
      {filter.length !== 0 ? <BarChart size="large" /> : <Life size="small" />}
      <EmptyTableTitleText size="small">{messageText}</EmptyTableTitleText>
      {filter.length !== 0 ? (
        <Button
          variant="quaternary"
          size="medium"
          onClick={() => {
            setFilter([]);
          }}
        >
          Reset filters
        </Button>
      ) : (
        <React.Fragment>
          <AccessText size="small">
            Access details and track progress of all referred cases from here
          </AccessText>
          {hasProducerRole && (
            <Button onClick={() => navigate(FORK_ROUTE)} variant="secondary">
              New case
            </Button>
          )}
        </React.Fragment>
      )}
    </PlaceholderBox>
  );
};

export default StatusTrackerEmpty;
