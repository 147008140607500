import { HelpItem } from './types';

export const gettingStartedWithPgProItems: HelpItem[] = [
  {
    displayText: 'Policygenius Pro platform demo video',
    link: 'https://pro.policygenius.com/hubfs/BD%20Resources/Policygenius%20Pro%20Walkthrough%20Video%202.23.mp4',
  },
  {
    displayText: 'Policygenius Pro client case study video',
    link: 'https://pro.policygenius.com/hubfs/BD%20Resources/Policygenius%20Pro%20Client%20Case%20Study%20%231.mp4',
  },
  {
    displayText: 'How to Talk About Term Life Insurance',
    link: 'https://pro.policygenius.com/hubfs/BD%20Resources/How%20to%20Talk%20About%20Term%20Life%20Insurance.pdf',
  },
  {
    displayText: 'Term Life Insurance 101',
    link: 'https://pro.policygenius.com/hubfs/Term%20Life%20Insurance%20101.pdf',
  },
  {
    displayText: 'What are life insurance health classifications? ',
    link: 'https://pro.policygenius.com/resources/health-classifications',
  },
  {
    displayText: 'Case Report - Glossary of Terms',
    link: 'https://pro.policygenius.com/hubfs/BD%20Resources/Case%20Report%20Overview.pdf',
  },
  {
    displayText: 'Client Journey Overview - Fully Underwritten',
    link: 'https://pro.policygenius.com/hubfs/BD%20Resources/PgPro%20Client%20Journey%20-%20Fully%20Underwritten.pdf',
  },
  {
    displayText: 'Client Journey Overview - Accelerated Underwriting',
    link: 'https://pro.policygenius.com/hubfs/BD%20Resources/PgPro%20Client%20Journey%20-%20Accelerated%20Underwriting.pdf',
  },
];

export const resourcesForAdvisorsItems: HelpItem[] = [
  {
    displayText: 'Q2 ‘24 Marketing Calendar',
    link: 'https://pro.policygenius.com/hubfs/BD%20Resources/Q2%20-%202024%20Marketing%20Calendar.pdf',
  },
  {
    displayText: 'Email templates for client outreach',
    link: 'https://pro.policygenius.com/hubfs/BD%20Resources/Partner%20Marketing%20Emails.pdf',
  },
  {
    displayText: 'Social media templates',
    link: 'https://pro.policygenius.com/hubfs/BD%20Resources/LIAM%20Marketing%20Templates%20-%202023.pdf',
  },
  {
    displayText:
      'Close the Sale: How to Overcome the 5 Most Common Life Insurance Buying Objections',
    link: 'https://pro.policygenius.com/hubfs/BD%20Resources/Close%20the%20Sale%20eBook.pdf',
  },
  {
    displayText: 'Client Handout: Fully Underwritten Client Journey',
    link: 'https://pro.policygenius.com/hubfs/BD%20Resources/PgPro%20Customer%20Journey%20Overview.pdf',
  },
  {
    displayText: 'Client Handout: The Do’s and Dont’s of the Life Insurance Medical Exam',
    link: 'https://pro.policygenius.com/hubfs/Medical%20Exam%20-%20Client%20Handout.png',
  },
];

export const carrierNoticePDFs = {
  aig: {
    name: 'Corebridge Financial',
    url: 'https://storage.googleapis.com/pg-production-carrier-producer-notices/corebridge.pdf',
  },
  'aig-ny': {
    name: 'Corebridge Financial',
    url: 'https://storage.googleapis.com/pg-production-carrier-producer-notices/corebridge.pdf',
  },
  'banner-life': {
    name: 'Banner Life',
    url: 'https://storage.googleapis.com/pg-production-carrier-producer-notices/banner-wp.pdf',
  },
  'william-penn': {
    name: 'William Penn',
    url: 'https://storage.googleapis.com/pg-production-carrier-producer-notices/banner-wp.pdf',
  },
  'lincoln-national-life': {
    name: 'Lincoln National Life',
    url: 'https://storage.googleapis.com/pg-production-carrier-producer-notices/lincoln.pdf',
  },
  'lincoln-ny': {
    name: 'Lincoln National Life',
    url: 'https://storage.googleapis.com/pg-production-carrier-producer-notices/lincoln.pdf',
  },
  'mutual-of-omaha': {
    name: 'Mutual of Omaha',
    url: 'https://storage.googleapis.com/pg-production-carrier-producer-notices/moo.pdf',
  },
  paclife: {
    name: 'Pacific Life',
    url: 'https://storage.googleapis.com/pg-production-carrier-producer-notices/paclife.pdf',
  },
  symetra: {
    name: 'Symetra',
    url: 'https://storage.googleapis.com/pg-production-carrier-producer-notices/symetra.pdf',
  },
  'symetra-ny': {
    name: 'Symetra',
    url: 'https://storage.googleapis.com/pg-production-carrier-producer-notices/symetra.pdf',
  },
  transamerica: {
    name: 'Transamerica',
    url: 'https://storage.googleapis.com/pg-production-carrier-producer-notices/transamerica.pdf',
  },
  'savings-bank-life-insurance': {
    name: 'Savings Bank Life Insurance',
    url: 'https://storage.googleapis.com/pg-production-carrier-producer-notices/sbli.pdf',
  },
};
