import { theme } from '@policygenius/mortar';
import styled from 'styled-components';

export const Table = styled.table`
  background: ${theme.colors.gray100};
  width: 100%;
  border-collapse: collapse;
  border-bottom: 1px solid ${theme.colors.gray100};

  @media (min-width: ${theme.breakpoints.large}px) {
    tbody {
      cursor: pointer;
    }
  }
`;
export const TableHead = styled.thead`
  text-align: left;
  background: ${theme.colors.white};
  th {
    font-size: 12px;
    color: ${theme.colors.gray400};
    padding: 12px 16px;
    border-top: 1px solid ${theme.colors.gray100};
    border-bottom: 1px solid ${theme.colors.gray100};
  }
`;

export const Content = styled.span`
  display: flex;
  align-items: center;
`;

export const ContentRightAlign = styled.span`
  display: flex;
  align-items: center;
  justify-content: right;
`;

export const HeaderNoDesktop = styled.th`
  @media (min-width: ${theme.breakpoints.large}px) {
    display: none;
  }
`;

export const LoadingIndicatorContainer = styled.td`
  background: ${theme.colors.white};
  height: 350px;
  text-align: center;
`;
