import { Button, theme, TitleText } from '@policygenius/mortar';
import styled from 'styled-components';
import { Gradient } from 'components/BackgroundGradient/styles';

export const Container = styled.div`
  display: flex;
  min-height: 100vh;
  justify-content: center;
  @media (min-width: ${theme.breakpoints.medium}px) {
    ${Gradient};
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;

  button {
    margin-bottom: 32px;
    width: fit-content;
  }
`;

export const Background = styled.div`
  display: flex;
  background-color: ${theme.colors.white};
  margin: auto;
  @media (min-width: ${theme.breakpoints.medium}px) {
    width: 552px;
    border-radius: 4px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 56px;
  width: 100%;
  margin: 40px;
`;

export const LabelContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

export const Spacer = styled.div<{ large?: boolean }>`
  margin-bottom: 16px;

  ${({ large }) =>
    large &&
    `
  margin-bottom: 24px;
    `}
`;

export const Title = styled(TitleText)`
  margin-bottom: 24px;
`;

export const Continue = styled(Button)`
  width: 100px !important; // these importants are required to override the button styles from mortar
  padding: 0 24px !important;
`;
