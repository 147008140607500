import React, { useEffect, useState } from 'react';
import { Selectbox } from '@policygenius/mortar';
import { useField } from 'react-final-form';
import { useFormContext } from 'context/FormContext';
import { Props } from './types';
import { HEALTH_CLASSES, TERM_LENGTHS, PAYMENT_MODES } from 'pages/ClientInfo/form/constants';
import { Events, track } from 'utils/analytics';
import { numberMask } from 'components/MaskedTextField/constants';
import {
  Coverage,
  HealthClassContainer,
  InputContainer,
  Label,
  LabelContainer,
  LabelContainerTermAndPayment,
  TermPayment,
} from './styles';

const InputFields: React.FC<Props> = ({ handleSubmit, valid, values }) => {
  const { state, dispatch } = useFormContext();
  const [coverage] = useState(state.coverageAmount ?? undefined);

  const healthClass = useField('healthClass');
  const coverageAmount = useField('coverageAmount');
  const paymentMode = useField('paymentMode');
  const term = useField('term');

  useEffect(() => {
    if (valid) {
      dispatch({
        type: 'UPDATE_CLIENT',
        payload: { ...values },
      });
    }
  }, [values.coverageAmount, values.healthClass, values.term, values.paymentMode]);

  useEffect(() => {
    const timeoutId = setTimeout(() => coverageAmount.input.onChange(values.coverageAmount), 500);

    return () => clearTimeout(timeoutId);
  }, [coverageAmount]);

  const handlePaymentModeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const mode = e.target.value;

    paymentMode.input.onChange(mode);
    track(Events.TOGGLED_PAYMENT_MODE, {
      paymentMode: mode,
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <InputContainer>
        <HealthClassContainer>
          <Label forwardedAs="label" size="large">
            Health class
          </Label>
          <Selectbox
            id="health-class"
            name="select-box"
            label="Health class"
            options={HEALTH_CLASSES}
            defaultValue={healthClass.input.value}
            errorMessage={
              healthClass.meta.touched && !healthClass.meta.active
                ? healthClass.meta.error
                : undefined
            }
            showLabel={false}
            onChange={healthClass.input.onChange} // eslint-disable-line react/jsx-handler-names
            onBlur={healthClass.input.onBlur} // eslint-disable-line react/jsx-handler-names
          />
        </HealthClassContainer>
        <LabelContainer>
          <Label forwardedAs="label" size="large">
            Coverage
          </Label>
          <Coverage
            input={coverageAmount.input}
            meta={coverageAmount.meta}
            id="coverage-amount"
            label="Coverage"
            showLabel={false}
            placeholder="0"
            type="text"
            maskOptions={numberMask}
            prefix="$"
            defaultValue={coverage}
          />
        </LabelContainer>
        <TermPayment>
          <LabelContainerTermAndPayment>
            <Label forwardedAs="label" size="large">
              Term length
            </Label>
            <Selectbox
              id="term"
              name="select-box"
              label="Term length"
              options={TERM_LENGTHS}
              defaultValue={term.input.value}
              errorMessage={term.meta.touched && !term.meta.active ? term.meta.error : undefined}
              showLabel={false}
              onChange={term.input.onChange} // eslint-disable-line react/jsx-handler-names
              onBlur={term.input.onBlur} // eslint-disable-line react/jsx-handler-names
            />
          </LabelContainerTermAndPayment>
          <LabelContainerTermAndPayment>
            <Label forwardedAs="label" size="large">
              Payment mode
            </Label>
            <Selectbox
              id="payment-mode"
              name="select-box"
              label="Payment mode"
              options={PAYMENT_MODES}
              defaultValue={paymentMode.input.value}
              errorMessage={
                paymentMode.meta.touched && !paymentMode.meta.active
                  ? paymentMode.meta.error
                  : undefined
              }
              showLabel={false}
              onChange={handlePaymentModeChange}
              onBlur={paymentMode.input.onBlur} // eslint-disable-line react/jsx-handler-names
            />
          </LabelContainerTermAndPayment>
        </TermPayment>
      </InputContainer>
    </form>
  );
};

export default InputFields;
