import styled from 'styled-components';
import { BodyText, theme, TitleText, Col, Row } from '@policygenius/mortar';
import Button from 'components/Button';

export const Body = styled(BodyText)`
  text-align: center;
  margin-bottom: 40px;
  @media (min-width: ${theme.breakpoints.medium}px) {
    text-align: left;
    margin-bottom: 40px;
  }
`;

export const ContainerRow = styled(Row)`
  margin: 0px auto 48px;
`;

export const GetQuotesButton = styled(Button)`
  padding: 28px 48px 28px 48px;
  margin-bottom: 24px;
  @media (min-width: ${theme.breakpoints.medium}px) {
    margin-bottom: 0px;
  }
`;

export const PartnerLogo = styled.img`
  max-height: 240px;
  max-width: 100%;
`;

export const Title = styled(TitleText)`
  text-align: center;
  margin-bottom: 24px;
  margin-top: 50px;
  @media (min-width: ${theme.breakpoints.medium}px) {
    text-align: left;
    margin-bottom: 16px;
  }
`;

export const RightCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: ${theme.breakpoints.medium}px) {
    padding-top: 50px;
    padding-bottom: 60px;
  }
`;

export const PoweredByContainer = styled.div`
  height: 56px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  svg {
    height: 24px;
  }
`;

export const PoweredByText = styled(TitleText)`
  margin-right: 8px;
`;

export const PoweredByBorder = styled.hr`
  width: 100vw;
  border: 1px solid ${theme.colors.gray200};
  position: absolute;
  left: 0px;
  top: 56px;
`;
