/* eslint-disable react/jsx-max-depth */
import { TitleText, UtilityText } from '@policygenius/mortar';
import React from 'react';
import { Container, Background, UtilityLink, Row, LabelContainer } from './styles';
import { PgProLogo } from 'components/PgIcons/PgProLogo';

export const UserNotFound: React.FC = () => {
  return (
    <Container>
      <Background>
        <Row>
          <LabelContainer>
            <PgProLogo />
          </LabelContainer>
          <TitleText size={'large'}>Account not found</TitleText>
          <UtilityText size="large">
            We’re unable to find an account for you at this organization.
          </UtilityText>
          <UtilityText size="large">
            If you believe this is an error, reach out to{' '}
            <UtilityLink
              forwardedAs="a"
              href="https://pro.policygenius.com/resources"
              target="_blank"
              rel="noopener noreferrer"
              size="large"
              color="orange"
            >
              pgproaccounts@policygenius.com
            </UtilityLink>{' '}
            for assistance.
          </UtilityText>
        </Row>
      </Background>
    </Container>
  );
};

export const LplUserNotFound: React.FC = () => {
  return (
    <Container>
      <Background>
        <Row>
          <LabelContainer>
            <PgProLogo />
          </LabelContainer>
          <br />
          <UtilityText size="medium">
            Thank you for your interest in the Policygenius portal! One more step is needed for you
            to gain access to the portal.
          </UtilityText>
          <br />
          <UtilityText size="medium">
            Please send an email to lplia.pgpro@lplfinancial.com communicating that you would like
            access. Access will be granted within 72 hours.
          </UtilityText>
          <UtilityText size="medium">
            If you have a question, please contact LPLIA at 877-318-6400
          </UtilityText>
        </Row>
      </Background>
    </Container>
  );
};
