import {
  composeValidators,
  isPresent,
  isDOBRangeValid,
  isEmail,
  isPhone,
  isBetween,
} from 'forms/validators/index';
import { ValidationErrors } from 'forms/types';
import { LifeApplicationInfo } from 'context/FormContext/types';
import {
  COVERAGE_AMOUNT_MAX,
  COVERAGE_AMOUNT_MIN,
  COVERAGE_ERROR_OVERRIDE,
} from 'pages/ClientInfo/form/constants';
import { moneyToString } from 'utils/helpers';

export const getValidator = (
  values: LifeApplicationInfo
): ValidationErrors<LifeApplicationInfo> => {
  const eventDateValidator = composeValidators(isPresent, isDOBRangeValid);
  const emailValidator = composeValidators(isPresent, isEmail);
  const phoneValidator = composeValidators(isPresent, isPhone);
  const { firstName, lastName, gender, dateOfBirth, state, phone, email, coverageAmount } = values;

  const money = moneyToString(coverageAmount);

  return {
    firstName: isPresent(firstName),
    lastName: isPresent(lastName),
    gender: isPresent(gender),
    dateOfBirth: eventDateValidator(dateOfBirth),
    state: isPresent(state),
    phone: phoneValidator(phone),
    email: emailValidator(email),
    coverageAmount: money
      ? isBetween(COVERAGE_AMOUNT_MIN, COVERAGE_AMOUNT_MAX, COVERAGE_ERROR_OVERRIDE)(Number(money))
      : undefined,
  };
};
