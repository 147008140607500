import styled from 'styled-components';
import { Row, theme, UtilityText } from '@policygenius/mortar';

export const Body = styled(UtilityText)`
  margin-bottom: 16px;
  @media (min-width: ${theme.breakpoints.medium}px) {
    margin-bottom: 0;
  }
  a {
    text-decoration: underline;
    color: inherit;
  }
`;

export const Container = styled.footer`
  background: ${theme.colors.white};
  border-top: 1px solid ${theme.colors.gray100};

  @media (min-width: ${theme.breakpoints.medium}px) {
    padding: 24px 0px 24px 0px;
    margin-top: auto;
  }
`;

export const Grid = styled(Row)`
  margin: auto;
  padding: 24px 16px 24px 16px;
  @media (min-width: ${theme.breakpoints.medium}px) {
    padding: 24px;
  }
`;

export const TabletPlusLink = styled(UtilityText)`
  overflow-wrap: break-word;
  display: block;
  margin-bottom: 8px;
  text-decoration: underline;
  color: ${theme.colors.gray500};
  &:hover {
    cursor: pointer;
  }
`;

export const OptOut = styled(UtilityText)`
  display: flex;
  flex-wrap: wrap;
  text-decoration: underline;
  color: ${theme.colors.gray500};
  margin-bottom: 16px;
  gap: 16px;
`;
