import React from 'react';
import { theme } from '@policygenius/mortar';

interface Props {
  className?: string;
}

export const Share: React.FunctionComponent<Props> = ({ className }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="box"
      d="M18.6277 3.3335C19.5424 3.3335 20.2908 4.0835 20.2908 5.00016C20.2908 5.91683 19.5424 6.66683 18.6277 6.66683H10.4785C8.64914 6.66683 7.15236 8.16683 7.15236 10.0002V30.0002C7.15236 31.8335 8.64914 33.3335 10.4785 33.3335H30.4357C32.2651 33.3335 33.7619 31.8335 33.7619 30.0002V26.6668C33.7619 25.7502 34.5103 25.0002 35.425 25.0002C36.3397 25.0002 37.0881 25.7502 37.0881 26.6668V30.0002C37.0881 33.6668 34.0945 36.6668 30.4357 36.6668H10.4785C6.81974 36.6668 3.82617 33.6668 3.82617 30.0002V10.0002C3.82617 6.3335 6.81974 3.3335 10.4785 3.3335H18.6277Z"
      fill={`${theme.colors.gray300}`}
    />
    <path
      className="arrow"
      d="M12.1419 23.1668C12.1419 21.9168 11.8924 16.9168 15.8007 12.9168C18.4616 10.2502 22.4531 9.75016 27.1929 9.5835V3.0835C27.1929 2.41683 27.775 1.8335 28.4402 1.8335C28.7728 1.8335 29.0223 1.91683 29.2717 2.16683L40.0819 12.0002C40.5808 12.5002 40.6639 13.2502 40.165 13.7502L40.0819 13.8335L29.2717 23.9168C28.7728 24.4168 27.9413 24.3335 27.5255 23.8335C27.276 23.5835 27.1929 23.3335 27.1929 23.0002V16.5835C24.1993 16.6668 22.0373 16.8335 20.0416 17.5002C17.2143 18.4168 15.0523 20.9168 13.5555 25.0835C13.3892 25.5835 12.8903 25.9168 12.3913 25.9168H12.1419C12.1419 25.0835 12.1419 23.8335 12.1419 23.1668Z"
      fill={`${theme.colors.gray500}`}
    />
  </svg>
);
