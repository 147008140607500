import React from 'react';
import { UtilIconWarning } from '@policygenius/mortar';
import {
  Body,
  BodyContainer,
  ReactNodeBody,
  Title,
  TitleContainer,
  TitleSubContainer,
  ToastContainer,
} from './styles';
import Close from '@policygenius/mortar/dist/components/Icons/Utility/components/Close';
import { useToastContext } from 'context/ToastContext';
import { Check } from './check';

const ICONS = {
  error: <UtilIconWarning color="maroon400" />,
  success: <Check />,
};

const Toast: React.FC = () => {
  const { toast, hide } = useToastContext();

  const isReactNode = React.isValidElement(toast.body);

  return (
    <ToastContainer active={toast.on}>
      <BodyContainer width={toast.width}>
        <TitleContainer>
          <TitleSubContainer>
            {ICONS[toast.type]}
            <Title size="small">{toast.title}</Title>
          </TitleSubContainer>
          <div onClick={hide}>
            <Close />
          </div>
        </TitleContainer>
        {isReactNode ? (
          <ReactNodeBody>{toast.body}</ReactNodeBody>
        ) : (
          <Body size="small">{toast.body}</Body>
        )}
      </BodyContainer>
    </ToastContainer>
  );
};

export default Toast;
