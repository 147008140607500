import styled from 'styled-components';
import { UtilityText, theme } from '@policygenius/mortar';
import Badge from 'components/Badge';
import { BadgeProps } from './types';

export const ApplicationBadge = styled(Badge)<BadgeProps>`
  margin-left: ${({ padding }) => (padding ? '12px' : 0)};
`;

export const Container = styled.div`
  padding: ${theme.spacing('medium')} ${theme.spacing('large')};
`;

export const Date = styled(UtilityText)`
  padding-left: 28px;
  margin-top: -8px;
`;

export const Empty = styled.div`
  width: 20px;
  height: 1px;
  background: ${theme.colors.gray300};
  margin: ${theme.spacing('small')} 120px ${theme.spacing('small')} 0;
`;

export const HelperText = styled(UtilityText)`
  padding: ${theme.spacing('small')} 0 ${theme.spacing('large')} 0;
`;

export const Icon = styled.div`
  padding-right: 12px;
  margin: auto;
`;

export const Left = styled.div`
  display: flex;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
`;

export const Status = styled.div`
  display: flex;
`;
