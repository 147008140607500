export const sortOrder: { [key: string]: number } = {
  'urn:pg:notification-type:partner:application_requested': 0,
  'urn:pg:notification-type:partner:phone_screen_decline': 1,
  'urn:pg:notification-type:partner:unable_to_reach_intake': 2,
  'urn:pg:notification-type:partner:application_in_underwriting': 3,
  'urn:pg:notification-type:partner:application_approved': 4,
  'urn:pg:notification-type:partner:carrier_postponed': 5,
  'urn:pg:notification-type:partner:carrier_decline': 6,
  'urn:pg:notification-type:partner:policy_sent_to_client': 7,
  'urn:pg:notification-type:partner:policy_in_force': 8,
};
