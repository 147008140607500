import styled from 'styled-components';
import { Button, theme, UtilIconClose, UtilIconHambuger } from '@policygenius/mortar';

interface StyleOps {
  open: boolean;
}

export const Icon = styled.div`
  padding-right: 8px;
  @media (min-width: ${theme.breakpoints.large}px) {
    display: none;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
  padding: 12px 16px;
  @media (min-width: ${theme.breakpoints.medium}px) {
    padding: 12px 24px;
  }
`;

export const Close = styled(UtilIconClose)`
  display: block;
`;

export const Email = styled.div`
  padding: 16px;
  border-bottom: 1px solid ${theme.colors.gray200};
`;

export const Hamburger = styled(UtilIconHambuger)`
  display: block;
`;

export const Label = styled.a`
  display: flex;
  align-items: center;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-grow: 2;
`;

export const Overlay = styled.div`
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  @media (min-width: ${theme.breakpoints.large}px) {
    display: none;
  }
`;

export const Circle = styled.div<StyleOps>`
  border-radius: 50%;
  background: ${theme.colors.orange100};
  padding: 2px 11px;
  cursor: pointer;
  display: ${({ open }) => (open ? 'none' : 'block')};
  @media (min-width: ${theme.breakpoints.large}px) {
    display: block;
  }
`;

export const IconWrapper = styled.div<StyleOps>`
  display: ${({ open }) => (open ? 'block' : 'none')};
  padding: 4px 0;
  cursor: pointer;
  @media (min-width: ${theme.breakpoints.large}px) {
    display: none;
  }
`;

export const Dropdown = styled.div`
  position: absolute;
  width: 100%;
  background: ${theme.colors.white};
  border-top: 1px solid ${theme.colors.gray200};
  border-bottom: 1px solid ${theme.colors.gray200};
  z-index: 2;
  @media (min-width: ${theme.breakpoints.large}px) {
    width: initial;
    right: 24px;
    border: 1px solid ${theme.colors.gray200};
    box-shadow: 0px -2px 7px rgba(0, 0, 0, 0.09);
  }
`;

export const Logout = styled(Button)`
  margin: 16px;
  padding: 0;
  height: initial;
  text-decoration: none;
`;
