import { Row, TitleText, UtilityText, theme } from '@policygenius/mortar';
import styled from 'styled-components';

export const Container = styled(Row)`
  padding: 32px 16px 80px 16px;
  width: 100%;

  @media (min-width: ${theme.breakpoints.medium}px) {
    padding: 64px 24px 120px 24px;
  }
`;

export const Title = styled(TitleText)`
  text-align: left;
  margin-bottom: 8px;
`;

export const Subheader = styled(UtilityText)`
  margin-bottom: 40px;
`;

export const SelectedPolicyContainer = styled.div`
  margin-bottom: 40px;
`;

export const SelectedPolicyTitle = styled(TitleText)`
  margin-bottom: 8px;
`;

export const SelectedPolicyBody = styled(UtilityText)`
  margin-bottom: 24px;
`;
