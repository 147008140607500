import snakeCase from 'lodash/snakeCase';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';

const decamelizeDeep = (value: any): any => {
  if (isArray(value)) {
    return value.map(decamelizeDeep);
  } else if (isPlainObject(value)) {
    return decamelizeKeys(value);
  }

  return value;
};

const decamelizeKeys = (fields: { [key: string]: any }, validKeys: string[] = []) => {
  const result: Record<string, any> = {};

  Object.keys(fields).forEach((key) => {
    const newKey = snakeCase(key);

    if (validKeys.find((validKey) => validKey === newKey) || !validKeys.length) {
      result[newKey] = decamelizeDeep(fields[key]);
    }
  });

  return result;
};

export default decamelizeKeys;
