import React, { useState, useRef, useEffect } from 'react';
import {
  Icon,
  Container,
  Email,
  Label,
  LabelContainer,
  Overlay,
  Close,
  Hamburger,
  Circle,
  IconWrapper,
  Dropdown,
  Logout,
} from './styles';
import { LabelText, UtilityText } from '@policygenius/mortar';
import { PgProLogo } from 'components/PgIcons/PgProLogo';
import { INDEX_ROUTE } from 'routes/constants';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavContext } from 'context/NavContext';
import localStore from 'store2';
import { storeFilterKey, storeSortKey } from 'context/ReportContext';
import { storeSectionKey } from 'context/EappContext';

const Navbar: React.FC = () => {
  const { user, isAuthenticated, logout } = useAuth0();

  const [open, setOpen] = useState(false);
  const { isOpen, setIsOpen } = useNavContext();

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    /* Used to close the dropdown if the user clicks outside of it */
    const dropdownCloser = (event: Event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', dropdownCloser);

    return () => document.removeEventListener('mousedown', dropdownCloser);
  }, [open]);

  const clearSortAndLogout = () => {
    localStore.remove(storeSortKey);
    localStore.remove(storeFilterKey);
    localStore.remove(storeSectionKey);
    logout();
  };

  return (
    <>
      <Container>
        <Icon onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? (
            <Close size="large" color="gray300" />
          ) : (
            <Hamburger size="large" color="gray300" />
          )}
        </Icon>
        <LabelContainer>
          <Label href={INDEX_ROUTE} id="nav-logo">
            <PgProLogo />
          </Label>
        </LabelContainer>
        {isAuthenticated && (
          <>
            <IconWrapper onClick={() => setOpen(!open)} open={open}>
              <Close size="large" color="gray300" />
            </IconWrapper>
            <Circle onClick={() => setOpen(!open)} data-testId="openNav" open={open}>
              <LabelText size="large" color="orange">
                {user?.name?.toUpperCase()[0]}
              </LabelText>
            </Circle>
          </>
        )}
      </Container>
      {open && (
        <>
          <Dropdown ref={dropdownRef}>
            <Email>
              <UtilityText size="small" color="secondary">
                {user?.email}
              </UtilityText>
            </Email>
            <Logout variant="quaternary" size="large" onClick={clearSortAndLogout}>
              Logout
            </Logout>
          </Dropdown>
          <Overlay />
        </>
      )}
    </>
  );
};

export default Navbar;
