import { TitleText, theme, Row } from '@policygenius/mortar';
import styled from 'styled-components';

export const Background = styled.div`
  background: ${theme.colors.white};
  margin-bottom: 50px;
`;

export const Grid = styled(Row)`
  margin: 0 auto;
`;

export const Title = styled(TitleText)`
  text-align: center;
  margin-bottom: 52px;
`;

export const DetailTitle = styled(TitleText)`
  margin-bottom: 24px;
`;

export const Detail = styled.div<{ bottom?: boolean }>`
  display: flex;
  flex-direction: column;

  ${({ bottom }) =>
    bottom &&
    `
    margin-bottom: 12px;
    `}
`;
