import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  useInterviewQuery,
  InterviewQuery,
  InterviewDocument,
} from 'query/interview/interviewQuery';
import DynamicForm, { FormConfigProvider } from '@policygenius/mortar-dynamic-forms';
import { useSaveEappMutation } from 'mutation/eapp/mutation';
import { Node as GraphModelNode, DataType } from 'graph/types';
import { formatSavedData, graphModelToMortarNodeSection } from './graphToMortarHelpers';
import { formatOutgoingDpidData } from './mortarToGraphHelpers';
import { interviews } from './constants';
import { EAPP_ROUTE } from 'routes/constants';
import {
  EAppContainer,
  FORM_SECTION_MARGIN_BOTTOM,
  FormSection,
  Name,
  TableOfContents,
  TableOfContentsItem,
  SavedIcon,
  UnsavedIcon,
  PageContainer,
  SideRailGrid,
  LoadingContainer,
  SectionHeader,
  ButtonContainer,
  NavCol,
} from './styles';
import {
  APP_DETAILS,
  benefitPercentageEligible,
  CONTINGENT_BENEFICIARIES_SECTION_URN,
  EXISTING_POLICIES,
  nonMedEligible,
  NON_MED_URN,
  PRIMARY_BENEFICIARIES_SECTION_URN,
  validateExistingPolicyYearOfIssue,
} from './rules';
import { Button, Col, LoadingIndicator, NavigatePrevious, UtilityText } from '@policygenius/mortar';
import { nameCase } from 'utils/helpers';
import { useEappContext } from 'context/EappContext';
import { eappParams } from './types';
import { useToastContext } from 'context/ToastContext';
import { TOAST_TEXT } from 'pages/DropTicket/constants';
import { PolicyPlaybackV2 } from 'components/PolicyPlaybackV2';
import { useFormContext } from 'context/FormContext';

export const EApp = () => {
  const { show } = useToastContext();
  const navigate = useNavigate();
  const { refId, page, carrier } = useParams<keyof eappParams>() as eappParams;
  const [dpidUrnToTypeMap, setDpidUrnToTypeMap] = useState<Record<string, string>>({});
  const [saveDpidData, { loading: saveLoading }] = useSaveEappMutation();
  const [selectedSection, setSelectedSection] = useState<null | string>();
  const { savedSections, setSavedSections } = useEappContext();
  const [sectionURNs, setSectionURNs] = useState<string[]>([]);
  const { state } = useFormContext();

  const eAppInterview = interviews[`${carrier}`];
  const currentPage = eAppInterview.find(
    (interviewPage) => interviewPage.pageNumber === Number(page)
  );

  const {
    data: interviewData,
    loading: interviewLoading,
    error: interviewError,
  } = useInterviewQuery({
    variables: {
      urn: currentPage?.urn || '',
      internalCaseReferenceID: refId,
    },
  });

  if (interviewError) {
    console.error(interviewError);
  }

  const scrollHandler = (): any => {
    const lastSection = document.getElementById(sectionURNs[sectionURNs.length - 1]);

    if (
      (lastSection && lastSection.offsetTop - FORM_SECTION_MARGIN_BOTTOM <= window.scrollY) ||
      window.scrollY + window.innerHeight >= document.body.offsetHeight - 100
    ) {
      setSelectedSection(sectionURNs[sectionURNs.length - 1]);

      return;
    }

    let selectedURN = sectionURNs[0];

    for (const urn of sectionURNs) {
      const section = document.getElementById(urn);

      if (section && window.scrollY < section?.offsetTop - FORM_SECTION_MARGIN_BOTTOM) {
        setSelectedSection(selectedURN);

        return;
      }

      selectedURN = urn;
    }
  };

  useEffect(() => {
    setDpidUrnToTypeMap(buildUrnToDataTypeMap());
    setSectionURNs(interviewData?.interview?.nodes.map((section) => section.urn) ?? []);
  }, [interviewData?.interview]);

  useEffect(() => {
    if (sectionURNs.length > 0) {
      window.addEventListener('scroll', scrollHandler, true);
      if (!selectedSection) {
        setSelectedSection(sectionURNs[0]);
      }

      return () => window.removeEventListener('scroll', scrollHandler, true);
    }
  }, [sectionURNs]);

  let formattedData: { [key: string]: any | any[] } = {};

  if (interviewData?.interview?.savedDpids) {
    formattedData = formatSavedData(interviewData.interview.savedDpids);
  }

  // buildUrnToDataTypeMap Runs once on mount and builds a map of Dpid URN to Dpid data type
  // e.g. { urn:pg:data-point:insured-first-name: "TEXT" }
  // These data types are needed to map to grpc dataTypes when saving
  const buildUrnToDataTypeMap = (): { [key: string]: string } => {
    const mapToOutput: { [key: string]: string } = {};

    interviewData?.interview?.nodes.forEach((section) => {
      if (section.dataPoint) {
        const parentURN = section.dataPoint.urn;

        mapToOutput[parentURN] = section.dataPoint.dataType;
        section?.dataPoint?.compositeStructure?.forEach((compositeStructure) => {
          mapToOutput[`${parentURN}:${compositeStructure!.key}`] = compositeStructure?.value
            ?.dataType as DataType;
        });
      } else {
        section?.children?.forEach((child: GraphModelNode) => {
          if (child.nodeType === 'SECTION' && child.dataPoint) {
            const parentURN = child.dataPoint.urn;

            mapToOutput[parentURN] = child.dataPoint.dataType;
            child?.dataPoint?.compositeStructure?.forEach((compositeStructure) => {
              mapToOutput[`${parentURN}:${compositeStructure!.key}`] = compositeStructure?.value
                ?.dataType as DataType;
            });
          }
          if (child.nodeType === 'QUESTION' && child.dataPoint) {
            mapToOutput[child.dataPoint.urn] = child.dataPoint.dataType;
          }
        });
      }
    });

    return mapToOutput;
  };

  const onSubmit = async (submitData: { [key: string]: any }, sectionUrn: string) => {
    if (sectionUrn === APP_DETAILS) {
      const eligible = validateExistingPolicyYearOfIssue(submitData[EXISTING_POLICIES]);

      if (!eligible) {
        show({
          type: 'error',
          title: 'Year of issue must be after 1900',
          body: '',
        });

        return;
      }
    }

    if (
      sectionUrn === PRIMARY_BENEFICIARIES_SECTION_URN ||
      (sectionUrn === CONTINGENT_BENEFICIARIES_SECTION_URN && !!Object.keys(submitData)[0])
    ) {
      const dataPointUrn = Object.keys(submitData)[0];
      const eligible = submitData[dataPointUrn]
        ? benefitPercentageEligible(submitData[dataPointUrn])
        : false;

      if (!eligible) {
        show({
          type: 'error',
          title: 'Percentage must equal 100%',
          body: `The total percentage between all ${
            sectionUrn === PRIMARY_BENEFICIARIES_SECTION_URN ? 'primary' : 'contingent'
          } beneficiaries must equal 100%`,
        });

        return;
      }
    }
    if (sectionUrn === NON_MED_URN) {
      const isEligible = nonMedEligible(
        interviewData?.statusReport.lifeAppliedPolicy?.coverageAmount,
        interviewData?.statusReport.client.dateOfBirth
      );

      if (!isEligible) {
        show({
          type: 'error',
          title: 'Client is not eligible',
          body: `Based on your client's age and selected coverage amount, they are unable to skip the medical exam. Please select "No" to proceed`,
        });

        return;
      }
    }
    const mappedData = formatOutgoingDpidData(submitData, dpidUrnToTypeMap);

    await saveDpidData({
      variables: {
        input: {
          dpids: mappedData.dpids,
          repeatableDpids: mappedData.repeatableDpids,
          referenceId: refId,
        },
      },
      onError: (e) => {
        const section = document.getElementById(sectionUrn);

        show({
          type: 'error',
          title: TOAST_TEXT.errorEAppSectionSubmission.title(section?.innerText),
          body: TOAST_TEXT.errorEAppSectionSubmission.body,
        });

        console.error(e);
      },
      onCompleted: () => {
        if (!savedSections[refId]) {
          setSavedSections({
            ...savedSections,
            [refId]: { [sectionUrn]: true },
          });
        } else if (!savedSections[refId][sectionUrn]) {
          setSavedSections({
            ...savedSections,
            [refId]: { ...savedSections[refId], [sectionUrn]: true },
          });
        }
      },
      refetchQueries: [
        {
          query: InterviewDocument,
          variables: { urn: currentPage?.urn, internalCaseReferenceID: refId },
        },
      ],
    });
  };

  const nextPage = () => {
    const proceedToNonMed = interviewData?.interview?.savedDpids?.dpids?.find(
      (item) => item?.dataPointIdUrn === NON_MED_URN
    )?.boolValue;

    const nextPageNumber = Number(page) + (proceedToNonMed ? 1 : 2);
    const nextPageExists = eAppInterview.find(
      (interview) => interview.pageNumber === nextPageNumber
    );

    if (nextPageExists) {
      navigate(`${EAPP_ROUTE}/${carrier}/${nextPageNumber}/${refId}`);
    } else {
      navigate(`${EAPP_ROUTE}/${carrier}/review/${refId}`);
    }
  };

  const renderSections = (interview: InterviewQuery['interview']) => {
    if (!interview?.nodes) {
      return;
    }

    return interview.nodes.map((section) => {
      const schema = graphModelToMortarNodeSection(section);

      return (
        <FormConfigProvider
          key={schema.urn}
          submitButtonText="Save"
          submitButtonStyle={{
            left: '100%',
            transform: 'translateX(-100%)',
          }}
        >
          <FormSection key={schema.urn}>
            <SectionHeader id={section.urn}>{section.content}</SectionHeader>
            <DynamicForm
              key={schema.urn}
              schema={schema}
              onSubmit={(formValues) => onSubmit(formValues, schema.urn)}
              name={schema.urn}
              initialValues={formattedData}
            />
          </FormSection>
        </FormConfigProvider>
      );
    });
  };

  const scrollToSection = (sectionURN: string): void => {
    const section = document.getElementById(sectionURN);

    if (section) {
      window.scrollTo({ top: section.offsetTop, behavior: 'smooth' });
    }
  };

  const renderTableOfContents = (
    interview: InterviewQuery['interview']
  ): JSX.Element | undefined => {
    if (!interview?.nodes) {
      return;
    }

    return (
      <TableOfContents>
        {interview.nodes.map((section) => {
          let savedStatusIcon = <UnsavedIcon color="gray400" ariaLabel="unsaved icon" />;

          if (!savedSections[refId]) {
            setSavedSections({ ...savedSections, [refId]: {} });
          } else if (savedSections[refId][section.urn]) {
            savedStatusIcon = <SavedIcon color="blue400" ariaLabel="saved icon" />;
          }

          return (
            <TableOfContentsItem
              key={section.urn}
              onClick={() => scrollToSection(section.urn)}
              selected={section.urn === selectedSection}
            >
              {savedStatusIcon}
              <UtilityText>{section.content}</UtilityText>
            </TableOfContentsItem>
          );
        })}
      </TableOfContents>
    );
  };

  const handleTabExit = (event: BeforeUnloadEvent) => {
    event.preventDefault();
  };

  const isAllLoading = interviewLoading || saveLoading;

  useEffect(() => {
    window.addEventListener('beforeunload', handleTabExit, true);

    return () => window.removeEventListener('beforeunload', handleTabExit, true);
  }, []);

  if (!interviewData?.interview?.nodes && !interviewLoading) {
    return <div> why are there no nodes</div>;
  }

  const allSectionsSaved = useMemo(() => {
    return interviewData?.interview?.nodes.every((node) => savedSections[refId]?.[node.urn]);
  }, [interviewData]);

  return (
    <EAppContainer>
      {interviewLoading ? (
        <LoadingContainer>
          <LoadingIndicator color="gray500" />
        </LoadingContainer>
      ) : (
        <PageContainer>
          <Col
            span={{
              small: 0,
              medium: 4,
              large: 3,
            }}
          >
            <SideRailGrid nested>
              <Col span={{ medium: 10, large: 11 }}>
                {!interviewLoading && (
                  <>
                    <UtilityText size="small">{currentPage?.tableOfContentsHeader}</UtilityText>
                    <Name size="large" weight="bold">
                      {nameCase(state.firstName || '')}&nbsp;
                      {nameCase(state.lastName || '')}
                    </Name>
                    {renderTableOfContents(interviewData?.interview)}
                  </>
                )}
              </Col>
            </SideRailGrid>
          </Col>
          <Col
            span={{
              small: 4,
              medium: 7,
              large: 6,
            }}
          >
            {renderSections(interviewData?.interview)}
            <NavCol>
              <ButtonContainer>
                {Number(page) > 1 && (
                  <Button size="large" variant="filled" onClick={() => navigate(-1)} type="button">
                    <NavigatePrevious size="large" color="black" />
                    <span>Back</span>
                  </Button>
                )}

                <Button
                  size="large"
                  variant="black"
                  onClick={() => nextPage()}
                  type="button"
                  disabled={isAllLoading || !allSectionsSaved}
                >
                  Continue
                </Button>
              </ButtonContainer>
            </NavCol>
          </Col>
          <Col
            span={{
              small: 0,
              medium: 0,
              large: 3,
            }}
          >
            <SideRailGrid>
              <Col span={{ large: 11 }} push={{ large: 1 }}>
                {!interviewLoading && <PolicyPlaybackV2 />}
              </Col>
            </SideRailGrid>
          </Col>
        </PageContainer>
      )}
    </EAppContainer>
  );
};
