import { composeValidators, isPresent, isDOBRangeValid, isBetween } from 'forms/validators/index';
import { ValidationErrors } from 'forms/types';
import { LifeApplicationInfo } from 'context/FormContext/types';
import { moneyToString } from 'utils/helpers';
import {
  COVERAGE_AMOUNT_MAX,
  COVERAGE_AMOUNT_MIN,
  COVERAGE_ERROR_OVERRIDE,
} from 'pages/ClientInfo/form/constants';

export const getValidator = (
  values: LifeApplicationInfo
): ValidationErrors<LifeApplicationInfo> => {
  const eventDateValidator = composeValidators(isPresent, isDOBRangeValid);
  const validateCoverageAmount = composeValidators(
    isPresent,
    isBetween(COVERAGE_AMOUNT_MIN, COVERAGE_AMOUNT_MAX, COVERAGE_ERROR_OVERRIDE)
  );

  const { gender, state, dateOfBirth, tobacco, healthClass, coverageAmount, term, paymentMode } =
    values;

  return {
    gender: isPresent(gender),
    state: isPresent(state),
    dateOfBirth: eventDateValidator(dateOfBirth),
    tobacco: isPresent(tobacco),
    healthClass: isPresent(healthClass),
    coverageAmount: validateCoverageAmount(moneyToString(coverageAmount)),
    term: isPresent(term),
    paymentMode: isPresent(paymentMode),
  };
};
