import React from 'react';
import { Col, Row, UtilIconClose } from '@policygenius/mortar';
import { Body, CloseIcon, Header, Loading } from './styles';
import LoadingShimmer from 'components/LoadingShimmer';

const LoadingDetailPanel: React.FC = () => {
  return (
    <>
      <Header>
        <Loading>
          <LoadingShimmer width={30} bottom={6} background="dark" />
          <LoadingShimmer width={70} bottom={6} background="dark" />
        </Loading>
        <CloseIcon data-testid="closeIcon">
          <UtilIconClose size="large" />
        </CloseIcon>
      </Header>
      <Body>
        <LoadingShimmer width={50} bottom={8} />
        <LoadingShimmer bottom={8} />
        <LoadingShimmer width={80} bottom={12} />
        <Row nested>
          <Col span={{ small: 2, medium: 6 }}>
            <Loading>
              <LoadingShimmer width={30} bottom={6} />
              <LoadingShimmer width={70} bottom={16} />
            </Loading>
          </Col>
          <Col span={{ small: 2, medium: 6 }}>
            <Loading>
              <LoadingShimmer width={30} bottom={6} />
              <LoadingShimmer width={70} bottom={16} />
            </Loading>
          </Col>
          <Col span={{ small: 2, medium: 6 }}>
            <Loading>
              <LoadingShimmer width={30} bottom={6} />
              <LoadingShimmer width={70} bottom={16} />
            </Loading>
          </Col>
          <Col span={{ small: 2, medium: 6 }}>
            <Loading>
              <LoadingShimmer width={30} bottom={6} />
              <LoadingShimmer width={70} bottom={16} />
            </Loading>
          </Col>
          <Col span={{ small: 2, medium: 6 }}>
            <Loading>
              <LoadingShimmer width={30} bottom={6} />
              <LoadingShimmer width={70} bottom={16} />
            </Loading>
          </Col>
          <Col span={{ small: 2, medium: 6 }}>
            <Loading>
              <LoadingShimmer width={30} bottom={6} />
              <LoadingShimmer width={70} bottom={16} />
            </Loading>
          </Col>
          <Col span={{ small: 2, medium: 6 }}>
            <Loading>
              <LoadingShimmer width={30} bottom={6} />
              <LoadingShimmer width={70} bottom={16} />
            </Loading>
          </Col>
        </Row>
      </Body>
    </>
  );
};

export default LoadingDetailPanel;
