import { Row, TitleText, UtilityText, theme } from '@policygenius/mortar';
import styled from 'styled-components';

export const Container = styled(Row)`
  padding: 32px 16px 80px 16px;
  width: 100%;

  @media (min-width: ${theme.breakpoints.medium}px) {
    padding: 64px 24px 120px 24px;
  }
`;

export const Title = styled(TitleText)`
  text-align: left;
  margin-bottom: 8px;
`;

export const Subheader = styled(UtilityText)`
  margin-bottom: 40px;
`;

export const SelectedPolicyContainer = styled.div`
  margin-bottom: 56px;
`;

export const SelectedPolicyTitle = styled(TitleText)`
  margin-bottom: 8px;
`;

export const SelectedPolicyBody = styled(UtilityText)`
  margin-bottom: 24px;
`;

export const Name = styled.div`
  display: flex;
  p:first-child {
    margin-right: 8px;
  }

  margin-bottom: 32px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button:first-child {
    margin-right: 10px;
  }
`;

export const ButtonLoading = styled.span`
  position: absolute;
  line-height: 0;
`;
