/* THIS FILE WAS AUTO-GENERATED. DO NOT MODIFY */
/* eslint-disable */
import * as Types from '../../graph/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserLifeAppMutationVariables = Types.Exact<{
  gender: Types.Gender;
  dateOfBirth: Types.Scalars['String']['input'];
  state: Types.Scalars['String']['input'];
  tobaccoUse?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  firstName: Types.Scalars['String']['input'];
  lastName: Types.Scalars['String']['input'];
  phone: Types.Scalars['String']['input'];
  email: Types.Scalars['String']['input'];
  policyPreference?: Types.InputMaybe<Types.PolicyPreference>;
  coverageAmount?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  termInYears?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  notes?: Types.InputMaybe<Types.Scalars['String']['input']>;
  paymentMode?: Types.InputMaybe<Types.Scalars['String']['input']>;
  referringAgent?: Types.InputMaybe<Types.Scalars['String']['input']>;
  creationMethod?: Types.InputMaybe<Types.CreationMethod>;
}>;


export type UserLifeAppMutation = { __typename?: 'Mutation', userLifeApp: { __typename?: 'UserCreationResponse', statusCode: number, applicationResponse?: { __typename?: 'ApplicationResponse', referenceId: string } | null } };


export const UserLifeAppDocument = gql`
    mutation UserLifeApp($gender: Gender!, $dateOfBirth: String!, $state: String!, $tobaccoUse: Boolean, $firstName: String!, $lastName: String!, $phone: String!, $email: String!, $policyPreference: PolicyPreference, $coverageAmount: Int, $termInYears: Int, $notes: String, $paymentMode: String, $referringAgent: String, $creationMethod: CreationMethod) {
  userLifeApp(
    input: {gender: $gender, dateOfBirth: $dateOfBirth, state: $state, tobaccoUse: $tobaccoUse, firstName: $firstName, lastName: $lastName, phone: $phone, email: $email, policyPreference: $policyPreference, coverageAmount: $coverageAmount, termInYears: $termInYears, notes: $notes, paymentMode: $paymentMode, referringAgent: $referringAgent, creationMethod: $creationMethod}
  ) {
    applicationResponse {
      referenceId
    }
    statusCode
  }
}
    `;
export type UserLifeAppMutationFn = Apollo.MutationFunction<UserLifeAppMutation, UserLifeAppMutationVariables>;

/**
 * __useUserLifeAppMutation__
 *
 * To run a mutation, you first call `useUserLifeAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserLifeAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userLifeAppMutation, { data, loading, error }] = useUserLifeAppMutation({
 *   variables: {
 *      gender: // value for 'gender'
 *      dateOfBirth: // value for 'dateOfBirth'
 *      state: // value for 'state'
 *      tobaccoUse: // value for 'tobaccoUse'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      phone: // value for 'phone'
 *      email: // value for 'email'
 *      policyPreference: // value for 'policyPreference'
 *      coverageAmount: // value for 'coverageAmount'
 *      termInYears: // value for 'termInYears'
 *      notes: // value for 'notes'
 *      paymentMode: // value for 'paymentMode'
 *      referringAgent: // value for 'referringAgent'
 *      creationMethod: // value for 'creationMethod'
 *   },
 * });
 */
export function useUserLifeAppMutation(baseOptions?: Apollo.MutationHookOptions<UserLifeAppMutation, UserLifeAppMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserLifeAppMutation, UserLifeAppMutationVariables>(UserLifeAppDocument, options);
      }
export type UserLifeAppMutationHookResult = ReturnType<typeof useUserLifeAppMutation>;
export type UserLifeAppMutationResult = Apollo.MutationResult<UserLifeAppMutation>;
export type UserLifeAppMutationOptions = Apollo.BaseMutationOptions<UserLifeAppMutation, UserLifeAppMutationVariables>;