import styled from 'styled-components';
import { theme } from '@policygenius/mortar';

export const Background = styled.div`
  background-color: ${theme.colors.white};
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
