import { LoadingIndicator } from '@policygenius/mortar';
import { useAffiliateQuery } from 'query/affiliate/query';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import About from './About';
import FeedbackContainer from './FeedbackContainer';
import Footer from './Footer';
import MarketingDisclosureContainer from './MarketingDisclosureContainer';
import Hero from './Hero';
import PriceCalculator from './PriceCalculator';
import { Loader } from './styles';
import { Helmet } from 'react-helmet';

const ReferralLandingPage = () => {
  const { slug } = useParams();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const advisor = searchParams.get('advisor');
  const agentUUID = searchParams.get('uuid');

  const { data, loading, error } = useAffiliateQuery({
    variables: {
      slug: slug!,
    },
  });

  if (error) {
    console.error(error);
  }

  const advisorName =
    advisor &&
    advisor
      .split('-')
      .map((name: string) => `${name[0].toUpperCase()}${name.substring(1)}`)
      .join(' ');

  const subtext = advisorName
    ? `Shop the market and access best-in-class guidance from ${advisorName} and Policygenius.`
    : data?.affiliate.subtext;

  return (
    <React.Fragment>
      <Helmet>
        {advisorName && <title>{advisorName}</title>}
        {advisorName && <meta property="og:title" content={advisorName} />}
        {advisorName && <meta property="og:description" content={advisorName} />}
        {advisorName && <meta name="description" content={advisorName} />}
      </Helmet>
      {loading ? (
        <Loader>
          <LoadingIndicator color={'black'} />
        </Loader>
      ) : (
        <React.Fragment>
          <Hero
            agentUUID={agentUUID}
            imageURL={data!.affiliate.logoURL}
            subtext={subtext}
            title={data!.affiliate.title}
          />
          <PriceCalculator />
          <About
            leftContent={data!.affiliate.aboutSectionLeft}
            rightContent={data!.affiliate.aboutSectionRight}
          />
          <FeedbackContainer />
          <MarketingDisclosureContainer />
          <Footer />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ReferralLandingPage;
