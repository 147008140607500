import { theme } from '@policygenius/mortar';
import React from 'react';

interface Props {
  className?: string;
}

export const Send: React.FunctionComponent<Props> = ({ className }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="arrow"
      d="M16 26.3001L40.9 24.0001L16 21.7001V11.5001C16 11.4001 16 11.2001 16.1 11.1001C16.3 10.6001 16.9 10.4001 17.4 10.6001L45.1 23.1001C45.3 23.2001 45.5 23.4001 45.6 23.6001C45.8 24.1001 45.6 24.7001 45.1 24.9001L17.4 37.4001C17.3 37.5001 17.1 37.5001 17 37.5001C16.4 37.5001 16 37.1001 16 36.5001V26.3001Z"
      fill={`${theme.colors.gray500}`}
    />
    <path
      className="lines"
      d="M8 32H10C11.1 32 12 32.9 12 34C12 35.1 11.1 36 10 36H8C6.9 36 6 35.1 6 34C6 32.9 6.9 32 8 32ZM2 22H10C11.1 22 12 22.9 12 24C12 25.1 11.1 26 10 26H2C0.9 26 0 25.1 0 24C0 22.9 0.9 22 2 22ZM8 12H10C11.1 12 12 12.9 12 14C12 15.1 11.1 16 10 16H8C6.9 16 6 15.1 6 14C6 12.9 6.9 12 8 12Z"
      fill={`${theme.colors.gray300}`}
    />
  </svg>
);
