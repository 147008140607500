import React from 'react';

interface Props {
  active: boolean;
}

export const RightChevron: React.FC<Props> = ({ active }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      d="M12.4 9.93334C11.9334 9.46667 11.9334 8.73334 12.4 8.33334C12.8667 7.86667 13.6 7.86667 14 8.33334L20.8667 15.2C21.2667 15.6 21.3334 16.3333 20.9334 16.8L14.6667 23.6C14.2667 24.0667 13.5334 24.0667 13.0667 23.6667C12.6 23.2667 12.6 22.5333 13 22.0667L18.5334 16L12.4 9.93334Z"
      fill={active ? '#707070' : '#C0C0C0'}
    />
  </svg>
);
