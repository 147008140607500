import React, { MouseEventHandler, useCallback } from 'react';
import { Button as MortarButton } from '@policygenius/mortar';
import { ButtonProps } from './types';
import { track, Events } from 'utils/analytics';

const Button: React.FC<ButtonProps> = ({ analyticsText, onClick, ...rest }) => {
  const trackedOnClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (...args) => {
      track(Events.CLICKED_BUTTON, { analyticsText });

      return onClick && onClick(...args);
    },
    [analyticsText, onClick]
  );

  return <MortarButton onClick={trackedOnClick} {...rest} />;
};

export default Button;
