import { Col, LabelText, theme, UtilityText } from '@policygenius/mortar';
import styled from 'styled-components';
import {
  AcceleratedBadge,
  FinalExpenseBadge,
  InstantBadge,
  NonMedBadge,
  SimplifiedBadge,
} from 'components/Badge/constants';
import { BadgeDescriptionProps } from './types';

export const TitlePrice = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  @media (min-width: ${theme.breakpoints.medium}px) {
    margin: 0;
    width: 300px;
  }

  @media (min-width: ${theme.breakpoints.large}px) {
    min-width: 196px;
    width: unset;
  }
`;

export const CarrierLink = styled(UtilityText)`
  &:hover {
    color: ${theme.colors.orange300};
  }
`;

export const MobilePrice = styled.div`
  display: flex;
  align-items: flex-end;

  @media (min-width: ${theme.breakpoints.medium}px) {
    display: none;
  }
`;

export const BadgeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 4px;

  @media (max-width: ${theme.breakpoints.large}px) {
    flex-direction: column;
  }

  @media (max-width: ${theme.breakpoints.medium}px) {
    flex-direction: row;
  }
`;

export const MobileBadgeContainer = styled.div`
  margin-bottom: 16px;

  @media (min-width: ${theme.breakpoints.medium}px) {
    display: none;
  }
`;

export const ProductHealthSection = styled.div`
  flex: 1;
`;

export const Label = styled(LabelText)`
  margin-bottom: 4px;
`;

export const Accelerated = styled(AcceleratedBadge)<BadgeDescriptionProps>`
  display: ${(props) => (props.mobile ? '' : 'none')};
  margin-bottom: ${(props) => props.mobile && '16px'};

  @media (min-width: ${theme.breakpoints.medium}px) {
    margin-top: 8px;
    display: flex;
  }
`;

export const Instant = styled(InstantBadge)<BadgeDescriptionProps>`
  display: ${(props) => (props.mobile ? '' : 'none')};
  margin-bottom: ${(props) => props.mobile && '16px'};

  @media (min-width: ${theme.breakpoints.medium}px) {
    margin-top: 8px;
    display: flex;
  }
`;

export const Simplified = styled(SimplifiedBadge)<BadgeDescriptionProps>`
  display: ${(props) => (props.mobile ? '' : 'none')};
  margin-bottom: ${(props) => props.mobile && '16px'};

  @media (min-width: ${theme.breakpoints.medium}px) {
    margin-top: 8px;
    display: flex;
  }
`;

export const NonMed = styled(NonMedBadge)<BadgeDescriptionProps>`
  display: ${(props) => (props.mobile ? '' : 'none')};
  margin-bottom: ${(props) => props.mobile && '16px'};

  @media (min-width: ${theme.breakpoints.medium}px) {
    margin-top: 8px;
    display: flex;
  }
`;

export const FinalExpense = styled(FinalExpenseBadge)<BadgeDescriptionProps>`
  display: ${(props) => (props.mobile ? '' : 'none')};
  margin-bottom: ${(props) => props.mobile && '16px'};

  @media (min-width: ${theme.breakpoints.medium}px) {
    margin-top: 8px;
    display: flex;
  }
`;

export const CardButtonsContainer = styled.div<{ renderEapp: boolean }>`
  display: flex;
  gap: 8px;
  @media (min-width: ${theme.breakpoints.medium}px) {
    width: ${({ renderEapp }) => (renderEapp ? '35%' : '30%')};
    flex: 1 2 auto;
    justify-content: flex-end;
    margin-bottom: 16px;
    margin-left: auto;
  }

  @media (min-width: ${theme.breakpoints.large}px) {
    display: none;
  }
`;

export const DesktopCardButtonsContainer = styled(CardButtonsContainer)`
  display: none;

  @media (min-width: ${theme.breakpoints.large}px) {
    width: 25%;
    display: flex;
    gap: 8px;
    margin-left: auto;
    justify-content: flex-end;
    margin-bottom: 0px;
    flex: none;
  }
`;

export const MobileDropTicketContainer = styled(Col)`
  @media (min-width: ${theme.breakpoints.medium}px) {
    margin-left: auto;

    button {
      margin-left: auto;
    }
  }
`;

export const CarrierProductHealthPrice = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${theme.breakpoints.medium}px) {
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    gap: 24px;
    width: 100%;
    margin-bottom: 16px;
  }
`;

export const EappBanner = styled.div`
  display: flex;
  align-items: center;
  background: ${theme.colors.green100};
  padding: 8px 16px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  svg {
    margin-right: 8px;
  }
`;
export const ProductCardContainer = styled.div<{
  hasBadge: boolean;
  isEapp: boolean;
}>`
  display: flex;
  flex-direction: column;
  background: ${theme.colors.white};
  padding: 16px;
  border: 1px solid ${theme.colors.gray200};
  border-radius: 4px;
  margin: 0px 0px 8px 0px;
  width: 100%;

  @media (min-width: ${theme.breakpoints.medium}px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    padding-bottom: ${({ hasBadge }) => (hasBadge ? '16px' : '0px')};
  }

  @media (min-width: ${theme.breakpoints.large}px) {
    flex-direction: row;
  }

  ${({ isEapp }) =>
    isEapp &&
    `
    margin-top: -3px;
    `}
`;

export const TabletUpPremium = styled.div`
  display: none;

  @media (min-width: ${theme.breakpoints.medium}px) {
    display: flex;
    align-items: flex-start;
  }
`;

export const ProductHealthContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 16px;

  @media (min-width: ${theme.breakpoints.medium}px) {
    gap: 24px;
    width: 100%;
    margin: 0;
  }

  @media (min-width: ${theme.breakpoints.large}px) {
    flex: 1 2 auto;
  }
`;
