import isNil from 'lodash/isNil';
import chunk from 'lodash/chunk';
import { MaskValue } from './types';

export const maskMoney = (maybeNumber?: MaskValue) => {
  const number = isNil(maybeNumber) ? '' : maybeNumber;
  const chars = number.toString().match(/\d/g) || [];

  if (chars.length < 4) {
    return chars.join('');
  }

  const reversedChars = [...chars].reverse();
  const chunks = chunk(reversedChars, 3);

  const returnVal = chunks
    .map((nums) => nums.reverse().join(''))
    .reverse()
    .join(',');

  return returnVal;
};
