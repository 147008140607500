import React, { createContext, useState, useContext } from 'react';
import { ContextProps } from './types';

export const NavContext = createContext<ContextProps>({
  isOpen: false,
  setIsOpen: () => undefined,
});

export const NavProvider: React.FunctionComponent = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return <NavContext.Provider value={{ isOpen, setIsOpen }}>{children}</NavContext.Provider>;
};

export const useNavContext = (): ContextProps => useContext(NavContext);

export default NavProvider;
