import { UnderwritingClassDecision } from 'graph/types';

export const HEALTH_CLASS = {
  [UnderwritingClassDecision.Better]: {
    name: 'Better health class',
    body: "Your client's policy has been approved at a less expensive health class than originally applied for!",
    link: 'https://www.policygenius.com/life-insurance/what-are-life-insurance-classifications/',
  },
  [UnderwritingClassDecision.Same]: {
    name: 'Same health class',
    body: "Your client's policy has been approved at the same health class as originally applied for!",
    link: 'https://www.policygenius.com/life-insurance/what-are-life-insurance-classifications/',
  },
  [UnderwritingClassDecision.Worse]: {
    name: 'Modified health class',
    body: "Following underwriting review, the carrier has offered the policy at a more expensive health class than originally applied for, based on your client's risk assessment.",
    link: 'https://www.policygenius.com/life-insurance/life-insurance-modified-offer-or-approved-other-than-applied/',
  },
};
