import { ReactElement } from 'react';
import { theme } from '@policygenius/mortar';
import { TypographyColors } from '@policygenius/mortar/dist/theme';

export type BadgeVariant =
  | 'INFO'
  | 'ALERT'
  | 'POSITIVE'
  | 'NEUTRAL'
  | 'SUCCESS'
  | 'WARNING'
  | 'DANGER'
  | 'BETA'
  | 'NEW';

export interface BadgeProps {
  icon?: ReactElement;
  label: string;
  variant?: BadgeVariant;
  className?: string;
}

export interface ClassProp {
  className?: string;
}

type ColorMap = {
  [key in BadgeVariant]: string;
};

export const themeColorMap: ColorMap = {
  NEUTRAL: theme.colors.gray100,
  SUCCESS: theme.colors.green400,
  WARNING: '#A4680F',
  DANGER: theme.colors.maroon400,
  ALERT: theme.colors.maroon100,
  POSITIVE: theme.colors.green100,
  BETA: theme.colors.orange100,
  INFO: theme.colors.blue100,
  NEW: theme.colors.blue400,
};

type VariantMap = {
  [key in BadgeVariant]: TypographyColors;
};

export const textColorMap: VariantMap = {
  NEUTRAL: 'primary',
  SUCCESS: 'white',
  WARNING: 'white',
  DANGER: 'white',
  ALERT: 'maroon',
  POSITIVE: 'green',
  BETA: 'orange',
  INFO: 'blue',
  NEW: 'white',
};
