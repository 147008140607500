import { theme, UtilityText } from '@policygenius/mortar';
import styled from 'styled-components';

export const AdvisorContainer = styled.div`
  margin-bottom: 32px;
  @media (min-width: ${theme.breakpoints.medium}px) {
    margin-bottom: 56px;
  }
`;

export const SelectedAdvisorRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const SelectedAdvisorName = styled.div`
  display: flex;
  align-items: center;
`;

export const SelectedAdvisorLabel = styled(UtilityText)`
  margin-left: 8px;
`;
