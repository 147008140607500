import React from 'react';

export const Duplicate: React.FunctionComponent = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_687_31)">
      <path
        d="M16 6H9C7.35 6 6 7.35 6 9V16C4.7 15.95 4 15.2 4 13.8V6.2C4 4.75 4.75 4 6.2 4H13.85C15.15 4 15.95 4.7 16 6Z"
        fill="#383838"
      />
      <path
        d="M10.2 8H17.85C19.25 8 20.05 8.75 20.05 10.2V17.85C20.05 19.25 19.3 20.05 17.85 20.05H10.2C8.8 20.05 8 19.3 8 17.85V10.2C8 8.75 8.75 8 10.2 8Z"
        fill="#383838"
      />
    </g>
  </svg>
);
