import React from 'react';
import { Tab, Container } from './styles';
import { ListFilesQuery } from 'query/files/query';
import { ActionDownload, BodyText } from '@policygenius/mortar';
import { useGetFileDataLazyQuery } from 'query/getFileData/query';
import { FileMetaData } from 'graph/types';
import { useParams } from 'react-router-dom';

export const DocumentTab: React.FC<{ files: ListFilesQuery['listFiles'] }> = ({ files }) => {
  const { id } = useParams();

  const [getFileData, { error }] = useGetFileDataLazyQuery({
    fetchPolicy: 'no-cache',
  });

  if (error) {
    console.log('ERROR downloading file', error);
  }

  const download = async (file: FileMetaData) => {
    const response = await getFileData({
      variables: {
        referenceId: id!,
        attachmentId: Number(file!.id!),
      },
    });

    if (response.data?.getFileData?.data) {
      // Decode Base64 string to a Uint8Array
      const link = document.createElement('a');

      // Convert the base64 string to a Blob
      const byteCharacters = atob(response.data?.getFileData?.data);
      const byteNumbers = Uint8Array.from(byteCharacters, (char) => char.charCodeAt(0));

      const fileName = response.data?.getFileData?.fileName.split('.');
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {
        type: `application/${fileName[1]}`,
      });

      // Create a URL for the Blob and set it as the href of the link
      const blobUrl = URL.createObjectURL(blob);

      link.href = blobUrl;

      // Set the download attribute of the link to the desired file name
      link.download = fileName[0];

      // Append the link to the body (this step is necessary for Firefox)
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);

      // Revoke the object URL after the download is complete
      URL.revokeObjectURL(blobUrl);
    }
  };

  return (
    <Tab>
      <Container>
        {files?.files.map((link) => {
          return (
            <div key={link!.id}>
              <ActionDownload size="large" />
              <BodyText onClick={() => download(link!)}>{link?.displayName}</BodyText>
            </div>
          );
        })}
      </Container>
    </Tab>
  );
};
