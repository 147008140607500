import { UtilityText, theme, TitleText } from '@policygenius/mortar';
import styled from 'styled-components';

export const PlaceholderBox = styled.div`
  width: 100%;
  height: 300px;
  background-color: ${theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const AccessText = styled(UtilityText)`
  padding-bottom: 16px;
  margin: 0px 16px;
  text-align: center;
`;

export const EmptyTableTitleText = styled(TitleText)`
  padding-top: 10px;
  margin-bottom: 4px;
`;
