import { UtilityText, theme } from '@policygenius/mortar';
import styled from 'styled-components';
import { Gradient } from 'components/BackgroundGradient/styles';

export const Container = styled.div`
  display: flex;
  min-height: 100vh;
  justify-content: center;
  @media (min-width: ${theme.breakpoints.medium}px) {
    ${Gradient};
  }
`;

export const Background = styled.div`
  display: flex;
  height: 342px;
  background-color: ${theme.colors.white};
  @media (min-width: ${theme.breakpoints.medium}px) {
    height: 290px;
    width: 552px;
    border-radius: 4px;
    margin-top: 94px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 24px;
  margin-bottom: 56px;
  @media (min-width: ${theme.breakpoints.medium}px) {
    margin: 40px;
  }
`;

export const UtilityLink = styled(UtilityText)`
  text-decoration: underline;
`;

export const LabelContainer = styled.div`
  display: flex;
`;
