import React from 'react';
import { UtilIconTaskDone, StatusOpen, UtilIconTaskUpcoming } from '@policygenius/mortar';
import { AttendingPhysicianStatement } from 'graph/types';

interface MedicalRecordProps {
  status: AttendingPhysicianStatement;
}

export const APSOrderIcon: React.FunctionComponent<MedicalRecordProps> = ({ status }) => {
  if (status === AttendingPhysicianStatement.Unstarted) {
    return <StatusOpen color="gray300" />;
  }

  const orderDone = [
    AttendingPhysicianStatement.AwaitingReq,
    AttendingPhysicianStatement.Ordered,
    AttendingPhysicianStatement.Received,
  ].includes(status);

  if (orderDone) {
    return <UtilIconTaskDone color="blue400" />;
  }

  return <></>;
};

export const APSReleaseIcon: React.FC<MedicalRecordProps> = ({ status }) => {
  if (status === AttendingPhysicianStatement.Unstarted) {
    return <UtilIconTaskUpcoming color="gray300" />;
  }
  if (status === AttendingPhysicianStatement.Received) {
    return <UtilIconTaskDone color="blue400" />;
  }

  const releaseTodo = [
    AttendingPhysicianStatement.AwaitingReq,
    AttendingPhysicianStatement.Ordered,
  ].includes(status);

  if (releaseTodo) {
    return <StatusOpen color="gray300" />;
  }

  return <></>;
};
