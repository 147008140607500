import { CheckboxGroup, Col, Row, theme, TitleText, UtilityText } from '@policygenius/mortar';
import styled from 'styled-components';

export const Background = styled.div`
  background-color: ${theme.colors.white};
  min-height: 80vh;
`;

export const Border = styled.hr`
  border: 1px solid ${theme.colors.gray200};
  margin-bottom: 20px;
`;

export const Grid = styled(Row)`
  padding: 32px 16px 80px 16px;
  background-color: ${theme.colors.white};
  margin: auto;
  @media (min-width: ${theme.breakpoints.medium}px) {
    padding: 64px 24px 120px 24px;
  }
`;

export const OptionsContainer = styled(Col)`
  padding-bottom: 40px;
`;

export const SectionTitle = styled(UtilityText)`
  margin-bottom: 16px;
`;

export const Subheader = styled(TitleText)`
  margin-bottom: 4px;
`;

export const PageTitle = styled(TitleText)`
  margin-bottom: 24px;
  @media (min-width: ${theme.breakpoints.medium}px) {
    margin-bottom: 40px;
  }
`;

export const Subtitle = styled(UtilityText)`
  margin-bottom: 32px;
`;

export const Options = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Checkboxes = styled(CheckboxGroup)`
  label:first-child {
    border-bottom: 1px solid ${theme.colors.gray200};
    padding-bottom: 20px;
    margin-bottom: 8px;
  }

  label:not(:first-child) {
    padding-left: 0px;
  }
`;
