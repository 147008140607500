import React from 'react';
import { BodyText, Col } from '@policygenius/mortar';
import { FOOTER_LINKS } from './constants';
import {
  Background,
  FooterCol,
  FooterRow,
  FooterContainer,
  LegalContainer,
  Security,
  MoreInfoLinks,
  LogoCol,
} from './styles';
import { getYear } from 'date-fns';
import PgLogo from 'components/PgIcons/PgLogo';

const Footer = () => {
  const currentYear = getYear(new Date());

  return (
    <Background>
      <FooterContainer>
        <LogoCol>
          <PgLogo />
        </LogoCol>
        <Col>
          <Security size="medium">Security you can trust</Security>
          <FooterRow>
            <FooterCol>
              {FOOTER_LINKS.map((link) => {
                return (
                  <MoreInfoLinks size="medium" href={link.href} key={link.href}>
                    {link.text}
                  </MoreInfoLinks>
                );
              })}
            </FooterCol>
          </FooterRow>
          <LegalContainer>
            <BodyText size="medium">
              Yes, we have to include some legalese down here. Policygenius Inc. (DBA Policygenius
              Insurance Services in California) (“Policygenius”), a Delaware corporation with its
              principal place of business in New York, New York, is a licensed independent insurance
              broker. The information provided on this site has been developed by Policygenius for
              general informational and educational purposes. We do our best to ensure that this
              information is up-to-date and accurate. Any insurance policy premium quotes or ranges
              displayed are non-binding. The final insurance policy premium for any policy is
              determined by the underwriting insurance company following application.
              <br />
              <br />
              If you are using a screen reader and are having problems using this website, please
              call 1-855-695-2255 for assistance.
              <br />
              <br />
              Copyright Policygenius © 2014-{currentYear}
            </BodyText>
          </LegalContainer>
        </Col>
      </FooterContainer>
    </Background>
  );
};

export default Footer;
