import React from 'react';
import { UtilityText } from '@policygenius/mortar';
import { AttendingPhysicianStatement } from 'graph/types';
import { APSOrderIcon, APSReleaseIcon } from './APSStatusIcons';
import { RecordType, Row } from './styles';

interface MedicalRecordProps {
  status: AttendingPhysicianStatement;
}

export const APSStatusDetails: React.FC<MedicalRecordProps> = ({ status }) => {
  return (
    <>
      <Row>
        <APSOrderIcon status={status} />
        <RecordType size="small">Order Records</RecordType>
        <UtilityText size="small">Carrier</UtilityText>
      </Row>
      <Row>
        <APSReleaseIcon status={status} />
        <RecordType size="small">Release records</RecordType>
        <UtilityText size="small">Physician</UtilityText>
      </Row>
      <div>
        {status === AttendingPhysicianStatement.AwaitingReq && (
          <UtilityText size="small">
            <i>
              Your client’s physician requires a special signed authorization before their medical
              records can be released. We’ve informed your client of this requirement and are
              awaiting their signature.
            </i>
          </UtilityText>
        )}
      </div>
    </>
  );
};
