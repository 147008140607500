import React from 'react';
import { LoadingIndicator, Textbox, UtilityText } from '@policygenius/mortar';
import {
  Container,
  Background,
  Row,
  LabelContainer,
  FormContainer,
  Spacer,
  Title,
  Continue,
} from './styles';
import { PgProLogoDark } from 'components/PgIcons/PgProLogo';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { useAuth0 } from '@auth0/auth0-react';
import { INDEX_ROUTE } from 'routes/constants';
import { useCreateAgentMutation } from 'mutation/createAgent/mutation';
import { useToastContext } from 'context/ToastContext';
import { FormValues } from './types';
import Toast from 'components/Toast';
import { nameCase } from 'utils/helpers';

export const SelfSignup: React.FC = () => {
  const { user } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();
  const { show } = useToastContext();

  const [createAgentMutation] = useCreateAgentMutation({
    onError: (e) => {
      console.error('ERROR saving new user', e.message);
      show({
        type: 'error',
        body: 'Thank you for your interest in the Policygenius Pro portal! There was an error processing your registration but not to worry, our team has been alerted and we will be in touch. Please reach out to pgproaccounts@policygenius.com with any questions.',
        title: '',
      });
    },
    onCompleted: () => {
      navigate(INDEX_ROUTE);
    },
  });

  const onSubmit = async (values: FormValues) => {
    await createAgentMutation({
      variables: {
        firstName: nameCase(values.firstName),
        lastName: nameCase(values.lastName),
      },
    });
  };

  const validate = (values: FormValues) => {
    const errors: Partial<FormValues> = {};

    if (!values.firstName) {
      errors.firstName = 'Required';
    }
    if (!values.lastName) {
      errors.lastName = 'Required';
    }

    return errors;
  };

  return (
    <Container>
      <Background>
        <Row>
          <LabelContainer>
            <PgProLogoDark />
          </LabelContainer>
          <Title size="large">Join {location.state?.orgName} on Policygenius Pro</Title>
          <Form onSubmit={onSubmit} validate={validate} initialValues={{ email: user?.email }}>
            {(formProps) => (
              <form onSubmit={formProps.handleSubmit}>
                <FormContainer>
                  <Field name="firstName" style={{ marginBottom: '16px' }}>
                    {({ input, meta }) => (
                      <Spacer>
                        <Textbox
                          {...input}
                          id="first-name"
                          name="first-name"
                          label="First name"
                          placeholder="Enter"
                          value={input.value}
                          defaultValue=""
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            input.onChange(e.target.value);
                          }}
                          type="text"
                          errorMessage={meta.touched && !meta.active ? meta.error : undefined}
                        />
                      </Spacer>
                    )}
                  </Field>
                  <Field name="lastName">
                    {({ input, meta }) => (
                      <Spacer>
                        <Textbox
                          {...input}
                          id="last-name"
                          name="last-name"
                          label="Last name"
                          placeholder="Enter"
                          value={input.value}
                          defaultValue=""
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            input.onChange(e.target.value);
                          }}
                          type="text"
                          errorMessage={meta.touched && !meta.active ? meta.error : undefined}
                        />
                      </Spacer>
                    )}
                  </Field>
                  <Field name="email">
                    {({ input }) => (
                      <Spacer large>
                        <Textbox
                          {...input}
                          id="email"
                          name="email"
                          label="Email"
                          placeholder="Enter"
                          value={input.value}
                          defaultValue=""
                          disabled
                          type="email"
                        />
                      </Spacer>
                    )}
                  </Field>
                  <Continue
                    fullWidth={false}
                    type="submit"
                    disabled={formProps.submitting || formProps.pristine || !formProps.valid}
                  >
                    {!formProps.submitting && 'Continue'}
                    {formProps.submitting && <LoadingIndicator color="gray400" />}
                  </Continue>
                  <UtilityText size="small">
                    Need help? Contact us at pgproaccounts@policygenius.com
                  </UtilityText>
                </FormContainer>
              </form>
            )}
          </Form>
        </Row>
      </Background>
      <Toast />
    </Container>
  );
};
