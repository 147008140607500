/* THIS FILE WAS AUTO-GENERATED. DO NOT MODIFY */
/* eslint-disable */
import * as Types from '../../graph/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CompleteEappMutationVariables = Types.Exact<{
  internalCaseReferenceID: Types.Scalars['String']['input'];
}>;


export type CompleteEappMutation = { __typename?: 'Mutation', completeEapp: boolean };


export const CompleteEappDocument = gql`
    mutation CompleteEapp($internalCaseReferenceID: String!) {
  completeEapp(internalCaseReferenceID: $internalCaseReferenceID)
}
    `;
export type CompleteEappMutationFn = Apollo.MutationFunction<CompleteEappMutation, CompleteEappMutationVariables>;

/**
 * __useCompleteEappMutation__
 *
 * To run a mutation, you first call `useCompleteEappMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteEappMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeEappMutation, { data, loading, error }] = useCompleteEappMutation({
 *   variables: {
 *      internalCaseReferenceID: // value for 'internalCaseReferenceID'
 *   },
 * });
 */
export function useCompleteEappMutation(baseOptions?: Apollo.MutationHookOptions<CompleteEappMutation, CompleteEappMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteEappMutation, CompleteEappMutationVariables>(CompleteEappDocument, options);
      }
export type CompleteEappMutationHookResult = ReturnType<typeof useCompleteEappMutation>;
export type CompleteEappMutationResult = Apollo.MutationResult<CompleteEappMutation>;
export type CompleteEappMutationOptions = Apollo.BaseMutationOptions<CompleteEappMutation, CompleteEappMutationVariables>;