import { ApplicationStage } from 'graph/types';

export interface Props {
  number: number | null;
  title: string;
  full?: boolean;
}

export type StageMappings = {
  [key in ApplicationStage]: number | null;
};

export const STAGE_MAPPING: StageMappings = {
  REFERRAL: 0,
  QUOTES: 1,
  NO_STAGE: null,
  APPLICATION: 2,
  UNDERWRITING: 3,
  DECISION: 4,
  ISSUANCE: 5,
  PLACED: 6,
  PLACEMENT: 6,
};
