import React from 'react';
import { UtilityText } from '@policygenius/mortar';
import { AttendingPhysicianStatement } from 'graph/types';
import { APSStatusDetails } from './APSStatusDetails';

interface MedicalRecords {
  status: AttendingPhysicianStatement;
}
export const MedicalRecords: React.FC<MedicalRecords> = ({ status }) => {
  if (status === AttendingPhysicianStatement.Canceled) {
    return (
      <UtilityText size="small">
        Records either cannot be found with current physician or are no longer required by the
        carrier.
      </UtilityText>
    );
  }
  if (status === AttendingPhysicianStatement.StatusUnknown) {
    return <>N/A</>;
  }

  return <APSStatusDetails status={status} />;
};
