import { isPast, isAfter, isBefore, isEqual, startOfDay, subYears } from 'date-fns';
import isNil from 'lodash/isNil';
import trim from 'lodash/trim';
import {
  AGE_MAX,
  AGE_MIN,
  DATE_BETWEEN_ERROR,
  DATE_IN_PAST_ERROR,
  DOB_RANGE_ERROR,
  EMAIL_ERROR,
  IS_PRESENT_ERROR,
  PHONE_ERROR,
} from './constants';

const EMAIL_REGEX = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i;

export const isPhone = (value: string | null | undefined): undefined | string => {
  if (!value) {
    return PHONE_ERROR;
  }

  const trimmedValue = trim(value);
  const hasTenDigits = /^\d{10}$/.test(trimmedValue);

  if (!hasTenDigits) {
    return PHONE_ERROR;
  }

  const firstNumber = parseInt(trimmedValue[0], 10);
  const validStartDigit = firstNumber > 1;

  if (!validStartDigit) {
    return PHONE_ERROR;
  }

  const hasMiddleFives = trimmedValue.substr(3, 3) === '555';
  let hasReservedNumbers = false;

  if (hasMiddleFives) {
    const endingDigits = parseInt(trimmedValue.substr(6, 4), 10);

    if (endingDigits > 99 && endingDigits < 200) {
      hasReservedNumbers = true;
    }
  }

  return !hasReservedNumbers ? undefined : PHONE_ERROR;
};
export const isEmail = (value: string | null | undefined): undefined | string =>
  !!value && EMAIL_REGEX.test(trim(value)) ? undefined : EMAIL_ERROR;

export const isDateInPast = (value: Date): undefined | string =>
  isNil(value) || isPast(value) ? undefined : DATE_IN_PAST_ERROR;

export const isDateBetween =
  (startDate: Date, endDate: Date) =>
  (value: Date): undefined | string =>
    isNil(value) || (isAfter(value, startDate) && isBefore(value, endDate))
      ? undefined
      : DATE_BETWEEN_ERROR;

export const isDOBRangeValid = (value: string): undefined | string => {
  const startDate = subYears(startOfDay(Date.now()), AGE_MAX + 1);
  const endDate = subYears(startOfDay(Date.now()), AGE_MIN);
  const date = new Date(value);

  return isNil(value) ||
    (isAfter(date, startDate) && (isBefore(date, endDate) || isEqual(date, endDate)))
    ? undefined
    : DOB_RANGE_ERROR;
};

export const isPresent = (value: unknown): undefined | string =>
  !(isNil(value) || value === '') ? undefined : IS_PRESENT_ERROR;

export const isBetween =
  (min: number, max: number, errorOverride?: string | null) =>
  (value: number): string | undefined =>
    isNil(value) || (value >= min && value <= max)
      ? undefined
      : errorOverride || `Must be between ${min} and ${max}`;

export const isGreater =
  (min: number) =>
  (value: number): string | undefined =>
    value > min ? undefined : `Must be greater than ${min}`;

export const minLength = (length: number) => (value: string | null | undefined) => {
  if (!value) {
    return undefined;
  }

  return value.length >= length ? undefined : `Must be ${length} or more characters`;
};

export const composeValidators =
  (...validators: Array<(value: any) => undefined | string>) =>
  (value: any) =>
    validators.reduce<string | undefined>(
      (error, validator) => error || validator(value),
      undefined
    );
