import React from 'react';
import {
  DropTicketErrorMessage,
  DropTicketErrorMessageNY,
  EAppSubmissionErrorMessage,
} from 'components/Toast/BodyMessages';
import { BodyText } from '@policygenius/mortar';

const TCPABody: React.FC = () => (
  <div>
    <BodyText style={{ marginBottom: '12px' }}>
      <strong>IMPORTANT!</strong> As a next step, your client must provide permission for
      Policygenius to contact them as laid out by the Telephone Consumer Protection Act (TCPA).
      Within the next 3-5 minutes your client will receive an email from
      partnersuccess@mail.policygeniuspro.com, Subject Line: Confirm your contact information
    </BodyText>
    <BodyText>
      <strong>Step 1:</strong> Client must click on the orange button titled &quot;Confirm your
      information&quot;
    </BodyText>
    <BodyText>
      <strong>Step 2:</strong> Client must log into their dashboard with the sign-in code received
      via email
    </BodyText>
    <BodyText style={{ marginBottom: '12px' }}>
      <strong>Step 3:</strong> Client must confirm their contact information on the
      &quot;Account&quot; page by clicking &quot;Save&quot;.
    </BodyText>
    <BodyText>
      If preferred, clients may also call us directly at 855-695-2255. If a client calls us, this
      supersedes the need to consent over email. Please feel free to live chat with questions or
      email us at pgproaccounts@policygenius.com.
    </BodyText>
  </div>
);

export const TOAST_TEXT = {
  success: {
    title: "You're all set!",
    body: <TCPABody />,
  },
  error: {
    title: 'Submission failed',
    body: <DropTicketErrorMessage />,
  },
  errorEAppSectionSubmission: {
    title: (section?: string) => `${section ?? 'Section'} failed to save`,
    body: <EAppSubmissionErrorMessage />,
  },
  errorEAppSubmission: {
    title: 'eApp submission failed',
    body: <EAppSubmissionErrorMessage />,
  },
  errorNYCarrier: {
    title: 'Unable to refer business in New York',
    body: <DropTicketErrorMessageNY />,
  },
};
