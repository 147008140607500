import React from 'react';

interface Props {
  className?: string;
}

export const Cake: React.FunctionComponent<Props> = ({ className }) => (
  <div className={className}>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5778 9.16667H14.9111C16.6611 9.16667 18.0778 10.5 18.2445 12.1667L17.6611 12.75C16.7028 13.7083 15.7861 14.1667 14.9111 14.1667C14.4945 14.1667 14.2445 14.0417 13.7028 13.6667C13.6611 13.625 13.5778 13.5833 13.4528 13.5C12.5361 12.7917 11.8278 12.5 10.7445 12.5C9.66113 12.5 8.99447 12.7917 7.91113 13.4583C7.78613 13.5417 7.7028 13.5833 7.66113 13.625C6.99447 14.0417 6.61947 14.1667 6.16113 14.1667C5.61947 14.1667 5.0778 13.9167 4.5778 13.5417C4.28613 13.2917 4.03613 13.0417 3.9528 12.875C3.74447 12.625 3.66113 12.5417 3.28613 12.0417C3.49447 10.4167 4.91113 9.16667 6.5778 9.16667H9.91113V7.5H11.5778V9.16667Z"
        fill="#ECA389"
      />
      <path
        d="M10.7443 5.83341C11.661 5.83341 12.411 5.08341 12.411 4.16675C12.411 3.54175 11.8693 2.70841 10.7443 1.66675C9.6193 2.70841 9.07764 3.54175 9.07764 4.16675C9.07764 5.08341 9.82764 5.83341 10.7443 5.83341Z"
        fill="#A4350F"
      />
      <path
        d="M18.2444 14.4584V17.5001C18.2444 17.9584 17.8694 18.3334 17.4111 18.3334H4.07772C3.61938 18.3334 3.24438 17.9584 3.24438 17.5001V14.5834C3.32772 14.6667 3.41105 14.7501 3.49438 14.7917C4.28605 15.4167 5.16105 15.8334 6.16105 15.8334C7.03605 15.8334 7.61938 15.5834 8.53605 15.0417C8.57772 15.0001 8.66105 14.9584 8.78605 14.8751C9.61938 14.3334 10.0777 14.1667 10.7444 14.1667C11.4111 14.1667 11.7861 14.3334 12.4944 14.8334C12.6194 14.9167 12.7027 14.9584 12.7444 15.0001C13.5361 15.5417 14.0777 15.8334 14.9111 15.8334C16.0361 15.8334 17.1611 15.3751 18.2444 14.4584Z"
        fill="#A4350F"
      />
    </svg>
  </div>
);
