export const carrierLinks = {
  Banner: 'https://www.policygenius.com/life-insurance/reviews/banner-life/',
  Brighthouse: 'https://www.policygenius.com/life-insurance/reviews/brighthouse/',
  Lincoln: 'https://www.policygenius.com/life-insurance/reviews/lincoln-financial/',
  'Mutual of Omaha': 'https://www.policygenius.com/life-insurance/reviews/mutual-of-omaha/',
  'Pacific Life': 'https://www.policygenius.com/life-insurance/reviews/pacific-life/',
  Principal: 'https://www.policygenius.com/life-insurance/reviews/principal-financial/',
  Protective: 'https://www.policygenius.com/life-insurance/reviews/protective/',
  Prudential: 'https://www.policygenius.com/life-insurance/reviews/prudential/',
  SBLI: 'https://www.policygenius.com/life-insurance/reviews/sbli/',
  Symetra: 'https://www.policygenius.com/life-insurance/reviews/symetra/',
  Transamerica: 'https://www.policygenius.com/life-insurance/reviews/transamerica/',
  'Corebridge Financial':
    'https://www.policygenius.com/life-insurance/reviews/corebridge-financial',
};
