import React from 'react';
import { LabelText } from '@policygenius/mortar';
import {
  ApprovalDetails,
  ApprovalDetailsName,
  CakeIcon,
  Container,
  Description,
  FlagIcon,
  ShieldIcon,
} from './styles';
import { PolicyInfo } from './PolicyInfo';
import { PolicyTabProps } from './types';
import { HEALTH_CLASS } from './constants';
import { UnderwritingClassDecision } from 'graph/types';
import DetailsContainer from 'components/DetailsContainer';
import CollapsibleDetailsContainer from 'components/CollapsibleDetailsContainer';
import { Item } from 'components/CollapsibleDetailsContainer/types';

export const PolicyTab: React.FunctionComponent<PolicyTabProps> = ({
  carrier,
  coverageAmount,
  healthClass,
  isAgeChange,
  isFinancialModification,
  issueType,
  policyName,
  policyNumber,
  premium,
  premiumMode,
  product,
  term,
  tableRating,
  underwritingClassDecision,
}) => {
  const generatePolicyNameAndNumber = (name: string, numberString?: string | null): string => {
    if (numberString) {
      return `${name} \xa0|\xa0 #${numberString}`;
    }

    return name;
  };

  const approvalDetails: Item[] = [];

  if (
    underwritingClassDecision &&
    underwritingClassDecision !== UnderwritingClassDecision.UnderwritingClassDecisionUnspecified
  ) {
    approvalDetails.push({
      name: (
        <ApprovalDetailsName>
          <ShieldIcon />
          <LabelText size="medium">{HEALTH_CLASS[underwritingClassDecision].name}</LabelText>
        </ApprovalDetailsName>
      ),
      body: <Description size="small">{HEALTH_CLASS[underwritingClassDecision].body}</Description>,
      link: HEALTH_CLASS[underwritingClassDecision].link,
    });
  }

  if (isAgeChange) {
    approvalDetails.push({
      name: (
        <ApprovalDetailsName>
          <CakeIcon />
          <LabelText size="medium">Age change</LabelText>
        </ApprovalDetailsName>
      ),
      body: (
        <Description size="small">
          Your client had an age change during the application process (usually occurs on
          half-birthdays). Your client will have the option to back-date the policy for lower
          premiums if preferable.
        </Description>
      ),
      link: 'https://www.policygenius.com/life-insurance/why-life-insurance-companies-care-about-your-half-birthday/',
    });
  }

  if (isFinancialModification) {
    approvalDetails.push({
      name: (
        <ApprovalDetailsName>
          <FlagIcon />
          <LabelText size="medium">Financial modification</LabelText>
        </ApprovalDetailsName>
      ),
      body: (
        <Description size="small">
          Based on the insurance carrier’s financial underwriting guidelines this policy was offered
          with a modified coverage amount.
        </Description>
      ),
      link: 'https://www.policygenius.com/life-insurance/evidence-of-insurability/',
    });
  }

  return (
    <Container>
      {approvalDetails.length !== 0 && (
        <ApprovalDetails>
          <CollapsibleDetailsContainer
            labelText="Approval details"
            items={approvalDetails}
            startCollapsed
          />
        </ApprovalDetails>
      )}
      <DetailsContainer
        labelText="Policy Details"
        header={carrier}
        subheader={policyName ? generatePolicyNameAndNumber(policyName!, policyNumber) : null}
        showDividerLine
        body={
          <PolicyInfo
            coverageAmount={coverageAmount}
            healthClass={healthClass}
            issueType={issueType}
            premium={premium}
            premiumMode={premiumMode}
            product={product}
            term={term}
            tableRating={tableRating}
          />
        }
      />
    </Container>
  );
};
