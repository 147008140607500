import React from 'react';
import { Row, Col, Selectbox, ToggleGroup } from '@policygenius/mortar';
import { HealthClassContainer, MarginBottomDiv, StyledButton, Title } from './styles';
import { useField } from 'react-final-form';
import DateField from 'components/DateField';
import { Props } from './types';
import { Gender, Role } from 'graph/types';
import MaskedTextField from 'components/MaskedTextField';
import { numberMask } from 'components/MaskedTextField/constants';
import { STATES, HEALTH_CLASSES, TERM_LENGTHS, PAYMENT_MODES } from './constants';
import { useFormContext } from 'context/FormContext';
import { fromString, toString } from 'utils/helpers';
import { useCurrentUserQuery } from 'query/currentUser/query';
import AdvisorSelect from 'components/AdvisorSelect';
import { useAvailableProducersQuery } from 'query/producers/query';

const ClientInfoForm: React.FC<Props> = ({ handleSubmit, invalid }) => {
  const gender = useField('gender');
  const dateOfBirth = useField('dateOfBirth');
  const stateUS = useField('state');
  const tobacco = useField('tobacco');
  const healthClass = useField('healthClass');
  const coverageAmount = useField('coverageAmount');
  const term = useField('term');
  const paymentMode = useField('paymentMode');
  const referringAgent = useField('referringAgent');

  const { state } = useFormContext();
  const { data: userData, loading: userLoading, error } = useCurrentUserQuery();

  if (error) {
    console.error(error);
  }

  const {
    data: advisorData,
    loading: loadingAdvisor,
    error: errorAdvisory,
  } = useAvailableProducersQuery();

  if (errorAdvisory) {
    console.error(errorAdvisory);
  }

  const assistant = userData?.currentUser.roles.includes(Role.Assistant);

  return (
    <form onSubmit={handleSubmit}>
      {!userLoading && assistant && !loadingAdvisor && (
        <AdvisorSelect
          referringAgent={referringAgent}
          listOfAgents={advisorData?.availableProducers || []}
        />
      )}
      <Title
        size={{
          small: 'medium',
        }}
      >
        Client information
      </Title>
      <MarginBottomDiv>
        <ToggleGroup
          {...gender.input}
          groupLabel="Gender"
          defaultValue={toString(gender.meta.initial)}
          errorMessage={gender.meta.touched && !gender.meta.active ? gender.meta.error : undefined}
          name="gender_selection"
          options={[
            { label: 'Male', value: Gender.Male },
            { label: 'Female', value: Gender.Female },
          ]}
          showGroupLabel
        />
      </MarginBottomDiv>
      <Row nested>
        <Col
          span={{
            medium: 8,
            small: 4,
          }}
        >
          <MarginBottomDiv>
            <DateField label="Date of birth" {...dateOfBirth} initialDate={state.dateOfBirth} />
          </MarginBottomDiv>
        </Col>
        <Col
          span={{
            medium: 4,
            small: 4,
          }}
        >
          <MarginBottomDiv>
            <Selectbox
              id="state"
              name="select-box"
              label="State"
              options={STATES}
              defaultValue={stateUS.input.value}
              errorMessage={
                stateUS.meta.touched && !stateUS.meta.active ? stateUS.meta.error : undefined
              }
              onChange={stateUS.input.onChange} // eslint-disable-line react/jsx-handler-names
              onBlur={stateUS.input.onBlur} // eslint-disable-line react/jsx-handler-names
            />
          </MarginBottomDiv>
        </Col>
      </Row>
      <MarginBottomDiv>
        <ToggleGroup
          {...tobacco.input}
          groupLabel="Tobacco use?"
          defaultValue={toString(tobacco.meta.initial)}
          errorMessage={
            tobacco.meta.touched && !tobacco.meta.active ? tobacco.meta.error : undefined
          }
          name="tobacco_selection"
          onChange={(event) => {
            const value = fromString(event.target.value);

            return tobacco.input.onChange(value);
          }}
          value={tobacco.input.value.toString()}
          options={[
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ]}
          showGroupLabel
        />
      </MarginBottomDiv>
      <HealthClassContainer>
        <Selectbox
          id="health-class"
          name="select-box"
          label="Health class"
          options={HEALTH_CLASSES}
          defaultValue={healthClass.input.value}
          errorMessage={
            healthClass.meta.touched && !healthClass.meta.active
              ? healthClass.meta.error
              : undefined
          }
          onChange={healthClass.input.onChange} // eslint-disable-line react/jsx-handler-names
          onBlur={healthClass.input.onBlur} // eslint-disable-line react/jsx-handler-names
        />
      </HealthClassContainer>
      <Title
        size={{
          small: 'medium',
        }}
      >
        Coverage details
      </Title>
      <MarginBottomDiv>
        <MaskedTextField
          input={coverageAmount.input}
          meta={coverageAmount.meta}
          id="coverage-amount"
          label="Coverage amount"
          showLabel
          placeholder="0"
          maskOptions={numberMask}
          defaultValue={coverageAmount.input.value}
          prefix="$"
          type="text"
        />
      </MarginBottomDiv>
      <Row nested>
        <Col
          span={{
            medium: 6,
            small: 4,
          }}
        >
          <MarginBottomDiv>
            <Selectbox
              id="term"
              name="select-box"
              label="Term length"
              options={TERM_LENGTHS}
              defaultValue={term.input.value}
              errorMessage={term.meta.touched && !term.meta.active ? term.meta.error : undefined}
              onChange={term.input.onChange} // eslint-disable-line react/jsx-handler-names
              onBlur={term.input.onBlur} // eslint-disable-line react/jsx-handler-names
            />
          </MarginBottomDiv>
        </Col>
        <Col
          span={{
            medium: 6,
            small: 4,
          }}
        >
          <MarginBottomDiv>
            <Selectbox
              id="payment-mode"
              name="select-box"
              label="Payment mode"
              options={PAYMENT_MODES}
              defaultValue={paymentMode.input.value}
              errorMessage={
                paymentMode.meta.touched && !paymentMode.meta.active ? term.meta.error : undefined
              }
              onChange={paymentMode.input.onChange} // eslint-disable-line react/jsx-handler-names
              onBlur={paymentMode.input.onBlur} // eslint-disable-line react/jsx-handler-names
            />
          </MarginBottomDiv>
        </Col>
      </Row>
      <StyledButton
        analyticsText="Continue"
        size="large"
        onClick={() => null}
        disabled={invalid}
        variant="black"
      >
        Continue
      </StyledButton>
    </form>
  );
};

export default ClientInfoForm;
