/* THIS FILE WAS AUTO-GENERATED. DO NOT MODIFY */
/* eslint-disable */
import * as Types from '../../graph/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserPreferencesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserPreferencesQuery = { __typename?: 'Query', userPreferences: { __typename?: 'Preference', notificationPreferences: Array<{ __typename?: 'NotificationPreference', name: string, description?: string | null, notificationURN: string, subscribed: boolean, level: Types.NotificationLevel }> } };


export const UserPreferencesDocument = gql`
    query UserPreferences {
  userPreferences {
    notificationPreferences {
      name
      description
      notificationURN
      subscribed
      level
    }
  }
}
    `;

/**
 * __useUserPreferencesQuery__
 *
 * To run a query within a React component, call `useUserPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPreferencesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserPreferencesQuery(baseOptions?: Apollo.QueryHookOptions<UserPreferencesQuery, UserPreferencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserPreferencesQuery, UserPreferencesQueryVariables>(UserPreferencesDocument, options);
      }
export function useUserPreferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserPreferencesQuery, UserPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserPreferencesQuery, UserPreferencesQueryVariables>(UserPreferencesDocument, options);
        }
export function useUserPreferencesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserPreferencesQuery, UserPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserPreferencesQuery, UserPreferencesQueryVariables>(UserPreferencesDocument, options);
        }
export type UserPreferencesQueryHookResult = ReturnType<typeof useUserPreferencesQuery>;
export type UserPreferencesLazyQueryHookResult = ReturnType<typeof useUserPreferencesLazyQuery>;
export type UserPreferencesSuspenseQueryHookResult = ReturnType<typeof useUserPreferencesSuspenseQuery>;
export type UserPreferencesQueryResult = Apollo.QueryResult<UserPreferencesQuery, UserPreferencesQueryVariables>;