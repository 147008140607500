import React, { useEffect, useState, ChangeEvent } from 'react';
import { Button, Selectbox, Tooltip, UtilityText } from '@policygenius/mortar';
import {
  AdvisorContainer,
  SelectedAdvisorLabel,
  SelectedAdvisorName,
  SelectedAdvisorRow,
} from './styles';
import { Props } from './types';
import { useNavigate } from 'react-router-dom';
import { CLIENT_INFO_ROUTE } from 'routes/constants';
import { useFormContext } from 'context/FormContext';
import { Actions } from 'context/FormContext/types';
import { Producer } from 'graph/types';

const AdvisorSelect: React.FC<Props> = ({ referringAgent, listOfAgents }) => {
  const navigate = useNavigate();
  const { state, dispatch } = useFormContext();
  const [hideDropdown, setHideDropdown] = useState(true);

  useEffect(() => {
    setHideDropdown(referringAgent?.input?.value !== '');
  }, []);

  const compareNames = (
    a: {
      firstName: string;
      lastName: string;
    } | null,
    b: {
      firstName: string;
      lastName: string;
    } | null
  ) => {
    if (a && b) {
      // This prevents casing from affecting order (ex. Dirito > DiRito evaluates to true)
      const aLastName = a.lastName.toLowerCase();
      const bLastName = b.lastName.toLowerCase();
      const aFirstName = a.firstName.toLowerCase();
      const bFirstName = b.firstName.toLowerCase();

      if (aLastName < bLastName) {
        return -1;
      }
      if (aLastName > bLastName) {
        return 1;
      }
      if (aFirstName < bFirstName) {
        return -1;
      }
      if (aFirstName > bFirstName) {
        return 1;
      }

      return 0;
    }

    return 0;
  };

  let alphaListOfAgents = listOfAgents;

  if (listOfAgents.length > 1) {
    alphaListOfAgents = [...listOfAgents].sort(compareNames);
  }

  const advisors = alphaListOfAgents?.map((advisor) => {
    return {
      value: advisor?.uuid || '',
      label: `${advisor?.firstName} ${advisor?.lastName}`,
    };
  });

  const selectedAdvisor = advisors?.find((obj) => obj.value === referringAgent.input.value.uuid);

  const changeAdvisor = () => {
    dispatch({
      type: Actions.CHANGE_ADVISOR,
      payload: {
        ...state,
      },
    });
    navigate(CLIENT_INFO_ROUTE);
    setHideDropdown(false);
  };

  const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const uuid = e.target.value;

    const advisor = alphaListOfAgents?.find((obj) => obj?.uuid === uuid) as Producer;

    dispatch({
      type: Actions.CHANGE_ADVISOR,
      payload: {
        ...state,
        referringAgent: {
          uuid,
          firstName: advisor.firstName,
          lastName: advisor.lastName,
        },
      },
    });
  };

  return (
    <AdvisorContainer>
      {hideDropdown ? (
        <SelectedAdvisorRow>
          <SelectedAdvisorName>
            <UtilityText size="large" weight="bold">
              Referring advisor:
            </UtilityText>
            <SelectedAdvisorLabel size="large">{selectedAdvisor?.label}</SelectedAdvisorLabel>
          </SelectedAdvisorName>

          <div>
            <Button size="large" variant="quaternary" onClick={changeAdvisor} type="button">
              Change advisor
            </Button>
          </div>
        </SelectedAdvisorRow>
      ) : (
        <Selectbox
          id="advisor-select"
          name="advisor-select"
          label="Advisor name"
          defaultValue={referringAgent.input.value}
          tooltip={<Tooltip left>This is the advisor you are referring on behalf of.</Tooltip>}
          options={advisors}
          onChange={onChange}
        />
      )}
    </AdvisorContainer>
  );
};

export default AdvisorSelect;
