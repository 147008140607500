import React, { useEffect } from 'react';
import { BodyText } from '@policygenius/mortar';
import { CalculatorContainer, Background, Title } from './styles';

const PriceCalculator = () => {
  useEffect(() => {
    if (typeof location === 'undefined') {
      return;
    }

    const script = document.createElement('script');

    if (!process.env.REACT_APP_PRICE_CALCULATOR_URL) {
      console.error('REACT_APP_PRICE_CALCULATOR_URL is undefined');
    } else script.src = process.env.REACT_APP_PRICE_CALCULATOR_URL;

    script.async = true;
    script.type = 'text/javascript';
    document.body.appendChild(script);
  }, []);

  return (
    <Background>
      <BodyText size="xxlarge">Want an estimate?</BodyText>
      <Title size="xxlarge">Life insurance calculator</Title>
      <CalculatorContainer id="policygenius-price-calculator" />
    </Background>
  );
};

export default PriceCalculator;
