import React from 'react';
import { Col, LoadingIndicator } from '@policygenius/mortar';
import {
  Background,
  Checkboxes,
  Grid,
  Options,
  OptionsContainer,
  PageTitle,
  SectionTitle,
  Subheader,
  Subtitle,
} from './styles';
import { UserPreferencesDocument, useUserPreferencesQuery } from 'query/userPreferences/query';
import { useUpdateNotificationPreferenceMutation } from 'mutation/updateNotificationPreference/mutation';
import { useCurrentUserQuery } from 'query/currentUser/query';
import { SubscriptionType, NotificationPreference, Role, NotificationLevel } from 'graph/types';
import { Events, track } from 'utils/analytics';
import { sortOrder } from './constants';

const Settings: React.FunctionComponent = () => {
  const { data, loading } = useUserPreferencesQuery();
  const { data: currentUser } = useCurrentUserQuery();
  const [updateUserPreferenceMutation] = useUpdateNotificationPreferenceMutation({
    refetchQueries: [{ query: UserPreferencesDocument }],
  });

  const handleAllPreferencesToggle = async (
    isChecked: boolean,
    analyticsText: string,
    roleType: NotificationLevel
  ) => {
    track(Events.CHANGED_CHECKBOX, { analyticsText });

    const updateType = isChecked ? SubscriptionType.Subscribe : SubscriptionType.Unsubscribe;

    const notificationPrefs = data?.userPreferences.notificationPreferences
      .filter((pref) => pref.subscribed !== isChecked && pref.level === roleType)
      .map((pref) => ({
        notificationURN: pref.notificationURN,
        type: updateType,
        role: pref.level === NotificationLevel.Personal ? Role.Producer : Role.Admin,
      }));

    await updateUserPreferenceMutation({
      variables: {
        subscriptionPreferences: notificationPrefs!,
      },
    });
  };

  const handlePreferenceToggle = async (preference: NotificationPreference) => {
    track(Events.CHANGED_CHECKBOX, { analyticsText: preference.name });

    await updateUserPreferenceMutation({
      variables: {
        subscriptionPreferences: {
          notificationURN: preference.notificationURN,
          type: preference.subscribed ? SubscriptionType.Unsubscribe : SubscriptionType.Subscribe,
          role: preference.level === NotificationLevel.Personal ? Role.Producer : Role.Admin,
        },
      },
    });
  };

  const sortedPreferences = data?.userPreferences.notificationPreferences
    ?.slice()
    .sort(
      (a: NotificationPreference, b: NotificationPreference) =>
        sortOrder[a.notificationURN] - sortOrder[b.notificationURN]
    );

  const sortedOrgPreferences = data?.userPreferences.notificationPreferences
    .filter((preference) => preference.level === NotificationLevel.Organization)
    ?.slice()
    .sort(
      (a: NotificationPreference, b: NotificationPreference) =>
        sortOrder[a.notificationURN.replace(/_organization$/, '')] -
        sortOrder[b.notificationURN.replace(/_organization$/, '')]
    );

  return (
    <Background>
      <Grid>
        <Col
          span={{
            large: 12,
            medium: 10,
            small: 4,
          }}
          push={{
            large: 1,
            medium: 1,
          }}
        >
          <PageTitle size="xlarge">Settings</PageTitle>
          <Subheader size="medium">Case update emails</Subheader>
          <Subtitle size="medium">Stay in the loop about clients progress</Subtitle>
        </Col>
        {currentUser?.currentUser.roles.includes(Role.Producer) && (
          <OptionsContainer
            span={{
              large: 4,
              medium: 10,
              small: 4,
            }}
            push={{
              large: 1,
              medium: 1,
            }}
          >
            <SectionTitle size="medium" weight="bold">
              Personal referrals
            </SectionTitle>
            {loading ? (
              <LoadingIndicator color="gray500" />
            ) : (
              <Options>
                <Checkboxes
                  groupLabel="notificationOptions"
                  name="notificationOptions"
                  options={[
                    {
                      label: 'All case update emails',
                      value: 'checked',
                      onChange: (e) =>
                        handleAllPreferencesToggle(
                          e.target.checked,
                          'All case update emails',
                          NotificationLevel.Personal
                        ),
                      subList: sortedPreferences
                        ?.filter((preference) => preference.level === NotificationLevel.Personal)
                        .map((preference) => ({
                          label: preference.name,
                          value: preference.notificationURN,
                          defaultChecked: preference.subscribed,
                          checked: preference.subscribed,
                          helpText: preference.description || undefined,
                          onChange: () => handlePreferenceToggle(preference),
                        })),
                    },
                  ]}
                />
              </Options>
            )}
          </OptionsContainer>
        )}
        <Col span={{ large: 1 }}>
          <></>
        </Col>
        {currentUser?.currentUser.roles.includes(Role.Admin) && (
          <OptionsContainer
            span={{
              large: 4,
              medium: 10,
              small: 4,
            }}
            push={{
              large: 0,
              medium: 1,
            }}
          >
            <SectionTitle size="medium" weight="bold">
              Organization referrals
            </SectionTitle>
            {loading ? (
              <LoadingIndicator color="gray500" />
            ) : (
              <Options>
                <Checkboxes
                  groupLabel="notificationOptions"
                  name="notificationOptions"
                  options={[
                    {
                      label: 'All case update emails',
                      value: 'checked',
                      onChange: (e) =>
                        handleAllPreferencesToggle(
                          e.target.checked,
                          'All case update emails',
                          NotificationLevel.Organization
                        ),
                      subList: sortedOrgPreferences
                        ?.filter(
                          (preference) => preference.level === NotificationLevel.Organization
                        )
                        .map((preference) => ({
                          label: preference.name,
                          value: preference.notificationURN,
                          defaultChecked: preference.subscribed,
                          checked: preference.subscribed,
                          helpText: preference.description || undefined,
                          onChange: () => handlePreferenceToggle(preference),
                        })),
                    },
                  ]}
                />
              </Options>
            )}
          </OptionsContainer>
        )}
      </Grid>
    </Background>
  );
};

export default Settings;
