import { BodyText, LabelText, Row, theme } from '@policygenius/mortar';
import styled from 'styled-components';

export const Background = styled.div`
  background-color: ${theme.colors.orange100};
`;

export const Grid = styled(Row)`
  padding-top: 48px;
  padding-bottom: 48px;
  margin: auto;
`;

export const Header = styled(LabelText)`
  margin-bottom: 16px;
`;

export const SectionOneText = styled(BodyText)`
  margin-bottom: 16px;
`;

export const DisclosuresLink = styled(BodyText)`
  text-decoration: underline;
`;
