import styled from 'styled-components';
import { theme } from '@policygenius/mortar';

export const Body = styled.div`
  padding: ${theme.spacing('medium')};
`;

export const CloseIcon = styled.span`
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px ${theme.spacing('small')};
  background-color: ${theme.colors.gray100};
  @media (min-width: ${theme.breakpoints.large}px) {
    border-radius: ${theme.spacing('small')} ${theme.spacing('small')} 0px 0px;
  }
`;

export const Loading = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
