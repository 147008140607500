import React, { useEffect } from 'react';
import { Background } from 'components/Background';
import {
  Title,
  Container,
  Subheader,
  SelectedPolicyContainer,
  SelectedPolicyTitle,
  SelectedPolicyBody,
} from './styles';
import { Form } from 'react-final-form';
import { EAppInternalCaseForm } from './form';
import { useFormContext } from 'context/FormContext';
import { LifeApplicationInfo } from 'context/FormContext/types';
import { EAPP_ROUTE } from 'routes/constants';
import { useUserLifeAppMutation } from 'mutation/user/mutation';
import {
  moneyToString,
  retrieveLifeApplicationInfo,
  setLifeApplicationInfo,
  userHasSelectedPolicy,
} from 'utils/helpers';
import { useNavigate } from 'react-router-dom';
import { useToastContext } from 'context/ToastContext';
import { Events, track } from 'utils/analytics';
import { TOAST_TEXT } from 'pages/DropTicket/constants';
import PolicyPlayback from 'components/PolicyPlayback';
import { getValidator } from 'pages/DropTicket/form/utils/validate';
import { PaginatedReportsDocument } from 'query/statusReportsPagination/query';
import { Col } from '@policygenius/mortar';
import { CreationMethod } from 'graph/types';

export const EAppInternalCase = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useFormContext();

  const { show } = useToastContext();

  const [userLifeAppMutation, { data }] = useUserLifeAppMutation({
    onError: (e) => {
      if (e.message.includes('Cannot submit business in NY')) {
        show({
          type: 'error',
          title: TOAST_TEXT.errorNYCarrier.title,
          body: TOAST_TEXT.errorNYCarrier.body,
        });
      } else {
        show({
          type: 'error',
          title: TOAST_TEXT.error.title,
          body: TOAST_TEXT.error.body,
        });
      }
      console.error(e);
    },
    onCompleted: () => {
      const refID = data?.userLifeApp.applicationResponse?.referenceId;
      const carrier = state?.policySelection?.carrier;

      navigate(`${EAPP_ROUTE}/${carrier}/1/${refID}`, {
        state: {
          referenceId: refID,
        },
      });
    },
    refetchQueries: [{ query: PaginatedReportsDocument }],
  });

  const onSubmit = async (values: LifeApplicationInfo) => {
    dispatch({ type: 'UPDATE_CLIENT', payload: values });
    track(Events.SUBMITTED_BUSINESS, {
      clientEmail: values.email,
      selectedPolicy: userHasSelectedPolicy(state),
    });
    await userLifeAppMutation({
      variables: {
        gender: state.gender!,
        dateOfBirth: state.dateOfBirth!,
        state: state.state!,
        tobaccoUse: state.tobacco,
        firstName: values.firstName!,
        lastName: values.lastName!,
        phone: values.phone!,
        email: values.email!,
        coverageAmount: values.coverageAmount ? Number(moneyToString(values.coverageAmount)) : null,
        termInYears: values.term ? Number(values.term) : null,
        notes: values.notes,
        paymentMode: state.paymentMode?.toLocaleLowerCase(),
        referringAgent: values.referringAgent?.uuid,
        creationMethod: CreationMethod.Eapp,
        ...(userHasSelectedPolicy(state) && {
          policyPreference: {
            annualPremium: state.policySelection.annualPremium!,
            carrier: state.policySelection.carrier!,
            carrierName: state.policySelection.carrierName!,
            healthCategory: state.policySelection.healthCategory!,
            isTobacco: state.policySelection.isTobacco!,
            issueAge: state.policySelection.issueAge!,
            issueType: state.policySelection.issueType!,
            monthlyPremium: state.policySelection.monthlyPremium!,
            name: state.policySelection.planName!,
            planIdentifierName: state.policySelection.planIdentifierName!,
            productType: state.policySelection.productType!,
            returnOfPremium: state.policySelection.returnOfPremium!,
            tableRating: state.policySelection.tableRating!,
            underwritingClass: state.policySelection.underwritingClass!,
            useNearestAge: state.policySelection.useNearestAge!,
          },
        }),
      },
    });
  };

  useEffect(() => {
    if (!userHasSelectedPolicy(state)) {
      const lifeApplicationInfo = retrieveLifeApplicationInfo();

      if (lifeApplicationInfo) {
        dispatch({
          type: 'UPDATE_CLIENT',
          payload: lifeApplicationInfo,
        });
        dispatch({
          type: 'SELECT_POLICY',
          payload: lifeApplicationInfo.policySelection,
        });
      }
    } else {
      setLifeApplicationInfo(state);
    }
  }, [state]);

  return (
    <Background>
      <Container>
        <Col
          span={{
            small: 4,
            medium: 10,
            large: 7,
          }}
          push={{ medium: 1, large: 1 }}
        >
          <Title>Create a new eApp</Title>
          <Subheader size="large">
            Complete the entire application online and we’ll do the rest.
          </Subheader>
          <SelectedPolicyContainer>
            <SelectedPolicyTitle size="medium">Selected policy</SelectedPolicyTitle>
            <SelectedPolicyBody size="large">
              Please note that this is an estimate and the final price is subject to change.
            </SelectedPolicyBody>
            {userHasSelectedPolicy(state) && <PolicyPlayback />}
          </SelectedPolicyContainer>
          <Form onSubmit={onSubmit} initialValues={state} validate={getValidator}>
            {(formProps) => <EAppInternalCaseForm {...formProps} />}
          </Form>
        </Col>
      </Container>
    </Background>
  );
};
