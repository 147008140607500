import { UtilityText, theme } from '@policygenius/mortar';
import styled from 'styled-components';

export const RecordType = styled(UtilityText)`
  margin-right: auto;
  margin-left: 12px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: ${theme.spacing('small')};
  width: 100%;
  align-items: center;
`;
