import styled from 'styled-components';
import { theme, UtilityText } from '@policygenius/mortar';
import Badge from 'components/Badge';
import { TableCellProps } from '../types';

export const BadgesWrapper = styled.div`
  display: flex;
  padding-top: 8px;

  @media (min-width: ${theme.breakpoints.medium}px) {
    display: none;
  }
`;

export const ClientName = styled(UtilityText)`
  text-transform: capitalize;
  padding-right: 12px;

  @media (min-width: ${theme.breakpoints.medium}px) {
    padding: 0px;
  }
`;

export const Empty = styled.div<{ rightAlign?: boolean }>`
  width: 20px;
  height: 1px;
  background: ${theme.colors.gray300};
  margin-left: ${({ rightAlign }) => (rightAlign ? 'auto' : '0px')};
`;

export const StatusBadge = styled(Badge)`
  margin-left: 12px;

  @media (min-width: ${theme.breakpoints.medium}px) {
    margin-left: 0px;
  }
`;

export const TableRow = styled.tr<{ selected?: boolean }>`
  background: ${theme.colors.white};
  border-bottom: 1px solid ${theme.colors.gray100};
  text-align: center;
  background-color: ${({ selected }) => (selected ? theme.colors.blue100 : 'none')};

  &:hover {
    background-color: ${theme.colors.gray100};
    cursor: pointer;
  }
`;

export const TableData = styled.td`
  text-align: left;
  padding: 12px 16px 16px 16px;

  @media (min-width: ${theme.breakpoints.medium}px) {
    padding: 12px 16px;
  }
`;

export const ListNumberCell = styled(TableData)`
  text-align: left;
  display: none;

  @media (min-width: ${theme.breakpoints.medium}px) {
    display: table-cell;
  }
`;

export const NameAndDate = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: ${theme.breakpoints.medium}px) {
    display: table-cell;
  }
`;

export const ReferredBy = styled.div`
  display: flex;
  align-items: 'center';
  padding-top: 8px;

  @media (min-width: ${theme.breakpoints.medium}px) {
    display: none;
  }
`;

export const Spacer = styled(UtilityText)`
  margin-right: 8px;
  margin-left: 8px;
`;
export const HiddenMobileCell = styled(TableData)`
  display: none;

  @media (min-width: ${theme.breakpoints.medium}px) {
    display: table-cell;
  }
`;

export const LastUpdatedCell = styled(HiddenMobileCell)<{ isMultiTierAdmin: boolean }>`
  text-align: right;

  @media (min-width: ${theme.breakpoints.medium}px) {
    ${({ isMultiTierAdmin }) =>
      isMultiTierAdmin &&
      `
      display: none;
      `}
  }

  @media (min-width: ${theme.breakpoints.large}px) {
    ${({ isMultiTierAdmin }) =>
      isMultiTierAdmin &&
      `
      display: table-cell;
      `}
  }
`;

export const TermCell = styled(TableData)<TableCellProps>`
  display: none;

  @media (min-width: ${theme.breakpoints.large}px) {
    display: ${(props) => (props.selectedReport ? 'none' : 'table-cell')};
  }
`;

export const CoverageAmountCell = styled(TermCell)<TableCellProps>`
  text-align: right;

  @media (min-width: ${theme.breakpoints.large}px) {
    display: ${(props) => (props.selectedReport ? 'none' : '')};
  }
`;

export const RightChevronCell = styled(TableData)`
  @media (min-width: ${theme.breakpoints.large}px) {
    display: none;
  }
`;

export const ReferringAgentName = styled(UtilityText)`
  text-transform: capitalize;
`;

export const CreatedDate = styled(UtilityText)`
  display: flex;

  @media (min-width: ${theme.breakpoints.medium}px) {
    display: none;
  }
`;
