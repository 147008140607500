import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import decamelizeKeys from './decamelizeKeys';
import { EAPP_ROUTE } from 'routes/constants';

export enum Events {
  CHANGED_CHECKBOX = 'Changed Checkbox',
  CLICKED_BUTTON = 'Clicked Button',
  CLICKED_LINK = 'Clicked Link',
  CLICKED_TAB = 'Clicked Tab',
  CLICKED_TILE = 'Clicked Tile',
  FETCHED_QUOTES = 'Fetched Quotes',
  SCHEDULED_CALENDLY_EVENT = 'Scheduled Calendly Event',
  SELECTED_CALENDLY_DATE_AND_TIME = 'Selected Calendly Date and Time',
  SELECTED_CASE = 'Selected Case',
  SELECTED_OPTION = 'Selected Option',
  SUBMITTED_BUSINESS = 'Submitted Business',
  TOGGLED_PAYMENT_MODE = 'Toggled Payment Mode',
}

export enum SegmentMethod {
  TRACK = 'track',
  PAGE = 'page',
  IDENTIFY = 'identify',
}

export const trySegmentCall = <T extends SegmentMethod>(
  type: T,
  ...args: Parameters<WindowAnalytics[T]>
): void => {
  try {
    // @ts-ignore
    window.analytics[type](...args);
  } catch (e) {
    console.error('Error', e);
  }
};

export const track = (event: Events, properties: Record<string, unknown> = {}): void => {
  const eventProperties = decamelizeKeys(properties);

  trySegmentCall(SegmentMethod.TRACK, event, eventProperties);
};

export const page = (): void => {
  const { pathname, href } = window.location;

  const properties: { [key: string]: any } = {
    url: href,
    path: pathname,
  };

  if (
    pathname.includes(EAPP_ROUTE) &&
    (pathname.includes('/1/') || pathname.includes('/review/'))
  ) {
    // '<EAPP_ROUTE>/<carrier>/<page>/<refid>'
    const eAppIndex = pathname.indexOf(EAPP_ROUTE);
    const startCarrierSubstring = eAppIndex + 6; // 6 chars in '/eapp/'
    const endCarrierSubstring = pathname.indexOf('/', startCarrierSubstring);

    const eappCarrier = pathname.substring(startCarrierSubstring, endCarrierSubstring);

    properties.carrier = eappCarrier;
  }

  trySegmentCall(SegmentMethod.PAGE, undefined, pathname, properties);
};

export const identify = (uuid = 'placeholder', traits: Record<string, unknown> = {}): void => {
  trySegmentCall(SegmentMethod.IDENTIFY, uuid, traits);
};

export const useTrackPages = (): void => {
  const location = useLocation();

  useEffect(() => {
    page();
  }, [location]);
};
