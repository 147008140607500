import React, { useEffect } from 'react';
import { Col, TitleText } from '@policygenius/mortar';
import {
  BottomSubtitle,
  ButtonContainer,
  FormContainer,
  HomeButton,
  ScheduleButton,
  TitleContainer,
  TopSubtitle,
  UtilityLink,
  Submitted,
} from './styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useFormContext } from 'context/FormContext';
import { CALENDLY_URL } from './constants';
import { track, Events } from 'utils/analytics';
import { Background } from 'components/Background';
import { REPORTS_ROUTE } from 'routes/constants';

const Schedule: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { state, dispatch } = useFormContext();

  const homeOnClick = () => {
    navigate(`${REPORTS_ROUTE}/${location?.state?.referenceId}`);

    dispatch({
      type: 'RESET_STATE',
    });
  };

  const scheduleOnClick = () => {
    window.Calendly?.initPopupWidget({
      url: CALENDLY_URL,
      parentElement: null,
      prefill: {
        name: `${state.firstName} ${state.lastName}`,
        firstName: state.firstName,
        lastName: state.lastName,
        email: state.email,
        customAnswers: {
          a1: `1${state.phone}`,
        },
      },
    });
  };

  const isCalendlyEvent = (e: MessageEvent<any>) => {
    return (
      e.origin === 'https://calendly.com' && e.data.event && e.data.event.indexOf('calendly.') === 0
    );
  };

  useEffect(() => {
    const handleCalendlyOutcome = (e: MessageEvent<any>) => {
      if (isCalendlyEvent(e)) {
        switch (e.data.event) {
          case 'calendly.date_and_time_selected':
            track(Events.SELECTED_CALENDLY_DATE_AND_TIME);
            break;
          case 'calendly.event_scheduled':
            track(Events.SCHEDULED_CALENDLY_EVENT);
            break;
          default:
        }
      }
    };

    window.addEventListener('message', handleCalendlyOutcome);

    return () => {
      window.removeEventListener('message', handleCalendlyOutcome);
    };
  }, []);

  return (
    <Background>
      <FormContainer>
        <Col push={{ medium: 1 }} span={{ small: 4, medium: 10, large: 7 }}>
          <Submitted size="medium" color="secondary">
            Submitted!
          </Submitted>
          <TitleContainer>
            <TitleText
              size={{
                small: 'large',
                large: 'xlarge',
              }}
            >
              Schedule a call
            </TitleText>
          </TitleContainer>
          <TopSubtitle size="large">
            Our{' '}
            <UtilityLink
              forwardedAs="a"
              href="https://pro.policygenius.com/hubfs/PgPro%201-Pagers/Calendly%20Onepager%20.pdf"
              target="_blank"
              rel="noopener noreferrer"
              size="large"
              color="orange"
            >
              Calendly integration
            </UtilityLink>{' '}
            allows you to quickly schedule an appointment with a Policygenius Pro agent on your
            client’s behalf. We see higher conversion rates for clients who have specific times
            scheduled with one of our licensed advisors.
          </TopSubtitle>
          <BottomSubtitle size="large">
            If a call isn’t scheduled, we’ll be reaching out to your client within 24 hours to
            collect their details and get an application submitted.
          </BottomSubtitle>
          <ButtonContainer>
            <ScheduleButton
              analyticsText="Schedule a call"
              fullWidth
              onClick={scheduleOnClick}
              size="large"
              variant="black"
            >
              Schedule
            </ScheduleButton>
            <HomeButton
              analyticsText="Back to home"
              fullWidth
              onClick={homeOnClick}
              variant="secondary"
              size="large"
            >
              Go to case report
            </HomeButton>
          </ButtonContainer>
        </Col>
      </FormContainer>
    </Background>
  );
};

export default Schedule;
