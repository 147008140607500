import React from 'react';
import { UtilityText } from '@policygenius/mortar';
import { Props } from './types';
import { Container, Subheader, TitleBlock } from './styles';

const DetailsContainer: React.FC<Props> = ({
  header,
  subheader,
  labelText,
  showDividerLine,
  body,
}) => {
  return (
    <React.Fragment>
      <UtilityText weight="bold" size="xsmall" color="secondary">
        {labelText}
      </UtilityText>
      <Container>
        <TitleBlock showDividerLine={showDividerLine}>
          <UtilityText weight="bold" size="medium">
            {header}
          </UtilityText>
          <Subheader>
            {subheader && (
              <UtilityText size="small" data-testid="subheader">
                {subheader}
              </UtilityText>
            )}
          </Subheader>
        </TitleBlock>
        {body}
      </Container>
    </React.Fragment>
  );
};

export default DetailsContainer;
