/* THIS FILE WAS AUTO-GENERATED. DO NOT MODIFY */
/* eslint-disable */
import * as Types from '../../graph/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AcceptTermsAndConditionsMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type AcceptTermsAndConditionsMutation = { __typename?: 'Mutation', acceptTermsAndConditions: boolean };


export const AcceptTermsAndConditionsDocument = gql`
    mutation AcceptTermsAndConditions {
  acceptTermsAndConditions
}
    `;
export type AcceptTermsAndConditionsMutationFn = Apollo.MutationFunction<AcceptTermsAndConditionsMutation, AcceptTermsAndConditionsMutationVariables>;

/**
 * __useAcceptTermsAndConditionsMutation__
 *
 * To run a mutation, you first call `useAcceptTermsAndConditionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptTermsAndConditionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptTermsAndConditionsMutation, { data, loading, error }] = useAcceptTermsAndConditionsMutation({
 *   variables: {
 *   },
 * });
 */
export function useAcceptTermsAndConditionsMutation(baseOptions?: Apollo.MutationHookOptions<AcceptTermsAndConditionsMutation, AcceptTermsAndConditionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptTermsAndConditionsMutation, AcceptTermsAndConditionsMutationVariables>(AcceptTermsAndConditionsDocument, options);
      }
export type AcceptTermsAndConditionsMutationHookResult = ReturnType<typeof useAcceptTermsAndConditionsMutation>;
export type AcceptTermsAndConditionsMutationResult = Apollo.MutationResult<AcceptTermsAndConditionsMutation>;
export type AcceptTermsAndConditionsMutationOptions = Apollo.BaseMutationOptions<AcceptTermsAndConditionsMutation, AcceptTermsAndConditionsMutationVariables>;