import { Link, Row, theme } from '@policygenius/mortar';
import styled from 'styled-components';
import { ClickableCard } from 'components/ClickableCard';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Background = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;
  padding-top: 32px;
  background-color: ${theme.colors.white};

  @media (min-width: ${theme.breakpoints.medium}px) {
    padding-bottom: 120px;
  }
`;

export const Header = styled.div`
  white-space: normal;
  background: none;
  @media (min-width: ${theme.breakpoints.medium}px) {
    white-space: nowrap;
  }
`;

export const HeaderRow = styled(Row)`
  padding: 54px 16px 84px 16px;
  @media (min-width: ${theme.breakpoints.medium}px) {
    margin: auto;
    padding: 96px 24px 89px 24px;
  }
`;

export const PageRow = styled(Row)`
  margin: auto;
  padding-left: 0px;
  padding-right: 0px;
  @media (min-width: ${theme.breakpoints.medium}px) {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

export const ContentRow = styled(Row)`
  @media (min-width: ${theme.breakpoints.medium}px) {
    padding-left: 0px;
    padding-right: 0px;
  }
  @media (min-width: ${theme.breakpoints.large}px) {
    margin-top: 0;
  }
`;

export const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  svg {
    margin-right: 8px;
  }
`;

export const Card = styled(ClickableCard)<{ last?: boolean }>`
  width: 100%;
  margin-bottom: 24px;
  svg {
    flex: auto 0 0;
  }

  ${({ last }) =>
    last &&
    `
    margin-bottom: 48px;;
    `}
`;

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;

  p:first-of-type {
    margin-bottom: 4px;
  }
`;

export const ReferralCard = styled(Card)`
  &:hover {
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: initial;
  }
`;
export const ReferralButtonContainer = styled.div`
  display: flex;
  align-items: baseline;
  gap: 8px;
  margin-top: 16px;

  @media (min-width: ${theme.breakpoints.medium}px) {
    justify-content: flex-start;
  }
`;

export const PreviewPageLink = styled(Link)`
  text-decoration: none;
  color: ${theme.colors.gray500};
  font-family: ${theme.typography.fontFamily.sansSerif};
  font-weight: ${theme.typography.fontWeight.bold};
`;

export const TitleCaseText = styled.span`
  text-transform: capitalize;
`;
