import React from 'react';
import Badge from '.';
import { UtilityText } from '@policygenius/mortar';
import { ClassProp } from './types';

export const AcceleratedBadge: React.FC<ClassProp> = ({ className }) => (
  <Badge label="Accelerated" variant="POSITIVE" className={className} />
);

export const AcceleratedDescription: React.FC<ClassProp> = ({ className }) => (
  <div className={className}>
    <UtilityText size="small">
      Qualified applicants complete a phone interview in lieu of a medical exam and receive a
      decision within an average of 2-3 days.
    </UtilityText>
  </div>
);

export const SimplifiedBadge: React.FC<ClassProp> = ({ className }) => (
  <Badge label="Simplified issue" variant="INFO" className={className} />
);

export const SimplifiedDescription: React.FC<ClassProp> = ({ className }) => (
  <div className={className}>
    <UtilityText size="small">
      Whole life insurance, reserved for individuals 45+ with health conditions. Clients complete a
      phone interview in lieu of a medical exam, but premiums are higher to account for greater
      risk. Up to $50,000 in coverage.
    </UtilityText>
  </div>
);

export const NonMedBadge: React.FC<ClassProp> = ({ className }) => (
  <Badge label="Non Med" variant="INFO" className={className} />
);

export const InstantBadge: React.FC<ClassProp> = ({ className }) => (
  <Badge label="Instant" variant="POSITIVE" className={className} />
);

export const FinalExpenseBadge: React.FC<ClassProp> = ({ className }) => (
  <Badge label="Final Expense" variant="INFO" className={className} />
);
