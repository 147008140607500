import React, { useState, useEffect } from 'react';
import { isValidMonth, isValidDay, getInitialDateValues } from './utils';
import { isValid } from 'date-fns';
import { Props } from './types';
import { MaskedTextbox, SplitTextbox } from '@policygenius/mortar';

const DateField: React.FC<Props> = ({ input, meta, initialDate }) => {
  const { initialMonthValue, initialDayValue, initialYearValue } = getInitialDateValues(
    initialDate ? new Date(initialDate) : null
  );

  const [monthValue, setMonthValue] = useState(initialMonthValue);
  const [dayValue, setDayValue] = useState(initialDayValue);
  const [yearValue, setYearValue] = useState(initialYearValue);

  useEffect(() => {
    if (!monthValue || !yearValue || !dayValue) {
      if (!input.value) {
        return;
      }

      return input.onChange(null);
    }

    if (yearValue.length < 4) {
      return input.onChange(null);
    }

    const date = new Date(Date.UTC(Number(yearValue), Number(monthValue) - 1, Number(dayValue)));

    if (isValid(date)) {
      return input.onChange(date.toISOString());
    }

    return input.onChange(null);
  }, [dayValue, monthValue, yearValue]);

  const onMonthChange = (month: string) => {
    if (isValidMonth(month)) {
      setMonthValue(month);
    } else {
      input.onChange(null);
    }
  };

  const onDayChange = (day: string) => {
    if (isValidDay(day, monthValue)) {
      setDayValue(day);
    } else {
      input.onChange(null);
    }
  };

  const onYearChange = (newYearValue: string) => {
    if (!newYearValue || Number(newYearValue) >= 1) {
      setYearValue(newYearValue);
    } else {
      input.onChange(null);
    }
  };

  const dateWrapper = React.useRef<HTMLDivElement>(null);

  return (
    <div ref={dateWrapper}>
      <SplitTextbox
        id="date-of-birth"
        label="Date of birth"
        {...input}
        errorMessage={meta.touched && (!meta.active ? meta.error : undefined)}
      >
        <MaskedTextbox
          {...input}
          id="dob-month"
          name="dob-month"
          label="month"
          placeholder="mm"
          type="text"
          defaultValue={monthValue}
          maskOptions={{
            mask: '00',
          }}
          value={monthValue}
          onChange={(value) => onMonthChange(value.unmasked)}
        />
        <MaskedTextbox
          {...input}
          id="dob-day"
          name="dob-day"
          label="day"
          placeholder="dd"
          type="text"
          defaultValue={dayValue}
          maskOptions={{
            mask: '00',
          }}
          value={dayValue}
          onChange={(value) => onDayChange(value.unmasked)}
        />
        <MaskedTextbox
          {...input}
          id="dob-year"
          name="dob-year"
          label="year"
          defaultValue={yearValue}
          placeholder="yyyy"
          type="text"
          maskOptions={{
            mask: '0000',
          }}
          value={yearValue}
          onChange={(value) => onYearChange(value.unmasked)}
        />
      </SplitTextbox>
    </div>
  );
};

export default DateField;
