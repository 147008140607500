import React, { createContext, useContext, useReducer } from 'react';
import { ContextProps } from './types';
import { formReducer, initialState } from './formReducer';

export const FormContext = createContext<ContextProps>({
  state: initialState,
  dispatch: () => undefined,
});

FormContext.displayName = 'FormContext';

export const FormProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(formReducer, initialState);

  return <FormContext.Provider value={{ state, dispatch }}>{children}</FormContext.Provider>;
};

export const useFormContext = (): ContextProps => useContext(FormContext);

export default FormProvider;
