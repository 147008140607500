import { LabelText, theme, UtilityText } from '@policygenius/mortar';
import styled from 'styled-components';
import Badge from 'components/Badge';

interface StyleOps {
  hasBadge?: boolean;
}

export const AcceleratedBadge = styled(Badge)`
  margin-bottom: 16px;
`;

export const CarrierLink = styled(UtilityText)`
  &:hover {
    color: ${theme.colors.orange300};
  }
`;

export const CoverageSelections = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
`;

export const Label = styled(LabelText)`
  margin-bottom: 4px;
`;

export const PlaybackContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${theme.colors.white};
  padding: 16px;
  margin-bottom: 24px;
  border: 1px solid ${theme.colors.gray200};
  @media (min-width: ${theme.breakpoints.medium}px) {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

export const Price = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const Selection = styled.div`
  width: 232px;
`;

export const SimplifiedBadge = styled(Badge)`
  margin-bottom: 16px;
`;

export const TitlePrice = styled.div<StyleOps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ hasBadge }) => (hasBadge ? 8 : 16)}px;
`;
