/* THIS FILE WAS AUTO-GENERATED. DO NOT MODIFY */
/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Time: { input: any; output: any; }
};

export type AboutSection = {
  __typename?: 'AboutSection';
  body?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum AgencyType {
  BrokerOfRecord = 'BROKER_OF_RECORD',
  FeeOnly = 'FEE_ONLY',
  SubProducer = 'SUB_PRODUCER',
  Unknown = 'UNKNOWN'
}

export type AgentName = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type ApplicationResponse = {
  __typename?: 'ApplicationResponse';
  referenceId: Scalars['String']['output'];
};

export enum ApplicationStage {
  Application = 'APPLICATION',
  Decision = 'DECISION',
  Issuance = 'ISSUANCE',
  NoStage = 'NO_STAGE',
  Placed = 'PLACED',
  Placement = 'PLACEMENT',
  Quotes = 'QUOTES',
  Referral = 'REFERRAL',
  Underwriting = 'UNDERWRITING'
}

export enum ApplicationStatus {
  Approved = 'APPROVED',
  Cold = 'COLD',
  Deceased = 'DECEASED',
  Declined = 'DECLINED',
  InForce = 'IN_FORCE',
  Lapsed = 'LAPSED',
  NotTaken = 'NOT_TAKEN',
  Pending = 'PENDING',
  PhoneDecline = 'PHONE_DECLINE',
  Postponed = 'POSTPONED',
  Surrendered = 'SURRENDERED',
  Withdrawn = 'WITHDRAWN'
}

export enum AttendingPhysicianStatement {
  AwaitingReq = 'AWAITING_REQ',
  Canceled = 'CANCELED',
  Ordered = 'ORDERED',
  Received = 'RECEIVED',
  StatusUnknown = 'STATUS_UNKNOWN',
  Unstarted = 'UNSTARTED'
}

export type Choice = {
  __typename?: 'Choice';
  displayText: Scalars['String']['output'];
  valueInt: Scalars['Int']['output'];
  valueText: Scalars['String']['output'];
  valueType?: Maybe<ValueType>;
};

export type Client = {
  coverageAmount: Scalars['Int']['input'];
  dateOfBirth: Scalars['String']['input'];
  gender: Gender;
  healthClass: Scalars['String']['input'];
  referringAgent?: InputMaybe<Scalars['String']['input']>;
  state: Scalars['String']['input'];
  termLength: Scalars['Int']['input'];
  tobaccoUse: Scalars['Boolean']['input'];
};

export type Composite = {
  __typename?: 'Composite';
  dataType?: Maybe<DataType>;
  sensitive?: Maybe<Scalars['Boolean']['output']>;
};

export type CompositeStructure = {
  __typename?: 'CompositeStructure';
  key: Scalars['String']['output'];
  value?: Maybe<Composite>;
};

export enum CreationMethod {
  Advisor = 'ADVISOR',
  Api = 'API',
  Client = 'CLIENT',
  Eapp = 'EAPP',
  Policygenius = 'POLICYGENIUS'
}

export type CurrentUser = {
  __typename?: 'CurrentUser';
  agencyName: Scalars['String']['output'];
  agencySlug: Scalars['String']['output'];
  agencyType: AgencyType;
  carriers: Array<Maybe<Scalars['String']['output']>>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  highestTierParent: Scalars['String']['output'];
  highestTierParentName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  portalOptions: PortalOptions;
  roles: Array<Maybe<Role>>;
  tcpaRequired: Scalars['Boolean']['output'];
  tierType: TierType;
  uuid: Scalars['String']['output'];
};

export type DataPointId = {
  __typename?: 'DataPointID';
  compositeStructure?: Maybe<Array<Maybe<CompositeStructure>>>;
  dataType: DataType;
  repeatable?: Maybe<Scalars['Boolean']['output']>;
  subtype: Subtype;
  urn: Scalars['String']['output'];
};

export type DataPointIdMapping = {
  __typename?: 'DataPointIDMapping';
  boolValue?: Maybe<Scalars['Boolean']['output']>;
  dataPointIdUrn: Scalars['String']['output'];
  dataType: DataValueTypes;
  dateValue?: Maybe<Date>;
  floatValue?: Maybe<Scalars['Float']['output']>;
  intListValue?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  intValue?: Maybe<Scalars['Int']['output']>;
  stringListValue?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  stringValue?: Maybe<Scalars['String']['output']>;
};

export type DataPointIdMappingInput = {
  boolValue?: InputMaybe<Scalars['Boolean']['input']>;
  dataPointIdUrn: Scalars['String']['input'];
  dataType: DataValueTypes;
  dateValue?: InputMaybe<DateInput>;
  floatValue?: InputMaybe<Scalars['Float']['input']>;
  intListValue?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  intValue?: InputMaybe<Scalars['Int']['input']>;
  stringListValue?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  stringValue?: InputMaybe<Scalars['String']['input']>;
};

export enum DataType {
  Boolean = 'BOOLEAN',
  Composite = 'COMPOSITE',
  DataTypeUnspecified = 'DATA_TYPE_UNSPECIFIED',
  Date = 'DATE',
  Decimal = 'DECIMAL',
  Integer = 'INTEGER',
  IntegerList = 'INTEGER_LIST',
  Text = 'TEXT',
  TextList = 'TEXT_LIST',
  Timestamp = 'TIMESTAMP'
}

export enum DataValueTypes {
  BoolValue = 'BOOL_VALUE',
  DateValue = 'DATE_VALUE',
  FloatValue = 'FLOAT_VALUE',
  IntListValue = 'INT_LIST_VALUE',
  IntValue = 'INT_VALUE',
  StringListValue = 'STRING_LIST_VALUE',
  StringValue = 'STRING_VALUE'
}

export type Date = {
  __typename?: 'Date';
  day?: Maybe<Scalars['Int']['output']>;
  month?: Maybe<Scalars['Int']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type DateInput = {
  day?: InputMaybe<Scalars['Int']['input']>;
  month?: InputMaybe<Scalars['Int']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type FileData = {
  __typename?: 'FileData';
  data?: Maybe<Scalars['String']['output']>;
  fileName: Scalars['String']['output'];
};

export type FileDataInput = {
  attachmentId?: InputMaybe<Scalars['Int']['input']>;
  referenceId?: InputMaybe<Scalars['String']['input']>;
};

export type FileMetaData = {
  __typename?: 'FileMetaData';
  displayName: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
};

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE'
}

export type GenerateReportResponse = {
  __typename?: 'GenerateReportResponse';
  message: Scalars['String']['output'];
};

export type Interview = {
  __typename?: 'Interview';
  dpidUrns: Array<Scalars['String']['output']>;
  nodes: Array<Node>;
  savedDpids?: Maybe<MappedDpidData>;
  urn: Scalars['String']['output'];
};

export enum IssueType {
  AcceleratedUnderwriting = 'ACCELERATED_UNDERWRITING',
  FullyUnderwritten = 'FULLY_UNDERWRITTEN',
  Guaranteed = 'GUARANTEED',
  IssueTypeUnspecified = 'ISSUE_TYPE_UNSPECIFIED',
  Simplified = 'SIMPLIFIED'
}

export type KeyValuePair = {
  __typename?: 'KeyValuePair';
  shortUrn: Scalars['String']['output'];
  value: DataPointIdMapping;
};

export type LifeAppliedPolicy = {
  __typename?: 'LifeAppliedPolicy';
  carrier: Scalars['String']['output'];
  coverageAmount: Scalars['Int']['output'];
  healthClass: Scalars['String']['output'];
  isAgeChange: Scalars['Boolean']['output'];
  isFinancialModification: Scalars['Boolean']['output'];
  issueType: IssueType;
  policyName: Scalars['String']['output'];
  policyNumber?: Maybe<Scalars['String']['output']>;
  premium: Scalars['Float']['output'];
  premiumMode: PremiumMode;
  product: ProductType;
  tableRating: Scalars['Int']['output'];
  term: Scalars['Int']['output'];
  underwritingClassDecision: UnderwritingClassDecision;
};

export type ListFilesInput = {
  filter?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  pageToken?: InputMaybe<Scalars['String']['input']>;
};

export type ListFilesResponse = {
  __typename?: 'ListFilesResponse';
  files: Array<Maybe<FileMetaData>>;
  nextPageToken?: Maybe<Scalars['String']['output']>;
};

export type MappedDpidData = {
  __typename?: 'MappedDpidData';
  dpids?: Maybe<Array<Maybe<DataPointIdMapping>>>;
  repeatableDpids?: Maybe<Array<Maybe<RepeatableDataPointMapping>>>;
};

export enum MedicalExamStatus {
  ExamDone = 'EXAM_DONE',
  ExamNotRequired = 'EXAM_NOT_REQUIRED',
  ExamSubmitted = 'EXAM_SUBMITTED',
  MedExamStatusUnspecified = 'MED_EXAM_STATUS_UNSPECIFIED',
  Other = 'OTHER',
  Scheduled = 'SCHEDULED',
  Unscheduled = 'UNSCHEDULED'
}

export type MedicalInfo = {
  __typename?: 'MedicalInfo';
  examDate?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptTermsAndConditions: Scalars['Boolean']['output'];
  completeEapp: Scalars['Boolean']['output'];
  createAgent: Scalars['Boolean']['output'];
  generateReport: GenerateReportResponse;
  saveEapp: Scalars['Boolean']['output'];
  updateNotificationPreference: Preference;
  userLifeApp: UserCreationResponse;
};


export type MutationCompleteEappArgs = {
  internalCaseReferenceID?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateAgentArgs = {
  input?: InputMaybe<AgentName>;
};


export type MutationSaveEappArgs = {
  input: SaveDpidDataRequest;
};


export type MutationUpdateNotificationPreferenceArgs = {
  input?: InputMaybe<Array<SubscriptionPreference>>;
};


export type MutationUserLifeAppArgs = {
  input: User;
};

export type Node = {
  __typename?: 'Node';
  children?: Maybe<Array<Node>>;
  choices?: Maybe<Array<Choice>>;
  content: Scalars['String']['output'];
  dataPoint?: Maybe<DataPointId>;
  helpText?: Maybe<Scalars['String']['output']>;
  lastUpdatedDate?: Maybe<Scalars['Time']['output']>;
  maxRepeatable?: Maybe<Scalars['Int']['output']>;
  minRepeatable?: Maybe<Scalars['Int']['output']>;
  nodeType: Scalars['String']['output'];
  presentationRules?: Maybe<Array<PresentationRule>>;
  questionType: Scalars['String']['output'];
  rules?: Maybe<Scalars['String']['output']>;
  urn: Scalars['String']['output'];
};

export enum NotificationLevel {
  Organization = 'ORGANIZATION',
  Personal = 'PERSONAL'
}

export type NotificationPreference = {
  __typename?: 'NotificationPreference';
  description?: Maybe<Scalars['String']['output']>;
  level: NotificationLevel;
  name: Scalars['String']['output'];
  notificationURN: Scalars['String']['output'];
  subscribed: Scalars['Boolean']['output'];
};

export enum OrderBy {
  AgencyName = 'AGENCY_NAME',
  AgencyNameDesc = 'AGENCY_NAME_DESC',
  AgentName = 'AGENT_NAME',
  AgentNameDesc = 'AGENT_NAME_DESC',
  Client = 'CLIENT',
  ClientDesc = 'CLIENT_DESC',
  LastUpdatedDate = 'LAST_UPDATED_DATE',
  LastUpdatedDateAsc = 'LAST_UPDATED_DATE_ASC',
  PremiumAsc = 'PREMIUM_ASC',
  PremiumDesc = 'PREMIUM_DESC',
  ReferralDate = 'REFERRAL_DATE',
  ReferralDateDesc = 'REFERRAL_DATE_DESC',
  Stage = 'STAGE',
  StageDesc = 'STAGE_DESC'
}

export enum PacketStatus {
  Interview = 'INTERVIEW',
  NotRequired = 'NOT_REQUIRED',
  PaperworkStatusUnspecified = 'PAPERWORK_STATUS_UNSPECIFIED',
  Sent = 'SENT',
  Signed = 'SIGNED',
  Submitted = 'SUBMITTED'
}

export type PageContent = {
  __typename?: 'PageContent';
  aboutSectionLeft: AboutSection;
  aboutSectionRight: AboutSection;
  hideLogoBackground: Scalars['Boolean']['output'];
  logoURL: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  subtext?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type PaginatedReports = {
  __typename?: 'PaginatedReports';
  nextPageToken: Scalars['String']['output'];
  reports?: Maybe<Array<StatusReport>>;
  totalSize: Scalars['Int']['output'];
};

export type Pagination = {
  filter?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<OrderBy>;
  pageSize: Scalars['Int']['input'];
  pageToken: Scalars['String']['input'];
};

export type Policy = {
  __typename?: 'Policy';
  annualPremium: Scalars['Float']['output'];
  attributes: Array<Scalars['String']['output']>;
  carrier: Scalars['String']['output'];
  carrierName: Scalars['String']['output'];
  coverageAmount: Scalars['Int']['output'];
  healthCategory: Scalars['String']['output'];
  isTobacco: Scalars['Boolean']['output'];
  issueAge: Scalars['Int']['output'];
  issueType: Scalars['String']['output'];
  monthlyPremium: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  planIdentifierName: Scalars['String']['output'];
  productType: Scalars['String']['output'];
  returnOfPremium: Scalars['Boolean']['output'];
  tableRating: Scalars['Int']['output'];
  termInYears: Scalars['Int']['output'];
  underwritingClass: Scalars['String']['output'];
  useNearestAge: Scalars['Boolean']['output'];
};

export type PolicyPreference = {
  annualPremium: Scalars['Float']['input'];
  carrier: Scalars['String']['input'];
  carrierName: Scalars['String']['input'];
  healthCategory: Scalars['String']['input'];
  isTobacco: Scalars['Boolean']['input'];
  issueAge: Scalars['Int']['input'];
  issueType: Scalars['String']['input'];
  monthlyPremium: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  planIdentifierName: Scalars['String']['input'];
  productType: Scalars['String']['input'];
  returnOfPremium: Scalars['Boolean']['input'];
  tableRating: Scalars['Int']['input'];
  underwritingClass: Scalars['String']['input'];
  useNearestAge: Scalars['Boolean']['input'];
};

export type PortalOptions = {
  __typename?: 'PortalOptions';
  acceptedTermsAndConditions: Scalars['Boolean']['output'];
};

export type Preference = {
  __typename?: 'Preference';
  notificationPreferences: Array<NotificationPreference>;
};

export enum PremiumMode {
  Annual = 'ANNUAL',
  Monthly = 'MONTHLY',
  PremiumModeUnspecified = 'PREMIUM_MODE_UNSPECIFIED'
}

export type PresentationRule = {
  __typename?: 'PresentationRule';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type Producer = {
  __typename?: 'Producer';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export enum ProductType {
  Term = 'TERM',
  Whole = 'WHOLE'
}

export type Query = {
  __typename?: 'Query';
  affiliate: PageContent;
  availableProducers: Array<Maybe<Producer>>;
  currentUser: CurrentUser;
  getFileData?: Maybe<FileData>;
  interview?: Maybe<Interview>;
  listFiles?: Maybe<ListFilesResponse>;
  medicalInfo: MedicalInfo;
  paginatedReports: PaginatedReports;
  policies: Array<Policy>;
  statusReport: StatusReport;
  userPreferences: Preference;
};


export type QueryAffiliateArgs = {
  input: Scalars['String']['input'];
};


export type QueryGetFileDataArgs = {
  input: FileDataInput;
};


export type QueryInterviewArgs = {
  internalCaseReferenceID?: InputMaybe<Scalars['String']['input']>;
  interviewUrn: Scalars['String']['input'];
};


export type QueryListFilesArgs = {
  input: ListFilesInput;
};


export type QueryMedicalInfoArgs = {
  input: Scalars['String']['input'];
};


export type QueryPaginatedReportsArgs = {
  input?: InputMaybe<Pagination>;
};


export type QueryPoliciesArgs = {
  input: Client;
};


export type QueryStatusReportArgs = {
  input: Scalars['String']['input'];
};

export type ReferringAgent = {
  __typename?: 'ReferringAgent';
  agencyName: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export type RepeatableDataPointIdInput = {
  dataPointIdUrn: Scalars['String']['input'];
  values: Array<InputMaybe<RepeatableValuesInput>>;
};

export type RepeatableDataPointMapping = {
  __typename?: 'RepeatableDataPointMapping';
  dataPointIdUrn: Scalars['String']['output'];
  values: Array<Maybe<RepeatableMap>>;
};

export type RepeatableMap = {
  __typename?: 'RepeatableMap';
  valueMap?: Maybe<Array<Maybe<KeyValuePair>>>;
};

export type RepeatableValuesInput = {
  operation?: InputMaybe<Scalars['String']['input']>;
  valueMap: Array<InputMaybe<DataPointIdMappingInput>>;
};

export type ReportClient = {
  __typename?: 'ReportClient';
  dateOfBirth?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  state?: Maybe<Scalars['String']['output']>;
};

export enum Role {
  Admin = 'ADMIN',
  Assistant = 'ASSISTANT',
  Producer = 'PRODUCER',
  RoleUnknown = 'ROLE_UNKNOWN'
}

export type SaveDpidDataRequest = {
  dpids?: InputMaybe<Array<InputMaybe<DataPointIdMappingInput>>>;
  referenceId: Scalars['String']['input'];
  repeatableDpids?: InputMaybe<Array<InputMaybe<RepeatableDataPointIdInput>>>;
};

export type StatusReport = {
  __typename?: 'StatusReport';
  applicationStage: ApplicationStage;
  applicationStatus: ApplicationStatus;
  apsMilestones?: Maybe<Array<Maybe<AttendingPhysicianStatement>>>;
  client: ReportClient;
  createdDate: Scalars['String']['output'];
  creationMethod: CreationMethod;
  lifeAppliedPolicy?: Maybe<LifeAppliedPolicy>;
  medicalExamStatus?: Maybe<MedicalExamStatus>;
  packetStatus?: Maybe<PacketStatus>;
  referenceId: Scalars['String']['output'];
  referringAgent: ReferringAgent;
  updatedDate: Scalars['String']['output'];
};

export type SubscriptionPreference = {
  notificationURN: Scalars['String']['input'];
  role?: InputMaybe<Role>;
  type: SubscriptionType;
};

export enum SubscriptionType {
  Subscribe = 'SUBSCRIBE',
  Unsubscribe = 'UNSUBSCRIBE'
}

export enum Subtype {
  Dollars = 'DOLLARS',
  Email = 'EMAIL',
  Inches = 'INCHES',
  LongText = 'LONG_TEXT',
  Month = 'MONTH',
  Percentage = 'PERCENTAGE',
  Pounds = 'POUNDS',
  Ssn = 'SSN',
  SubtypeUnspecified = 'SUBTYPE_UNSPECIFIED',
  Telephone = 'TELEPHONE',
  ZipCode = 'ZIP_CODE'
}

export enum TierType {
  MultiTier = 'MULTI_TIER',
  SingleTier = 'SINGLE_TIER'
}

export enum UnderwritingClassDecision {
  Better = 'BETTER',
  Same = 'SAME',
  UnderwritingClassDecisionUnspecified = 'UNDERWRITING_CLASS_DECISION_UNSPECIFIED',
  Worse = 'WORSE'
}

export type User = {
  coverageAmount?: InputMaybe<Scalars['Int']['input']>;
  creationMethod?: InputMaybe<CreationMethod>;
  dateOfBirth: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  gender: Gender;
  lastName: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  paymentMode?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
  policyPreference?: InputMaybe<PolicyPreference>;
  referringAgent?: InputMaybe<Scalars['String']['input']>;
  state: Scalars['String']['input'];
  termInYears?: InputMaybe<Scalars['Int']['input']>;
  tobaccoUse?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserCreationResponse = {
  __typename?: 'UserCreationResponse';
  applicationResponse?: Maybe<ApplicationResponse>;
  statusCode: Scalars['Int']['output'];
};

export enum ValueType {
  ValueInt = 'VALUE_INT',
  ValueText = 'VALUE_TEXT'
}
