import React from 'react';
import { UtilityText } from '@policygenius/mortar';
import { Props } from './types';
import { usdFormat } from 'utils/helpers';

const Premium: React.FC<Props> = ({ premiumAmount, frequency }) => {
  return (
    <>
      <UtilityText size="xxlarge" weight="bold">
        {usdFormat(premiumAmount)}
      </UtilityText>
      <UtilityText size="large" weight="bold">
        {frequency}
      </UtilityText>
    </>
  );
};

export default Premium;
