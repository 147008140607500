import React, { useMemo } from 'react';
import { CircleContainer, Wrapper, Title, Empty } from './styles';
import { Props } from './types';
import Circle from './Circle';

const MAX_CIRCLES = 6;

const Tracker: React.FC<Props> = ({ number, title, full }) => {
  const list = useMemo(() => {
    if (number == null) {
      return;
    }

    const circles: React.ReactElement[] = [];

    if (number === 0) {
      [...Array(MAX_CIRCLES).keys()].forEach((num) => {
        circles.push(<Circle key={`${num}-grey`} variant="grey" />);
      });

      return circles;
    }

    if (full) {
      [...Array(MAX_CIRCLES).keys()].forEach((num) => {
        circles.push(<Circle key={`${num}-blue`} variant="blue" />);
      });

      return circles;
    }

    [...Array(number).keys()].forEach((num, index) => {
      if (index + 1 === number) {
        return circles.push(<Circle key={`${num}-lightblue`} variant="lightblue" />);
      }

      return circles.push(<Circle key={`${num}-lightblue`} variant="blue" />);
    });

    const leftOver = MAX_CIRCLES - number;

    [...Array(leftOver).keys()].forEach((num) => {
      circles.push(<Circle key={`${num}-grey`} variant="grey" />);
    });

    return circles;
  }, [number]);

  return Number.isInteger(number) ? (
    <Wrapper>
      <CircleContainer>{list}</CircleContainer>
      <Title size="small">{title.toLowerCase()}</Title>
    </Wrapper>
  ) : (
    <Empty />
  );
};

export default Tracker;
