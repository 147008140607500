import {
  theme,
  StatusDone,
  StatusOpen,
  UtilityText,
  Row,
  Col,
  TitleText,
} from '@policygenius/mortar';
import styled from 'styled-components';

export const FORM_SECTION_MARGIN_BOTTOM = 56;

export const EAppContainer = styled.div`
  min-height: 80vh;
  background-color: ${theme.colors.white};
`;

export const LoadingContainer = styled.div`
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Name = styled(UtilityText)`
  margin: 4px 0px;
`;

export const PageContainer = styled(Row)`
  padding: 40px 24px;

  @media (min-width: ${theme.breakpoints.large}px) {
    padding: 40px;
  }
`;

export const SideRailGrid = styled(Row)`
  padding-left: 0px;
  padding-right: 0px;
  position: sticky;
  top: 40px;
`;

export const TableOfContents = styled.div`
  border: 1px solid ${theme.colors.gray100};
  border-radius: 4px;
  margin-top: 12px;

  div {
    padding: 12px;
    cursor: pointer;

    &:hover {
      background-color: ${theme.colors.gray100};
    }

    &:active {
      background-color: ${theme.colors.blue100};
    }

    &:not(:last-child) {
      border: 1px solid ${theme.colors.gray100};
    }
  }
`;

export const TableOfContentsItem = styled.div<{ selected: boolean }>`
  background-color: ${({ selected }) => selected && theme.colors.blue100};
  display: flex;
  align-items: center;
`;

export const SavedIcon = styled(StatusDone)`
  margin-right: 8px;
  flex-shrink: 0;
`;

export const UnsavedIcon = styled(StatusOpen)`
  margin-right: 8px;
  flex-shrink: 0;
`;

export const FormSection = styled.div`
  margin-bottom: ${FORM_SECTION_MARGIN_BOTTOM}px;
`;

export const SectionHeader = styled(TitleText)`
  margin-bottom: 32px;
`;

export const NavCol = styled(Col)`
  padding-top: 24px;
  text-align: right;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  button:first-child {
    margin-right: 8px;
  }
`;
