import React from 'react';
import Tracker from 'components/Tracker';
import { STAGE_MAPPING } from 'components/Tracker/types';
import { STATUS_BADGE } from 'components/StatusTrackerTable/constants';
import { DEFAULT_COLD_HELPER_TEXT, DEFINITION } from '../constants';
import { ApplicationStatus } from 'graph/types';
import { StatusBarProps } from './types';
import { ApplicationBadge, Empty, HelperText, Status } from './styles';

export const StatusBar: React.FunctionComponent<StatusBarProps> = ({
  applicationStatus,
  applicationStage,
}) => {
  const statusBadge = STATUS_BADGE[applicationStatus];
  const stageNumber = STAGE_MAPPING[applicationStage];
  const isExcludedStatus = ![ApplicationStatus.Deceased, ApplicationStatus.Withdrawn].some(
    (status) => status === applicationStatus
  );

  const getDefinition = () => {
    if (applicationStatus === ApplicationStatus.Cold) {
      return DEFAULT_COLD_HELPER_TEXT;
    }

    const definition = DEFINITION.find(
      (def) => def.stage === applicationStage && def.status === applicationStatus
    );

    return definition?.helperText;
  };

  return (
    <React.Fragment>
      <Status>
        {isExcludedStatus && (
          <Tracker
            title={applicationStage}
            number={stageNumber}
            full={applicationStage === 'PLACED'}
          />
        )}
        {statusBadge ? (
          <ApplicationBadge
            label={statusBadge.statusName}
            variant={statusBadge.variant}
            padding={isExcludedStatus}
          />
        ) : (
          <Empty />
        )}
      </Status>
      <HelperText size="small">{getDefinition()}</HelperText>
    </React.Fragment>
  );
};
