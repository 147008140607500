import React from 'react';
import { BodyText, Col } from '@policygenius/mortar';
import { Detail, Title, DetailTitle, Grid, Background } from './styles';
import { Props } from './types';

const About: React.FunctionComponent<Props> = ({ leftContent, rightContent }) => {
  return (
    <Background>
      <Grid>
        <Col span={{ small: 4, medium: 12 }}>
          <Title size="xxlarge">About Policygenius</Title>
        </Col>
        <Col span={{ small: 4, medium: 6 }}>
          <Detail bottom>
            <DetailTitle size="large">{leftContent.title}</DetailTitle>
            <BodyText size="large">{leftContent.body}</BodyText>
          </Detail>
        </Col>
        <Col span={{ small: 4, medium: 6 }}>
          <Detail>
            <DetailTitle size="large">{rightContent.title}</DetailTitle>
            <BodyText size="large">{rightContent.body}</BodyText>
          </Detail>
        </Col>
      </Grid>
    </Background>
  );
};

export default About;
