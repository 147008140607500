import styled from 'styled-components';
import { Link, theme, TitleText, Row, Col } from '@policygenius/mortar';

export const Background = styled.div`
  background: ${theme.colors.white};
  border-top: 1px solid ${theme.colors.gray200};
`;

export const FooterContainer = styled(Row)`
  padding-top: 40px;
  padding-bottom: 72px;
  margin: auto;
`;

export const FooterRow = styled.div`
  padding-bottom: 12px;

  @media (min-width: ${theme.breakpoints.large}px) {
    padding-bottom: 0;
  }
`;

export const FooterCol = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${theme.breakpoints.medium}px) {
    flex-direction: row;
  }
`;

export const LegalContainer = styled.div`
  padding-top: 40px;
`;

export const Security = styled(TitleText)`
  margin-bottom: 8px;
`;

export const MoreInfoLinks = styled(Link)`
  margin-right: 12px;
  text-decoration: underline;
  color: ${theme.colors.gray500};
`;

export const LogoCol = styled(Col)`
  margin-bottom: 65px;

  svg {
    height: 25px;
  }
`;
