import { theme } from '@policygenius/mortar';
import styled from 'styled-components';

export const Card = styled.div`
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.gray200};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0px;
  gap: 10px;
  border-radius: 4px;
  width: 100%;
  margin: 0 16px 24px 16px;

  &:hover {
    cursor: pointer;
    background: ${theme.colors.orange100};
    border: 1px solid ${theme.colors.orange400};
  }
  @media (min-width: ${theme.breakpoints.medium}px) {
    margin: 0 0 24px 0;
  }
`;
