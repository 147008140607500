import {
  DataPointIdMappingInput,
  DataValueTypes,
  RepeatableDataPointIdInput,
  RepeatableValuesInput,
  DataType,
} from 'graph/types';

const noopFormatter = (element: any) => element;

const dpidTypeToGraphInfo = {
  TEXT: {
    dataType: 'STRING_VALUE',
    dataKey: 'stringValue',
    formatter: noopFormatter,
  },
  DATE: {
    dataType: 'DATE_VALUE',
    dataKey: 'dateValue',
    formatter: (dateStr: string) => {
      // They come in as UTC iso strings e.g. "1999-01-01T00:00:00.000Z"
      const date = new Date(dateStr);

      return {
        year: date.getUTCFullYear(),
        month: date.getUTCMonth() + 1, // Jan is month 0
        day: date.getUTCDate(),
      };
    },
  },
  TIMESTAMP: { dataType: 'TODO', dataKey: 'TODO', formatter: noopFormatter },
  BOOLEAN: {
    dataType: 'BOOL_VALUE',
    dataKey: 'boolValue',
    formatter: noopFormatter,
  },
  INTEGER: {
    dataType: 'INT_VALUE',
    dataKey: 'intValue',
    formatter: noopFormatter,
  },
  DECIMAL: {
    dataType: 'FLOAT_VALUE',
    dataKey: 'floatValue',
    formatter: noopFormatter,
  },
  /* eslint-disable-next-line camelcase */
  TEXT_LIST: {
    dataType: 'STRING_LIST_VALUE',
    dataKey: 'stringListValue',
    formatter: noopFormatter,
  },
  /* eslint-disable-next-line camelcase */
  INTEGER_LIST: {
    dataType: 'INT_LIST_VALUE',
    dataKey: 'intListValue',
    formatter: noopFormatter,
  },
};

export const formatOutgoingDpidData = (
  submitData: { [key: string]: any },
  urnToTypeMap: { [key: string]: string }
) => {
  const mappedData: DataPointIdMappingInput[] = [];
  const repeatableData: RepeatableDataPointIdInput[] = [];

  for (const [urn, value] of Object.entries(submitData)) {
    // undefined means we have never touched this piece of data, skip it
    if (value === undefined) continue;

    // These are the "types" as defined by the interview-service
    const dpidType: string = urnToTypeMap[urn];

    if (dpidType === DataType.Composite) {
      repeatableData.push(formatOutgoingRepeatableDpidData(urn, urnToTypeMap, value));
      continue;
    }

    const graphDataInfo = dpidTypeToGraphInfo[dpidType as keyof typeof dpidTypeToGraphInfo];

    const element: DataPointIdMappingInput = {
      dataPointIdUrn: urn,
      dataType: graphDataInfo.dataType as DataValueTypes,
      [graphDataInfo.dataKey]: graphDataInfo.formatter(value),
    };

    mappedData.push(element);
  }

  return {
    dpids: mappedData,
    repeatableDpids: repeatableData,
  };
};

export const formatOutgoingRepeatableDpidData = (
  urn: string,
  urnToTypeMap: { [key: string]: string },
  repeatableData: { [key: string]: any }[]
): RepeatableDataPointIdInput => {
  // Construct a short urn => type map so we can lookup types for
  // our repeated values
  const shortUrnTypeMap: { [key: string]: string } = {};

  Object.entries(urnToTypeMap).forEach(([fullUrn, value]) => {
    if (fullUrn.startsWith(urn) && fullUrn !== urn) {
      const shortUrn = fullUrn.substring(fullUrn.lastIndexOf(':') + 1);

      shortUrnTypeMap[shortUrn] = value;
    }
  });

  // Iterate through the repeated data, building out the graph request
  const graphValues: RepeatableValuesInput[] = [];

  repeatableData.forEach((data) => {
    const valueMap: DataPointIdMappingInput[] = [];

    Object.entries(data.values)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([_, val]) => val !== undefined)
      .forEach(([shortUrn, val]: [string, any]) => {
        const type = shortUrnTypeMap[shortUrn];
        const graphDataInfo = dpidTypeToGraphInfo[type as keyof typeof dpidTypeToGraphInfo];

        let element: DataPointIdMappingInput;

        if (shortUrn === 'composite-id') {
          element = {
            dataPointIdUrn: shortUrn,
            dataType: DataValueTypes.StringValue,
            stringValue: val,
          };
        } else {
          element = {
            dataPointIdUrn: shortUrn,
            dataType: graphDataInfo.dataType as DataValueTypes,
            [graphDataInfo.dataKey]: graphDataInfo.formatter(val),
          };
        }

        valueMap.push(element);
      });
    graphValues.push({ operation: data.operation, valueMap });
  });

  return {
    dataPointIdUrn: urn,
    values: graphValues,
  };
};
