import styled from 'styled-components';
import { theme, UtilityText } from '@policygenius/mortar';

export const Tab = styled.div`
  padding: 24px;
`;
export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
`;

export const Heading = styled(UtilityText)`
  width: 140px;
`;

export const Empty = styled.div`
  width: 20px;
  height: 1px;
  background: ${theme.colors.gray300};
`;
