/* eslint-disable react/jsx-max-depth */
import {
  Col,
  LabelText,
  LoadingIndicator,
  TitleText,
  BrandIconLife,
  UtilityText,
  BrandIconDisability,
  BrandIconMore,
  BrandIconHomeAndAuto,
} from '@policygenius/mortar';
import React, { useState } from 'react';
import Button from 'components/Button';
import {
  CLIENT_INFO_ROUTE,
  DROP_TICKET_ROUTE,
  HELP_CENTER_ROUTE,
  REPORTS_ROUTE,
} from 'routes/constants';
import {
  CardInfo,
  Container,
  Background,
  PageRow,
  ReferralCard,
  HeaderRow,
  ContentRow,
  HeaderTitle,
  Header,
  Card,
  ReferralButtonContainer,
  PreviewPageLink,
  TitleCaseText,
} from './styles';
import { Events, track } from 'utils/analytics';
import { useNavigate } from 'react-router-dom';
import { useCurrentUserQuery } from 'query/currentUser/query';
import { Role } from 'graph/types';
import { useDecision } from '@optimizely/react-sdk';
import { Duplicate } from '../ForkPage/duplicate';
import { encodeReferralUrl } from 'utils/helpers';
import { HOME_AND_AUTO_FUNNEL, OPERATIONS_DISABILITY } from 'utils/optimizelyConstants';

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const { data, loading, error } = useCurrentUserQuery();
  const [copyButtonText, setCopyButtonText] = useState('Copy link');

  if (error) {
    console.log(error);
  }

  const isProducer = data?.currentUser.roles.includes(Role.Producer);
  const isAssistant = data?.currentUser.roles.includes(Role.Assistant);

  const [disabilityInPortal, optimizelyClientReady] = useDecision(
    OPERATIONS_DISABILITY,
    {},
    {
      overrideUserId: 'agent-portal',
      overrideAttributes: {
        organization: data?.currentUser.agencySlug || '',
        organizationType: data?.currentUser.agencyType || '',
      },
    }
  );
  const disabilityInPortalActive =
    disabilityInPortal.enabled && optimizelyClientReady && isProducer && !isAssistant;

  const disabilityLink = `https://s.alchemer.com/s3/12c083e60736?email=${data?.currentUser.email}&firstName=${data?.currentUser.firstName}&lastName=${data?.currentUser.lastName}&agency=${data?.currentUser.agencySlug}`;

  const [homeAndAutoInPortal] = useDecision(
    HOME_AND_AUTO_FUNNEL,
    {},
    {
      overrideUserId: 'agent-portal',
      overrideAttributes: {
        organization: data?.currentUser.agencySlug || '',
        organizationType: data?.currentUser.agencyType || '',
      },
    }
  );

  const homeAndAutoInPortalActive =
    homeAndAutoInPortal.enabled && optimizelyClientReady && isProducer;

  const homeAndAutoInPortalLink = 'https://forms.gle/wtr5UbJ5v7mKZb329';

  const referralLink = encodeReferralUrl({
    slug: data?.currentUser.agencySlug,
    first: data?.currentUser.firstName,
    last: data?.currentUser.lastName,
    uuid: data?.currentUser.uuid,
  });

  return (
    <Container>
      {loading ? (
        <LoadingIndicator color={'black'} />
      ) : (
        <React.Fragment>
          <Header>
            <HeaderRow>
              <Col span={{ small: 3, medium: 5 }} push={{ medium: 1, large: 0 }}>
                <TitleText size="xxlarge" color="white">
                  Welcome, <TitleCaseText>{data?.currentUser.firstName}</TitleCaseText>!
                </TitleText>
              </Col>
            </HeaderRow>
          </Header>
          <Background>
            <PageRow>
              <Col
                span={{
                  medium: 10,
                  large: 6,
                }}
                push={{ medium: 1, large: 0 }}
              >
                <ContentRow>
                  <Col>
                    <HeaderTitle>
                      <BrandIconLife size="xsmall" />
                      <LabelText size="medium"> Life Insurance</LabelText>
                    </HeaderTitle>
                  </Col>
                  {(isProducer || isAssistant) && (
                    <>
                      <Col span={{ medium: 6 }}>
                        <Card
                          analyticsText="Submit a drop ticket"
                          showNavigationIcon
                          onClick={() => {
                            navigate(DROP_TICKET_ROUTE);
                          }}
                          id="life-drop-ticket"
                        >
                          <CardInfo>
                            <TitleText size="small" color="black">
                              Submit a drop ticket
                            </TitleText>
                            <UtilityText size="medium">
                              Provide basic client info to make a quick referral.
                            </UtilityText>
                          </CardInfo>
                        </Card>
                      </Col>
                      <Col span={{ medium: 6 }}>
                        <Card
                          analyticsText="Get quotes"
                          showNavigationIcon
                          onClick={() => {
                            track(Events.CLICKED_TILE, {
                              analyticsText: 'Get quotes',
                            });
                            navigate(CLIENT_INFO_ROUTE);
                          }}
                        >
                          <CardInfo>
                            <TitleText size="small" color="black">
                              Get quotes
                            </TitleText>
                            <UtilityText size="medium">
                              Compare policy options before making a referral.
                            </UtilityText>
                          </CardInfo>
                        </Card>
                      </Col>
                      {isProducer && (
                        <Col>
                          <ReferralCard showNavigationIcon={false}>
                            <CardInfo>
                              <TitleText size="small" color="black">
                                Share your unique referral link
                              </TitleText>
                              <UtilityText size="medium">
                                Clients can apply for Life insurance with Policygenius directly from
                                your co-branded landing page.
                              </UtilityText>
                              <ReferralButtonContainer>
                                <PreviewPageLink
                                  href={referralLink}
                                  target="_blank"
                                  rel="no opener noreferrer"
                                >
                                  <Button analyticsText="Preview my page" variant="filled">
                                    Preview my page
                                  </Button>
                                </PreviewPageLink>
                                <Button
                                  analyticsText="copied referral link"
                                  variant="filled"
                                  onClick={() => {
                                    navigator.clipboard.writeText(referralLink);
                                    setCopyButtonText('Copied!');
                                    setTimeout(() => setCopyButtonText('Copy link'), 1000);
                                  }}
                                >
                                  <span>{copyButtonText}</span>
                                  <Duplicate />
                                </Button>
                              </ReferralButtonContainer>
                            </CardInfo>
                          </ReferralCard>
                        </Col>
                      )}
                    </>
                  )}
                  <Col>
                    <Card
                      showNavigationIcon
                      last
                      analyticsText="Check the status of a case"
                      onClick={() => {
                        navigate(REPORTS_ROUTE);
                      }}
                    >
                      <CardInfo>
                        <TitleText size="small" color="black">
                          Check the status of a case
                        </TitleText>
                      </CardInfo>
                    </Card>
                  </Col>
                  {homeAndAutoInPortalActive && (
                    <>
                      <Col>
                        <HeaderTitle>
                          <BrandIconHomeAndAuto size="xsmall" />
                          <LabelText size="medium">Home & Auto insurance</LabelText>
                        </HeaderTitle>
                      </Col>
                      <Col>
                        <Card
                          id="home-auto-drop-ticket"
                          showNavigationIcon
                          last
                          analyticsText="Home & Auto alchemer drop ticket"
                          onClick={() => {
                            window.open(homeAndAutoInPortalLink);
                          }}
                        >
                          <CardInfo>
                            <TitleText size="small" color="black">
                              Submit a drop ticket
                            </TitleText>
                          </CardInfo>
                        </Card>
                      </Col>
                    </>
                  )}
                  {disabilityInPortalActive && (
                    <>
                      <Col>
                        <HeaderTitle>
                          <BrandIconDisability size="xsmall" />
                          <LabelText size="medium">Disability Insurance</LabelText>
                        </HeaderTitle>
                      </Col>
                      <Col>
                        <Card
                          id="disability-drop-ticket"
                          showNavigationIcon
                          last
                          analyticsText="Disability alchemer drop ticket"
                          onClick={() => {
                            window.open(disabilityLink);
                          }}
                        >
                          <CardInfo>
                            <TitleText size="small" color="black">
                              Submit a drop ticket
                            </TitleText>
                          </CardInfo>
                        </Card>
                      </Col>
                    </>
                  )}
                </ContentRow>
              </Col>
              <Col span={{ large: 1 }}>
                <></>
              </Col>
              <Col span={{ large: 5, medium: 10 }} push={{ medium: 1, large: 0 }}>
                <ContentRow>
                  <Col>
                    <HeaderTitle>
                      <BrandIconMore size="xsmall" />
                      <LabelText size="medium">Resources</LabelText>
                    </HeaderTitle>
                  </Col>
                  <Col>
                    <Card
                      showNavigationIcon
                      analyticsText="clicked hubspot chat"
                      onClick={() => {
                        window.HubSpotConversations?.widget.load();
                        window.HubSpotConversations?.widget.open();
                      }}
                    >
                      <CardInfo>
                        <TitleText size="small" color="black">
                          Get in touch
                        </TitleText>
                        <UtilityText size="medium">
                          Chat live with our in-house support team.
                        </UtilityText>
                      </CardInfo>
                    </Card>
                  </Col>
                  <Col>
                    <Card
                      showNavigationIcon
                      analyticsText="Visit the Help Center"
                      onClick={() => {
                        navigate(HELP_CENTER_ROUTE);
                      }}
                    >
                      <CardInfo>
                        <TitleText size="small" color="black">
                          Visit the Help Center
                        </TitleText>
                        <UtilityText size="medium">
                          Learn more about the Policygenius process through step-by-step guides,
                          demo videos, and more!
                        </UtilityText>
                      </CardInfo>
                    </Card>
                  </Col>
                </ContentRow>
              </Col>
            </PageRow>
          </Background>
        </React.Fragment>
      )}
    </Container>
  );
};

export default Dashboard;
