/* THIS FILE WAS AUTO-GENERATED. DO NOT MODIFY */
/* eslint-disable */
import * as Types from '../../graph/types';

import { gql } from '@apollo/client';
import { NodeFieldsFragmentDoc } from './NodeFields';
export type ChildrenFieldsFragment = { __typename?: 'Node', urn: string, nodeType: string, questionType: string, content: string, helpText?: string | null, rules?: string | null, children?: Array<{ __typename?: 'Node', urn: string, nodeType: string, questionType: string, content: string, helpText?: string | null, rules?: string | null, children?: Array<{ __typename?: 'Node', urn: string, nodeType: string, questionType: string, content: string, helpText?: string | null, rules?: string | null, dataPoint?: { __typename?: 'DataPointID', urn: string, dataType: Types.DataType, subtype: Types.Subtype, repeatable?: boolean | null, compositeStructure?: Array<{ __typename?: 'CompositeStructure', key: string, value?: { __typename?: 'Composite', dataType?: Types.DataType | null, sensitive?: boolean | null } | null } | null> | null } | null, choices?: Array<{ __typename?: 'Choice', valueType?: Types.ValueType | null, valueInt: number, valueText: string, displayText: string }> | null }> | null, dataPoint?: { __typename?: 'DataPointID', urn: string, dataType: Types.DataType, subtype: Types.Subtype, repeatable?: boolean | null, compositeStructure?: Array<{ __typename?: 'CompositeStructure', key: string, value?: { __typename?: 'Composite', dataType?: Types.DataType | null, sensitive?: boolean | null } | null } | null> | null } | null, choices?: Array<{ __typename?: 'Choice', valueType?: Types.ValueType | null, valueInt: number, valueText: string, displayText: string }> | null }> | null, dataPoint?: { __typename?: 'DataPointID', urn: string, dataType: Types.DataType, subtype: Types.Subtype, repeatable?: boolean | null, compositeStructure?: Array<{ __typename?: 'CompositeStructure', key: string, value?: { __typename?: 'Composite', dataType?: Types.DataType | null, sensitive?: boolean | null } | null } | null> | null } | null, choices?: Array<{ __typename?: 'Choice', valueType?: Types.ValueType | null, valueInt: number, valueText: string, displayText: string }> | null };

export const ChildrenFieldsFragmentDoc = gql`
    fragment ChildrenFields on Node {
  ...NodeFields
  children {
    ...NodeFields
    children {
      ...NodeFields
    }
  }
}
    ${NodeFieldsFragmentDoc}`;