import { css } from 'styled-components';

export const Gradient = css`
  background: radial-gradient(
        20.17% 53.85% at 38.61% 85.19%,
        rgba(189, 189, 189, 0.2) 17.19%,
        rgba(189, 189, 189, 0) 95.83%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    linear-gradient(295.18deg, rgba(0, 0, 0, 0.126) 16.72%, rgba(0, 0, 0, 0) 57.98%),
    linear-gradient(257.68deg, rgba(0, 84, 115, 0.28) 29.17%, rgba(56, 56, 56, 0.11) 46.69%),
    linear-gradient(125.41deg, rgba(112, 112, 112, 0.2) 19.21%, rgba(112, 112, 112, 0.066) 43.11%),
    radial-gradient(
        51.42% 99.35% at 81.11% 25.1%,
        rgba(0, 50, 69, 0.2) 2.95%,
        rgba(112, 112, 112, 0.126) 46.14%,
        rgba(56, 56, 56, 0.2) 85.67%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    linear-gradient(71.4deg, rgba(0, 0, 0, 0.2) 36.82%, rgba(112, 112, 112, 0.2) 64.43%),
    radial-gradient(26.08% 97.1% at 63.19% 61.44%, #013f3a 20.68%, #000000 94.06%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
`;
