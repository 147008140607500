// value is last day + 1 because the function that checks the range, doesn't include the upper limit
export const LAST_DATE_MONTH = {
  1: 32,
  2: 30,
  3: 32,
  4: 31,
  5: 32,
  6: 31,
  7: 32,
  8: 32,
  9: 31,
  10: 32,
  11: 31,
  12: 32,
};
