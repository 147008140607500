import { theme } from '@policygenius/mortar';
import styled from 'styled-components';
import { TitleBlockProps } from './types';

export const Container = styled.div`
  border: solid;
  border-width: 1px;
  border-color: ${theme.colors.gray200};
  border-radius: 8px;
  padding: 12px 16px 0px 12px;
  margin-top: 8px;
`;

export const TitleBlock = styled.div<TitleBlockProps>((props) =>
  props.showDividerLine
    ? {
        borderBottom: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.gray200,
        borderBottomRadius: 8,
        paddingBottom: 16,
      }
    : {}
);

export const Subheader = styled.div`
  display: flex;
`;
