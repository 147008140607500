interface InterviewPage {
  pageNumber: number;
  urn: string;
  tableOfContentsHeader: string;
}

export const interviews: { [key: string]: InterviewPage[] } = {
  transamerica: [
    {
      pageNumber: 1,
      urn: 'urn:pg:interview:69573449-ba09-48c7-bd84-8e2da904552c',
      tableOfContentsHeader: 'eApplication',
    },
    {
      pageNumber: 2,
      urn: 'urn:pg:interview:9589c641-c355-43c2-9af6-f87bb0a9593d',
      tableOfContentsHeader: 'Medical Interview',
    },
  ],
};
