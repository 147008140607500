import React, { useState, useEffect } from 'react';
import {
  ActionFilter,
  Col,
  Pagination,
  UtilityText,
  LoadingIndicator,
  ExpandMore,
} from '@policygenius/mortar';
import { usePaginatedReportsQuery } from 'query/statusReportsPagination/query';
import { OrderBy, Role, TierType } from 'graph/types';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CaseDetailPanelCol,
  TableColumn,
  ContainerCol,
  TableContainer,
  HeaderContainer,
  ContainerRow,
  FeedbackContainer,
  FeedbackContent,
  PaginationText,
  UtilityLink,
  CaseCount,
  MobileSortButton,
  MobileFilterButton,
  MobileResetButton,
} from './styles';
import { useCurrentUserQuery } from 'query/currentUser/query';
import { FILTER_ROUTE, REPORTS_ROUTE } from 'routes/constants';
import { SORT_MAP, SortDirection, SortType } from './constants';
import { useReportContext, DEFAULT_SORTING, DEFAULT_PAGE_NUMBER } from 'context/ReportContext';
import { useGenerateReportMutation } from 'mutation/generateReport/mutation';
import { useToastContext } from 'context/ToastContext';
import { Events, track } from 'utils/analytics';
import StatusTrackerTable from 'components/StatusTrackerTable';
import CaseDetailPanel from 'components/CaseDetailPanel';
import { useNavContext } from 'context/NavContext';
import { LARGE_DESKTOP_WIDTH } from 'utils/helpers';
import { useUserContext } from 'context/UserContext';
import ReportsHeader from 'components/ReportsHeader';
import { DesktopStatusFilter } from './DesktopStatusFilter';
import snakeCase from 'lodash/snakeCase';
import { STATUS_CONTAINER_ID } from 'pages/Filter';

const DEFAULT_PAGE_SIZE = 25;

const Reports: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    sortOrder,
    setSortOrder,
    filter,
    setFilter,
    pageTokens,
    setPageTokens,
    pageNumber,
    setPageNumber,
  } = useReportContext();
  const { isOpen } = useNavContext();
  const { show, hide } = useToastContext();
  const [totalSize, setTotalSize] = useState(0);
  const [isReportsError, setReportsError] = useState(false);
  const { setUserIsAdmin } = useUserContext();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [pageNumber]);

  const formatFilter = () => {
    let queryFilter = '';

    filter.forEach((option, index) => {
      const key = Object.keys(option)[0];

      if (index === 0) {
        queryFilter += `${snakeCase(key)} = ${option[key]}`;
      } else {
        queryFilter += ` OR ${snakeCase(key)} = ${option[key]}`;
      }
    });

    return queryFilter;
  };

  const resetTokenAndPage = () => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageTokens(['']);
  };
  const { data, loading: loadingReports } = usePaginatedReportsQuery({
    variables: {
      filter: formatFilter(),
      orderBy: sortOrder,
      pageToken: pageTokens[pageNumber - 1],
      pageSize: DEFAULT_PAGE_SIZE,
    },
    onCompleted: (reports) => {
      setTotalSize(reports?.paginatedReports.totalSize || totalSize);
    },
    onError: (error) => {
      setReportsError(!!error.graphQLErrors.length);
      console.error(error);
    },
  });

  const next = () => {
    track(Events.CLICKED_BUTTON, {
      analyticsText: 'NextPage',
    });

    if (data) {
      setPageTokens([...pageTokens, data.paginatedReports.nextPageToken]);
      setPageNumber(pageNumber + 1);
    }
  };

  const back = () => {
    track(Events.CLICKED_BUTTON, {
      analyticsText: 'PrevPage',
    });

    setPageTokens(pageTokens.slice(0, -1));
    setPageNumber(pageNumber - 1);
  };

  const helperSort = (value: OrderBy) => {
    track(Events.SELECTED_OPTION, {
      option: value,
    });

    setSortOrder(value as OrderBy);
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageTokens(['']);
  };

  const flipSort = (type: SortType) => {
    const sortValues = SORT_MAP.filter((sortOption) => sortOption.sortType === type);

    const foundSort = sortValues.find((currentSort) => {
      return currentSort.id === sortOrder;
    });

    if (!foundSort) {
      const ascendingSort = sortValues.find((a) => a.orderDirection === SortDirection.Ascending);

      helperSort(ascendingSort?.id as OrderBy);

      return;
    }

    const flippedSortValue = sortValues.find((currentSort) => currentSort.id !== sortOrder);

    helperSort(flippedSortValue?.id as OrderBy);
  };

  const { data: userData, loading: userLoading, error } = useCurrentUserQuery();

  if (error) {
    console.error(error);
  }

  const producer = userData?.currentUser.roles.includes(Role.Producer);
  const admin = userData?.currentUser.roles.includes(Role.Admin);
  const assistant = userData?.currentUser.roles.includes(Role.Assistant);
  const pageOffset = (pageNumber - 1) * DEFAULT_PAGE_SIZE;

  setUserIsAdmin(admin || false);

  const [generateReport, { loading }] = useGenerateReportMutation({
    onError: () => {
      show({
        type: 'error',
        title: 'Report failed to generate',
        body: 'Please wait a few minutes and try again.',
      });
      setTimeout(hide, 4000);
    },
    onCompleted: () => {
      show({
        type: 'success',
        title: 'Preparing csv for delivery',
        body: 'A csv file of this entire case report is being sent to your email. Depending on the number of cases, this could take several minutes.',
      });
      setTimeout(hide, 4000);
    },
  });

  const emailCsvOnClick = () => {
    track(Events.CLICKED_BUTTON, {
      analyticsText: 'Email CSV',
    });

    generateReport();
  };

  const hideDetailPanel = () => {
    navigate(`${REPORTS_ROUTE}`);
  };

  const totalPageCount = (): number =>
    data?.paginatedReports.nextPageToken === '' ? totalSize : pageNumber * DEFAULT_PAGE_SIZE;

  const renderDetailPanel = !(isOpen && window.innerWidth < LARGE_DESKTOP_WIDTH);

  const canReset = filter.length > 0 || sortOrder !== DEFAULT_SORTING;

  const resetOnClick = () => {
    setSortOrder(DEFAULT_SORTING);
    setFilter([]);
    resetTokenAndPage();
  };

  return (
    <React.Fragment>
      <ContainerRow fullWidth>
        <ContainerCol
          span={{
            medium: 12,
            small: 4,
            large: id && renderDetailPanel ? 8 : 12,
          }}
        >
          <HeaderContainer>
            <ReportsHeader
              loading={loading}
              producer={producer}
              assistant={assistant}
              emailCsvOnClick={emailCsvOnClick}
            />
            <>
              {loadingReports ? (
                <div>
                  <LoadingIndicator color="gray500" />
                </div>
              ) : (
                <CaseCount size="xlarge">{totalSize} cases</CaseCount>
              )}
              <MobileSortButton onClick={() => navigate(FILTER_ROUTE)} variant="filled">
                <span>Sort</span>
                <ExpandMore size="small" />
              </MobileSortButton>
              <MobileFilterButton
                onClick={() => navigate(`${FILTER_ROUTE}#${STATUS_CONTAINER_ID}`)}
                variant="filled"
              >
                <span>Filter {filter.length > 0 && <span>({filter.length})</span>}</span>
                <ActionFilter size="small" />
              </MobileFilterButton>
              {canReset && (
                <MobileResetButton variant="quaternary" onClick={resetOnClick}>
                  Reset all
                </MobileResetButton>
              )}
              <DesktopStatusFilter reset={() => resetTokenAndPage()} />
            </>
          </HeaderContainer>
          <TableContainer selectedReport={!!id}>
            <TableColumn hasData={!!data?.paginatedReports.reports?.length}>
              <StatusTrackerTable
                data={data?.paginatedReports.reports}
                loading={loadingReports}
                indexOffset={pageOffset + 1}
                isMultiTierAdmin={userData?.currentUser.tierType === TierType.MultiTier && !!admin}
                hasProducerRole={producer}
                isReportsError={isReportsError}
                flipSort={flipSort}
              />
            </TableColumn>
          </TableContainer>
        </ContainerCol>
        {renderDetailPanel && !!id && (
          <CaseDetailPanelCol
            span={{
              large: 4,
            }}
          >
            <CaseDetailPanel closeHandler={hideDetailPanel} id={id} />
          </CaseDetailPanelCol>
        )}
        <Col span={{ large: id && renderDetailPanel ? 8 : 12 }}>
          {!userLoading && (
            <FeedbackContainer>
              <FeedbackContent>
                {data?.paginatedReports.totalSize !== 0 && (
                  <PaginationText size="small">
                    {pageOffset + 1}-{totalPageCount()} of {totalSize}
                  </PaginationText>
                )}
                <UtilityText size="small">Thoughts on case tracking?</UtilityText>
                <UtilityLink
                  forwardedAs="a"
                  href={`https://survey.alchemer.com/s3/6828497/PgPro-Case-Reporting-Feedback?name=${userData?.currentUser.firstName}%20${userData?.currentUser.lastName}&email=${userData?.currentUser.email}&agency=${userData?.currentUser.agencySlug}`}
                  target="_blank"
                  size="small"
                >
                  Share feedback
                </UtilityLink>
              </FeedbackContent>
              {data?.paginatedReports.totalSize !== 0 && (
                <Pagination
                  currentPage={pageNumber}
                  totalPages={Math.ceil(totalSize / DEFAULT_PAGE_SIZE)}
                  onNext={next}
                  onBack={back}
                />
              )}
            </FeedbackContainer>
          )}
        </Col>
      </ContainerRow>
    </React.Fragment>
  );
};

export default Reports;
