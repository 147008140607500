import styled, { keyframes } from 'styled-components';
import { theme } from '@policygenius/mortar';

const slideRight = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  background-color: ${theme.colors.white};
  animation: 0.2s ease-in ${slideRight};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  @media (min-width: ${theme.breakpoints.large}px) {
    animation: none;
    position: relative;
    top: initial;
    border-radius: ${theme.spacing('small')};
  }
`;
