/* THIS FILE WAS AUTO-GENERATED. DO NOT MODIFY */
/* eslint-disable */
import * as Types from '../../graph/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MedicalInfoQueryVariables = Types.Exact<{
  refID: Types.Scalars['String']['input'];
}>;


export type MedicalInfoQuery = { __typename?: 'Query', medicalInfo: { __typename?: 'MedicalInfo', examDate?: string | null } };


export const MedicalInfoDocument = gql`
    query medicalInfo($refID: String!) {
  medicalInfo(input: $refID) {
    examDate
  }
}
    `;

/**
 * __useMedicalInfoQuery__
 *
 * To run a query within a React component, call `useMedicalInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedicalInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicalInfoQuery({
 *   variables: {
 *      refID: // value for 'refID'
 *   },
 * });
 */
export function useMedicalInfoQuery(baseOptions: Apollo.QueryHookOptions<MedicalInfoQuery, MedicalInfoQueryVariables> & ({ variables: MedicalInfoQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MedicalInfoQuery, MedicalInfoQueryVariables>(MedicalInfoDocument, options);
      }
export function useMedicalInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MedicalInfoQuery, MedicalInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MedicalInfoQuery, MedicalInfoQueryVariables>(MedicalInfoDocument, options);
        }
export function useMedicalInfoSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MedicalInfoQuery, MedicalInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MedicalInfoQuery, MedicalInfoQueryVariables>(MedicalInfoDocument, options);
        }
export type MedicalInfoQueryHookResult = ReturnType<typeof useMedicalInfoQuery>;
export type MedicalInfoLazyQueryHookResult = ReturnType<typeof useMedicalInfoLazyQuery>;
export type MedicalInfoSuspenseQueryHookResult = ReturnType<typeof useMedicalInfoSuspenseQuery>;
export type MedicalInfoQueryResult = Apollo.QueryResult<MedicalInfoQuery, MedicalInfoQueryVariables>;