import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { STATUS_CONTAINER_ID } from 'pages/Filter';

const ScrollToTop: React.FC = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash === `#${STATUS_CONTAINER_ID}`) {
      const statusContainer = document.getElementById(STATUS_CONTAINER_ID);

      statusContainer?.scrollIntoView();
    } else {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  return <>{children}</>;
};

export default ScrollToTop;
