import {
  ApplicationStage,
  ApplicationStatus,
  IssueType,
  ProductType,
  CreationMethod,
} from 'graph/types';
import { Tooltips } from './types';

export const DEFINITION = [
  {
    stage: ApplicationStage.Referral,
    status: ApplicationStatus.Pending,
    helperText:
      "Policygenius is awaiting the client's permission to contact them via phone as laid out by the Telephone Consumer Protection Act (TCPA).",
  },
  {
    stage: ApplicationStage.Quotes,
    status: ApplicationStatus.Pending,
    helperText:
      'Policygenius agents are working to get in touch with your client to discuss their personal history and needs, review quotes, and help them select the best policy for their situation.',
  },
  {
    stage: ApplicationStage.Quotes,
    status: ApplicationStatus.PhoneDecline,
    helperText:
      "Unfortunately, based upon your client's risk profile, we do not currently have any policy options available.",
  },
  {
    stage: ApplicationStage.Application,
    status: ApplicationStatus.Pending,
    helperText:
      'Your client has application documents to sign and may also require a medical exam or online interview to submit their application for coverage.',
  },
  {
    stage: ApplicationStage.Underwriting,
    status: ApplicationStatus.Pending,
    helperText:
      "The carrier underwriter is evaluating your client's application, personal, financial, and health details, and advising Policygenius of any additional requirements to provide an offer of coverage.",
  },
  {
    stage: ApplicationStage.Underwriting,
    status: ApplicationStatus.Withdrawn,
    helperText:
      'Your client has actively requested their application be withdrawn or has become non-responsive to our follow ups.',
  },
  {
    stage: ApplicationStage.Decision,
    status: ApplicationStatus.Approved,
    helperText:
      "Your client's policy has been approved! We will review the offer with your client and discuss next steps. If the decision was modified, we'll be sure to present any other coverage options your client should consider.",
  },
  {
    stage: ApplicationStage.Decision,
    status: ApplicationStatus.Postponed,
    helperText:
      'The carrier underwriter has postponed an offer of coverage. Your client will be provided the postponement details, steps for reconsideration, or other current coverage options.',
  },
  {
    stage: ApplicationStage.Decision,
    status: ApplicationStatus.Declined,
    helperText:
      'Your client has been declined for coverage by the carrier and we will inform your client if other options are available.',
  },
  {
    stage: ApplicationStage.Decision,
    status: ApplicationStatus.NotTaken,
    helperText: "Your client has rejected the carrier's offer for coverage.",
  },
  {
    stage: ApplicationStage.Issuance,
    status: ApplicationStatus.Pending,
    helperText:
      'Final policy documents have been released to your client for signature and payment.',
  },
  {
    stage: ApplicationStage.Placement,
    status: ApplicationStatus.Pending,
    helperText:
      'Your client has signed and submitted their final policy documents. The carrier is reviewing these before placing coverage in force. ',
  },
  {
    stage: ApplicationStage.Placement,
    status: ApplicationStatus.Lapsed,
    helperText:
      'Your client has missed their premium payment and coverage is no longer active. Policygenius or the carrier can be contacted to discuss options for reinstatement!',
  },
  {
    stage: ApplicationStage.Placed,
    status: ApplicationStatus.InForce,
    helperText: "Your client's policy has been placed in force and their coverage is active!",
  },
  {
    stage: ApplicationStage.Placed,
    status: ApplicationStatus.Surrendered,
    helperText: 'Your client has surrendered coverage and cancelled their policy.',
  },
  {
    stage: ApplicationStage.NoStage,
    status: ApplicationStatus.Withdrawn,
    helperText:
      'Your client has actively requested their application be withdrawn or has become non-responsive to our follow ups.',
  },
  {
    stage: ApplicationStage.Decision,
    status: ApplicationStatus.NotTaken,
    helperText: "Your client has rejected the carrier's offer for coverage.",
  },
  {
    stage: ApplicationStage.Quotes,
    status: ApplicationStatus.Withdrawn,
    helperText: 'Your client has actively requested their application be withdrawn.',
  },
  {
    stage: ApplicationStage.Application,
    status: ApplicationStatus.Withdrawn,
    helperText: 'Your client has actively requested their application be withdrawn.',
  },
  {
    stage: ApplicationStage.Underwriting,
    status: ApplicationStatus.Withdrawn,
    helperText: 'Your client has actively requested their application be withdrawn.',
  },
  {
    stage: ApplicationStage.Issuance,
    status: ApplicationStatus.NotTaken,
    helperText: "Your client has rejected the carrier's offer for coverage.",
  },
];

export const PRODUCT_TYPE_TOOLTIPS: { [key in ProductType]: Tooltips } = {
  TERM: {
    title: 'Term',
    body: 'Term life insurance guarantees financial protection over a specific period — the term — before expiring. Terms usually last 10 to 40 years, after which the policy can be renewed, converted to permanent coverage, or left to expire. ',
  },
  WHOLE: {
    title: 'Whole',
    body: ' A whole life insurance policy remains in force as long as the premiums are paid, and has a cash value component that grows at a low interest rate set by the insurer. ',
  },
};

export const ISSUE_TYPE_TOOLTIPS: {
  [key in IssueType]: Tooltips;
} = {
  ACCELERATED_UNDERWRITING: {
    title: 'Accelerated',
    body: 'Your client may qualify for this product without requiring an in-person medical exam.',
  },
  SIMPLIFIED: {
    title: 'Simplified issue',
    body: 'This product does not require your client to complete an in-person medical exam to proceed to underwriting.',
  },
  FULLY_UNDERWRITTEN: {
    title: 'Fully underwritten',
    body: 'This product requires your client to sign an application and complete a medical exam to proceed to underwriting.',
  },
  GUARANTEED: {
    title: 'Guaranteed issue',
    body: 'This product is offered to qualified clients without in-person exams, medical questions, or underwriting requirements. ',
  },
  ISSUE_TYPE_UNSPECIFIED: {
    body: '',
    title: '',
  },
};

export const PREMIUM_TOOLTIP =
  'Pricing is subject to change following underwriting review. Additionally: flat extras, riders or quarterly and semi-annual premium modes may not be captured.';

export const HEALTHCLASS_TOOLTIP =
  "This is your client's current risk assessment. This may change at time of decision, based on the carrier underwriter's evaluation.";

export const CREATED_BY_TOOLTIP_DEFINITIONS: {
  [key in CreationMethod]?: string;
} = {
  ADVISOR: 'This case was created via drop ticket submission in the portal',
  CLIENT: 'The client created this case online through a referral link',
  POLICYGENIUS: 'Policygenius operations created this case internally',
  EAPP: 'This case was created via eApp in the portal',
  API: 'This case was created via API submission outside the portal',
};

export const DEFAULT_COLD_HELPER_TEXT = 'Your client has become non-responsive to our follow ups.';

export const convertCreationMethodDisplay: { [key in CreationMethod]: string } = {
  [CreationMethod.Advisor]: 'Drop ticket',
  [CreationMethod.Client]: 'Referral link',
  [CreationMethod.Policygenius]: 'Policygenius',
  [CreationMethod.Eapp]: 'eApp',
  [CreationMethod.Api]: 'API',
};
