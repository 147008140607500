/* THIS FILE WAS AUTO-GENERATED. DO NOT MODIFY */
/* eslint-disable */
import * as Types from '../../graph/types';

import { gql } from '@apollo/client';
export type NodeFieldsFragment = { __typename?: 'Node', urn: string, nodeType: string, questionType: string, content: string, helpText?: string | null, rules?: string | null, dataPoint?: { __typename?: 'DataPointID', urn: string, dataType: Types.DataType, subtype: Types.Subtype, repeatable?: boolean | null, compositeStructure?: Array<{ __typename?: 'CompositeStructure', key: string, value?: { __typename?: 'Composite', dataType?: Types.DataType | null, sensitive?: boolean | null } | null } | null> | null } | null, choices?: Array<{ __typename?: 'Choice', valueType?: Types.ValueType | null, valueInt: number, valueText: string, displayText: string }> | null };

export const NodeFieldsFragmentDoc = gql`
    fragment NodeFields on Node {
  urn
  nodeType
  questionType
  content
  helpText
  rules
  dataPoint {
    urn
    dataType
    subtype
    repeatable
    compositeStructure {
      key
      value {
        dataType
        sensitive
      }
    }
  }
  choices {
    valueType
    valueInt
    valueText
    displayText
  }
}
    `;