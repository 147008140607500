import React from 'react';

interface Props {
  className?: string;
}

export const Flag: React.FunctionComponent<Props> = ({ className }) => (
  <div className={className}>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.66113 2.5H4.91113V16.25C4.91113 16.9583 4.36947 17.5 3.66113 17.5C2.9528 17.5 2.41113 16.9583 2.41113 16.25V3.75C2.41113 3.04167 2.9528 2.5 3.66113 2.5Z"
        fill="#ECA389"
      />
      <path
        d="M6.57764 2.5H17.2026C17.661 2.5 18.036 2.875 18.036 3.33333C18.036 3.54167 17.9526 3.75 17.8276 3.875L15.3276 6.66667L17.8276 9.45833C18.1193 9.79167 18.1193 10.3333 17.786 10.625C17.6193 10.75 17.411 10.8333 17.2026 10.8333H6.57764V2.5Z"
        fill="#A4350F"
      />
    </svg>
  </div>
);
