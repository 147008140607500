import React from 'react';

interface Props {
  flip: boolean;
}

export const Arrow: React.FunctionComponent<Props> = ({ flip }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    transform={flip ? 'scale(-1)' : ''}
  >
    <path
      d="M16.59 15.7051L12 11.1251L7.41 15.7051L6 14.2951L12 8.29508L18 14.2951L16.59 15.7051Z"
      fill="#707070"
    />
  </svg>
);
