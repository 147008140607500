import { theme } from '@policygenius/mortar';
import { styled } from 'styled-components';

export const Tab = styled.div`
  padding: 24px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    margin-bottom: 8px;
    svg {
      margin-right: 8px;
    }
  }

  p {
    cursor: pointer;
    &:hover {
      color: ${theme.colors.orange500};
    }
  }
`;
