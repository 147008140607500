import { LabelText, theme, UtilityText } from '@policygenius/mortar';
import styled from 'styled-components';

export const PlaybackContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${theme.colors.white};
  padding: 16px;
  margin-bottom: 24px;
  border: 1px solid ${theme.colors.gray200};
  border-radius: 4px;
`;

export const CarrierLink = styled(UtilityText)`
  &:hover {
    color: ${theme.colors.orange300};
  }

  margin-bottom: 4px;
`;

export const CoverageSelections = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
`;

export const Label = styled(LabelText)`
  margin-bottom: 4px;
`;

export const Price = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const Selection = styled.div`
  width: 232px;
`;
