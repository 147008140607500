import React from 'react';
import { format, isValid } from 'date-fns';
import { UtilityText } from '@policygenius/mortar';
import { MedicalExamStatus } from 'graph/types';
import { MEDICAL_EXAM_STATUS_ICON } from './constants';
import { ProgressStep } from './ProgressStep';
import { MedicalExamProps } from './types';
import { Date } from './styles';

export const MedicalExam: React.FunctionComponent<MedicalExamProps> = ({
  examDate,
  medicalExamStatus,
}) => {
  const dateIsValid = isValid(examDate);

  return (
    <React.Fragment>
      <div>
        {medicalExamStatus === MedicalExamStatus.ExamNotRequired ? (
          <UtilityText size="small">
            Given your client’s current application path, a medical exam is not presently required.
            Depending on the carrier’s assessment, an exam may be requested and will be reflected
            here accordingly.
          </UtilityText>
        ) : (
          <React.Fragment>
            <ProgressStep
              icon={MEDICAL_EXAM_STATUS_ICON[medicalExamStatus].schedule}
              text="Schedule"
              responsible="Client"
            />
            <ProgressStep
              icon={MEDICAL_EXAM_STATUS_ICON[medicalExamStatus].complete}
              text="Complete"
              responsible="Client"
            />
            {examDate && dateIsValid && (
              <Date size="small" color="secondary">
                {format(examDate, 'MMMM do @ h:mmaaa')}
              </Date>
            )}
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};
