import React from 'react';
import { Events, track } from 'utils/analytics';
import { Props } from './types';
import { ClickableCard as Card } from '@policygenius/mortar';

export const ClickableCard: React.FunctionComponent<Props> = ({
  analyticsText,
  children,
  onClick,
  className,
  id,
  ...rest
}) => {
  const handleClick: React.MouseEventHandler = () => {
    if (onClick) {
      track(Events.CLICKED_TILE, { analyticsText });

      onClick();
    }
  };

  return (
    <Card onClick={handleClick} className={className} id={id} {...rest}>
      {children}
    </Card>
  );
};
