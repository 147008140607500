import React, { createContext, useState, useContext } from 'react';
import { ContextProps } from './types';
import localStore from 'store2';

export const storeIsWritingAgentKey = 'isWritingAgent';
export const storeIsAdminKey = 'isAdmin';

export const UserContext = createContext<ContextProps>({
  userIsWritingAgent: false,
  setUserIsWritingAgent: () => undefined,
  userIsAdmin: false,
  setUserIsAdmin: () => undefined,
});

UserContext.displayName = 'UserContext';

export const UserProvider: React.FunctionComponent = ({ children }) => {
  const [userIsWritingAgent, setUserIsWritingAgent] = useState<boolean>(
    localStore.get(storeIsWritingAgentKey) || false
  );

  const setWritingAgentWithLocalStore = (isWritingAgent: boolean) => {
    localStore.set(storeIsWritingAgentKey, isWritingAgent);
    setUserIsWritingAgent(isWritingAgent);
  };

  const [userIsAdmin, setUserIsAdmin] = useState<boolean>(localStore.get(storeIsAdminKey) || false);

  const setAdminWithLocalStore = (isAdmin: boolean) => {
    localStore.set(storeIsAdminKey, isAdmin);
    setUserIsAdmin(isAdmin);
  };

  return (
    <UserContext.Provider
      value={{
        userIsWritingAgent,
        setUserIsWritingAgent: setWritingAgentWithLocalStore,
        userIsAdmin,
        setUserIsAdmin: setAdminWithLocalStore,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = (): ContextProps => useContext(UserContext);

export default UserProvider;
