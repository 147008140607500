import React from 'react';

export const DisabilityPlus: React.FunctionComponent = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_295_450)">
      <path
        d="M12 22.2571H22.2857C22.2857 16.5764 17.6807 11.9714 12 11.9714V22.2571Z"
        fill="#007562"
      />
      <path
        d="M12.0001 22.2571H1.71436C1.71436 16.5764 6.31936 11.9714 12.0001 11.9714V22.2571Z"
        fill="#FF6E00"
      />
      <path
        d="M12.0002 10.8272C14.5245 10.8272 16.5709 8.78078 16.5709 6.25644C16.5709 3.73211 14.5245 1.68573 12.0002 1.68573C9.47582 1.68573 7.42944 3.73211 7.42944 6.25644C7.42944 8.78078 9.47582 10.8272 12.0002 10.8272Z"
        fill="#D44513"
      />
      <path
        d="M14.8821 22.2572C16.4464 22.2572 17.715 20.9779 17.715 19.4007C17.715 17.8236 16.4464 16.5443 14.8821 16.5443H12V22.2529L14.8821 22.2572Z"
        fill="#FF6E00"
      />
      <path d="M1.71436 22.2571H12.0001V11.9714" fill="black" />
      <g clipPath="url(#clip1_295_450)">
        <path
          d="M18.5 24C21.5376 24 24 21.5376 24 18.5C24 15.4624 21.5376 13 18.5 13C15.4624 13 13 15.4624 13 18.5C13 21.5376 15.4624 24 18.5 24Z"
          fill="white"
        />
        <path
          d="M17.9167 17.9167V15.5833C17.9167 15.2625 18.1792 15 18.5 15C18.8208 15 19.0833 15.2625 19.0833 15.5833V17.9167H21.4167C21.7375 17.9167 22 18.1792 22 18.5C22 18.8208 21.7375 19.0833 21.4167 19.0833H19.0833V21.4167C19.0833 21.7375 18.8208 22 18.5 22C18.1792 22 17.9167 21.7375 17.9167 21.4167V19.0833H15.5833C15.2625 19.0833 15 18.8208 15 18.5C15 18.1792 15.2625 17.9167 15.5833 17.9167H17.9167Z"
          fill="#383838"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_295_450">
        <rect width="24" height="24" fill="white" />
      </clipPath>
      <clipPath id="clip1_295_450">
        <rect width="11" height="11" fill="white" transform="translate(13 13)" />
      </clipPath>
    </defs>
  </svg>
);
