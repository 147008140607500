import React, { useState } from 'react';
import { UtilityText } from '@policygenius/mortar';
import { CollapsibleRows, Props } from './types';
import { Container, Content, Header, UtilityLink } from './styles';
import { Arrow } from 'components/CaseDetailPanel/ProgressTab/arrow';

const CollapsibleDetailsContainer: React.FC<Props> = ({ items, labelText, startCollapsed }) => {
  const rowNames = items.map((item, index) => [index, startCollapsed || false]);
  const [collapsedItems, updateCollapsedItems] = useState<CollapsibleRows>(
    Object.fromEntries(rowNames)
  );

  return (
    <React.Fragment>
      <UtilityText weight="bold" size="xsmall" color="secondary">
        {labelText}
      </UtilityText>
      <Container>
        {items.map((item, index) => {
          return (
            <div key={item.name?.toString()}>
              <Header
                onClick={() => {
                  updateCollapsedItems({
                    ...collapsedItems,
                    [index]: !collapsedItems[index],
                  });
                }}
              >
                {item.name}
                <Arrow flip={collapsedItems[index]} />
              </Header>
              <Content collapsedItems={!!collapsedItems[index]}>
                {item.body}
                {item.link && (
                  <UtilityLink forwardedAs="a" href={item.link} target="_blank" size="small">
                    Learn more
                  </UtilityLink>
                )}
              </Content>
            </div>
          );
        })}
      </Container>
    </React.Fragment>
  );
};

export default CollapsibleDetailsContainer;
