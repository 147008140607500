import React from 'react';
import {
  UtilIconBoxes,
  UtilIconGraph,
  UtilIconHelp,
  theme,
  GeneralSettings,
} from '@policygenius/mortar';
import { useNavContext } from 'context/NavContext';
import {
  Bar,
  BottomMenu,
  Close,
  HamburgerIcon,
  MenuItem,
  MenuText,
  OpenCloseIcon,
  TopMenu,
} from './styles';
import { useLocation } from 'react-router-dom';
import {
  CLIENT_INFO_ROUTE,
  DROP_TICKET_ROUTE,
  EAPP_ROUTE,
  FORK_ROUTE,
  HELP_CENTER_ROUTE,
  INDEX_ROUTE,
  QUOTES_ROUTE,
  REPORTS_ROUTE,
  SCHEDULE,
  SETTINGS_ROUTE,
} from 'routes/constants';
import { useCurrentUserQuery } from 'query/currentUser/query';
import { Role } from 'graph/types';
import { useDecision } from '@optimizely/react-sdk';
import { useWindowSize } from 'hooks/useWindowSize';
import { DisabilityPlus } from './disabilityPlus';
import { LifePlus } from './lifePlus';
import { OPERATIONS_DISABILITY } from 'utils/optimizelyConstants';

const LeftNavbar: React.FC = () => {
  const { isOpen, setIsOpen } = useNavContext();
  const location = useLocation();
  const { data: userData, error } = useCurrentUserQuery();
  const windowSize = useWindowSize();
  const [disabilityInPortal, optimizelyClientReady] = useDecision(
    OPERATIONS_DISABILITY,
    {},
    {
      overrideUserId: 'agent-portal',
      overrideAttributes: {
        organization: userData?.currentUser.agencySlug || '',
        organizationType: userData?.currentUser.agencyType || '',
      },
    }
  );

  const disabilityEnabled = disabilityInPortal.enabled && optimizelyClientReady;

  if (error) {
    console.error(error);
  }

  const isProducer = userData?.currentUser.roles.includes(Role.Producer);
  const isAssistant = userData?.currentUser.roles.includes(Role.Assistant);
  const isAdmin = userData?.currentUser.roles.includes(Role.Admin);

  const newCaseRoutes = new Set([
    FORK_ROUTE,
    DROP_TICKET_ROUTE,
    CLIENT_INFO_ROUTE,
    QUOTES_ROUTE,
    SCHEDULE,
  ]);

  const handleClick = () => {
    if (windowSize.width && windowSize.width < theme.breakpoints.large) {
      setIsOpen(false);
    }
  };

  const disabilityLink = 'https://forms.gle/BZVRkmW31uakq5Cb9';

  return (
    <Bar open={isOpen}>
      <TopMenu>
        <OpenCloseIcon onClick={() => setIsOpen(!isOpen)} id="nav-open-close">
          {isOpen ? (
            <Close size="large" color="gray300" />
          ) : (
            <HamburgerIcon size="large" color="gray300" />
          )}
        </OpenCloseIcon>
        <MenuItem
          id="dashboard"
          analyticsProperties={{
            source: 'left nav',
            text: 'dashboard',
          }}
          selected={location.pathname === INDEX_ROUTE}
          href={INDEX_ROUTE}
          onClick={handleClick}
        >
          <UtilIconBoxes size="large" color="gray300" />
          <MenuText
            size={{ small: 'large', medium: 'small', large: 'small' }}
            open={isOpen}
            weight="bold"
            color="white"
          >
            Dashboard
          </MenuText>
        </MenuItem>
        <MenuItem
          analyticsProperties={{
            source: 'left nav',
            text: 'case report',
          }}
          selected={location.pathname === REPORTS_ROUTE}
          href={REPORTS_ROUTE}
          onClick={handleClick}
        >
          <UtilIconGraph size="large" color="gray300" />
          <MenuText
            size={{ small: 'large', medium: 'small', large: 'small' }}
            open={isOpen}
            weight="bold"
            color="white"
          >
            Case report
          </MenuText>
        </MenuItem>
        {(isProducer || isAssistant) && (
          <MenuItem
            analyticsProperties={{
              source: 'left nav',
              text: 'life case',
            }}
            selected={
              newCaseRoutes.has(location.pathname) || location.pathname.includes(EAPP_ROUTE)
            }
            href={FORK_ROUTE}
            onClick={handleClick}
          >
            <LifePlus />
            <MenuText
              size={{ small: 'large', medium: 'small', large: 'small' }}
              open={isOpen}
              weight="bold"
              color="white"
            >
              Life case
            </MenuText>
          </MenuItem>
        )}
        {isProducer && disabilityEnabled && !isAssistant && (
          <MenuItem
            analyticsProperties={{
              source: 'left nav',
              text: 'disability case',
            }}
            selected={false}
            href={disabilityLink}
            target="_blank"
            rel="no opener noreferrer"
            external
          >
            <DisabilityPlus />
            <MenuText
              size={{ small: 'large', medium: 'small', large: 'small' }}
              open={isOpen}
              weight="bold"
              color="white"
            >
              Disability case
            </MenuText>
          </MenuItem>
        )}
      </TopMenu>
      <BottomMenu>
        <MenuItem
          analyticsProperties={{
            source: 'left nav',
            text: 'help center',
          }}
          selected={location.pathname === HELP_CENTER_ROUTE}
          href={HELP_CENTER_ROUTE}
          onClick={handleClick}
        >
          <UtilIconHelp size="large" color="gray300" />
          <MenuText
            size={{ small: 'large', medium: 'small', large: 'small' }}
            open={isOpen}
            weight="bold"
            color="white"
          >
            Help Center
          </MenuText>
        </MenuItem>
        {(isProducer || isAdmin) && (
          <MenuItem
            analyticsProperties={{
              source: 'left nav',
              text: 'settings',
            }}
            selected={location.pathname === SETTINGS_ROUTE}
            href={SETTINGS_ROUTE}
            onClick={handleClick}
          >
            <GeneralSettings size="large" color="gray300" />
            <MenuText
              size={{ small: 'large', medium: 'small', large: 'small' }}
              open={isOpen}
              weight="bold"
              color="white"
            >
              Settings
            </MenuText>
          </MenuItem>
        )}
      </BottomMenu>
    </Bar>
  );
};

export default LeftNavbar;
