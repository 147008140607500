import React, { createContext, useState, useContext } from 'react';
import { ToastContextProps, StateProps } from './types';

export const ToastContext = createContext<ToastContextProps>({
  toast: { on: false, type: 'success', title: '', body: '' },
  show: () => undefined,
  hide: () => undefined,
});

ToastContext.displayName = 'ToastContext';

export const ToastProvider: React.FC = ({ children }) => {
  const [toast, setToast] = useState<StateProps>({
    on: false,
    type: 'success',
    title: '',
    body: '',
  });

  return (
    <ToastContext.Provider
      value={{
        toast,
        hide: () => setToast({ ...toast, on: false }),
        show: ({ type, title, body, width }) => {
          setToast({
            on: true,
            type,
            title,
            body,
            ...(width && { width }),
          });
        },
      }}
    >
      {children}
    </ToastContext.Provider>
  );
};

export const useToastContext = () => useContext(ToastContext);
