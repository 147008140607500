import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { REPORTS_ROUTE } from 'routes/constants';
import CaseDetailPanel from 'components/CaseDetailPanel';
import { Container } from './styles';

const DetailPage: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  if (!id) {
    navigate(REPORTS_ROUTE);

    return <React.Fragment />;
  }

  return (
    <Container>
      <CaseDetailPanel closeHandler={() => navigate(REPORTS_ROUTE)} id={id} />
    </Container>
  );
};

export default DetailPage;
