import React from 'react';
import { Link, UtilityText } from '@policygenius/mortar';
import { useFormContext } from 'context/FormContext';
import {
  AcceleratedBadge,
  CarrierLink,
  CoverageSelections,
  Label,
  PlaybackContainer,
  Price,
  Selection,
  SimplifiedBadge,
  TitlePrice,
} from './styles';
import Premium from '../Premium';
import { maskMoney } from 'forms/masks';

export const issueTypes = {
  /* eslint-disable camelcase */
  accelerated_underwriting: true,
  simplified: true,
  /* eslint-enable camelcase */
};

const PolicyPlayback: React.FC = () => {
  const { state } = useFormContext();

  const hasBadge = Object.prototype.hasOwnProperty.call(
    issueTypes,
    state.policySelection.issueType!
  );

  return (
    <PlaybackContainer data-testid="policyPlayback">
      <TitlePrice hasBadge={hasBadge}>
        <Link
          href={state.policySelection.carrierLink!}
          variant="secondary"
          target="_blank"
          rel="noreferrer"
          color="inherit"
        >
          <CarrierLink weight="bold" size="xlarge" text-decoration-line="underline">
            {state.policySelection.carrierName}
          </CarrierLink>
        </Link>
        <Price>
          <Premium
            premiumAmount={
              state.paymentMode === 'Monthly'
                ? state.policySelection.monthlyPremium!
                : state.policySelection.annualPremium!
            }
            frequency={state.paymentMode === 'Monthly' ? '/mo' : '/yr'}
          />
        </Price>
      </TitlePrice>
      {state.policySelection.issueType === 'accelerated_underwriting' && (
        <AcceleratedBadge label="Accelerated" variant="POSITIVE" />
      )}
      {state.policySelection.issueType === 'simplified' && (
        <SimplifiedBadge label="Simplified issue" variant="INFO" />
      )}
      <CoverageSelections>
        <Selection>
          <Label>Product name</Label>
          <UtilityText size="large">{state.policySelection.planName}</UtilityText>
        </Selection>
        <Selection>
          <Label>Health class</Label>
          <UtilityText size="large">{state.healthClass}</UtilityText>
        </Selection>
      </CoverageSelections>
      <CoverageSelections>
        <Selection>
          <Label>Coverage</Label>
          <UtilityText size="large">${maskMoney(state.coverageAmount)}</UtilityText>
        </Selection>
        <Selection>
          <Label>Term</Label>
          <UtilityText size="large">{state.term} years</UtilityText>
        </Selection>
      </CoverageSelections>
    </PlaybackContainer>
  );
};

export default PolicyPlayback;
