import { isPresent, isBetween, composeValidators } from 'forms/validators/index';
import { ValidationErrors } from 'forms/types';
import { LifeApplicationInfo } from 'context/FormContext/types';
import { moneyToString } from 'utils/helpers';
import {
  COVERAGE_AMOUNT_MAX,
  COVERAGE_AMOUNT_MIN,
  COVERAGE_ERROR_OVERRIDE,
} from 'pages/ClientInfo/form/constants';

export const getValidator = (
  values: LifeApplicationInfo
): ValidationErrors<LifeApplicationInfo> => {
  const { healthClass, coverageAmount, term, paymentMode } = values;
  const validateCoverageAmount = composeValidators(
    isPresent,
    isBetween(COVERAGE_AMOUNT_MIN, COVERAGE_AMOUNT_MAX, COVERAGE_ERROR_OVERRIDE)
  );

  return {
    healthClass: isPresent(healthClass),
    coverageAmount: validateCoverageAmount(moneyToString(coverageAmount)),
    term: isPresent(term),
    paymentMode: isPresent(paymentMode),
  };
};
