import styled from 'styled-components';
import { Button, Col, LabelText, Row, Selectbox, theme, UtilityText } from '@policygenius/mortar';
import { TableColumnProps } from './types';

export const CaseDetailPanelCol = styled(Col)`
  @media (min-width: ${theme.breakpoints.medium}px) {
    padding-right: 24px;
    box-shadow: -2px 0px 16px rgba(0, 0, 0, 0.1);
  }

  @media (min-width: ${theme.breakpoints.large}px) {
    overflow-y: auto;
    margin-top: 0;
    margin-left: -24px;
    position: sticky;
    top: 0;
    right: 0;
    padding-right: 0;
    height: 100vh;
  }
`;

export const TableColumn = styled.div<TableColumnProps>`
  min-height: ${(props) => (props.hasData ? '350px' : '')};
  background: ${(props) => (props.hasData ? theme.colors.white : '')};

  @media (min-width: ${theme.breakpoints.large}px) {
    margin-left: -24px;
    margin-right: -24px;
  }
`;

export const TableContainer = styled.div<{
  selectedReport: boolean;
}>`
  width: 100%;
  @media (min-width: ${theme.breakpoints.medium}px) {
    width: 100%;
    padding: 0px;
    margin-top: 24px;
  }

  @media (min-width: ${theme.breakpoints.large}px) {
    padding: 0px;
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  height: initial;
  padding: 0px 16px 16px 16px;
  background: ${theme.colors.white};

  @media (min-width: ${theme.breakpoints.medium}px) {
    padding: 0px 24px 0px 24px;
  }
  @media (min-width: ${theme.breakpoints.large}px) {
    padding: 0;
  }
`;

export const ContainerCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 24px 0px;
  background: ${theme.colors.white};

  @media (min-width: ${theme.breakpoints.medium}px) {
    padding: 24px 0px 32px 0px;
  }

  @media (min-width: ${theme.breakpoints.large}px) {
    padding: 24px 24px 0 24px;
  }
`;

export const CaseCount = styled(UtilityText)`
  margin-left: 4px;
`;

export const ContainerRow = styled(Row)`
  padding: 0px;
  background-color: ${theme.colors.white};
  align-items: start;
`;

export const SortByLabel = styled(LabelText)`
  padding: 16px 0 8px 0;

  @media (min-width: ${theme.breakpoints.medium}px) {
    padding-top: 24px;
  }
`;

export const SortBySelectbox = styled(Selectbox)`
  @media (min-width: ${theme.breakpoints.medium}px) {
    max-width: 300px;
  }
`;

export const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding: 16px;
  justify-content: space-between;
  background: white;
  & svg: {
    transform: translateY(0px);
  }

  @media (min-width: ${theme.breakpoints.medium}px) {
    flex-direction: row;
  }
`;

export const FeedbackContent = styled.div`
  display: flex;
  margin-top: 32px;

  @media (min-width: ${theme.breakpoints.medium}px) {
    margin-top: 0px;
  }
`;

export const PaginationText = styled(UtilityText)`
  display: none;

  @media (min-width: ${theme.breakpoints.medium}px) {
    margin-right: 24px;
    display: initial;
  }
`;

export const UtilityLink = styled(UtilityText)`
  padding-left: 8px;
  color: ${theme.colors.orange400};
  text-decoration: underline;
`;

export const MobileSortButton = styled(Button)`
  margin-top: 24px;
  margin-right: 8px;
  @media (min-width: ${theme.breakpoints.large}px) {
    display: none;
  }
`;

export const MobileFilterButton = styled(Button)`
  margin-right: 16px;
  @media (min-width: ${theme.breakpoints.large}px) {
    display: none;
  }
`;

export const MobileResetButton = styled(Button)`
  @media (min-width: ${theme.breakpoints.large}px) {
    display: none;
  }
`;
