export const FOOTER_LINKS = [
  { text: 'Privacy Policy', href: 'https://www.policygenius.com/about/privacy/' },
  {
    text: 'Security & Your Data',
    href: 'https://www.policygenius.com/about/security/',
  },
  {
    text: 'Online Tracking Opt-Out Guide ',
    href: 'https://www.policygenius.com/about/privacy/#opt-out-guide',
  },
  {
    text: 'Accessibility',
    href: 'https://www.policygenius.com/about/disclosures/#web-accessibility',
  },
  { text: 'Terms & conditions', href: 'https://www.policygenius.com/about/terms/' },
  { text: 'Disclosures', href: 'https://www.policygenius.com/about/disclosures/' },
];
