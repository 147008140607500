import { UtilityText } from '@policygenius/mortar';
import { PacketStatus } from 'graph/types';
import React from 'react';
import { PACKET_STATUS_ICON } from './constants';
import { ProgressStep } from './ProgressStep';
import { PaperworkStatusProps } from './types';

export const PaperworkStatus: React.FunctionComponent<PaperworkStatusProps> = ({
  packetStatus,
}) => {
  return (
    <React.Fragment>
      <div>
        {packetStatus === PacketStatus.Interview ? (
          <UtilityText size="small" data-testid="packet-text">
            Your client is completing and signing the carrier-facilitated medical questionnaire.
            Once complete, the carrier will determine if a medical exam is required.
          </UtilityText>
        ) : (
          <>
            <ProgressStep
              icon={PACKET_STATUS_ICON[packetStatus].sendToClient}
              text="Send to client"
              responsible="Policygenius"
            />
            <ProgressStep
              icon={PACKET_STATUS_ICON[packetStatus].reviewAndSign}
              text="Review & sign"
              responsible="Client"
            />
            <ProgressStep
              icon={PACKET_STATUS_ICON[packetStatus].submitToCarrier}
              text="Submit to carrier"
              responsible="Policygenius"
            />
          </>
        )}
      </div>
    </React.Fragment>
  );
};
