import React from 'react';
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useAuth0 } from '@auth0/auth0-react';

const customFetch = (uri: any, options: any) => {
  const { operationName } = JSON.parse(options.body);

  return fetch(`${uri}?opname=${operationName}`, options);
};

const httpLink = createHttpLink({
  uri: String(`${process.env.REACT_APP_API}`),
  fetch: customFetch,
});

const ApolloWrapper: React.FC = ({ children }) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const authLink = React.useMemo(() => {
    const auth = setContext(async () => {
      try {
        if (isAuthenticated) {
          const token = await getAccessTokenSilently();

          return {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        }
      } catch (e) {
        console.error('Error getting token', e);
      }

      return {};
    });

    return new ApolloClient({
      cache: new InMemoryCache(),
      link: auth.concat(httpLink),
    });
  }, [getAccessTokenSilently, isAuthenticated]);

  return <ApolloProvider client={authLink}>{children}</ApolloProvider>;
};

export default ApolloWrapper;
