import React, { useState, useRef, useEffect } from 'react';
import { Button, ExpandMore } from '@policygenius/mortar';
import {
  DesktopStatusFilterDropDown,
  FilterContainer,
  Reset,
  Status,
  StatusCheckBox,
  ResetButton,
} from './styles';
import { STATUS_FILTER_OPTIONS } from '../constants';
import { useReportContext } from 'context/ReportContext';
import { Props } from './types';

export const DesktopStatusFilter: React.FC<Props> = ({ reset }) => {
  const { filter, setFilter } = useReportContext();
  const [showDropDown, setShowDropdown] = useState(false);

  const updateStatusSelections = (option: string, checked: boolean) => {
    reset();
    // if the option is already checked when the user clicks the checkbox,
    // that means it should be removed from filter state so it becomes unchecked
    if (checked) {
      const newFilter = filter.filter((object) => object.applicationStatus !== option);

      setFilter(newFilter);
    } else {
      setFilter([...filter, { applicationStatus: option }]);
    }
  };

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const dropdownCloser = (event: Event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        document.getElementById('dropdownButton') !== event.target
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', dropdownCloser);

    return () => document.removeEventListener('mousedown', dropdownCloser);
  }, [showDropDown]);

  const statusCountNumber = () => {
    const count = filter.length;

    return count === 0 ? '' : `(${count})`;
  };

  return (
    <Status>
      <Button variant="filled" id="dropdownButton" onClick={() => setShowDropdown(!showDropDown)}>
        <span>Status {statusCountNumber()}</span>
        <ExpandMore size="small" />
      </Button>
      {filter.length > 0 && (
        <ResetButton
          variant="quaternary"
          size="medium"
          onClick={() => {
            setFilter([]);
            reset();
          }}
        >
          Reset
        </ResetButton>
      )}
      {showDropDown && (
        <FilterContainer ref={dropdownRef}>
          <DesktopStatusFilterDropDown>
            {STATUS_FILTER_OPTIONS.map((option) => {
              return (
                <StatusCheckBox
                  name={option.value}
                  label={option.label}
                  value={option.value}
                  helpText={option.helpText}
                  checked={!!filter.find((item) => item.applicationStatus === option.value)}
                  key={option.value}
                  onChange={() => {
                    updateStatusSelections(
                      option.value,
                      !!filter.find((item) => item.applicationStatus === option.value)
                    );
                  }}
                />
              );
            })}
          </DesktopStatusFilterDropDown>
          <Reset>
            <Button
              variant="quaternary"
              size="medium"
              disabled={filter.length === 0}
              onClick={() => {
                setFilter([]);
                setShowDropdown(false);
              }}
            >
              Reset
            </Button>
          </Reset>
        </FilterContainer>
      )}
    </Status>
  );
};
