/* THIS FILE WAS AUTO-GENERATED. DO NOT MODIFY */
/* eslint-disable */
import * as Types from '../../graph/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AffiliateQueryVariables = Types.Exact<{
  slug: Types.Scalars['String']['input'];
}>;


export type AffiliateQuery = { __typename?: 'Query', affiliate: { __typename?: 'PageContent', logoURL: string, slug: string, subtext?: string | null, title: string, hideLogoBackground: boolean, aboutSectionLeft: { __typename?: 'AboutSection', title?: string | null, body?: string | null }, aboutSectionRight: { __typename?: 'AboutSection', title?: string | null, body?: string | null } } };


export const AffiliateDocument = gql`
    query Affiliate($slug: String!) {
  affiliate(input: $slug) {
    aboutSectionLeft {
      title
      body
    }
    aboutSectionRight {
      title
      body
    }
    logoURL
    slug
    subtext
    title
    hideLogoBackground
  }
}
    `;

/**
 * __useAffiliateQuery__
 *
 * To run a query within a React component, call `useAffiliateQuery` and pass it any options that fit your needs.
 * When your component renders, `useAffiliateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAffiliateQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useAffiliateQuery(baseOptions: Apollo.QueryHookOptions<AffiliateQuery, AffiliateQueryVariables> & ({ variables: AffiliateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AffiliateQuery, AffiliateQueryVariables>(AffiliateDocument, options);
      }
export function useAffiliateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AffiliateQuery, AffiliateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AffiliateQuery, AffiliateQueryVariables>(AffiliateDocument, options);
        }
export function useAffiliateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AffiliateQuery, AffiliateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AffiliateQuery, AffiliateQueryVariables>(AffiliateDocument, options);
        }
export type AffiliateQueryHookResult = ReturnType<typeof useAffiliateQuery>;
export type AffiliateLazyQueryHookResult = ReturnType<typeof useAffiliateLazyQuery>;
export type AffiliateSuspenseQueryHookResult = ReturnType<typeof useAffiliateSuspenseQuery>;
export type AffiliateQueryResult = Apollo.QueryResult<AffiliateQuery, AffiliateQueryVariables>;