import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Props } from './types';

const animate = keyframes`
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`;

const Shimmer = styled.div<Props>`
  height: 20px;
  width: ${(props) => props.width}%;
  margin-bottom: ${(props) => props.bottom}px;
  animation: ${animate} 3s infinite linear;
  background-size: 1000px 100%;
  ${({ background }) => {
    if (background === 'dark') {
      return `background: linear-gradient(to right, #D9D9D9 4%, #e2e2e2 25%, #D9D9D9 36%)`;
    }
    if (background === 'light') {
      return `background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%)`;
    }
  }}
`;

const LoadingShimmer: React.FunctionComponent<Props> = ({
  width = 100,
  bottom = 0,
  background = 'light',
}) => {
  return <Shimmer width={width} bottom={bottom} background={background} />;
};

export default LoadingShimmer;
