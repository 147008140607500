import styled from 'styled-components';
import { theme, Row, TitleText } from '@policygenius/mortar';
import Button from 'components/Button';
import { V2_CONTAINER_PADDING } from 'utils/helpers';

export const FormContainer = styled(Row)`
  padding: 32px 16px 80px 16px;
  width: 100%;
  @media (min-width: ${theme.breakpoints.medium}px) {
    padding: ${V2_CONTAINER_PADDING};
  }
`;

export const Title = styled(TitleText)`
  margin-bottom: 16px;
`;

export const EditClientButton = styled(Button)`
  margin-bottom: 32px;
  margin-right: 8px;
  @media (min-width: ${theme.breakpoints.medium}px) {
    margin-bottom: 40px;
  }
`;

export const ContinueButton = styled(Button)`
  margin-bottom: 32px;
  @media (min-width: ${theme.breakpoints.medium}px) {
    margin-bottom: 40px;
  }
`;
