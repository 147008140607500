import {
  Node as MortarNode,
  SingleChoiceQuestion,
  MultiChoiceQuestion,
  RepeatableSection,
  Section,
} from '@policygenius/dif-ts-types';
import {
  Choice,
  Node as GraphModelNode,
  ValueType,
  DataPointIdMapping,
  DataValueTypes,
  MappedDpidData,
  Maybe,
  RepeatableDataPointMapping,
  Subtype,
} from 'graph/types';
import {
  PRESENTATION_RULES_MAPPINGS,
  QUESTION_RULES_MAPPINGS,
  SECTION_RULES_MAPPINGS,
} from './rules';

const formatChoices = (
  node: GraphModelNode
): SingleChoiceQuestion['choices'] | MultiChoiceQuestion['choices'] => {
  const newChoices: SingleChoiceQuestion['choices'] | MultiChoiceQuestion['choices'] = [];

  if (!node.choices || node.choices.length === 0) return newChoices;
  node.choices.forEach((choice: Choice) => {
    const value = choice.valueType === ValueType.ValueInt ? choice.valueInt : choice.valueText;
    const newChoice: any = {
      displayText: choice.displayText,
      value,
    };

    newChoices.push(newChoice);
  });

  return newChoices;
};

const formatSingleOrMultiChoiceQuestion = (
  question: GraphModelNode
): SingleChoiceQuestion | MultiChoiceQuestion => {
  const dataType = question.dataPoint!.dataType.toLowerCase();

  if (question.questionType === 'SINGLE_CHOICE') {
    return {
      nodeType: 'QUESTION',
      urn: question.urn,
      questionType: 'SINGLE_CHOICE',
      content: question.content,
      dataPoint: {
        dataType: dataType as unknown as 'integer' | 'text' | 'decimal',
        urn: question.dataPoint!.urn,
      },
      choices: formatChoices(question) as SingleChoiceQuestion['choices'],
      ...(QUESTION_RULES_MAPPINGS[question.urn] && {
        rules: QUESTION_RULES_MAPPINGS[question.urn],
      }),
    };
  }

  return {
    nodeType: 'QUESTION',
    urn: question.urn,
    questionType: 'MULTI_CHOICE',
    content: question.content,
    dataPoint: {
      dataType: dataType as unknown as 'integer_list' | 'text_list',
      urn: question.dataPoint!.urn,
    },
    choices: formatChoices(question) as MultiChoiceQuestion['choices'],
    ...(QUESTION_RULES_MAPPINGS[question.urn] && {
      rules: QUESTION_RULES_MAPPINGS[question.urn],
    }),
  };
};

export const graphModelToMortarNodeTextOrQuestion = (
  child: GraphModelNode
): MortarNode | undefined => {
  if (child.nodeType === 'QUESTION') {
    if (child.questionType === 'SINGLE_CHOICE' || child.questionType === 'MULTI_CHOICE') {
      return formatSingleOrMultiChoiceQuestion(child);
    }

    const childDataPoint = child.dataPoint!;

    const subType =
      childDataPoint.subtype === Subtype.SubtypeUnspecified
        ? undefined
        : childDataPoint.subtype.toLowerCase();

    if (child.questionType === 'DATE') {
      return {
        nodeType: 'QUESTION',
        urn: child.urn,
        questionType: child.questionType,
        content: child.content,
        dataPoint: {
          dataType: 'date',
          urn: childDataPoint.urn,
          subtype: subType as unknown as undefined | 'month',
        },
        ...(QUESTION_RULES_MAPPINGS[child.urn] && {
          rules: QUESTION_RULES_MAPPINGS[child.urn],
        }),
      };
    }

    if (child.questionType === 'BOOLEAN') {
      return {
        nodeType: 'QUESTION',
        urn: child.urn,
        questionType: child.questionType,
        content: child.content,
        dataPoint: {
          dataType: 'boolean',
          urn: childDataPoint.urn,
          // there are no subtypes for boolean question datapoints & this field is optional for MDF
        },
        ...(QUESTION_RULES_MAPPINGS[child.urn] && {
          rules: QUESTION_RULES_MAPPINGS[child.urn],
        }),
      };
    }

    if (child.questionType === 'NUMBER') {
      return {
        nodeType: 'QUESTION',
        urn: child.urn,
        questionType: child.questionType,
        content: child.content,
        dataPoint: {
          dataType: 'integer',
          urn: childDataPoint.urn,
          subtype: subType as unknown as
            | undefined
            | 'dollars'
            | 'inches'
            | 'pounds'
            | 'percentage'
            | 'years',
        },
        ...(QUESTION_RULES_MAPPINGS[child.urn] && {
          rules: QUESTION_RULES_MAPPINGS[child.urn],
        }),
      };
    }

    if (child.questionType === 'TEXT') {
      return {
        nodeType: 'QUESTION',
        urn: child.urn,
        questionType: child.questionType,
        content: child.content,
        dataPoint: {
          dataType: 'text',
          urn: childDataPoint.urn,
          subtype: subType as unknown as
            | undefined
            | 'email'
            | 'ssn'
            | 'telephone'
            | 'zip_code'
            | 'long_text',
        },
        ...(QUESTION_RULES_MAPPINGS[child.urn] && {
          rules: QUESTION_RULES_MAPPINGS[child.urn],
        }),
      };
    }
  }

  if (child.nodeType === 'TEXT') {
    return {
      nodeType: 'TEXT',
      urn: child.urn,
      content: child.content,
    };
  }
};

const generateMortarRepeatableSection = (section: GraphModelNode) => {
  const repeatableSection: RepeatableSection = {
    nodeType: 'SECTION',
    urn: section.urn,
    content: section.content,
    dataPoint: {
      urn: section.dataPoint!.urn,
      sensitive: false,
      repeatable: true,
      dataType: 'composite',
      compositeStructure: {},
    },
    minRepeatable: section.urn === 'urn:pg:interview:section:contingent-beneficiaries' ? 0 : 1,
    maxRepeatable: 10,
    children: [],
  };

  const compositeStructure: any = {};

  section.dataPoint?.compositeStructure?.forEach((graphComposite: any) => {
    compositeStructure[graphComposite.key] = {
      sensitive: graphComposite.value.sensitive,
      dataType: graphComposite.value.dataType.toLowerCase(),
    };
  });

  repeatableSection.dataPoint.compositeStructure = compositeStructure;

  return repeatableSection;
};

const generateMortarNonRepeatableSection = (section: GraphModelNode) => {
  const nonrepeatableSection: Section = {
    nodeType: 'SECTION',
    urn: section.urn,
    content: section.content,
    children: [],
  };

  return nonrepeatableSection;
};

export const graphModelToMortarNodeSection = (section: GraphModelNode) => {
  const schema = section.dataPoint?.repeatable
    ? generateMortarRepeatableSection(section)
    : generateMortarNonRepeatableSection(section);

  if (section.children) {
    section.children.forEach((subChild) => {
      if (subChild.nodeType === 'QUESTION' || subChild.nodeType === 'TEXT') {
        const node = graphModelToMortarNodeTextOrQuestion(subChild);

        return node && schema.children.push(node);
      } else if (subChild.nodeType === 'SECTION') {
        const node = graphModelToMortarNodeSection(subChild);

        return node && schema.children.push(node);
      }
    });
  }

  if (SECTION_RULES_MAPPINGS[schema.urn]) {
    schema.rules = SECTION_RULES_MAPPINGS[schema.urn];
  }

  if (PRESENTATION_RULES_MAPPINGS[schema.urn]) {
    schema.presentationRules = PRESENTATION_RULES_MAPPINGS[schema.urn];
  }

  return schema;
};

export const formatSavedData = (savedDpids: MappedDpidData): { [key: string]: any } => {
  const formattedData: { [key: string]: any } = {};

  savedDpids?.dpids && parseNonRepeatableDpids(formattedData, savedDpids?.dpids);
  savedDpids?.repeatableDpids && parseRepeatableDpids(formattedData, savedDpids?.repeatableDpids);

  return formattedData;
};

const parseNonRepeatableDpids = (
  formattedData: { [key: string]: any },
  dpids: Maybe<DataPointIdMapping>[]
) => {
  if (dpids) {
    for (const dpid of dpids) {
      if (dpid?.dataPointIdUrn && dpid.dataType) {
        formattedData[dpid?.dataPointIdUrn] = getDpidValue(dpid);
      }
    }
  }
};

// //
const parseRepeatableDpids = (
  formattedRepeatableData: { [key: string]: any },
  data: Maybe<Maybe<RepeatableDataPointMapping>[]>
) => {
  if (!data) {
    return formattedRepeatableData;
  }
  data.forEach((dpid) => {
    if (!dpid) {
      return;
    }
    formattedRepeatableData[dpid.dataPointIdUrn] = [];
    const { values } = dpid;

    values.forEach((value) => {
      const formatted: { [key: string]: any } = {};

      value?.valueMap?.forEach((v) => {
        if (!v) {
          return;
        }
        formatted[v.shortUrn] = getDpidValue(v.value);
      });
      formattedRepeatableData[dpid.dataPointIdUrn].push(formatted);
    });
  });

  return formattedRepeatableData;
};

const getDpidValue = (dpid: DataPointIdMapping): any => {
  const valueType = dpid.dataType;

  switch (valueType) {
    case DataValueTypes.BoolValue:
      return dpid.boolValue;
    case DataValueTypes.DateValue:
      return `${dpid.dateValue?.year}-${dpid.dateValue?.month}-${dpid.dateValue?.day}`;
    case DataValueTypes.FloatValue:
      return dpid.floatValue;
    case DataValueTypes.IntValue:
      return dpid.intValue;
    case DataValueTypes.StringValue:
      return dpid.stringValue;
    default:
      console.error('unexpected data type returned');
  }
};
