import React from 'react';
import { MaskedTextbox } from '@policygenius/mortar';
import { Props } from './types';

const MaskedTextField: React.FC<Props> = ({
  input,
  id,
  label,
  showLabel,
  className,
  placeholder,
  defaultValue,
  type,
  meta,
  maskOptions,
  prefix,
}) => {
  const onChange: MaskedTextbox.Props['onChange'] = ({ unmasked }) => input.onChange(unmasked);

  return (
    <MaskedTextbox
      {...input}
      id={id}
      name={input.name}
      label={label}
      showLabel={showLabel}
      className={className}
      placeholder={placeholder}
      defaultValue={defaultValue}
      prefix={prefix}
      type={type}
      onChange={onChange}
      maskOptions={maskOptions}
      errorMessage={meta.touched && !meta.active ? meta.error : undefined}
    />
  );
};

export default MaskedTextField;
