import React from 'react';
import { maskMoney } from 'forms/masks';
import { BodyText, Col, Row, Tooltip, UtilityText } from '@policygenius/mortar';
import { usdFormat } from 'utils/helpers';
import {
  PRODUCT_TYPE_TOOLTIPS,
  ISSUE_TYPE_TOOLTIPS,
  PREMIUM_TOOLTIP,
  HEALTHCLASS_TOOLTIP,
} from '../constants';
import { PremiumMode } from 'graph/types';
import { PolicyInfoProps } from './types';
import { Capitalize, Empty, Item, Label, Status } from './styles';

export const PolicyInfo: React.FunctionComponent<PolicyInfoProps> = ({
  issueType,
  product,
  healthClass,
  coverageAmount,
  term,
  premium,
  premiumMode,
  tableRating,
}) => {
  return (
    <React.Fragment>
      <Row nested>
        <Col span={{ small: 2, medium: 6 }}>
          <Item>
            <Status>
              <Label size="small">Issue type</Label>
              {issueType && (
                <Tooltip left headerText={ISSUE_TYPE_TOOLTIPS[issueType].title}>
                  <BodyText>{ISSUE_TYPE_TOOLTIPS[issueType].body}</BodyText>
                </Tooltip>
              )}
            </Status>
            {issueType ? (
              <Capitalize size="small">{issueType.replaceAll('_', ' ').toLowerCase()}</Capitalize>
            ) : (
              <Empty />
            )}
          </Item>
        </Col>
        <Col span={{ small: 2, medium: 6 }}>
          <Item>
            <Status>
              <Label size="small">Product type</Label>
              {product && (
                <Tooltip headerText={PRODUCT_TYPE_TOOLTIPS[product].title}>
                  <BodyText>{PRODUCT_TYPE_TOOLTIPS[product].body}</BodyText>
                </Tooltip>
              )}
            </Status>
            {product ? <Capitalize size="small">{product.toLowerCase()}</Capitalize> : <Empty />}
          </Item>
        </Col>
        <Col span={{ small: 2, medium: 6 }}>
          <Item>
            <Status>
              <Label size="small">Coverage</Label>
            </Status>
            {coverageAmount ? (
              <UtilityText size="small">${maskMoney(coverageAmount)}</UtilityText>
            ) : (
              <Empty />
            )}
          </Item>
        </Col>
        <Col span={{ small: 2, medium: 6 }}>
          <Item>
            <Status>
              <Label size="small">Term</Label>
            </Status>
            {term ? <UtilityText size="small">{`${term} years`}</UtilityText> : <Empty />}
          </Item>
        </Col>
        <Col span={{ small: 2, medium: 6 }}>
          <Item>
            <Status>
              <Label size="small">Premium (est.)</Label>
              <Tooltip left headerText="Premium (est.)">
                <BodyText>{PREMIUM_TOOLTIP}</BodyText>
              </Tooltip>
            </Status>
            {premium ? (
              <UtilityText size="small">{usdFormat(premium!)} per year</UtilityText>
            ) : (
              <Empty />
            )}
          </Item>
        </Col>
        <Col span={{ small: 2, medium: 6 }}>
          <Item>
            <Status>
              <Label size="small">Premium mode</Label>
            </Status>
            {premiumMode && premiumMode !== PremiumMode.PremiumModeUnspecified ? (
              <Capitalize size="small">{premiumMode.toLowerCase()}</Capitalize>
            ) : (
              <Empty />
            )}
          </Item>
        </Col>
        <Col span={{ small: 2, medium: 6 }}>
          <Item>
            <Status>
              <Label size="small">Health class</Label>
              <Tooltip left headerText="Health class">
                <BodyText>{HEALTHCLASS_TOOLTIP}</BodyText>
              </Tooltip>
            </Status>
            {healthClass ? (
              <Capitalize size="small">
                {healthClass} (Table {tableRating})
              </Capitalize>
            ) : (
              <Empty />
            )}
          </Item>
        </Col>
      </Row>
    </React.Fragment>
  );
};
