import { OrderBy } from 'graph/types';

export const REFERRING_AGENT_SORT_OPTIONS = [
  {
    label: 'Created date (Newest first)',
    value: OrderBy.ReferralDateDesc,
    type: 'CreatedDate',
  },
  {
    label: 'Created date (Oldest first)',
    value: OrderBy.ReferralDate,
    type: 'CreatedDate',
  },
  {
    label: 'Client (A-Z)',
    value: OrderBy.Client,
    type: 'Client',
  },
  {
    label: 'Client (Z-A)',
    value: OrderBy.ClientDesc,
    type: 'Client',
  },
  {
    label: 'Premium (Lowest first)',
    value: OrderBy.PremiumAsc,
    type: 'Premium',
  },
  {
    label: 'Premium (Highest first)',
    value: OrderBy.PremiumDesc,
    type: 'Premium',
  },
  {
    label: 'Last updated (Newest first)',
    value: OrderBy.LastUpdatedDate,
    type: 'LastUpdated',
  },
  {
    label: 'Last updated (Oldest first)',
    value: OrderBy.LastUpdatedDateAsc,
    type: 'LastUpdated',
  },
];

export const ALL_SORT_OPTIONS = [
  ...REFERRING_AGENT_SORT_OPTIONS,
  {
    label: 'Organization (A-Z)',
    value: OrderBy.AgencyName,
    type: 'Organization',
  },
  {
    label: 'Organization (Z-A)',
    value: OrderBy.AgencyNameDesc,
    type: 'Organization',
  },
  {
    label: 'Advisor (A-Z)',
    value: OrderBy.AgentName,
    type: 'Advisor',
  },
  {
    label: 'Advisor (Z-A)',
    value: OrderBy.AgentNameDesc,
    type: 'Advisor',
  },
];

export const BASE_SORT_OPTIONS = [
  {
    label: 'Created date (Newest first)',
    value: OrderBy.ReferralDateDesc,
    type: 'CreatedDate',
  },
  {
    label: 'Created date (Oldest first)',
    value: OrderBy.ReferralDate,
    type: 'CreatedDate',
  },
  {
    label: 'Client (A-Z)',
    value: OrderBy.Client,
    type: 'Client',
  },
  {
    label: 'Client (Z-A)',
    value: OrderBy.ClientDesc,
    type: 'Client',
  },
  {
    label: 'Advisor (A-Z)',
    value: OrderBy.AgentName,
    type: 'Advisor',
  },
  {
    label: 'Advisor (Z-A)',
    value: OrderBy.AgentNameDesc,
    type: 'Advisor',
  },
  {
    label: 'Stage',
    value: OrderBy.Stage,
    type: 'Stage',
  },
  {
    label: 'Stage desc',
    value: OrderBy.StageDesc,
    type: 'Stage',
  },
  {
    label: 'Premium (Lowest first)',
    value: OrderBy.PremiumAsc,
    type: 'Premium',
  },
  {
    label: 'Premium (Highest first)',
    value: OrderBy.PremiumDesc,
    type: 'Premium',
  },
  {
    label: 'Last updated (Newest first)',
    value: OrderBy.LastUpdatedDate,
    type: 'LastUpdated',
  },
  {
    label: 'Last updated (Oldest first)',
    value: OrderBy.LastUpdatedDateAsc,
    type: 'LastUpdated',
  },
];

export const NEW_ALL_SORT_OPTIONS = [
  {
    label: 'Created date (Newest first)',
    value: OrderBy.ReferralDateDesc,
    type: 'CreatedDate',
  },
  {
    label: 'Created date (Oldest first)',
    value: OrderBy.ReferralDate,
    type: 'CreatedDate',
  },
  {
    label: 'Client (A-Z)',
    value: OrderBy.Client,
    type: 'Client',
  },
  {
    label: 'Client (Z-A)',
    value: OrderBy.ClientDesc,
    type: 'Client',
  },
  {
    label: 'Organization (A-Z)',
    value: OrderBy.AgencyName,
    type: 'Organization',
  },
  {
    label: 'Organization (Z-A)',
    value: OrderBy.AgencyNameDesc,
    type: 'Organization',
  },
  {
    label: 'Advisor (A-Z)',
    value: OrderBy.AgentName,
    type: 'Advisor',
  },
  {
    label: 'Advisor (Z-A)',
    value: OrderBy.AgentNameDesc,
    type: 'Advisor',
  },
  {
    label: 'Stage (First to last)',
    value: OrderBy.Stage,
    type: 'Stage',
  },
  {
    label: 'Stage (Last to first)',
    value: OrderBy.StageDesc,
    type: 'Stage',
  },
  {
    label: 'Premium (Lowest first)',
    value: OrderBy.PremiumAsc,
    type: 'Premium',
  },
  {
    label: 'Premium (Highest first)',
    value: OrderBy.PremiumDesc,
    type: 'Premium',
  },
  {
    label: 'Last updated (Newest first)',
    value: OrderBy.LastUpdatedDate,
    type: 'LastUpdated',
  },
  {
    label: 'Last updated (Oldest first)',
    value: OrderBy.LastUpdatedDateAsc,
    type: 'LastUpdated',
  },
];

export enum SortType {
  Advisor = 'ADVISOR',
  Client = 'CLIENT',
  Organization = 'AGENCY',
  Premium = 'PREMIUM',
  CreatedDate = 'CREATED_DATE',
  LastUpdated = 'LAST_UPDATED',
  Stage = 'STAGE',
}

export enum SortDirection {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
}

type Sort_Map_Type = {
  id: OrderBy;
  sortType: SortType;
  orderDirection: SortDirection;
};

export const SORT_MAP: Sort_Map_Type[] = [
  {
    id: OrderBy.AgentName,
    sortType: SortType.Advisor,
    orderDirection: SortDirection.Ascending,
  },
  {
    id: OrderBy.AgentNameDesc,
    sortType: SortType.Advisor,
    orderDirection: SortDirection.Descending,
  },
  {
    id: OrderBy.AgencyName,
    sortType: SortType.Organization,
    orderDirection: SortDirection.Ascending,
  },
  {
    id: OrderBy.AgencyNameDesc,
    sortType: SortType.Organization,
    orderDirection: SortDirection.Descending,
  },
  {
    id: OrderBy.Client,
    sortType: SortType.Client,
    orderDirection: SortDirection.Ascending,
  },
  {
    id: OrderBy.ClientDesc,
    sortType: SortType.Client,
    orderDirection: SortDirection.Descending,
  },
  {
    id: OrderBy.LastUpdatedDateAsc,
    sortType: SortType.LastUpdated,
    orderDirection: SortDirection.Ascending,
  },
  {
    id: OrderBy.LastUpdatedDate,
    sortType: SortType.LastUpdated,
    orderDirection: SortDirection.Descending,
  },
  {
    id: OrderBy.PremiumAsc,
    sortType: SortType.Premium,
    orderDirection: SortDirection.Ascending,
  },
  {
    id: OrderBy.PremiumDesc,
    sortType: SortType.Premium,
    orderDirection: SortDirection.Descending,
  },
  {
    id: OrderBy.ReferralDate,
    sortType: SortType.CreatedDate,
    orderDirection: SortDirection.Ascending,
  },
  {
    id: OrderBy.ReferralDateDesc,
    sortType: SortType.CreatedDate,
    orderDirection: SortDirection.Descending,
  },
  {
    id: OrderBy.Stage,
    sortType: SortType.Stage,
    orderDirection: SortDirection.Ascending,
  },
  {
    id: OrderBy.StageDesc,
    sortType: SortType.Stage,
    orderDirection: SortDirection.Descending,
  },
];

export const STATUS_FILTER_OPTIONS = [
  {
    label: 'Open',
    value: 'open',
    helpText: 'Pending & approved',
    checked: false,
  },
  {
    label: 'Cold',
    value: 'cold',
    helpText: 'Unresponsive clients',
    checked: false,
  },
  {
    label: 'Closed',
    value: 'closed',
    helpText: 'Postpones, declines, and withdrawals',
    checked: false,
  },
  {
    label: 'In force',
    value: 'inForce',
    helpText: 'Active coverage',
    checked: false,
  },
  {
    label: 'Lapsed',
    value: 'lapsed',
    helpText: 'Policy lapsed due to missed payments',
    checked: false,
  },
  {
    label: 'Canceled',
    value: 'canceled',
    helpText: 'Policy canceled by the client',
    checked: false,
  },
];
